import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type EventFundraisingProps = IconProps;

export const EventFundraising: FC<EventFundraisingProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6667 7.50008H9.58335C8.893 7.50008 8.33335 8.05972 8.33335 8.75008C8.33335 9.44044 8.893 10.0001 9.58335 10.0001H10.4167C11.107 10.0001 11.6667 10.5597 11.6667 11.2501C11.6667 11.9404 11.107 12.5001 10.4167 12.5001H8.33335M10 6.66675V7.50008M10 12.5001V13.3334M15 10.0001H15.0084M5.00002 10.0001H5.00835M1.66669 6.83341L1.66669 13.1667C1.66669 14.1002 1.66669 14.5669 1.84834 14.9234C2.00813 15.237 2.2631 15.492 2.5767 15.6518C2.93322 15.8334 3.39993 15.8334 4.33335 15.8334L15.6667 15.8334C16.6001 15.8334 17.0668 15.8334 17.4233 15.6518C17.7369 15.492 17.9919 15.237 18.1517 14.9234C18.3334 14.5669 18.3334 14.1002 18.3334 13.1667V6.83342C18.3334 5.89999 18.3334 5.43328 18.1517 5.07677C17.9919 4.76316 17.7369 4.50819 17.4233 4.34841C17.0668 4.16675 16.6001 4.16675 15.6667 4.16675L4.33335 4.16675C3.39993 4.16675 2.93322 4.16675 2.5767 4.3484C2.2631 4.50819 2.00813 4.76316 1.84834 5.07676C1.66669 5.43328 1.66669 5.89999 1.66669 6.83341ZM15.4167 10.0001C15.4167 10.2302 15.2301 10.4167 15 10.4167C14.7699 10.4167 14.5834 10.2302 14.5834 10.0001C14.5834 9.76996 14.7699 9.58341 15 9.58341C15.2301 9.58341 15.4167 9.76996 15.4167 10.0001ZM5.41669 10.0001C5.41669 10.2302 5.23014 10.4167 5.00002 10.4167C4.7699 10.4167 4.58335 10.2302 4.58335 10.0001C4.58335 9.76996 4.7699 9.58341 5.00002 9.58341C5.23014 9.58341 5.41669 9.76996 5.41669 10.0001Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);
