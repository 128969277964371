import React, { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import {
  CircleCheckIcon,
  CloudUploadIcon,
  DownloadIcon,
  ImportIcon,
  InfoCircle,
  UploadIcon,
} from '../../../../../assets/icons';
import { AppFormattedMessage } from '../../../../../components/AppFormattedMessage';
import Button from '../../../../../components/Button';
import { CloseModalButton } from '../../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../../components/Sheet';
import { BackendRoute } from '../../../../../config';
import { QueryKey } from '../../../../../constants';
import { Stakeholder, useAppMutation, useLocale, useReactForm } from '../../../../../hooks';
import { Locale, StringKey } from '../../../../../lang';
import { ApiService } from '../../../../../services';
import { accessTokenStorage } from '../../../../../storage/accessTokenStorage';
import { StakeholderTypeGroup } from '../../../../../types/stakeholderTypes';
import StakeholderTypeGroupCombobox from '../StakeholderTypeCombobox';
import { FormSchema, formSchema } from './Validation';

export type BulkModifyStakeholdersModalProps = {
  isOpenModal: boolean;
  setOpen: (state: boolean) => void;
  companyId: string;
  invalidateQuery: () => void;
};

const BulkModifyStakeholdersModal: FC<BulkModifyStakeholdersModalProps> = ({
  companyId,
  invalidateQuery,
  isOpenModal,
  setOpen,
}) => {
  const { messagesLocale } = useLocale();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    getFieldState,
    formState: { isValid, errors, dirtyFields },
  } = useReactForm({ schema: formSchema(messagesLocale) });

  const { downloadStakeholderInfo } = Stakeholder.useStakeholderInfo();
  const { locale } = useLocale();

  const { mutate } = useAppMutation([QueryKey.STAKEHOLDERS_BULK_IMPORT, companyId], {
    mutationFn: async ({ file, typeGroup }: { typeGroup: StakeholderTypeGroup; file: File }) => {
      const fileData = new FormData();
      await ApiService.refreshTokens();
      const accessToken = accessTokenStorage.get();
      fileData.append('file', file);
      return fetch(
        `${process.env.REACT_APP_BACKEND_URL}${BackendRoute.COMPANIES}/${companyId}/stakeholders/${typeGroup}/modify`,
        {
          body: fileData,
          method: 'POST',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
    },
    onSuccess: async (response) => {
      setOpen(false);
      reset();
      invalidateQuery();

      if (response.ok) return toast.success('Stakeholders successfully imported');

      const body = await response.json();

      if (body?.message) {
        return toast.error(body?.message);
      }

      const row: number = body?.row;
      const errors: {
        property: string;
        constrains: { [key: string]: string };
        value: string;
      }[] = body?.errors;

      const firstError = errors?.[0];
      const firstConstraintKey = Object.keys(firstError?.constrains)[0];

      return toast.error(
        `Error at row ${row}: ${firstError?.property} violates constraint "${firstError?.constrains?.[firstConstraintKey]}"`,
      );
    },
  });

  const handleCloseModal = useCallback(() => {
    reset();
    setOpen(false);
  }, [reset, setOpen]);

  const submitHandler = useCallback(
    (data: FormSchema) => {
      mutate({
        file: data.file,
        typeGroup: data.stakeholderTypeGroup,
      });
    },
    [mutate],
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setValue('file', acceptedFiles[0], { shouldValidate: true });
    },
    [setValue],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  });

  const isValidFileState = getValues('file') && !getFieldState('file').invalid;
  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-fit border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <div className="flex h-full w-full flex-col overflow-hidden overflow-y-auto rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-semibold text-gray-700">
              <AppFormattedMessage id={StringKey.BULK_MODIFY_STAKEHOLDERS} />
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <form className="flex h-full flex-col gap-4" onSubmit={handleSubmit(submitHandler)}>
            <div className="flex h-full flex-col gap-6 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
              <StakeholderTypeGroupCombobox control={control} name="stakeholderTypeGroup" />
              <div className="flex h-11 w-full justify-between gap-4 text-nowrap">
                <Button
                  className="flex w-fit items-center gap-4 rounded border border-gray-100 px-3 py-[6px] shadow-xs"
                  disabled={!dirtyFields.stakeholderTypeGroup}
                  onClick={() =>
                    downloadStakeholderInfo({
                      companyId,
                      typeGroup: getValues('stakeholderTypeGroup'),
                    })
                  }
                  styleType="NONE"
                  type="button"
                >
                  <DownloadIcon
                    iconColor={!dirtyFields.stakeholderTypeGroup ? '#D0D5DD' : '#344054'}
                  />
                  <p
                    className={twMerge(
                      'text-sm font-[450] text-gray-700',
                      !dirtyFields.stakeholderTypeGroup && 'text-gray-300',
                    )}
                  >
                    <AppFormattedMessage id={StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART1} />{' '}
                    <span
                      className={twMerge(
                        'max-xs:hidden',
                        !dirtyFields.stakeholderTypeGroup && 'text-gray-300',
                      )}
                    >
                      <AppFormattedMessage id={StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART2} />
                    </span>{' '}
                    <AppFormattedMessage id={StringKey.DOWNLOAD_STAKEHOLDER_INFO_PART3} />
                  </p>
                </Button>
                <Button
                  className="pointer-events-none flex w-fit items-center gap-2"
                  styleType="NONE"
                >
                  <p className="text-sm font-[450] text-brand-700 underline">
                    {locale === Locale.FR ? (
                      <AppFormattedMessage id={StringKey.VIEW_MODIFICATION_GUID} />
                    ) : (
                      <>
                        View <span className="max-[520px]:hidden">modification</span> guide
                      </>
                    )}
                  </p>
                  <ImportIcon />
                </Button>
              </div>
              <input {...getInputProps()} className="hidden" type="file" />
              <label
                {...getRootProps()}
                className="relative flex w-full cursor-pointer flex-col items-center gap-6 rounded-lg border border-dashed border-gray-200 py-6"
              >
                {isValidFileState ? (
                  <CircleCheckIcon />
                ) : (
                  <>
                    {errors.file ? (
                      <InfoCircle className="size-14" thinIconVariant />
                    ) : (
                      <CloudUploadIcon />
                    )}
                  </>
                )}
                <div className="flex flex-col items-center gap-2 text-center">
                  <div className="flex justify-center">
                    {isValidFileState ? (
                      <span className="text-sm font-[550] text-forest-500">
                        <AppFormattedMessage id={StringKey.FILE_PROCESSED_SUCCESSFULLY} />
                      </span>
                    ) : errors.file ? (
                      <span className="text-sm font-[550] text-fireside-600">
                        {errors.file.message?.toString()}
                      </span>
                    ) : (
                      <p className="text-sm font-medium text-gray-700">
                        <span className="underline">
                          <AppFormattedMessage id={StringKey.CLICK_TO_UPLOAD} />{' '}
                        </span>
                        <AppFormattedMessage id={StringKey.OR_DRAG_AND_DROP} />
                      </p>
                    )}
                  </div>

                  <div className="flex flex-col gap-1 text-[10px] font-[450] leading-4 text-gray-500">
                    {isValidFileState ? (
                      <>
                        <span>{(getValues('file') as unknown as File).name}</span>
                        <span>
                          {((getValues('file') as unknown as File).size / 1024).toFixed(3)}
                          <AppFormattedMessage id={StringKey.KILOBYTE} />
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          <AppFormattedMessage
                            id={StringKey.SUPPORTED_FORMATS}
                            values={{
                              formats: 'xlsx',
                            }}
                          />
                        </span>
                        <span>
                          <AppFormattedMessage
                            id={StringKey.MAXIMUM_FILE_SIZE}
                            values={{ size: 10 }}
                          />
                        </span>
                      </>
                    )}
                  </div>
                  {errors.file && (
                    <span className="flex h-9 w-fit items-center gap-1 rounded border border-gray-100 bg-gray-25 px-3 py-[6px] text-sm font-[450] text-gray-700">
                      <UploadIcon />
                      <AppFormattedMessage id={StringKey.UPLOAD_AGAIN} />
                    </span>
                  )}
                </div>
              </label>
              {isValidFileState && !getValues('stakeholderTypeGroup') && (
                <span className="flex items-center gap-1 text-xs font-[450] text-fireside-600">
                  <InfoCircle />
                  Please select Stakeholder Type from the dropdown before saving upload
                </span>
              )}
            </div>

            <div className="flex h-9 w-full justify-end gap-3 px-6">
              <Button
                className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700"
                onClick={() => setOpen(false)}
                styleType="NONE"
                type="button"
              >
                <AppFormattedMessage id={StringKey.CLOSE} />
              </Button>
              <Button
                className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
                disabled={!isValid}
                type="submit"
              >
                <AppFormattedMessage id={StringKey.SAVE} />
              </Button>
            </div>
          </form>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default BulkModifyStakeholdersModal;
