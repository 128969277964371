import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDeactivate = () => {
  const { mutate: deactivate, isPending } = useAppMutation([QueryKey.DEACTIVATE_COMPANY], {
    mutationFn: ({ companyId }: { companyId: string }) =>
      ApiService.patch({ endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'deactivate'] }),
  });

  return { deactivate, isPending };
};
