import React, { FC, useRef, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, SearchIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { QueryKey } from '../../../constants';
import { useAppQuery } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ApiCountryInfoService, RequestType, ResponseDataType } from '../../../services';
import { useFilteredData } from '../CompanySettings';
import { FormSchema } from './Validation';

export type CityOfStateItemsListProps = {
  data: ResponseDataType<RequestType.GET_CITIES_BY_COUNTRY_AND_STATE>;
  control: Control<FormSchema>;
  selectedCountry: Partial<FormSchema['country']> | undefined;
  selectedState: FormSchema['state'] | undefined;
  isLoading: boolean;
  isDataEmpty: boolean;
};

const CityOfStateItemsList: FC<CityOfStateItemsListProps> = ({
  data,
  control,
  selectedCountry,
  selectedState,
  isDataEmpty,
  isLoading,
}) => {
  if (!data || !selectedState || !selectedCountry || isLoading) return <span>Loading ...</span>;

  if (isDataEmpty)
    return (
      <FormComboboxOption
        control={control}
        key={`${selectedState.name || selectedCountry.name}_${selectedState.iso2Code || selectedCountry.iso2Code}`}
        name="city"
        value={selectedState.name ?? selectedCountry.name ?? ''}
      >
        <span className="text-sm text-gray-700">{selectedState.name || selectedCountry.name}</span>
      </FormComboboxOption>
    );

  return (
    <>
      {data.map(({ name, id }) => (
        <FormComboboxOption control={control} key={`${id}_${name}`} name="city" value={name}>
          <span className="text-sm text-gray-700">{name}</span>
        </FormComboboxOption>
      ))}
    </>
  );
};

export type CityOfStateComboboxProps = {
  control: Control<FormSchema>;
  selectedState: FormSchema['state'] | undefined;
  selectedCountry: Partial<FormSchema['country']> | undefined;
};

const CityOfStateCombobox: FC<CityOfStateComboboxProps> = ({
  control,
  selectedCountry,
  selectedState,
}) => {
  const [search, setSearch] = useState('');
  const { formState } = useController({
    name: 'city',
    control,
  });

  const { data: cityData, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_CITY, selectedState?.name, { inputValue: search }],
    queryFn: async () => {
      if (!selectedCountry || !selectedState) return;
      return ApiCountryInfoService({
        countryCode: selectedCountry.iso2Code,
        stateCode: selectedState.iso2Code,
        requestType: RequestType.GET_CITIES_BY_COUNTRY_AND_STATE,
      });
    },
    enabled: !!selectedState?.iso2Code && !!selectedCountry?.iso2Code,
  });

  const regExp = new RegExp(search, 'gi');
  const cityFilteredData = useFilteredData({
    data: cityData,
    inputValue: search,
    filterFunc: ({ name }) => regExp.test(name),
  }).sort((a, b) => a.name.localeCompare(b.name));

  const ref = useRef<HTMLInputElement>(null);

  return (
    <FormCombobox
      className="relative w-full"
      control={control}
      disabled={!selectedCountry?.iso2Code || !selectedState?.iso2Code}
      name={'city'}
      onClose={() => setSearch('')}
      onSelect={() => setSearch('')}
    >
      <div className="relative">
        <FormComboboxInput
          className={'bg-gray-900'}
          control={control}
          customValue={(value) => value}
          icon={<ChevronDownIcon className={twMerge('mt-3', formState.disabled && 'hidden')} />}
          name={'city'}
          placeholder={<AppFormattedMessage id={StringKey.CITY} />}
          readOnly
          wrapperClassName={formState.disabled && 'border-none'}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <div className="relative flex items-center" onClick={() => ref.current?.focus()}>
          <SearchIcon className="absolute ml-[6px]" />
          <input
            className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
            onChange={({ target: { value } }) => setSearch(value)}
            ref={ref}
            value={search}
          />
        </div>
        <CityOfStateItemsList
          control={control}
          data={cityFilteredData}
          isDataEmpty={cityData?.length === 0}
          isLoading={isLoading}
          selectedCountry={selectedCountry}
          selectedState={selectedState}
        />
      </ComboboxOptions>
    </FormCombobox>
  );
};

export default CityOfStateCombobox;
