import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

type BuildingIconProps = IconProps;

const BuildingIcon: FC<BuildingIconProps> = ({ iconColor = '#2565C8', className, ...props }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8332 9.16667H14.8332C15.7666 9.16667 16.2333 9.16667 16.5898 9.34832C16.9034 9.50811 17.1584 9.76308 17.3182 10.0767C17.4998 10.4332 17.4998 10.8999 17.4998 11.8333V17.5M10.8332 17.5V5.16667C10.8332 4.23325 10.8332 3.76654 10.6515 3.41002C10.4917 3.09641 10.2368 2.84144 9.92315 2.68166C9.56664 2.5 9.09992 2.5 8.1665 2.5H5.1665C4.23308 2.5 3.76637 2.5 3.40985 2.68166C3.09625 2.84144 2.84128 3.09641 2.68149 3.41002C2.49984 3.76654 2.49984 4.23325 2.49984 5.16667V17.5M18.3332 17.5H1.6665M5.4165 5.83333H7.9165M5.4165 9.16667H7.9165M5.4165 12.5H7.9165"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default BuildingIcon;
