import React from 'react';

import { StringKey } from '../../lang';
import { AppFormattedMessage } from '../AppFormattedMessage';
import LineDelimiter from '../LineDelimiter';

const OrDelimiter = () => {
  return (
    <div className="flex items-center gap-4">
      <LineDelimiter />
      <div className="select-none font-inter text-label-md font-[550] text-gray-300">
        <AppFormattedMessage id={StringKey.OR} />
      </div>
      <LineDelimiter />
    </div>
  );
};

export default OrDelimiter;
