import React, { FC, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { DoubleChevronIcon, MessageIcon } from '../../assets/icons';
import { StringKey } from '../../lang';
import { PageRoute, replaceIdWithPath } from '../../types/pageTypes';
import { AppFormattedMessage } from '../AppFormattedMessage';
import Header from '../Header';

interface HeaderLayoutProps {
  children: ReactNode;
  className?: string;
}

const HeaderLayout: FC<HeaderLayoutProps> = ({ children, className }) => {
  const { pathname } = useLocation();
  return (
    <div className="flex h-full w-full flex-col gap-3 overflow-y-auto bg-gray-50 lg:pb-4 lg:pr-6">
      <Header />
      <div className="h-full w-full overflow-y-auto max-lg:px-4" id="scrollbar-target">
        <div
          className={twMerge(
            'flex h-fit w-full justify-between gap-4 overflow-y-auto bg-white px-3 py-4 shadow-sm max-lg:mb-4 max-lg:mt-[1px] max-lg:rounded-md max-xs:rounded-b-[6px] max-xs:rounded-t-[6px] max-xs:shadow-sm lg:h-full lg:rounded-t-[24px] lg:shadow-none',
            className,
          )}
        >
          {children}
          {replaceIdWithPath(pathname) !== PageRoute.COMPANY_SETUP &&
            replaceIdWithPath(pathname) !== PageRoute.BILLING && (
              <div className="sticky top-0 flex h-full w-14 shrink-0 flex-col items-center justify-start gap-6 rounded-md bg-white pt-4 shadow-sm max-lg:hidden">
                <div className="flex flex-col items-center gap-2">
                  <div className="relative h-fit w-fit">
                    <MessageIcon />
                    <span
                      className="absolute -right-1 -top-1 flex size-4 shrink-0 items-center justify-center rounded-full bg-fireside-600 text-white"
                      style={{
                        fontSize: '9px',
                        fontWeight: '600',
                        lineHeight: '11.2px',
                        textAlign: 'center',
                      }}
                    >
                      1
                    </span>
                  </div>
                  <span className="text-label-md font-medium text-gray-700">
                    <AppFormattedMessage id={StringKey.ACTIVITY} />
                  </span>
                </div>
                <DoubleChevronIcon iconColor="#344054" />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default HeaderLayout;
