import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type ExportIconProps = IconProps;

const ExportIcon: FC<ExportIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="18"
    viewBox="0 0 17 18"
    width="17"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6666 9.4165V4.6665C14.6666 3.26637 14.6666 2.56631 14.3942 2.03153C14.1545 1.56112 13.772 1.17867 13.3016 0.938988C12.7668 0.666504 12.0668 0.666504 10.6666 0.666504H5.33331C3.93318 0.666504 3.23312 0.666504 2.69834 0.938988C2.22793 1.17867 1.84548 1.56112 1.6058 2.03153C1.33331 2.56631 1.33331 3.26637 1.33331 4.6665V13.3332C1.33331 14.7333 1.33331 15.4334 1.6058 15.9681C1.84548 16.4386 2.22793 16.821 2.69834 17.0607C3.23312 17.3332 3.93314 17.3332 5.33319 17.3332H8.41665M9.66665 8.1665H4.66665M6.33331 11.4998H4.66665M11.3333 4.83317H4.66665M10.5 14.8332L13 17.3332M13 17.3332L15.5 14.8332M13 17.3332V12.3332"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

ExportIcon.displayName = 'ExportIcon';

export default ExportIcon;
