import { FC, useState } from 'react';

import { AddDocumentIcon } from '../../../../assets/icons';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../components/HoverCard';
import { PaginationController } from '../../../../components/PaginationController';
import { EventHooks, useFormat } from '../../../../hooks';
import {
  ConversionEventItem,
  eventFormType,
  EventTypeBackend,
} from '../../../../types/events.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Conversion: FC<CardProps> = ({ event, companyId, openFileUploadModal }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { format } = useFormat();
  const { items, totalPages } = EventHooks.useTransactions<ConversionEventItem>({
    eventId: event.id,
    eventType: EventTypeBackend.CONVERSION,
    companyId: companyId,
    currentPage,
  });

  if (event.type !== EventTypeBackend.CONVERSION) return <></>;

  const { type, filesLinks } = event;

  return (
    <>
      {items.map(({ date, fromShareClass, stakeholder, toShareClass, sharesCount }, i) => (
        <div
          className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm"
          key={i + stakeholder.id}
        >
          <TransactionTitleItem
            createdAt={format(date, 'dd/MM/yyyy')}
            name={stakeholder?.fullName}
          />
          <TransactionOverviewItem title={'Transaction Type'} value={eventFormType[type]} />
          <TransactionOverviewItem title={'From Share Class'} value={fromShareClass?.name} />
          <TransactionOverviewItem title={'To Share Class'} value={toShareClass?.name} />
          <TransactionOverviewItem
            title={'No of Shares'}
            value={sharesCount?.toLocaleString('en-US')}
          />
          {filesLinks.length > 0 && (
            <TransactionOverviewItem
              title="Documents"
              value={
                <HoverCard>
                  <HoverCardTrigger onClick={() => openFileUploadModal(filesLinks)}>
                    <AddDocumentIcon className="cursor-pointer" />
                  </HoverCardTrigger>

                  <HoverCardContent className="max-w-[360px] rounded-lg bg-[#101828] px-3 py-2">
                    <span className="block w-full truncate p-0 text-label-md font-[550] text-white">
                      {filesLinks.length === 1
                        ? getS3FileOriginalName(filesLinks[0])
                        : `Download ${filesLinks.length} files`}
                    </span>
                  </HoverCardContent>
                </HoverCard>
              }
            />
          )}
        </div>
      ))}

      <PaginationController
        className="mt-6 shrink-0"
        currentPage={currentPage}
        onClick={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};
