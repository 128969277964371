import { z } from 'zod';

import { StringKey } from '../../../../../lang';
import { StakeholderTypeGroup } from '../../../../../types/stakeholderTypes';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const fileSchemaMessage = 'File size is too large';

const fileSchema = (t: Record<StringKey, string>) =>
  z
    .custom<File>()
    .refine(
      (files) => ACCEPTED_FILE_MIME_TYPES.includes(files?.type),
      t[StringKey.FILE_FORMAT_NOT_SUPPORTED],
    )
    .refine((files) => {
      return files?.size <= MAX_FILE_SIZE;
    }, fileSchemaMessage);

export const formSchema = (t: Record<StringKey, string>) =>
  z.object({
    file: fileSchema(t),
    stakeholderTypeGroup: z.nativeEnum(StakeholderTypeGroup),
  });

export type FormSchema = z.infer<ReturnType<typeof formSchema>>;
