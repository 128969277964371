import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PatchSharePlanDto } from '../../types/pool-plans.types';
import { useAppMutation } from '../useAppMutation';

export const useUpdate = () => {
  const { mutate: update, isPending } = useAppMutation([QueryKey.PATCH_SHARE_PLAN], {
    mutationFn: ({
      companyId,
      data,
      planId,
    }: {
      data: PatchSharePlanDto;
      companyId: string;
      planId: string;
    }) =>
      ApiService.patch(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'share-plans', planId] },
        {
          body: data,
        },
      ),
  });
  return { update, isPending };
};
