import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { ShareClass, useReactForm } from '../../../../hooks';
import { DaysPerYearOption } from '../../../../types/share-classes.types';
import { StepFour, StepOne, StepThree, StepTwo } from '../Steps';
import { FinalStep } from '../Steps/FinalStep';
import { ChildrenFormProps } from '../type';
import { FormSchema, formSchema } from '../Validation';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
};

const formSteps: ((props: ChildrenFormProps) => JSX.Element)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <StepThree {...props} />,
  (props) => <StepFour {...props} />,
  (props) => <FinalStep {...props} />,
];

export const AddShareClassModal: FC<ModalProps> = ({
  companyId,
  isOpenModal,
  handleClose,
  invalidateQuery,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    setError,
    formState: { errors },
    clearErrors,
  } = useReactForm({
    schema: formSchema,
    mode: 'onChange',
    defaultValues: {
      stepOne: {
        dividendRightEnabled: true,
        votingRight: {
          enabled: true,
          value: 1,
        },
      },
    },
  });
  const handleCloseModal = useCallback(() => {
    reset();
    handleClose();
    setCurrentStep(1);
  }, [reset, handleClose]);

  const { create } = ShareClass.useCreate();

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepFour, stepThree, stepTwo } = data;

      const participating = stepTwo?.liquidityPreferences?.participating
        ? stepTwo?.liquidityPreferences?.participating.daysPersYear
          ? {
              capValue: stepTwo?.liquidityPreferences?.participating?.capValue,
              interest: stepTwo?.liquidityPreferences?.participating?.interest,
              daysPersYear: Number(
                stepTwo?.liquidityPreferences?.participating?.daysPersYear,
              ) as unknown as DaysPerYearOption,
            }
          : { capValue: stepTwo?.liquidityPreferences?.participating?.capValue }
        : undefined;

      const liquidityPreferences = stepTwo?.liquidityPreferences
        ? {
            multiple: stepTwo?.liquidityPreferences?.multiple,
            seniority: stepTwo?.liquidityPreferences?.seniority,
            participating,
          }
        : undefined;

      const antiDilutionRights = stepThree?.antiDilutionRights
        ? {
            base: stepThree?.antiDilutionRights?.base,
          }
        : undefined;
      create(
        {
          companyId,
          data: {
            name: stepOne?.name,
            creationDate: new Date(stepOne.creationDate).toISOString(),
            votingRightEnabled: stepOne?.votingRight?.enabled || false,
            votingRight: stepOne?.votingRight?.value || 0,
            conversionRatioEnabled: stepOne?.conversionRatio?.enabled || false,
            conversionRatio: stepOne?.conversionRatio?.value || 0,
            dividendRightEnabled: stepOne?.dividendRightEnabled,
            liquidityPreferences,
            antiDilutionRights,
            docLink: stepFour?.docLink,
          },
        },
        {
          onSuccess: () => {
            toast.success('Share class successfully created');
            handleCloseModal();
            invalidateQuery();
          },
        },
      );
    },
    [companyId, create, handleCloseModal, invalidateQuery],
  );

  const handleNextStep = useCallback(() => {
    const nextStep = currentStep + 1;
    if (nextStep === Object.keys(formSteps).length + 1) {
      handleSubmit(submitHandler)();
      return;
    }
    if (nextStep > Object.keys(formSteps).length) return;

    setCurrentStep(nextStep);
  }, [currentStep, handleSubmit, submitHandler]);

  const handlePrevStep = useCallback(() => {
    const prevStep = currentStep - 1;
    if (prevStep < 1) return;
    setCurrentStep(prevStep);
  }, [currentStep]);

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-[550] text-gray-700">Add Share Class</span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex w-full items-center gap-[5px] bg-white px-6 pb-6">
            {formSteps.map((_, i) => (
              <div
                className={twMerge(
                  'h-1 w-full rounded-[25px]',
                  i + 1 <= currentStep ? 'bg-[#12B76A]' : 'bg-gray-100',
                  i + 1 === currentStep &&
                    currentStep === formSteps.length &&
                    'bg-gradient-to-r from-forest-500 to-forest-300',
                )}
                key={`${i}_${companyId}`}
              />
            ))}
          </div>

          {formSteps[currentStep - 1]({
            companyId,
            clearErrors,
            formData: getValues,
            nextFormStep: handleNextStep,
            prevFormStep: handlePrevStep,
            setFormData: setValue,
            control,
            setError,
            handleCloseModal,
            filedState: getFieldState,
            errors,
          })}
        </div>
      </SheetContent>
    </Sheet>
  );
};
