import React, { FC } from 'react';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { StringKey } from '../../lang';

export type DeleteAccountModalProps = {
  onClose: () => void;
  onSuccess: () => void;
};

const DeleteAccountModal: FC<DeleteAccountModalProps> = ({ onClose, onSuccess }) => (
  <div className="flex w-full max-w-[450px] flex-col rounded bg-white p-4 text-xs font-normal text-gray-500 shadow-xl">
    <span className="text-xl font-semibold text-gray-700">
      <AppFormattedMessage id={StringKey.DELETE_ACCOUNT} />?
    </span>
    <span>
      <AppFormattedMessage id={StringKey.ARE_YOU_SURE_YOU_WANT_DELETE_ACCOUNT} />
    </span>
    <span className="mt-4 line-clamp-2 text-wrap">
      <AppFormattedMessage id={StringKey.NOTE_THAT_YOUR_INFORMATION_STILL_SAVED} />
    </span>
    <div className="mt-8 flex justify-end gap-2">
      <Button
        className="rounded border-[1px] border-gray-300 text-sm text-gray-700"
        onClick={onClose}
        styleType="DEFAULT_ROUNDED"
      >
        <AppFormattedMessage id={StringKey.CANCEL} />
      </Button>
      <Button
        className="rounded border-gray-300 bg-fireside-600 text-sm font-[550] text-gray-25"
        onClick={onSuccess}
        styleType="DEFAULT_ROUNDED"
      >
        <AppFormattedMessage id={StringKey.YES} />
      </Button>
    </div>
  </div>
);

export default DeleteAccountModal;
