import React, { FC, useRef, useState } from 'react';
import { Control, useController, UseFormResetField, UseFormSetValue } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, SearchIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { BackendRoute } from '../../../config';
import { QueryKey } from '../../../constants';
import { useAppQuery, useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ApiService } from '../../../services';
import { State } from '../../../types/stateTypes';
import { FormSchema } from './Validation';

export type StateOfCountryItemsListProps = {
  data: State[];
  control: Control<FormSchema>;
  selectedCountry: Partial<FormSchema['country']> | undefined;
  isLoading: boolean;
  isDataEmpty: boolean;
};

const StateOfCountryItemsList: FC<StateOfCountryItemsListProps> = ({
  data,
  control,
  selectedCountry,
  isLoading,
  isDataEmpty,
}) => {
  if (!data || !selectedCountry || isLoading) return <span>Loading ...</span>;
  if (isDataEmpty)
    return (
      <FormComboboxOption
        control={control}
        key={`${selectedCountry.name}_${selectedCountry.iso2Code}}`}
        name="state"
        value={{
          iso2Code: selectedCountry.iso2Code || '',
          name: selectedCountry.name || '',
        }}
      >
        <span className="text-sm text-gray-700">{selectedCountry.name}</span>
      </FormComboboxOption>
    );
  return (
    <>
      {data.map(({ name, iso2 }) => (
        <FormComboboxOption
          control={control}
          key={`${iso2}_${name}`}
          name="state"
          value={{ iso2Code: iso2, name }}
        >
          <span className="text-sm text-gray-700">{name}</span>
        </FormComboboxOption>
      ))}
    </>
  );
};

export type StateOfCountryComboboxProps = {
  control: Control<FormSchema>;
  selectedCountry?: Partial<FormSchema['country']> | undefined;
  resetField: UseFormResetField<FormSchema>;
  setValue: UseFormSetValue<FormSchema>;
};

const StateOfCountryCombobox: FC<StateOfCountryComboboxProps> = ({
  control,
  resetField,
  selectedCountry,
  setValue,
}) => {
  const [search, setSearch] = useState('');
  const { formState } = useController({
    name: 'state',
    control,
  });
  const { locale } = useLocale();
  const { data: stateData, isLoading } = useAppQuery<State[]>({
    queryKey: [QueryKey.GET_STATE, selectedCountry?.iso2Code, { search }],
    queryFn: () =>
      ApiService.get(
        {
          endpoint: BackendRoute.COUNTRIES,
          routePath: [selectedCountry?.iso2Code || '', 'states'],
        },
        { queryParams: { search }, locale },
      ),
    enabled: !!selectedCountry?.iso2Code,
  });
  const ref = useRef<HTMLInputElement>(null);
  return (
    <FormCombobox
      className="relative w-full"
      control={control}
      disabled={!selectedCountry?.iso2Code}
      name={'state'}
      onClose={() => setSearch('')}
      onSelect={() => {
        setSearch('');
        resetField('city');
        setValue('city', '', { shouldValidate: true });
      }}
    >
      <div className="relative">
        <FormComboboxInput
          className={'bg-gray-900'}
          control={control}
          customValue={(value) => value?.name}
          icon={<ChevronDownIcon className={twMerge('mt-3', formState.disabled && 'hidden')} />}
          name={'state'}
          placeholder={<AppFormattedMessage id={StringKey.STATE} />}
          readOnly
          wrapperClassName={formState.disabled && 'border-none'}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <div className="relative flex items-center" onClick={() => ref.current?.focus()}>
          <SearchIcon className="absolute ml-[6px]" />
          <input
            className="w-full rounded border-[2px] border-transparent bg-gray-50 p-[6px] pl-8 text-gray-700 outline-none placeholder:text-gray-400 focus:border-brand-700 focus:bg-white"
            onChange={({ target: { value } }) => setSearch(value)}
            ref={ref}
            value={search}
          />
        </div>
        <StateOfCountryItemsList
          control={control}
          data={stateData || []}
          isDataEmpty={stateData?.length === 0 && !search}
          isLoading={isLoading}
          selectedCountry={selectedCountry}
        />
      </ComboboxOptions>
    </FormCombobox>
  );
};

export default StateOfCountryCombobox;
