import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Button from '../Button';
import { DeprecatedModalWrapper } from './ModalWrapper';

export type ModalProps = {
  onSuccess?: () => void;
  onDismiss?: () => void;
  title?: ReactNode;
  isOpen: boolean;
  description?: ReactNode;
  dismissButtonContent: ReactNode;
  successButtonContent: ReactNode;
  children?: ReactNode;
  className?: string;
  successButtonClassName?: string;
  dismissButtonClassName?: string;
  titleClassName?: string;
  descriptionClassName?: string;
};

const Modal: FC<ModalProps> = ({
  onDismiss,
  onSuccess,
  children,
  dismissButtonContent,
  successButtonContent,
  className,
  description,
  descriptionClassName,
  dismissButtonClassName,
  successButtonClassName,
  title,
  titleClassName,
  isOpen,
}) => (
  <DeprecatedModalWrapper isOpen={isOpen}>
    <div
      className={twMerge(
        'relative flex w-full max-w-[450px] flex-col gap-8 rounded bg-white p-4',
        className,
      )}
    >
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-1">
          <span className={twMerge('text-xl font-[550] text-gray-700', titleClassName)}>
            {title}
          </span>
          <span className={twMerge('text-xs font-[450] text-gray-500', descriptionClassName)}>
            {description}
          </span>
        </div>
        {children}
      </div>
      <div className="flex h-11 justify-end gap-3">
        <Button
          className={twMerge(
            'border-[1px] border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700 transition-colors hover:bg-gray-50',
            dismissButtonClassName,
          )}
          onClick={() => onDismiss?.()}
          styleType="DEFAULT_ROUNDED"
        >
          {dismissButtonContent}
        </Button>
        <Button
          className={twMerge(
            'w-fit rounded bg-fireside-600 px-6 py-[10px] text-sm font-[550] text-white',
            successButtonClassName,
          )}
          onClick={() => onSuccess?.()}
          styleType="NONE"
        >
          {successButtonContent}
        </Button>
      </div>
    </div>
  </DeprecatedModalWrapper>
);

Modal.displayName = 'Modal';

export default Modal;
