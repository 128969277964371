import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDelete = () => {
  const { mutate: deleteShareClass, isPending } = useAppMutation([QueryKey.DELETE_SHARE_CLASS], {
    mutationFn: ({ companyId, shareClassId }: { companyId: string; shareClassId: string }) =>
      ApiService.delete({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'share-classes', shareClassId],
      }),
  });
  return { deleteShareClass, isPending };
};
