import React, { FC, useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronLeftIcon } from '../../assets/icons';

export interface ImageCarouselItem {
  from?: string;
  image: string;
  title?: string;
}

const DELAY_BETWEEN_CHANGE_PAGE = 5000; // 5s

interface ImageCarouselProps {
  items: ImageCarouselItem[];
}

const ImageCarousel: FC<ImageCarouselProps> = ({ items }) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const currentOffset = useMemo(() => `${-currentItemIndex * 100}%`, [currentItemIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItemIndex(currentItemIndex + 2 > items.length ? 0 : currentItemIndex + 1);
    }, DELAY_BETWEEN_CHANGE_PAGE);

    return () => {
      clearInterval(interval);
    };
  }, [currentItemIndex, items.length]);

  return (
    <div
      className="relative h-full max-h-[752px] w-full overflow-hidden rounded-[24px]"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={twMerge(
          'absolute left-[32px] top-1/2 z-2 flex h-[32px] w-[32px] -translate-y-1/2 cursor-pointer items-center justify-center rounded-[32px] bg-white transition-all',
          isHovered ? '' : 'opacity-0',
        )}
        onClick={() =>
          setCurrentItemIndex(currentItemIndex - 1 >= 0 ? currentItemIndex - 1 : items.length - 1)
        }
        style={{
          boxShadow: '0px 0px 12px 0px #1018281A',
        }}
      >
        <ChevronLeftIcon />
      </div>

      <div
        className={twMerge(
          'absolute right-[32px] top-1/2 z-2 flex h-[32px] w-[32px] -translate-y-1/2 rotate-180 cursor-pointer items-center justify-center rounded-[32px] bg-white transition-all',
          isHovered ? '' : 'opacity-0',
        )}
        onClick={() =>
          setCurrentItemIndex(currentItemIndex + 2 > items.length ? 0 : currentItemIndex + 1)
        }
        style={{
          boxShadow: '0px 0px 12px 0px #1018281A',
        }}
      >
        <ChevronLeftIcon />
      </div>

      <div
        className="absolute left-0 top-0 z-1 h-full w-full"
        style={{
          background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 53.49%, rgba(0, 0, 0, 0.8) 87.38%)',
        }}
      />
      <div className="absolute bottom-12 left-12 z-3 flex gap-[5px] text-white">
        {items.map((_item, i) => (
          <div
            className={`z-3 h-[3px] rounded-[24px] bg-white transition-all ${i === currentItemIndex ? 'w-[32px]' : 'w-[16px] opacity-20'}`}
            key={`item-control-${i}`}
          />
        ))}
      </div>
      <div
        className="absolute top-0 h-full w-full transition-all duration-300"
        style={{
          left: currentOffset,
        }}
      >
        <div className="flex h-full w-full">
          {items.map(({ image, title, from }, i) => (
            <div
              className="absolute flex h-full w-full"
              key={`item-${i}`}
              style={{
                left: `${100 * i}%`,
              }}
            >
              <img alt="" className="h-full w-full object-cover" key={image} src={image} />
              <div className="absolute bottom-0 left-1/2 z-2 w-full -translate-x-1/2 px-12 pb-[75px] font-inter text-gray-25">
                <div className="flex flex-col gap-5">
                  {from && (
                    <div className="text-[20px] font-[550] leading-[25px] text-white">{from}</div>
                  )}
                  {title && (
                    <div className="text-[18px] font-[450] leading-[25px] text-white">{title}</div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
