import { FC } from 'react';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { StringKey } from '../../lang';
import { SharePlanUnionType } from '../../types/pool-plans.types';

export type DeletePoolModalProps = {
  onClose: () => void;
  sharePlans: SharePlanUnionType[];
};

export const DeleteBlockModal: FC<DeletePoolModalProps> = ({ onClose, sharePlans }) => (
  <div className="flex min-w-[300px] max-w-[453px] shrink-0 flex-col gap-8 rounded bg-white p-4">
    <div className="flex flex-col gap-1">
      <span className="text-xl font-[550] text-gray-700">
        <AppFormattedMessage id={StringKey.UNABLE_DELETE} />
      </span>
      <span className="text-xs font-[450] text-gray-500">
        The pool cannot be deleted as they are associated to plans. Please review associations
        before deleting them.
      </span>
    </div>
    <div className="flex flex-col gap-3">
      {sharePlans.map(({ name }, i) => (
        <span className="truncate text-xs font-[450] text-gray-700">
          {i + 1}. {name}
        </span>
      ))}
    </div>
    <div className="flex h-11 justify-end gap-4">
      <Button
        className="h-full w-fit bg-brand-700 px-6 py-[10px] text-sm font-[550] text-white"
        onClick={onClose}
        styleType="NONE"
      >
        Go, Back
      </Button>
    </div>
  </div>
);
