import { StakeholderRole } from '../../types/stakeholderTypes';

type StakeholderActionsProps = {
  isActive?: boolean;
  role: StakeholderRole;
  isAccessRevoked?: boolean;
  isInvited?: boolean;
};

type StakeholderPermission = {
  canTerminate?: boolean;
  makeContributor?: boolean;
  revokeContributor?: boolean;
  resendInvitation?: boolean;
  cancelInvitation?: boolean;
  revokeAccess?: boolean;
  grantAccess?: boolean;
  canInvite?: boolean;
  canDelete?: boolean;
};

type Status =
  | 'ACTIVE'
  | 'INACTIVE'
  | 'INACTIVE_ACCESS_REVOKED'
  | 'INACTIVE_INVITED'
  | 'INACTIVE_NOT_INVITED';

type RolePermissions = {
  [status in Status]?: StakeholderPermission;
};

type Permissions = {
  [role in StakeholderRole]: RolePermissions;
};

const stakeholderPermissions: Permissions = {
  [StakeholderRole.STAKEHOLDER]: {
    INACTIVE_NOT_INVITED: {
      makeContributor: true,
      canDelete: true,
      canInvite: true,
    },
    INACTIVE_INVITED: {
      makeContributor: true,
      resendInvitation: true,
      cancelInvitation: true,
      canDelete: true,
    },
    INACTIVE_ACCESS_REVOKED: {
      grantAccess: true,
      canTerminate: true,
      canDelete: true,
    },
    ACTIVE: {
      revokeAccess: true,
      makeContributor: true,
      canTerminate: true,
      canDelete: true,
    },
  },
  [StakeholderRole.CONTRIBUTOR]: {
    ACTIVE: {
      canTerminate: true,
      revokeContributor: true,
      revokeAccess: true,
      canDelete: true,
    },
    INACTIVE_INVITED: {
      revokeContributor: true,
      resendInvitation: true,
      cancelInvitation: true,
      canDelete: true,
    },
    INACTIVE_ACCESS_REVOKED: {
      revokeContributor: true,
      grantAccess: true,
      canTerminate: true,
      canDelete: true,
    },
    INACTIVE_NOT_INVITED: {
      revokeContributor: true,
      canDelete: true,
      canInvite: true,
    },
  },
  [StakeholderRole.ADMIN]: {
    ACTIVE: {
      canTerminate: true,
    },
  },
};

export const getStakeholderCommonActions = ({
  role,
  isAccessRevoked = false,
  isActive = false,
  isInvited = false,
}: StakeholderActionsProps): StakeholderPermission => {
  const defaultPermissions: StakeholderPermission = {
    cancelInvitation: false,
    canDelete: false,
    canInvite: false,
    canTerminate: false,
    grantAccess: false,
    makeContributor: false,
    resendInvitation: false,
    revokeAccess: false,
    revokeContributor: false,
  };

  switch (role) {
    case StakeholderRole.STAKEHOLDER:
      if (isActive)
        return {
          ...defaultPermissions,
          ...stakeholderPermissions.stakeholder.ACTIVE,
        };

      if (isAccessRevoked)
        return {
          ...defaultPermissions,
          ...stakeholderPermissions.stakeholder.INACTIVE_ACCESS_REVOKED,
        };

      if (isInvited)
        return {
          ...defaultPermissions,
          ...stakeholderPermissions.stakeholder.INACTIVE_INVITED,
        };

      return {
        ...defaultPermissions,
        ...stakeholderPermissions.stakeholder.INACTIVE_NOT_INVITED,
      };
    case StakeholderRole.CONTRIBUTOR:
      if (isActive)
        return {
          ...defaultPermissions,
          ...stakeholderPermissions.contributor.ACTIVE,
        };

      if (isAccessRevoked)
        return {
          ...defaultPermissions,
          ...stakeholderPermissions.contributor.INACTIVE_ACCESS_REVOKED,
        };

      if (isInvited)
        return {
          ...defaultPermissions,
          ...stakeholderPermissions.contributor.INACTIVE_INVITED,
        };

      return {
        ...defaultPermissions,
        ...stakeholderPermissions.contributor.INACTIVE_NOT_INVITED,
      };
    case StakeholderRole.ADMIN:
      return {
        ...defaultPermissions,
        ...stakeholderPermissions.admin.ACTIVE,
      };
  }
};
