import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Modal } from '../../../components/Modal';
import { useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';

export type CancelInvitationProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
};

const CancelInvitationModal: FC<CancelInvitationProps> = ({ isOpen, onDismiss, onSuccess }) => {
  const { messagesLocale } = useLocale();
  return (
    <Modal
      description={<AppFormattedMessage id={StringKey.STAKEHOLDER_CANCEL_INVITE_DESCRIPTION} />}
      dismissButtonContent={<AppFormattedMessage id={StringKey.NO_KEEP_4} />}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onSuccess={onSuccess}
      successButtonContent={messagesLocale[StringKey.CONFIRM_CANCEL]}
      title={
        <>
          <AppFormattedMessage id={StringKey.CANCEL_INVITATION} />?
        </>
      }
    />
  );
};

export default CancelInvitationModal;
