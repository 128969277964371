import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntentResult, StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import { addMonths, addYears } from 'date-fns';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import { HelpIcon, LockIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormInput } from '../../../../components/Input';
import Loader from '../../../../components/Loader';
import { ReactPortal } from '../../../../components/Modal/ReactPortal';
import {
  StripeCardCvcInput,
  StripeCardExpiryInput,
  StripeCardNumberInput,
} from '../../../../components/Stripe';
import { QueryKey } from '../../../../constants';
import { useHeaderContext } from '../../../../context/headerContext';
import { Company, useAppMutation, useAppQuery, useFormat, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { Plan, SubscriptionDuration } from '../../../../types/planTypes';
import { FormSchema, formSchema } from './Validation';

const roundToNearestTenth = (number: number) => {
  return number.toFixed(2);
};

export interface BillingFormProps {
  clientSecret: string;
  companyId: string;
  isLoading: boolean;
  onSuccess: () => void;
  selectedPlanInfo: Plan | undefined;
  setLoading: (state: boolean) => void;
}

const BillingForm: FC<BillingFormProps> = ({
  clientSecret,
  onSuccess,
  selectedPlanInfo,
  setLoading,
  isLoading,
  companyId,
}) => {
  const { format } = useFormat();
  const {
    formState: { isValid },
    setValue,
    setError,
    trigger,
    control,
    handleSubmit,
  } = useReactForm({
    schema: formSchema,
    mode: 'all',
  });

  const { showCompanySelect } = useHeaderContext();
  const stripe = useStripe();
  const elements = useElements();
  const { data } = useAppQuery<PaymentIntentResult | undefined>({
    queryKey: [QueryKey.GET_RETRIEVE_PAYMENT_INTENT, clientSecret || ''],
    queryFn: () => {
      if (!clientSecret) return;
      return stripe?.retrievePaymentIntent(clientSecret);
    },
    enabled: !!clientSecret && !!stripe,
  });

  const queryClient = useQueryClient();

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_SELECTED_COMPANY],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_COMPANIES],
    });
  }, [queryClient]);

  const { setSelected } = Company.useSetSelected();

  useEffect(() => {
    if (data || !isLoading) return setLoading(false);
  }, [data, isLoading, setLoading]);

  const { isPending: stripeIsPending, mutate } = useAppMutation<
    PaymentIntentResult | undefined,
    StripeError,
    {
      cardNumber: StripeCardNumberElement;
      cardholderName: string;
    }
  >([], {
    mutationFn: async (data: { cardNumber: StripeCardNumberElement; cardholderName: string }) =>
      await stripe?.confirmCardPayment(clientSecret, {
        payment_method: {
          card: data.cardNumber,
          billing_details: { name: data.cardholderName },
        },
      }),
    onSuccess: (paymentRes) => {
      if (paymentRes?.error)
        return toast(paymentRes.error?.message, {
          type: 'error',
          position: 'bottom-center',
        });
      showCompanySelect();
      onSuccess();

      setTimeout(() => {
        setSelected(companyId, { onSuccess: invalidateQuery });
      }, 1000);
    },
    onError: (paymentError) => {
      toast(paymentError.message, {
        type: 'error',
        position: 'bottom-center',
      });
    },
  });

  const submitHandler = useCallback(
    async (data: FormSchema) => {
      if (!elements || !stripe) return;
      const { error } = await elements.submit();

      if (error)
        return toast(error.message, {
          type: 'error',
          position: 'bottom-center',
        });

      const cardNumber = elements.getElement('cardNumber');
      if (!cardNumber) return;

      mutate({
        cardholderName: data.cardholderName,
        cardNumber,
      });
    },
    [elements, mutate, stripe],
  );

  const planPrice = useMemo(
    () =>
      selectedPlanInfo?.duration === SubscriptionDuration.MONTHLY
        ? (data?.paymentIntent?.amount || 0) / 100
        : (data?.paymentIntent?.amount || 0) / 100 / 12,
    [data?.paymentIntent?.amount, selectedPlanInfo?.duration],
  );

  const renewDate = useMemo(
    () =>
      selectedPlanInfo?.duration === SubscriptionDuration.MONTHLY
        ? addMonths(new Date(), 1)
        : addYears(new Date(), 1),
    [selectedPlanInfo?.duration],
  );

  const renewFormattedDate = useMemo(
    () => format(renewDate, 'cccc, dd MMMM Y'),
    [format, renewDate],
  );

  if (isLoading)
    return (
      <ReactPortal initialPortalId="modal">
        <Loader className="absolute left-0 top-0 z-[999999999] h-screen w-screen pl-10 pt-10 max-lg:pl-16 max-lg:pt-36" />
      </ReactPortal>
    );

  return (
    <div className="flex w-full max-w-[400px] flex-col items-center gap-4 lg:pb-4">
      <span className="w-fit rounded-2xl bg-gray-100 px-2 py-[2px] text-label-md font-[450] text-gray-700">
        <AppFormattedMessage id={StringKey.PLAN_SELECTED} />
      </span>
      <span
        className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
        style={{
          background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
        }}
      >
        {selectedPlanInfo?.title}
      </span>
      <div className="flex w-full flex-col gap-3 border-t-[1px] border-gray-200 pt-4">
        <div className="flex w-full items-center justify-between py-2">
          <span className="text-sm font-[450] text-gray-700">
            <AppFormattedMessage id={StringKey.PER_MONTH} />
          </span>
          <span className="text-sm font-[550] uppercase text-gray-700">
            {roundToNearestTenth(planPrice)} {data?.paymentIntent?.currency}
          </span>
        </div>
        <div className="flex w-full items-center justify-between py-2">
          <span className="text-sm font-medium text-gray-700">
            <AppFormattedMessage id={StringKey.AMOUNT_TO_PAY_TODAY} />
          </span>
          <span className="text-sm font-semibold uppercase text-gray-700">
            {roundToNearestTenth((data?.paymentIntent?.amount || 0) / 100)}{' '}
            {data?.paymentIntent?.currency}
          </span>
        </div>
        <div className="flex w-full items-center justify-between py-2">
          <span className="text-sm font-medium text-gray-700">
            <AppFormattedMessage id={StringKey.AUTO_RENEW_ON} />
          </span>
          <span className="text-sm font-semibold text-gray-700">{renewFormattedDate}</span>
        </div>
      </div>
      <form
        className="flex w-full flex-col gap-4 border-t-[1px] border-gray-200 pt-4"
        onSubmit={handleSubmit(submitHandler)}
      >
        <FormInput
          control={control}
          name="cardholderName"
          placeholder={<AppFormattedMessage id={StringKey.CARDHOLDER_NAME} />}
        />
        <StripeCardNumberInput
          onError={(error) => {
            setError('cardNumber', { ...error });
          }}
          onSuccess={() => {
            setValue('cardNumber', true);
            trigger('cardNumber');
          }}
        />
        <div className="flex w-full justify-between gap-6">
          <StripeCardExpiryInput
            onError={(error) => {
              setError('cardExpiry', { ...error });
            }}
            onSuccess={() => {
              setValue('cardExpiry', true);
              trigger('cardExpiry');
            }}
          />
          <StripeCardCvcInput
            icon={<HelpIcon iconColor="#344054" />}
            onError={(error) => {
              setError('cardCvc', { ...error });
            }}
            onSuccess={() => {
              setValue('cardCvc', true);
              trigger('cardCvc');
            }}
          />
        </div>
        <Button
          className="mt-4 flex w-full gap-2"
          disabled={!isValid}
          isLoading={stripeIsPending}
          type="submit"
        >
          <LockIcon iconColor={isValid ? '#FFFFFF' : '#D0D5DD'} />
          <AppFormattedMessage id={StringKey.PAY_NOW} />
        </Button>
      </form>
    </div>
  );
};

export default BillingForm;
