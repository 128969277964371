import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';
import { useLocale } from '../useLocale';

export const useDelete = () => {
  const { messagesLocale } = useLocale();
  const { mutate: deleteStakeholder, isPending } = useAppMutation([QueryKey.DELETE_STAKEHOLDER], {
    mutationFn: ({ companyId, stakeholderId }: { companyId: string; stakeholderId: string }) =>
      ApiService.delete({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'stakeholders', stakeholderId],
      }),
  });
  return { deleteStakeholder, isPending };
};
