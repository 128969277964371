import React from 'react';

const GoogleIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_818_15905)">
        <path
          d="M19.2449 8.26132L11.087 8.26093C10.7268 8.26093 10.4348 8.55288 10.4348 8.91311V11.5192C10.4348 11.8793 10.7268 12.1714 11.087 12.1714H15.681C15.1779 13.4769 14.239 14.5702 13.0411 15.2649L15 18.6558C18.1423 16.8385 20 13.6499 20 10.0805C20 9.57221 19.9625 9.20889 19.8876 8.79979C19.8307 8.48897 19.5608 8.26132 19.2449 8.26132Z"
          fill="#167EE6"
        />
        <path
          d="M10.0001 16.0864C7.75186 16.0864 5.7892 14.8581 4.7351 13.0404L1.34424 14.9948C3.06982 17.9855 6.3024 19.9995 10.0001 19.9995C11.814 19.9995 13.5256 19.5111 15.0001 18.6599V18.6553L13.0412 15.2643C12.1452 15.784 11.1083 16.0864 10.0001 16.0864Z"
          fill="#12B347"
        />
        <path
          d="M15 18.661V18.6563L13.0411 15.2653C12.1451 15.785 11.1083 16.0875 10 16.0875V20.0005C11.8139 20.0005 13.5256 19.5121 15 18.661Z"
          fill="#0F993E"
        />
        <path
          d="M3.91305 10.0002C3.91305 8.89208 4.21547 7.85533 4.73504 6.95935L1.34418 5.0049C0.488359 6.4747 0 8.18166 0 10.0002C0 11.8188 0.488359 13.5258 1.34418 14.9956L4.73504 13.0411C4.21547 12.1452 3.91305 11.1084 3.91305 10.0002Z"
          fill="#FFD500"
        />
        <path
          d="M10.0001 3.91306C11.4661 3.91306 12.8128 4.434 13.8646 5.30052C14.1241 5.51427 14.5012 5.49884 14.7389 5.26115L16.5854 3.41466C16.8551 3.14498 16.8359 2.70353 16.5478 2.45361C14.7855 0.924742 12.4925 1.52588e-05 10.0001 1.52588e-05C6.3024 1.52588e-05 3.06982 2.01396 1.34424 5.00466L4.7351 6.95912C5.7892 5.14142 7.75186 3.91306 10.0001 3.91306Z"
          fill="#FF4B26"
        />
        <path
          d="M13.8645 5.30052C14.124 5.51427 14.5012 5.49884 14.7389 5.26115L16.5854 3.41466C16.855 3.14498 16.8358 2.70353 16.5477 2.45361C14.7854 0.924703 12.4925 1.52588e-05 10 1.52588e-05V3.91306C11.466 3.91306 12.8127 4.434 13.8645 5.30052Z"
          fill="#D93F21"
        />
      </g>
      <defs>
        <clipPath id="clip0_818_15905">
          <rect fill="white" height="20" width="20" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleIcon;
