import React, { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { CheckIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { FormInput } from '../../../../components/Input';
import { FormRadio, FormRadioGroup } from '../../../../components/RadioGroup';
import { BackendRoute } from '../../../../config';
import { QueryKey } from '../../../../constants';
import { useAppMutation, useDebounce, useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import { DilutionConditionTitle } from '../../../../types/pool-plans.types';
import { ShareClassCombobox } from '../ShareClassCombobox';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepOne as stepOneFormSchema } from '../Validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  handleCloseModal,
  nextFormStep,
  control,
  setFormData,
  companyId,
  lockMode,
  clearErrors,
  setError,
  filedState,
}) => {
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();
  const { success } = stepOneFormSchema.safeParse(stepOne);
  const { mutate } = useAppMutation([QueryKey.POOL_NAME_CHECK, { name: stepOne?.name || '' }], {
    mutationFn: async (name: string) =>
      await ApiService.get<{ isInUse: boolean }>({
        endpoint: BackendRoute.POOLS,
        routePath: ['check-is-name-in-use', name],
      }),
    onSuccess: ({ isInUse }) => {
      setError('stepOne.name', {
        message: 'Name should be unique',
        type: 'validate',
        types: { required: true },
      });
      if (!isInUse) return clearErrors('stepOne.name');
    },
    defaultErrorHandling: false,
  });

  const isValid = success && !filedState('stepOne').invalid;

  const { debounceCallback } = useDebounce<string>({
    callback: (name) => name && mutate(name),
    debounce: 300,
  });

  stepOne;

  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <FormInput
          control={control}
          name="stepOne.name"
          onChange={debounceCallback}
          placeholder="Pool name"
        />
        <FormInput
          control={control}
          name="stepOne.dillutedSharesCount"
          numberOnly
          placeholder="Amount of fully diluted shares"
          shouldFormatNumber
        />
        <ShareClassCombobox companyId={companyId} control={control} />
        <FormDatePicker
          inputValue={stepOne?.date ? format(stepOne?.date, 'dd/MM/yyyy') : ''}
          inputWrapperClassName="w-full"
          isDefaultOpenCalendar={isOpenCalendar}
          onSelect={(date) => {
            setFormData('stepOne.date', date);
            setOpenCalendar(false);
          }}
          placeholder="Date"
          value={stepOne?.date}
        />

        <div className="flex w-full flex-col gap-3 rounded bg-gray-50 px-3 py-2">
          <span className="text-base font-[550] text-gray-700">Dilution condition</span>

          <FormRadioGroup
            className={'flex shrink-0 flex-col gap-3 divide-x-0 overflow-x-auto'}
            control={control}
            name="stepOne.dilutionCondition"
          >
            {Object.entries(DilutionConditionTitle).map(([dilutionKey, dilutionTitle]) => (
              <div className="flex items-center gap-3" key={dilutionKey}>
                <FormRadio
                  className={
                    'flex size-4 items-center justify-center overflow-hidden rounded-full border border-gray-300 data-[checked]:border-none data-[checked]:bg-brand-500'
                  }
                  control={control}
                  name="stepOne.dilutionCondition"
                  styleType="NONE"
                  value={dilutionKey}
                >
                  <CheckIcon
                    className="hidden size-[10px] group-data-[checked]:block"
                    iconColor={'#ffffff'}
                  />
                </FormRadio>
                <span className="text-nowrap text-sm font-[450] text-gray-700">
                  {dilutionTitle}
                </span>
              </div>
            ))}
          </FormRadioGroup>
        </div>

        <div className="flex flex-col divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50 px-4">
          <DropDown
            answer='The "amount of fully diluted shares" refers to the total number of shares that would be outstanding if all possible sources of conversion, such as convertible securities, options, and warrants, were exercised or converted into shares. This figure represents the total potential ownership of a company, considering all equity instruments that could be converted into shares, and is used to understand the complete dilution effect on existing shareholders. It provides a more comprehensive view of a company`s equity structure.'
            question="What is “Amount of fully dilluted shares”?"
          />
          <DropDown
            answer="Dilution conditions refer to scenarios or events that can lead to the reduction of existing shareholders' ownership percentage in a company due to the issuance of additional shares."
            question="What are the dilution conditions?"
          />
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : (
              <AppFormattedMessage id={StringKey.NEXT} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
