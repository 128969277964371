import { z } from 'zod';

import { StringKey } from '../../../../lang';

export enum CancelledPlanReason {
  NO_NEED_IT = 'no-need-it',
  TO_EXPENSIVE = 'to-expensive',
  FOUND_BETTER = 'found-better',
  MISSING_FEATURES = 'missing-features',
  NO_ACTIVE = 'no-active',
  ELSE = 'else',
}

export const CancelledPlanReasonTranslation: Record<CancelledPlanReason, StringKey> = {
  [CancelledPlanReason.NO_NEED_IT]: StringKey.I_NO_LONGER_NEED_IT,
  [CancelledPlanReason.TO_EXPENSIVE]: StringKey.TOO_EXPENSIVE,
  [CancelledPlanReason.FOUND_BETTER]: StringKey.I_FOUND_BETTER_ALTERNATIVE,
  [CancelledPlanReason.MISSING_FEATURES]: StringKey.MISSING_FEATURES,
  [CancelledPlanReason.NO_ACTIVE]: StringKey.MY_BUSINESS_IS_NO_LONGER_ACTIVE,
  [CancelledPlanReason.ELSE]: StringKey.SOMETHING_ELSE,
};

export const arrayOfCancelledPlanReasonTranslation = Object.entries(CancelledPlanReasonTranslation);

export const formSchema = z.object({
  feedback: z.string(),
  noNeedIt: z.boolean().optional(),
  toExpensive: z.boolean().optional(),
  foundBetter: z.boolean().optional(),
  missingFeatures: z.boolean().optional(),
  noActive: z.boolean().optional(),
  else: z.boolean().optional(),
});

export type FormSchema = z.infer<typeof formSchema>;
