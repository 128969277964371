import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type EventGrantProps = IconProps;

export const EventGrant: FC<EventGrantProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8982 16.7419C13.4745 17.7763 11.7598 18.3334 10 18.3334C8.24018 18.3334 6.52553 17.7763 5.1018 16.7419M13.6531 2.51009C15.2348 3.28155 16.5317 4.53394 17.3579 6.08778C18.1841 7.64161 18.4972 9.41712 18.2522 11.1598M1.74786 11.1597C1.50294 9.41704 1.81601 7.64153 2.64219 6.0877C3.46838 4.53386 4.76528 3.28147 6.347 2.51001M9.52861 5.47145L5.47141 9.52864C5.30641 9.69364 5.2239 9.77615 5.19299 9.87128C5.1658 9.95497 5.1658 10.0451 5.19299 10.1288C5.2239 10.2239 5.30641 10.3064 5.47141 10.4714L9.52861 14.5286C9.69361 14.6936 9.77612 14.7761 9.87125 14.8071C9.95494 14.8343 10.0451 14.8343 10.1288 14.8071C10.2239 14.7761 10.3064 14.6936 10.4714 14.5286L14.5286 10.4714C14.6936 10.3064 14.7761 10.2239 14.807 10.1288C14.8342 10.0451 14.8342 9.95497 14.807 9.87128C14.7761 9.77615 14.6936 9.69364 14.5286 9.52864L10.4714 5.47145C10.3064 5.30644 10.2239 5.22394 10.1288 5.19302C10.0451 5.16583 9.95494 5.16583 9.87125 5.19302C9.77612 5.22394 9.69361 5.30644 9.52861 5.47145Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);
