import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import clipboard from '../../assets/images/clipboard.png';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { StringKey } from '../../lang';

export type EmptySearchListProps = {
  className?: string;
};

export const EmptySearchList: FC<EmptySearchListProps> = ({ className }) => (
  <div className={twMerge('flex w-fit flex-col items-center gap-6', className)}>
    <div
      className="flex size-32 shrink-0 items-center justify-center rounded-full bg-fireside-50"
      style={{
        border: '1.07px solid',
        borderImageSource:
          'linear-gradient(180deg, rgba(254, 228, 226, 0.2) 0%, rgba(254, 205, 202, 0.3) 100%)',
        boxShadow: '0px 4.27px 6.4px -2.13px #D92D2008, 0px 12.8px 17.07px -4.27px #F044381F',
      }}
    >
      <div
        className="flex size-32 shrink-0 items-center justify-center rounded-full bg-fireside-50"
        style={{
          border: '1.07px solid',
          borderImageSource:
            'linear-gradient(180deg, rgba(254, 228, 226, 0.2) 0%, rgba(254, 205, 202, 0.3) 100%)',
          boxShadow: ' 0px 0px 17.07px 0px #7A271A14 inset',
        }}
      >
        <div className="size-[68px] object-cover">
          <img alt="" src={clipboard} />
        </div>
      </div>
    </div>
    <div className="flex w-full flex-col gap-[6px] text-center">
      <span className="text-nowrap text-sm font-[450] text-gray-700">
        <AppFormattedMessage id={StringKey.NO_RESULTS_MATCHS} />
      </span>
      <span className="text-nowrap text-xs font-[450] text-gray-600">
        <AppFormattedMessage id={StringKey.PLEASE_TRY_AGAIN} />
      </span>
    </div>
  </div>
);
