import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

export const useReactForm = <T extends z.Schema, C = unknown>({
  schema,
  ...props
}: {
  schema: T;
} & Omit<UseFormProps<z.infer<T>, C>, 'resolver'>): UseFormReturn<z.infer<T>, C> => {
  return useForm<z.infer<T>, C>({
    mode: 'onChange',
    ...props,
    resolver: zodResolver(schema),
  });
};
