import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { StakeholderBulkAction, StakeholderBulkActionDto } from '../../types/stakeholderTypes';
import { useAppMutation } from '../useAppMutation';

export const useBulkActions = () => {
  const { mutate: bulkActions, isPending } = useAppMutation([QueryKey.BULK_STAKEHOLDER_ACTIONS], {
    mutationFn: ({
      action,
      companyId,
      ...body
    }: {
      companyId: string;
      action: keyof typeof StakeholderBulkAction;
    } & StakeholderBulkActionDto) =>
      ApiService.post<StakeholderBulkActionDto>(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'stakeholders', 'bulk', StakeholderBulkAction[action]],
        },
        { body },
      ),
  });
  return { bulkActions, isPending };
};
