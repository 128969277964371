import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CheckIconProps = IconProps;

const CheckIcon: FC<CheckIconProps> = ({ iconColor = '#2565C8', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="12"
    viewBox="0 0 15 12"
    width="15"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1663 1.66663L4.99967 10.8333L0.833008 6.66663"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
    />
  </svg>
);

CheckIcon.displayName = 'CheckIcon';

export default CheckIcon;
