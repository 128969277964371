import { useQueryClient } from '@tanstack/react-query';
import { FC, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import Loader from '../../components/Loader';
import { QueryKey } from '../../constants';
import { Company as CompanyHook } from '../../hooks';
import { FilterVariant } from '../../types/companyTypes';
import { StakeholderRole } from '../../types/stakeholderTypes';
import MyCompanyCard from './components/CompanyCard';
import EmptyCompanyCard from './components/EmptyCompanyCard';

export type MyCompaniesItemsListProps = {
  numberOfFetchedItems?: number;
  selectedFilter: FilterVariant;
};

const MyCompaniesItemsList: FC<MyCompaniesItemsListProps> = ({
  selectedFilter,
  numberOfFetchedItems = 20,
}) => {
  const queryClient = useQueryClient();

  const { companies, fetchNextPage, hasNextPage, isLoading } = CompanyHook.useCompanies({
    filter: selectedFilter,
    numberOfFetchedItems,
  });

  const { selectedCompany, isLoading: isSelectedLoading } = CompanyHook.useSelected();

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_SELECTED_COMPANY],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_COMPANIES],
    });
  }, [queryClient]);

  const isEmptyCardShown = selectedFilter === 'all' && companies.length === 0;

  if (isSelectedLoading || isLoading || !companies)
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Loader />
      </div>
    );

  return (
    <>
      {!isEmptyCardShown ? (
        <InfiniteScroll
          className="flex h-fit w-full flex-wrap items-stretch justify-start gap-4 p-4 max-lg:justify-center"
          dataLength={numberOfFetchedItems}
          hasMore={hasNextPage}
          loader={<Loader />}
          next={fetchNextPage}
          scrollableTarget="scrollbar-target"
        >
          {companies?.map(
            (company) =>
              !(company.roleInCompany !== StakeholderRole.ADMIN && !company.isActive) && (
                <MyCompanyCard
                  key={company.id}
                  {...company}
                  invalidateQuery={invalidateQuery}
                  isSelected={company.id === selectedCompany?.id}
                />
              ),
          )}
        </InfiniteScroll>
      ) : (
        <EmptyCompanyCard />
      )}
    </>
  );
};

export default MyCompaniesItemsList;
