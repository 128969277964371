import React, { FC, ReactNode } from 'react';
import { useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { CheckIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormInput } from '../../../../components/Input';
import { Radio, RadioGroup } from '../../../../components/RadioGroup';
import { Switch } from '../../../../components/Switch';
import { StringKey } from '../../../../lang';
import { IntervalCombobox } from '../IntervalCombobox';
import { ChildrenFormProps } from '../type';
import { FormSchema, stepThree as stepThreeFormSchema } from '../Validation';

export type StepThreeProps = ChildrenFormProps;

const FormOption = ({ children, title }: { children: ReactNode; title: ReactNode }) => (
  <div className="flex w-full flex-col divide-y divide-gray-100 rounded-lg border border-gray-100">
    <div className="flex w-full justify-between bg-gray-50 p-3">
      <span className="text-sm font-[550] text-gray-700">{title}</span>
    </div>
    <div className="flex flex-col gap-4 p-3">{children}</div>
  </div>
);

type OptionVariant = { state: boolean; title: string };

const FormRadioOptions = ({
  onSelect,
  optionVariants,
  value,
}: {
  onSelect: (state: boolean) => void;
  optionVariants: OptionVariant[];
  value?: boolean;
}) => (
  <RadioGroup
    className={'flex shrink-0 flex-col gap-2 divide-x-0'}
    onChange={onSelect}
    value={value}
  >
    {optionVariants.map(({ state, title }) => (
      <div className="flex items-center gap-4" key={title}>
        <Radio
          className={
            'flex size-4 items-center justify-center overflow-hidden rounded-full border border-gray-300 data-[checked]:border-none data-[checked]:bg-brand-500'
          }
          styleType="NONE"
          value={state}
        >
          <CheckIcon
            className="hidden size-[10px] group-data-[checked]:block"
            iconColor={'#ffffff'}
          />
        </Radio>
        <span className="text-sm font-[450] text-gray-700">{title}</span>
      </div>
    ))}
  </RadioGroup>
);

const resignationTitleVariant: { state: boolean; title: string }[] = [
  { state: false, title: 'No Exercise Expiration Period' },
  { state: true, title: 'Exercise Expiration Period' },
];

export const StepThree: FC<StepThreeProps> = ({
  handleCloseModal,
  nextFormStep,
  prevFormStep,
  control,
  setFormData,
  lockMode,
}) => {
  const { stepThree } = useWatch<FormSchema>({ control });

  const { success: isValid } = stepThreeFormSchema.safeParse(stepThree);
  const isEnabledRegistration = stepThree?.terminationLogic?.resignation?.enabled;
  const isEnabledTermination = stepThree?.terminationLogic?.termination?.enabled;
  const isEnabledTerminationWithCause = stepThree?.terminationLogic?.terminationWithCause?.enabled;
  const isEnabledDeath = stepThree?.terminationLogic?.death?.enabled;
  const isEnabledRetirement = stepThree?.terminationLogic?.retirement?.enabled;
  return (
    <>
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div
          className={twMerge(
            'flex flex-col gap-3 rounded-md bg-gray-50 p-[2px] pt-3',
            !stepThree?.terminationLogic?.enabled && 'pb-3',
          )}
        >
          <div className="flex items-center gap-2 px-3">
            <Switch
              checked={stepThree?.terminationLogic?.enabled}
              onChange={(state) => {
                setFormData('stepThree.terminationLogic.enabled', state);
                if (!state) setFormData('stepThree.terminationLogic', undefined);
              }}
            />
            <div className="flex flex-col">
              <span className="text-sm font-[450] text-gray-700">Termination logic</span>
              <span className="text-[10px] leading-[16px] text-gray-500">
                Establish custom termination period for different termination types
              </span>
            </div>
          </div>
          {stepThree?.terminationLogic?.enabled && (
            <div className="flex w-full flex-col gap-3 rounded-md bg-white px-4 py-3 shadow-sm">
              <FormOption title={<AppFormattedMessage id={StringKey.RESIGNATION} />}>
                <FormRadioOptions
                  onSelect={(state: boolean) => {
                    setFormData('stepThree.terminationLogic.resignation.enabled', state);
                  }}
                  optionVariants={resignationTitleVariant}
                  value={stepThree?.terminationLogic?.resignation?.enabled}
                />
                {isEnabledRegistration && (
                  <div className="flex w-full gap-4">
                    <FormInput
                      control={control}
                      isShownErrorMessage={false}
                      name="stepThree.terminationLogic.resignation.value"
                      numberOnly
                      placeholder="Value"
                    />
                    <IntervalCombobox
                      defaultData={stepThree?.terminationLogic?.resignation?.interval}
                      onSelect={(state) =>
                        setFormData('stepThree.terminationLogic.resignation.interval', state)
                      }
                    />
                  </div>
                )}
              </FormOption>
              <FormOption title={<AppFormattedMessage id={StringKey.TERMINATION} />}>
                <FormRadioOptions
                  onSelect={(state: boolean) => {
                    setFormData('stepThree.terminationLogic.termination.enabled', state);
                  }}
                  optionVariants={resignationTitleVariant}
                  value={stepThree?.terminationLogic?.termination?.enabled}
                />
                {isEnabledTermination && (
                  <div className="flex gap-4">
                    <FormInput
                      control={control}
                      isShownErrorMessage={false}
                      name="stepThree.terminationLogic.termination.value"
                      numberOnly
                      placeholder="Value"
                    />
                    <IntervalCombobox
                      defaultData={stepThree?.terminationLogic?.termination?.interval}
                      onSelect={(state) =>
                        setFormData('stepThree.terminationLogic.termination.interval', state)
                      }
                    />
                  </div>
                )}
              </FormOption>
              <FormOption title="Termination with Cause">
                <FormRadioOptions
                  onSelect={(state: boolean) => {
                    setFormData('stepThree.terminationLogic.terminationWithCause.enabled', state);
                  }}
                  optionVariants={resignationTitleVariant}
                  value={stepThree?.terminationLogic?.terminationWithCause?.enabled}
                />
                {isEnabledTerminationWithCause && (
                  <div className="flex gap-4">
                    <FormInput
                      control={control}
                      isShownErrorMessage={false}
                      name="stepThree.terminationLogic.terminationWithCause.value"
                      numberOnly
                      placeholder="Value"
                    />
                    <IntervalCombobox
                      defaultData={stepThree?.terminationLogic?.terminationWithCause?.interval}
                      onSelect={(state) =>
                        setFormData(
                          'stepThree.terminationLogic.terminationWithCause.interval',
                          state,
                        )
                      }
                    />
                  </div>
                )}
              </FormOption>
              <FormOption title="Death">
                <FormRadioOptions
                  onSelect={(state: boolean) => {
                    setFormData('stepThree.terminationLogic.death.enabled', state);
                  }}
                  optionVariants={resignationTitleVariant}
                  value={stepThree?.terminationLogic?.death?.enabled}
                />
                {isEnabledDeath && (
                  <div className="flex gap-4">
                    <FormInput
                      control={control}
                      isShownErrorMessage={false}
                      name="stepThree.terminationLogic.death.value"
                      numberOnly
                      placeholder="Value"
                    />
                    <IntervalCombobox
                      defaultData={stepThree?.terminationLogic?.death?.interval}
                      onSelect={(state) =>
                        setFormData('stepThree.terminationLogic.death.interval', state)
                      }
                    />
                  </div>
                )}
              </FormOption>

              <FormOption title="Retirement">
                <FormRadioOptions
                  onSelect={(state: boolean) => {
                    setFormData('stepThree.terminationLogic.retirement.enabled', state);
                  }}
                  optionVariants={resignationTitleVariant}
                  value={stepThree?.terminationLogic?.retirement?.enabled}
                />
                {isEnabledRetirement && (
                  <div className="flex gap-4">
                    <FormInput
                      control={control}
                      isShownErrorMessage={false}
                      name="stepThree.terminationLogic.retirement.value"
                      numberOnly
                      placeholder="Value"
                    />
                    <IntervalCombobox
                      defaultData={stepThree?.terminationLogic?.retirement?.interval}
                      onSelect={(state) =>
                        setFormData('stepThree.terminationLogic.retirement.interval', state)
                      }
                    />
                  </div>
                )}
              </FormOption>
            </div>
          )}
        </div>
      </div>
      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          {!lockMode && (
            <Button
              className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={prevFormStep}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.BACK} />
            </Button>
          )}
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : stepThree?.terminationLogic?.enabled ? (
              <AppFormattedMessage id={StringKey.NEXT} />
            ) : (
              <AppFormattedMessage id={StringKey.SKIP} />
            )}
          </Button>
        </div>
      </div>
    </>
  );
};
