import { useIntl } from 'react-intl';

import { dateFnsLocaleMap, localeMessagesMap } from '../lang';

export const useLocale = () => {
  const { locale } = useIntl();

  return {
    locale,
    dateFnsLocale: dateFnsLocaleMap[locale],
    messagesLocale: localeMessagesMap[locale],
  };
};
