import { StringKey } from './string-key.enum';

export enum Locale {
  EN = 'en',
  FR = 'fr',
}

export const localeTitleMap: Record<Locale, StringKey> = {
  [Locale.EN]: StringKey.ENGLISH,
  [Locale.FR]: StringKey.FRENCH,
};
