import React, { ReactNode, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CapQuestLogo, EyeIcon } from '../../assets/icons';
import LoaderIcon from '../../assets/icons/LoaderIcon';
import signupVerifyImage from '../../assets/images/signup-verify-image.jpg';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { Input } from '../../components/Input';
import Loader from '../../components/Loader';
import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { useAppMutation, useAppQuery, useEnterEvent } from '../../hooks';
import { StringKey } from '../../lang';
import { ApiService } from '../../services';
import { accessTokenStorage } from '../../storage/accessTokenStorage';

export interface PasswordsDto {
  password: string;
  repeatPassword: string;
}

export interface PasswordRule {
  check: (registerDto: PasswordsDto) => boolean;
  title: ReactNode;
}

export const passwordRules: PasswordRule[] = [
  {
    title: <AppFormattedMessage id={StringKey.AT_LEAST_EIGHT_CHARS} />,
    check: ({ password }: PasswordsDto) => password.length >= 8,
  },
  {
    title: <AppFormattedMessage id={StringKey.AT_LEAST_ONE_LOWER_CASE_LETTER} />,
    check: ({ password }: PasswordsDto) => !!password.match(/.*[a-z].*/)?.[0],
  },
  {
    title: <AppFormattedMessage id={StringKey.AT_LEAST_ONE_UPPER_CASE_LETTER} />,
    check: ({ password }: PasswordsDto) => !!password.match(/.*[A-Z].*/)?.[0],
  },
  {
    title: <AppFormattedMessage id={StringKey.AT_LEAST_ONE_SPECIAL_CHAR} />,
    check: ({ password }: PasswordsDto) => !!password.match(/.*[!@#$%^&*].*/i)?.[0],
  },
  {
    title: 'Passwords match',
    check: ({ password, repeatPassword }: PasswordsDto) =>
      password ? password === repeatPassword : false,
  },
];

interface RegisterDto {
  fullName: string;
  password: string;
  repeatPassword: string;
}

const fullNameRegExp = new RegExp('^[\\p{L}]+(?: [\\p{L}]+)*$', 'u');
const RegisterVerify = () => {
  const [isPasswordShown, setPasswordShown] = useState(false);
  const [isPasswordVerifyShown, setPasswordVerifyShown] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const [registerDto, setRegisterDto] = useState<RegisterDto>({
    fullName: '',
    password: '',
    repeatPassword: '',
  });

  const { isLoading: isLoadingIdCheck } = useAppQuery({
    queryKey: [QueryKey.VERIFICATION_ID_CHECK, id],
    queryFn: () => ApiService.get({ endpoint: BackendRoute.VERIFICATION, routePath: [id || ''] }),
    defaultErrorHandling: false,
    onError: () => navigate('/error-page'),
  });

  const isPasswordValid = passwordRules.every(({ check }) => check(registerDto));
  const isFullNameValid = fullNameRegExp.test(registerDto.fullName.trim());

  const { mutate: mutateRegister, isPending } = useAppMutation([QueryKey.REGISTER_VERIFY, id], {
    mutationFn: async (data: RegisterDto) => {
      const { accessToken } = await ApiService.post<{ accessToken: string }>(
        { endpoint: BackendRoute.AUTH_REGISTER },
        {
          body: {
            verificationId: id,
            ...data,
            fullName: data.fullName.trim(),
          },
        },
      );
      accessTokenStorage.set(accessToken || '');
    },
    onSuccess: () => {
      navigate('/');
    },
  });

  const handleValidateRegistration = useCallback(
    async () => mutateRegister(registerDto),
    [mutateRegister, registerDto],
  );

  useEnterEvent(() => isPasswordValid && isFullNameValid && handleValidateRegistration());

  if (isLoadingIdCheck) return <Loader />;

  return (
    <div className="flex h-full justify-between overflow-hidden font-inter lg:p-6">
      <div className="flex h-full w-full justify-between overflow-y-auto">
        <div className="hidden h-full w-full justify-end overflow-hidden pt-5 lg:flex">
          <div className="max-h-[752px] max-w-[696px]">
            <img alt="" src={signupVerifyImage} />
          </div>
        </div>
        <div className="flex h-full w-full items-center justify-center">
          {!isPending ? (
            <div className="flex h-full flex-col items-center rounded-lg px-[clamp(16px,3%,24px)] pb-10 pt-16">
              <div className="flex w-full max-w-[394px] flex-col gap-8">
                <div>
                  <CapQuestLogo size={48} />
                </div>
                <div className="flex flex-col gap-2">
                  <div
                    className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
                    style={{
                      background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
                    }}
                  >
                    <AppFormattedMessage id={StringKey.WELCOME} />
                  </div>
                  <div className="text-sm text-gray-500">
                    <AppFormattedMessage id={StringKey.FEW_DETAILS} />
                  </div>
                </div>
                <form className="flex flex-col gap-4">
                  <Input
                    autoComplete="new-password"
                    errorMessage={
                      registerDto.fullName === '' ? false : !isFullNameValid && 'Invalid full name'
                    }
                    onChange={(value) => setRegisterDto({ ...registerDto, fullName: value })}
                    placeholder={<AppFormattedMessage id={StringKey.FULL_NAME} />}
                    type="text"
                    value={registerDto.fullName}
                  />
                  <Input
                    autoComplete="new-password"
                    icon={<EyeIcon variant={isPasswordShown ? 'ACTIVE' : 'CLOSE'} />}
                    onChange={(value) => setRegisterDto({ ...registerDto, password: value })}
                    onIconClick={() => setPasswordShown((prev) => !prev)}
                    placeholder={<AppFormattedMessage id={StringKey.NEW_PASSWORD} />}
                    type={isPasswordShown ? 'text' : 'password'}
                    value={registerDto.password}
                  />
                  <Input
                    autoComplete="new-password"
                    icon={<EyeIcon variant={isPasswordVerifyShown ? 'ACTIVE' : 'CLOSE'} />}
                    onChange={(value) => setRegisterDto({ ...registerDto, repeatPassword: value })}
                    onIconClick={() => setPasswordVerifyShown((prev) => !prev)}
                    placeholder={<AppFormattedMessage id={StringKey.REENTER_NEW_PASSWORD} />}
                    type={isPasswordVerifyShown ? 'text' : 'password'}
                    value={registerDto.repeatPassword}
                  />

                  <div className="flex flex-col gap-4 border border-gray-200 p-4 font-inter text-xs text-gray-700">
                    <div className="text-sm">
                      <AppFormattedMessage id={StringKey.PASSWORD_RECOMMENDATION} />
                    </div>
                    <div className="flex flex-col gap-3">
                      {passwordRules.map(({ title, check }, i) => (
                        <div
                          className={`flex items-center gap-2 ${check(registerDto) ? 'text-forest-500' : ''}`}
                          key={i}
                        >
                          <svg
                            fill="none"
                            height="16"
                            viewBox="0 0 16 16"
                            width="16"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M13.3333 4L6 11.3333L2.66667 8"
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.33333"
                            />
                          </svg>
                          <div>{title}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <Button
                    disabled={!isPasswordValid || !isFullNameValid}
                    onClick={handleValidateRegistration}
                    type="button"
                  >
                    <AppFormattedMessage id={StringKey.CREATE_MY_ACCOUNT} />
                  </Button>
                </form>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-center gap-10">
              <LoaderIcon height={28} width={28} />
              <span className="text-sm font-[550] text-brand-700">
                <AppFormattedMessage id={StringKey.CREATING_YOUR_ACCOUNT} />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterVerify;
