import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CloudUploadIconProps = IconProps;

const CloudUploadIcon: FC<CloudUploadIconProps> = ({
  iconColor = '#2565C8',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="46"
    viewBox="0 0 50 46"
    width="50"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.33268 32.8985C3.51872 31.0149 1.66602 27.8071 1.66602 24.1667C1.66602 18.6983 5.8462 14.2063 11.1854 13.7119C12.2776 7.06831 18.0466 2 24.9993 2C31.9521 2 37.7211 7.06831 38.8133 13.7119C44.1525 14.2063 48.3327 18.6983 48.3327 24.1667C48.3327 27.8072 46.48 31.0149 43.666 32.8985M15.666 32.3333L24.9993 23M24.9993 23L34.3327 32.3333M24.9993 23V44"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
);

export default CloudUploadIcon;
