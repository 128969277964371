import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Modal } from '../../../components/Modal';
import { useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';

export type ResendInvitationProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
};

const ResendInvitationModal: FC<ResendInvitationProps> = ({ isOpen, onDismiss, onSuccess }) => {
  const { messagesLocale } = useLocale();
  return (
    <Modal
      description={<AppFormattedMessage id={StringKey.GENERATING_INVITATION_AGAIN_WILL_VOID} />}
      dismissButtonContent={<AppFormattedMessage id={StringKey.NO_KEEP_4} />}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onSuccess={onSuccess}
      successButtonContent={<AppFormattedMessage id={StringKey.YES_RESEND} />}
      title={messagesLocale[StringKey.RESEND_INVITATION] + '?'}
    />
  );
};

export default ResendInvitationModal;
