import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { User } from '../../types/userTypes';
import { useAppQuery } from '../useAppQuery';

export const useUser = () => {
  const { invalidateQueries } = useQueryClient();

  const { data: user, isLoading } = useAppQuery<User>({
    queryKey: [QueryKey.GET_USER],
    queryFn: () => ApiService.get({ endpoint: BackendRoute.USERS }),
  });

  const invalidateQuery = useCallback(() => {
    invalidateQueries({ queryKey: [QueryKey.GET_USER] });
  }, [invalidateQueries]);

  return {
    isLoading,
    invalidateQuery,
    user,
  };
};
