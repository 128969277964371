import React, { FC, ReactNode } from 'react';

import { HeaderContextProvider } from '../../context/headerContext';
import InviteModal from '../../pages/MyCompanies/InviteModal';
import HeaderLayout from '../HeaderLayout';
import SideBarLayout from '../SideBarLayout';

export type AppLayoutProps = {
  children: ReactNode;
  className?: string;
};

const AppLayout: FC<AppLayoutProps> = ({ children, className }) => (
  <>
    <SideBarLayout>
      <HeaderContextProvider>
        <HeaderLayout className={className}>{children}</HeaderLayout>
      </HeaderContextProvider>
    </SideBarLayout>
    <InviteModal />
  </>
);

AppLayout.displayName = 'AppLayout';

export default AppLayout;
