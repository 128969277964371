import React, { FC } from 'react';

import { Stakeholder } from '../../../../types/stakeholderTypes';

type StakeholderCardProps = {
  stakeholder: Stakeholder;
};

export const StakeholderCard: FC<StakeholderCardProps> = ({ stakeholder }) => {
  const stakeholderDilutedSharesCount = 0;
  const stakeholderAvailablePool = 0;
  const stakeholderPoolGranted = 0;

  return (
    <div className="mb-4 flex h-fit w-full min-w-[300px] max-w-[440px] flex-col divide-y-[1px] divide-gray-100 rounded-md shadow-sm">
      <div className="flex flex-col p-3">
        <div className="flex items-center gap-3">
          <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
            {stakeholder.fullName[0]}
          </div>
          <div className="flex flex-col">
            <span className="truncate text-sm font-[550] text-gray-700">
              {stakeholder.fullName}
            </span>
            <span className="truncate text-xs font-[450] text-gray-400">{stakeholder.email}</span>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap divide-x-[1px] divide-y-[1px] divide-[#F2F4F7]">
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Diluted shares
          </span>
          <span className="whitespace-nowrap text-sm text-gray-300">
            {stakeholderDilutedSharesCount || '-'}
          </span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Available pool
          </span>
          <span className="whitespace-nowrap text-sm text-gray-300">
            {stakeholderAvailablePool || '-'}
          </span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Pool granted
          </span>
          <span className="whitespace-nowrap text-sm text-gray-300">
            {stakeholderPoolGranted || '-'}
          </span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Total grant vested
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Shares vested
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Exercisable
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Exercised
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Cash settled
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">
            Returned to pool
          </span>
          <span className="text-sm text-gray-300">-</span>
        </div>
        <div className="flex w-1/2 flex-col items-center gap-3 p-3">
          <span className="whitespace-nowrap text-label-md font-[450] text-[#172335]">Expired</span>
          <span className="text-sm text-gray-300">-</span>
        </div>
      </div>
    </div>
  );
};
