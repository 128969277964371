import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Modal } from '../../../components/Modal';
import { useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';

export type DeleteStakeholderModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
};

const DeleteStakeholderModal: FC<DeleteStakeholderModalProps> = ({
  isOpen,
  onDismiss,
  onSuccess,
}) => {
  const { messagesLocale } = useLocale();
  return (
    <Modal
      description={
        <AppFormattedMessage id={StringKey.ALL_ASSOCIATED_STAKEHOLDER_DATA_WILL_BE_LOST} />
      }
      dismissButtonContent={<AppFormattedMessage id={StringKey.NO_KEEP_3} />}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onSuccess={onSuccess}
      successButtonContent={messagesLocale[StringKey.CONFIRM_DELETE]}
      title={
        <>
          <AppFormattedMessage id={StringKey.DELETE_STAKEHOLDER} values={{ count: 1 }} />?
        </>
      }
    />
  );
};

export default DeleteStakeholderModal;
