import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { TerminatePoolDto } from '../../types/pool-plans.types';
import { toBackendDateFormat } from '../../utils/toBackendDateFormat';
import { useAppMutation } from '../useAppMutation';

export const useTerminate = () => {
  const { mutate: terminate, isPending } = useAppMutation([QueryKey.TERMINATE_SHARE_PLAN], {
    mutationFn: ({ date, planId, companyId }: { planId: string; date: Date; companyId: string }) =>
      ApiService.patch<unknown, TerminatePoolDto>(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'share-plans', planId, 'terminate'],
        },
        { body: { date: toBackendDateFormat(date) } },
      ),
  });
  return { terminate, isPending };
};
