import React, { FC, ReactNode, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Pool, useReactForm } from '../../../../hooks';
import { DilutionCondition } from '../../../../types/pool-plans.types';
import { toBackendDateFormat } from '../../../../utils/toBackendDateFormat';
import { FinalStep, StepOne, StepTwo } from '../Steps';
import { ChildrenFormProps } from '../type';
import { FormSchema, formSchema } from '../Validation';

export type AddPoolFormProps = {
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
};

const formSteps: ((props: ChildrenFormProps) => ReactNode)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <FinalStep {...props} />,
];

export const AddPoolForm: FC<AddPoolFormProps> = ({ companyId, handleClose, invalidateQuery }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    formState: { errors },
  } = useReactForm({
    defaultValues: { stepOne: { dilutionCondition: DilutionCondition.INCLUDE_CAP_TABLE } },
    schema: formSchema,
  });
  const { create } = Pool.useCreate();

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { stepOne, stepTwo } = data;
      create(
        {
          companyId,
          data: {
            capTableInclusion: DilutionCondition.INCLUDE_CAP_TABLE === stepOne.dilutionCondition,
            date: toBackendDateFormat(stepOne.date),
            dillutedSharesCount: stepOne.dillutedSharesCount,
            name: stepOne.name,
            additionalNotes: stepTwo?.additionalNotes,
            filesLinks:
              stepTwo?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
            shareClassId: stepOne.shareClass.id,
          },
        },
        {
          onSuccess: () => {
            toast.success('New pool created successfully');
            invalidateQuery();
            handleClose();
          },
        },
      );
    },
    [companyId, create, handleClose, invalidateQuery],
  );

  const handleCloseModal = useCallback(() => {
    reset();
    handleClose();
    setCurrentStep(1);
  }, [reset, handleClose]);

  const handleNextStep = useCallback(() => {
    const nextStep = currentStep + 1;
    if (nextStep === Object.keys(formSteps).length + 1) {
      handleSubmit(submitHandler)();
      return;
    }
    if (nextStep > Object.keys(formSteps).length) return;

    setCurrentStep(nextStep);
  }, [currentStep, handleSubmit, submitHandler]);

  const handlePrevStep = useCallback(() => {
    const prevStep = currentStep - 1;
    if (prevStep < 1) return;
    setCurrentStep(prevStep);
  }, [currentStep]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
      <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
        <span className="text-xl font-[550] text-gray-700">Add new Pool</span>
        <CloseModalButton onClose={handleCloseModal} />
      </div>
      <div className="flex w-full items-center gap-[5px] bg-white px-6 pb-6">
        {formSteps.map((_, i) => (
          <div
            className={twMerge(
              'h-1 w-full rounded-[25px]',
              i + 1 <= currentStep ? 'bg-[#12B76A]' : 'bg-gray-100',
              i + 1 === currentStep &&
                currentStep === formSteps.length &&
                'bg-gradient-to-r from-forest-500 to-forest-300',
            )}
            key={`${i}_${companyId}`}
          />
        ))}
      </div>

      <div className="flex h-full flex-col gap-4 overflow-hidden">
        {formSteps[currentStep - 1]({
          companyId,
          formData: getValues,
          nextFormStep: handleNextStep,
          prevFormStep: handlePrevStep,
          setFormData: setValue,
          control,
          clearErrors,
          handleCloseModal,
          setError,
          filedState: getFieldState,
          errors,
        })}
      </div>
    </div>
  );
};
