import React, { FC, memo } from 'react';

import { CloseIcon } from '../../assets/icons';
import Button from '../Button';

export type ButtonsProps = {
  onClose: () => void;
};

export const CloseModalButton: FC<ButtonsProps> = memo(({ onClose }) => {
  return (
    <Button
      className="h-fit w-fit rounded p-2 transition-colors hover:bg-gray-200"
      onClick={onClose}
      styleType="NONE"
    >
      <CloseIcon className="h-3 w-3" iconColor="#344054" />
    </Button>
  );
});
