import { UseMutateFunction, useQueryClient } from '@tanstack/react-query';
import React, {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { QueryKey } from '../../constants';
import { Company as CompanyHook, useSelectedCompany } from '../../hooks';
import { companySettingsTabStore } from '../../storage/companySettingsTabStore';
import { Company, PatchCompanyDto } from '../../types/companyTypes';
import AddressFormCard from './Address';
import DetailsFormCard from './Details';
import PlanBilling from './PlanBilling';
import { arrayOfCompanySettingsCardTitleMap, CompanySettingsCardTab } from './types';

export const filterArray = <T,>({
  array,
  filterFunc,
  query,
}: {
  query: string;
  array: T[];
  filterFunc: (item: T) => boolean;
}) => (query ? array.filter(filterFunc) : array);

export const useFilteredData = <T,>({
  data = [],
  filterFunc,
  inputValue,
}: {
  data?: T[];
  inputValue: string;
  filterFunc: (item: T) => boolean;
}) =>
  useMemo(
    () => filterArray({ array: data, query: inputValue, filterFunc }),
    [data, filterFunc, inputValue],
  );

export type CompanySettingsCardComponentProps = {
  data: Company;
  isEditing: boolean;
  invalidateQuery: () => void;
  updateData: UseMutateFunction<unknown, unknown, PatchCompanyDto>;
  setEditing: Dispatch<SetStateAction<boolean>>;
  companyId?: string;
};

const companySettingsCardComponentVariant: Record<
  CompanySettingsCardTab,
  (props: CompanySettingsCardComponentProps) => ReactNode
> = {
  [CompanySettingsCardTab.COMPANY_ADDRESS]: (props) => <AddressFormCard {...props} />,
  [CompanySettingsCardTab.COMPANY_DETAILS]: (props) => <DetailsFormCard {...props} />,
  [CompanySettingsCardTab.COMPANY_PLAN_BILLING]: (props) => <PlanBilling {...props} />,
};

const CompanySettings: FC = () => {
  const { isLoading, selectedCompany, isFetched } = useSelectedCompany();
  const id = selectedCompany?.id;
  const queryClient = useQueryClient();
  const [isEditing, setEditing] = useState(false);
  const [selectedCard, setSelectedCard] = useState<CompanySettingsCardTab>(
    companySettingsTabStore.get() || CompanySettingsCardTab.COMPANY_DETAILS,
  );
  companySettingsTabStore.delete();

  const { patch } = CompanyHook.useUpdate();

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_SELECTED_COMPANY],
    });
  }, [queryClient]);

  if (!selectedCompany || isLoading || !isFetched) return <Loader />;

  return (
    <div className="flex h-fit min-h-full w-full flex-col gap-4 rounded-md lg:p-4 lg:shadow-sm">
      <div className="flex h-[38px] w-full gap-4 border-b-[1px] border-gray-200">
        {arrayOfCompanySettingsCardTitleMap.map(([cardKey, card]) => (
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
              selectedCard === cardKey && 'border-brand-700 font-[550] text-brand-700',
            )}
            key={cardKey}
            onClick={() => {
              setSelectedCard(cardKey);
              setEditing(false);
            }}
            styleType="NONE"
          >
            {card}
          </Button>
        ))}
      </div>
      {companySettingsCardComponentVariant[selectedCard]({
        data: selectedCompany,
        setEditing,
        isEditing,
        companyId: id,
        updateData: (props, params) =>
          patch(
            { companyId: id || '', data: props },
            {
              ...params,
              onSuccess: (data, variable, context) => {
                params?.onSuccess?.(data, variable, context);
                setEditing(false);
                toast.success('Company info successfully updated');
              },
            },
          ),
        invalidateQuery,
      })}
    </div>
  );
};

export default CompanySettings;
