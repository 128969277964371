import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { Plan } from '../../types/planTypes';
import { useAppQuery } from '../useAppQuery';

type PlanProps = {
  onError?: (error: HttpException | unknown) => void;
  enabled?: boolean;
};

export const usePlans = ({ enabled, onError }: PlanProps = {}) => {
  const { invalidateQueries } = useQueryClient();
  const { data: plans, isLoading } = useAppQuery<Plan[]>({
    queryKey: [QueryKey.GET_PLANS],
    queryFn: () => ApiService.get({ endpoint: BackendRoute.PLANS }),
    onError,
    enabled,
  });
  const invalidateQuery = useCallback(() => {
    invalidateQueries({ queryKey: [QueryKey.GET_PLANS] });
  }, [invalidateQueries]);
  return { plans, invalidateQuery, isLoading };
};
