import { enGB } from 'date-fns/locale';
import { ComponentProps, FC } from 'react';
import { DayPicker } from 'react-day-picker';
import { twMerge } from 'tailwind-merge';

export type CalendarProps = ComponentProps<typeof DayPicker>;

export const Calendar: FC<CalendarProps> = ({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}) => {
  return (
    <DayPicker
      className={twMerge('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4 ',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-gray-700 font-[550] text-sm',
        nav: 'space-x-1 flex items-center',
        nav_button: twMerge(
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 disabled:hidden',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-muted-foreground rounded-md w-10 font-normal text-[0.8rem] text-gray-500',
        row: 'flex w-full mt-1',
        cell: twMerge(
          'h-8 w-10 text-center text-sm p-0 relative',
          '[&:has([aria-selected].day-range-end)]:rounded-r-md',
          '[&:has([aria-selected].day-outside)]:bg-accent/50',
          'first:[&:has([aria-selected])]:rounded-l-md',
          'last:[&:has([aria-selected])]:rounded-r-md',
          'focus-within:relative focus-within:z-20',
          'hover:bg-gray-200 rounded-full',
        ),
        day: twMerge('h-8 w-10 p-0 font-normal aria-selected:opacity-100'),
        day_range_end: 'day-range-end rounded-l-md',
        day_range_start: 'day-range-start rounded-r-md',
        day_selected:
          'rounded-full bg-brand-700 text-white hover:bg-brand-700 hover:text-white focus:bg-brand-700 focus:text-white',
        day_today: twMerge(
          '[&:not(.day-selected):not(.day-range-middle):not(.day-range-start):not(.day-range-end)]:border-[1px] border-gray-700 rounded-full',
        ),
        day_outside:
          'day-outside text-muted-foreground opacity-100 aria-selected:bg-accent/50 aria-selected:text-muted-foreground',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle:
          'day-range-middle aria-selected:bg-accent aria-selected:text-accent-foreground rounded-none !bg-brand-25 !text-gray-700',
        day_hidden: 'invisible',
        ...classNames,
      }}
      locale={enGB}
      showOutsideDays={showOutsideDays}
      {...props}
    />
  );
};
