import { StringKey } from '../lang';
import { StakeholderRole, StakeholderType, StakeholderTypeGroup } from '../types/stakeholderTypes';

export const stakeholderRoleTranslation: Record<StakeholderRole, StringKey> = {
  [StakeholderRole.ADMIN]: StringKey.ADMIN,
  [StakeholderRole.CONTRIBUTOR]: StringKey.CONTRIBUTOR,
  [StakeholderRole.STAKEHOLDER]: StringKey.STAKEHOLDER,
};

export const stakeholderTypeTranslation: Record<StakeholderType, StringKey> = {
  [StakeholderType.ADVISOR]: StringKey.ADVISOR,
  [StakeholderType.ANGEL]: StringKey.ANGEL,
  [StakeholderType.EMPLOYEE]: StringKey.EMPLOYEE,
  [StakeholderType.FOUNDER]: StringKey.FOUNDER,
  [StakeholderType.BOARD_MEMBERS]: StringKey.BOARD_MEMBERS,
  [StakeholderType.VCS]: StringKey.VCS,
  [StakeholderType.INVESTMENT_BANKS]: StringKey.INVESTMENT_BANKS,
  [StakeholderType.PES]: StringKey.PES,
  [StakeholderType.FAMILY_OFFICE]: StringKey.FAMILY_OFFICE,
  [StakeholderType.OTHER_INST]: StringKey.OTHER_INST,
};

export const arrayOfStakeholderTypeTranslation = Object.entries(stakeholderTypeTranslation);

export const stakeholderTypeGroupTranslation: Record<StakeholderTypeGroup, StringKey> = {
  [StakeholderTypeGroup.COMPANY]: StringKey.STAKEHOLDER_COMPANY_GROUP,
  [StakeholderTypeGroup.HUMAN]: StringKey.STAKEHOLDER_HUMAN_GROUP,
};

export const arrayOfStakeholderTypeGroupTranslation = Object.entries(
  stakeholderTypeGroupTranslation,
);
