import React, { FC } from 'react';

import { AddDocumentIcon, CircleConfirmIcon, CloseCircleIcon } from '../../assets/icons';
import { useFormat } from '../../hooks';
import { ShareClass } from '../../types/share-classes.types';
import { getRoundedNumber } from '../../utils/getRoundedNumber';

export type ShareClassItemProps = {
  stakeholders: number;
} & Pick<
  ShareClass,
  | 'antiDilutionRights'
  | 'conversionRatio'
  | 'creationDate'
  | 'dividendRightEnabled'
  | 'docLink'
  | 'id'
  | 'liquidityPreferences'
  | 'name'
  | 'votingRight'
  | 'isUsed'
>;

export const ShareClassItem: FC<ShareClassItemProps> = ({
  conversionRatio,
  dividendRightEnabled,
  name,
  stakeholders,
  votingRight,
  antiDilutionRights,
  creationDate = new Date(),
  docLink,
  liquidityPreferences,
  isUsed,
}) => {
  const { format } = useFormat();
  const handleDocumentClick = (link: string) => {
    window.open(link, '_blank');
  };

  return (
    <div className="flex w-full max-w-[432px] flex-col divide-y-[1px] divide-gray-100 rounded-md bg-white shadow-sm">
      <div className="flex w-full items-center justify-between gap-8 p-3">
        <div className="flex flex-col gap-3 overflow-hidden">
          <span className="w-full truncate text-base font-[550] text-gray-700">{name}</span>
          <span className="text-xs font-[450] text-gray-700">
            {format(creationDate, 'dd/MM/yyyy')}
          </span>
        </div>
        {isUsed ? (
          <span className="shrink-0 rounded-2xl bg-forest-50 px-2 py-[2px] text-label-md font-[450] text-forest-600">
            In Use
          </span>
        ) : (
          <span className="shrink-0 rounded-2xl bg-fireside-50 px-2 py-[2px] text-label-md font-[450] text-fireside-600">
            Not Used
          </span>
        )}
      </div>
      <div className="flex w-full items-center justify-between p-3">
        <span className="text-sm font-[450] text-gray-700">Share holders</span>
        <span className="text-sm font-[450] text-gray-700">{stakeholders}</span>
      </div>
      <div className="flex w-full flex-wrap items-center justify-between gap-4 p-3">
        <span className="text-sm font-[450] text-gray-700">Share holders</span>
        <div className="flex gap-3 text-xs font-normal">
          <div className="flex items-center gap-1">
            {dividendRightEnabled ? <CircleConfirmIcon /> : <CloseCircleIcon className="size-4" />}
            Dividends
          </div>

          <div className="flex items-center gap-1">
            {antiDilutionRights ? <CircleConfirmIcon /> : <CloseCircleIcon className="size-4" />}
            Anti - Dilution
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-between p-3">
        <span className="text-sm font-[450] text-gray-700">Voting right</span>
        <span className="text-sm font-[450] text-gray-700">{votingRight}</span>
      </div>
      <div className="flex w-full items-center justify-between p-3">
        <span className="text-sm font-[450] text-gray-700">Conversion ratio</span>
        <span className="text-sm font-[450] text-gray-700">
          {conversionRatio ? getRoundedNumber(conversionRatio) + 'x' : '-'}
        </span>
      </div>
      <div className="flex w-full items-center justify-between p-3">
        <span className="text-sm font-[450] text-gray-700">Seniority</span>
        <span className="text-sm font-[450] text-gray-700">
          {liquidityPreferences ? liquidityPreferences?.seniority : '-'}
        </span>
      </div>
      <div className="flex w-full flex-wrap items-center justify-between gap-4 overflow-hidden p-3">
        <span className="text-sm font-[450] text-gray-700">Liquidity preference</span>
        {liquidityPreferences ? (
          <div className="flex w-fit max-w-[150px] flex-col text-wrap break-all text-xs font-normal text-gray-700">
            <span>Multiple: {liquidityPreferences.multiple}</span>
            {liquidityPreferences.participating?.capValue && (
              <span>Participating Cap: {liquidityPreferences.participating?.capValue}</span>
            )}
            {liquidityPreferences.participating?.interest && (
              <span>Interest: {liquidityPreferences.participating?.interest}%</span>
            )}
          </div>
        ) : (
          '-'
        )}
      </div>

      <div className="flex w-full items-center justify-between p-3">
        <span className="text-sm font-[450] text-gray-700">Document</span>
        <span
          className="cursor-pointer text-sm font-[450] text-gray-700"
          onClick={() => docLink && handleDocumentClick(docLink)}
        >
          {docLink ? <AddDocumentIcon /> : '-'}
        </span>
      </div>
    </div>
  );
};
