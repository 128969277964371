import React, { FC, useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import Loader from '../../components/Loader';
import { DeprecatedModalWrapper } from '../../components/Modal';
import { User } from '../../hooks';
import { useModalState } from '../../hooks';
import { accessTokenStorage } from '../../storage/accessTokenStorage';
import DeleteAccountModal from './DeleteAccountModal';
import ProfileEditForm from './ProfileEditForm';
const Profile: FC = () => {
  const [isEditing, setEditing] = useState(false);

  const { user, isLoading, invalidateQuery } = User.useUser();
  const { update } = User.useUpdate();
  const { deleteUser } = User.useDelete();

  const {
    isOpen: isOpenDeleteModal,
    handleDismissModal,
    handleOpenModal,
    handleSuccessModal,
  } = useModalState({ onSuccess: () => handleDeleteProfile() });

  const handleDeleteProfile = useCallback(
    () =>
      deleteUser(undefined, {
        onSuccess: () => {
          accessTokenStorage.delete();
          window.location.reload();
        },
      }),
    [deleteUser],
  );

  if (!user || isLoading) return <Loader />;

  return (
    <>
      <DeprecatedModalWrapper isOpen={isOpenDeleteModal}>
        <DeleteAccountModal onClose={handleDismissModal} onSuccess={handleSuccessModal} />
      </DeprecatedModalWrapper>
      <ProfileEditForm
        {...user}
        handleOpenDeleteModal={handleOpenModal}
        isEditing={isEditing}
        patchUser={(data, callbacks) =>
          update(
            { data },
            {
              onSuccess: (data, variables, context) => {
                toast.success('Data successfully updated');
                callbacks?.onSuccess?.(data, variables.data, context);
                setEditing(false);
                invalidateQuery();
              },
            },
          )
        }
        setEditing={setEditing}
      />
    </>
  );
};

Profile.displayName = 'Profile';

export default Profile;
