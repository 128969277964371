import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';

import { InfoCircle } from '../../assets/icons';
import {
  InputStatus,
  inputStatusToPlaceholderClassNamesMap,
  inputStatusToWrapperClassNamesMap,
} from './constants';

export type FileInputProps = {
  placeholder?: ReactNode;
  value: File | undefined;
  isError?: boolean;
  errorMessage?: false | string;
  isSuccess?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  onChange: (params?: File) => void;
  wrapperClassName?: string;
  onIconClick?: React.MouseEventHandler<HTMLLabelElement>;
  iconWrapper?: string;
  description?: false | string | ReactNode;
  accept?: { [key: string]: string[] };
};

const FileInput: FC<FileInputProps> = ({
  value,
  placeholder,
  isError,
  errorMessage,
  isSuccess,
  disabled,
  icon,
  wrapperClassName,
  onChange,
  onIconClick,
  description,
  iconWrapper,
  accept,
}) => {
  const [status, setStatus] = useState(InputStatus.IDLE_EMPTY_DASHED);
  useEffect(() => {
    if (isError || errorMessage) {
      setStatus(InputStatus.ERROR);
      return;
    }

    if (isSuccess) {
      setStatus(InputStatus.SUCCESS);
      return;
    }

    setStatus(InputStatus.FOCUSED);

    setStatus(value ? InputStatus.IDLE_FULL : InputStatus.IDLE_EMPTY_DASHED);
  }, [errorMessage, isError, isSuccess, value]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onChange?.(acceptedFiles[0]);
    },
    [onChange],
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: false,
    accept,
  });

  return (
    <div
      className={twMerge('flex cursor-text flex-col gap-3 disabled:cursor-auto', wrapperClassName)}
    >
      <div
        className={twMerge(
          'relative flex h-14 items-center gap-2.5 rounded-t bg-gray-50 px-2.5',
          inputStatusToWrapperClassNamesMap[status],
          disabled && 'cursor-default !border-transparent',
        )}
        style={{
          backgroundImage: value
            ? 'none'
            : `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23475467' stroke-width='1' stroke-dasharray='8%2c 5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
          backgroundRepeat: value ? 'none' : 'no-repeat',
          backgroundPosition: value ? 'initial' : 'bottom',
          backgroundSize: value ? 'initial' : '100% 1px',
        }}
      >
        {placeholder && (
          <div
            className={twMerge(
              'pointer-events-none absolute left-2.5 top-1/2 -translate-y-1/2 font-inter text-sm font-[450] transition-all duration-75',
              inputStatusToPlaceholderClassNamesMap[status],
              value ? '!top-[8px] !translate-y-0 !text-xs' : '',
            )}
          >
            {placeholder}
          </div>
        )}
        <div className="flex h-full w-full flex-col justify-end overflow-hidden pb-2">
          <input
            {...getInputProps()}
            className="z-1 hidden w-full bg-transparent font-inter text-sm font-[450] text-gray-700"
          />
          <label
            {...getRootProps()}
            className={twMerge(
              'z-1 flex h-full w-full items-end bg-transparent font-inter text-sm font-[450] text-gray-700',
              disabled ? 'cursor-default' : 'cursor-pointer',
            )}
          >
            <span className="truncate">{value?.name}</span>
          </label>
        </div>
        {icon && (
          <label
            className={twMerge('flex gap-3 hover:cursor-pointer', iconWrapper)}
            onClick={(e) => {
              value ? onIconClick?.(e) : open();
            }}
          >
            {icon}
          </label>
        )}
      </div>
      {(errorMessage || description) && (
        <div className="flex items-center gap-1">
          <InfoCircle iconColor={description && !errorMessage ? '#475467' : undefined} />
          <div
            className={twMerge(
              'whitespace-pre-line text-left font-inter text-xs font-[450]',
              errorMessage ? 'text-fireside-600' : 'text-gray-600',
            )}
          >
            {errorMessage || description}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileInput;
