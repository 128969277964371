import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Modal } from '../../../components/Modal';
import { useLocale } from '../../../hooks';
import { StringKey } from '../../../lang';

export type RevokeAccessProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
};

const RevokeAccessModal: FC<RevokeAccessProps> = ({ isOpen, onDismiss, onSuccess }) => {
  const { messagesLocale } = useLocale();
  return (
    <Modal
      description={<AppFormattedMessage id={StringKey.NOT_ABLE_TO_USE_STAKEHOLDER} />}
      dismissButtonContent={<AppFormattedMessage id={StringKey.NO_KEEP_5} />}
      isOpen={isOpen}
      onDismiss={onDismiss}
      onSuccess={onSuccess}
      successButtonContent={<AppFormattedMessage id={StringKey.YES_REVOKE} />}
      title={messagesLocale[StringKey.REVOKE_ACCESS] + '?'}
    />
  );
};

export default RevokeAccessModal;
