import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CircleArrowUpIconProps = IconProps;

const CircleArrowUpIcon: FC<CircleArrowUpIconProps> = ({
  iconColor = '#ffffff',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="18"
    viewBox="0 0 18 18"
    width="18"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 9L9 6M9 6L6 9M9 6V12M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.35"
    />
  </svg>
);

export default CircleArrowUpIcon;
