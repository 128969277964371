import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type ThreeDotsIconProps = IconProps;

const ThreeDotsIcon: FC<ThreeDotsIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 13 4"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.32992 2.66683C6.69811 2.66683 6.99658 2.36835 6.99658 2.00016C6.99658 1.63197 6.69811 1.3335 6.32992 1.3335C5.96173 1.3335 5.66325 1.63197 5.66325 2.00016C5.66325 2.36835 5.96173 2.66683 6.32992 2.66683Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M10.9966 2.66683C11.3648 2.66683 11.6632 2.36835 11.6632 2.00016C11.6632 1.63197 11.3648 1.3335 10.9966 1.3335C10.6284 1.3335 10.3299 1.63197 10.3299 2.00016C10.3299 2.36835 10.6284 2.66683 10.9966 2.66683Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M1.66325 2.66683C2.03144 2.66683 2.32992 2.36835 2.32992 2.00016C2.32992 1.63197 2.03144 1.3335 1.66325 1.3335C1.29506 1.3335 0.996582 1.63197 0.996582 2.00016C0.996582 2.36835 1.29506 2.66683 1.66325 2.66683Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
  </svg>
);

export default ThreeDotsIcon;
