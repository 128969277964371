import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CircleCheckIconProps = IconProps;

const CircleCheckIcon: FC<CircleCheckIconProps> = ({
  iconColor = '#2FB487',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="50"
    viewBox="0 0 50 50"
    width="50"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M48.3327 22.8665V25.0132C48.3298 30.0448 46.7005 34.9408 43.6878 38.9708C40.6751 43.0008 36.4403 45.949 31.6152 47.3756C26.79 48.8023 21.633 48.631 16.9131 46.8872C12.1933 45.1435 8.16354 41.9208 5.42492 37.6997C2.6863 33.4786 1.38553 28.4853 1.71659 23.4646C2.04766 18.4438 3.99283 13.6646 7.262 9.8397C10.5312 6.01477 14.9492 3.34908 19.8571 2.2402C24.7651 1.13132 29.9 1.63865 34.496 3.68653M48.3327 6.33317L24.9993 29.6898L17.9993 22.6898"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
);

export default CircleCheckIcon;
