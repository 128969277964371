import React, { FC, useState } from 'react';

import { FilterVariant } from '../../types/companyTypes';
import MyCompaniesHeader from './components/Header';
import MyCompaniesItemsList from './MyCompaniesItemsList';

export const MyCompanies: FC = () => {
  const [selectedFilter, setSelectedFiler] = useState<FilterVariant>('all');

  return (
    <div className="flex h-fit min-h-full w-full flex-col gap-4 rounded-md border-gray-100 lg:border-[1px] lg:shadow-sm">
      <MyCompaniesHeader selectedFilter={selectedFilter} setSelectedFilter={setSelectedFiler} />
      <div className="h-[1px] w-full bg-gray-100" />
      <div className="min-h-[96px]">
        <MyCompaniesItemsList selectedFilter={selectedFilter} />
      </div>
    </div>
  );
};
