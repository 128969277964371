import { Description } from '@radix-ui/react-alert-dialog';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const AlertDialogDescription = forwardRef<
  ElementRef<typeof Description>,
  ComponentPropsWithoutRef<typeof Description>
>(({ className, ...props }, ref) => (
  <Description
    className={twMerge('text-zinc-500 dark:text-zinc-400 text-sm', className)}
    ref={ref}
    {...props}
  />
));
AlertDialogDescription.displayName = Description.displayName;
