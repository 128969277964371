import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { CreateEventDto } from '../../types/events.types';
import { useAppMutation } from '../useAppMutation';

export const useCreate = () => {
  const {
    mutate: create,
    isPending,
    data,
  } = useAppMutation<
    CreateEventDto & { id: string },
    HttpException,
    { data: CreateEventDto; companyId: string }
  >([QueryKey.CREATE_EVENT], {
    mutationFn: ({ companyId, data }) =>
      ApiService.post(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'events'] },
        { body: data },
      ),
  });

  return { create, isPending, data };
};
