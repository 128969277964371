import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Modal } from '../../../components/Modal';
import { StringKey } from '../../../lang';

export type MakeContributorProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
};

const MakeContributorModal: FC<MakeContributorProps> = ({ isOpen, onDismiss, onSuccess }) => (
  <Modal
    description={<AppFormattedMessage id={StringKey.ARE_YOU_SURE_YOU_WANT_MAKE_CONTRIBUTOR} />}
    dismissButtonContent={<AppFormattedMessage id={StringKey.NO} />}
    isOpen={isOpen}
    onDismiss={onDismiss}
    onSuccess={onSuccess}
    successButtonClassName="bg-brand-700"
    successButtonContent={<AppFormattedMessage id={StringKey.YES} />}
    title={<AppFormattedMessage id={StringKey.MAKE_CONTRIBUTOR} />}
  />
);

export default MakeContributorModal;
