export enum TextareaStatus {
  IDLE_EMPTY = 'idle-empty',
  IDLE_EMPTY_DASHED = 'idle-empty-dashed',
  IDLE_FULL = 'idle-full',
  FOCUSED = 'focused',
  ERROR = 'error',
  SUCCESS = 'success',
  WITHOUT_BORDER = 'without-border',
}

export const textareaStatusToWrapperClassNamesMap: Record<TextareaStatus, string> = {
  [TextareaStatus.IDLE_EMPTY]: 'border-b-[1px] border-gray-600',
  [TextareaStatus.IDLE_FULL]: 'border-b-[1px] border-gray-700',
  [TextareaStatus.FOCUSED]: 'border-b-[2px] border-brand-700',
  [TextareaStatus.ERROR]: 'border-b-[2px] border-fireside-600',
  [TextareaStatus.SUCCESS]: 'border-b-[2px] border-forest-500',
  [TextareaStatus.IDLE_EMPTY_DASHED]: '!border-b-[1px] !border-gray-600 !border-dashed',
  [TextareaStatus.WITHOUT_BORDER]: '',
};

export const textareaStatusToPlaceholderClassNamesMap: Record<TextareaStatus, string> = {
  [TextareaStatus.IDLE_EMPTY]: '!text-gray-400',
  [TextareaStatus.IDLE_EMPTY_DASHED]: '!text-gray-400',
  [TextareaStatus.IDLE_FULL]: '!text-gray-500',
  [TextareaStatus.FOCUSED]: '!text-brand-700',
  [TextareaStatus.ERROR]: '!text-fireside-600',
  [TextareaStatus.SUCCESS]: '!text-forest-500',
  [TextareaStatus.WITHOUT_BORDER]: '!text-gray-500',
};
