import { Combobox as HeadlessCombobox } from '@headlessui/react';
import React, { ComponentPropsWithoutRef, memo, ReactElement, useCallback } from 'react';
import { Control, FieldPathByValue, FieldValues, PathValue, useController } from 'react-hook-form';

export type FormComboboxProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
> = {
  control: Control<TFieldValues>;
  defaultValue?: PathValue<TFieldValues, TPath>;
  onSelect?: (value: PathValue<TFieldValues, TPath>) => void;
  name: TPath;
} & Omit<
  ComponentPropsWithoutRef<typeof HeadlessCombobox>,
  'defaultValue' | 'onBlur' | 'onChange' | 'onSelect' | 'value'
>;

const FormCombobox = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
>({
  control,
  defaultValue,
  onSelect,
  name,
  ...props
}: FormComboboxProps<TFieldValues, TPath>): ReactElement | null => {
  const { field } = useController({
    control,
    defaultValue,
    name,
  });

  const handleChange = useCallback(
    (value: PathValue<TFieldValues, TPath>) => {
      field.onChange(value);
      onSelect?.(value);
    },
    [field, onSelect],
  );
  return (
    <HeadlessCombobox
      as={'div'}
      {...props}
      {...field}
      onChange={handleChange}
      value={(field.value || '') as unknown}
    >
      {props.children}
    </HeadlessCombobox>
  );
};

export default memo(FormCombobox) as typeof FormCombobox;
