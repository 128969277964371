import { CardExpiryElement, CardExpiryElementProps } from '@stripe/react-stripe-js';
import {
  StripeCardExpiryElementChangeEvent,
  StripeCardExpiryElementOptions,
  StripeElementType,
} from '@stripe/stripe-js';
import React, { FC, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { InfoCircle } from '../../assets/icons';
import { StringKey } from '../../lang';
import { AppFormattedMessage } from '../AppFormattedMessage';
import {
  InputStatus,
  inputStatusToPlaceholderClassNamesMap,
  inputStatusToWrapperClassNamesMap,
} from '../Input/constants';

export type StripeCardExpiryInputProps = {
  isSuccess?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  iconBeforeInput?: ReactNode;
  wrapperClassName?: string;
  onIconClick?: () => void;
  onError?: (error: StripeCardExpiryElementChangeEvent['error']) => void;
  onSuccess?: (status: true) => void;
  onIconBeforeInputClick?: () => void;
  onFocus?: (event: { elementType: StripeElementType }) => void;
  onBlur?: (event: { elementType: StripeElementType }) => void;
  onChange?: (event: StripeCardExpiryElementChangeEvent) => void;
  options?: Omit<StripeCardExpiryElementOptions, 'disabled'>;
} & CardExpiryElementProps;

const StripeCardExpiryInput: FC<StripeCardExpiryInputProps> = memo(
  ({
    onChange,
    isSuccess,
    disabled,
    icon,
    wrapperClassName,
    onIconClick,
    onIconBeforeInputClick,
    iconBeforeInput,
    onBlur,
    onFocus,
    options,
    className,
    onError,
    onSuccess,
    ...props
  }) => {
    const [isInputError, setError] = useState<StripeCardExpiryElementChangeEvent['error'] | null>(
      null,
    );
    const [isInputEmpty, setEmptyInput] = useState(true);
    const [isFocused, setIsFocused] = useState(false);
    const [status, setStatus] = useState(InputStatus.IDLE_EMPTY);
    useEffect(() => {
      if (isInputError) {
        setStatus(InputStatus.ERROR);
        return;
      }

      if (isSuccess) {
        setStatus(InputStatus.SUCCESS);
        return;
      }

      if (isFocused) {
        setStatus(InputStatus.FOCUSED);
        return;
      }

      setStatus(!isInputEmpty ? InputStatus.IDLE_FULL : InputStatus.IDLE_EMPTY);
    }, [isFocused, isInputEmpty, isInputError, isSuccess]);

    const handleFocus = useCallback(
      (event: { elementType: StripeElementType }) => {
        setIsFocused(true);
        onFocus?.(event);
      },
      [onFocus, setIsFocused],
    );

    const handleBlur = useCallback(
      (event: { elementType: StripeElementType }) => {
        setIsFocused(false);
        onBlur?.(event);
      },
      [setIsFocused, onBlur],
    );

    const handleChange = useCallback(
      (event: StripeCardExpiryElementChangeEvent) => {
        setEmptyInput(event.empty);
        setError(event.error);
        event.error && onError?.(event.error);
        event.complete && onSuccess?.(true);
        onChange?.(event);
      },
      [onChange, onError, onSuccess],
    );

    return (
      <label
        className={twMerge(
          'flex w-full cursor-text flex-col gap-3 disabled:cursor-auto',
          wrapperClassName,
        )}
        htmlFor="stripe-expiry-number"
      >
        <div
          className={`relative flex h-14 items-center gap-2.5 rounded-t bg-gray-50 px-2.5 ${inputStatusToWrapperClassNamesMap[status]} ${disabled && '!border-transparent'}`}
        >
          {iconBeforeInput && (
            <div
              className={`${onIconClick ? 'cursor-pointer' : ''}`}
              onClick={onIconBeforeInputClick}
            >
              {iconBeforeInput}
            </div>
          )}

          <div
            className={`pointer-events-none absolute left-2.5 top-1/2 -translate-y-1/2 font-inter text-sm font-[450] transition-all duration-75 ${inputStatusToPlaceholderClassNamesMap[status]} ${isFocused || !isInputEmpty ? '!top-[8px] !translate-y-0 !text-xs' : ''}`}
          >
            <AppFormattedMessage id={StringKey.EXPIRY} />
          </div>
          <div className="flex h-full w-full flex-col justify-end pb-2">
            <CardExpiryElement
              className={(twMerge('z-1 w-full bg-transparent'), className)}
              id="stripe-expiry-number"
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              options={{
                placeholder: isFocused ? 'MM/YY' : '',
                disabled,
                style: {
                  base: {
                    '::placeholder': {
                      color: '#98A2B3',
                    },
                    color: '#344054',
                    fontFamily: 'Inter',
                    fontWeight: '450',
                    fontSize: '14px',
                    lineHeight: '20px',
                  },
                },
                ...options,
              }}
              {...props}
            />
          </div>
          {icon && (
            <div className={`${onIconClick ? 'cursor-pointer' : ''}`} onClick={onIconClick}>
              {icon}
            </div>
          )}
        </div>
        {isInputError && (
          <div className="flex items-center gap-1">
            <InfoCircle />
            <div className="whitespace-pre-line text-left font-inter text-xs font-[450] text-fireside-600">
              {isInputError?.message}
            </div>
          </div>
        )}
      </label>
    );
  },
);

StripeCardExpiryInput.displayName = 'StripeCardExpiryInput';

export default StripeCardExpiryInput;
