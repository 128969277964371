import { FC } from 'react';

import { useFormat } from '../../../../../hooks';
import { eventFormType, EventTypeBackend } from '../../../../../types/events.types';
import { BasicTable } from './BasicTable';
import { Td } from './TableTd';
import { TableBodyProps } from './types';

export const Valuation: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();
  if (props.type !== EventTypeBackend.VALUATION) return <></>;

  const { sharePrice, date, type, issuedSharesOnStart } = props;

  return (
    <BasicTable eventType={EventTypeBackend.VALUATION}>
      <tr>
        <Td value={format(date, 'dd/MM/yyyy')} />
        <Td value={eventFormType[type]} />
        <Td value={`$${sharePrice.toLocaleString('en-US')}`} />
        <Td value={(issuedSharesOnStart * sharePrice).toLocaleString('en-US')} />
        <Td className="w-12" value="" />
      </tr>
    </BasicTable>
  );
};
