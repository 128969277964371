import { useEffect } from 'react';

export const useEnterEvent = (callBack: () => void) => {
  useEffect(() => {
    const handleKeypress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        return callBack();
      }
    };
    window.addEventListener('keypress', handleKeypress);

    return () => {
      window.removeEventListener('keypress', handleKeypress);
    };
  }, [callBack]);
};
