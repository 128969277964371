import { FC } from 'react';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { StringKey } from '../../lang';
import { toUpperCaseWords } from '../../utils/toUppercaseUtil';

export type UpdateConfirmationModalProps = {
  onSuccess: () => void;
  onClose: () => void;
  text: string;
};

export const UpdateConfirmationModal: FC<UpdateConfirmationModalProps> = ({
  onClose,
  onSuccess,
  text,
}) => {
  return (
    <div className="m-2 flex w-full max-w-[453px] flex-col gap-8 rounded bg-white p-4">
      <div className="flex flex-col gap-6">
        <span className="text-xl font-[550] text-gray-700">
          <AppFormattedMessage id={StringKey.UPDATE} /> {toUpperCaseWords(text)}?
        </span>
        <div className="flex flex-col gap-3 rounded-[4px] bg-gray-50 px-3 pb-[52px] pt-3">
          <span className="text-xs font-[450] text-gray-700">
            Are you sure you want to update this {text}?
          </span>
          <span className="text-xs font-[450] text-gray-700">
            Please review your changes carefully. Modifying the {text} can have significant
            implications for all associated shareholders and future allocations.
          </span>
        </div>
      </div>
      <div className="flex h-11 w-full justify-end gap-3">
        <Button
          className="h-full w-fit rounded border border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700"
          onClick={onClose}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <Button
          className="h-full w-fit bg-brand-700 px-6 py-[10px] text-sm font-[550] text-white"
          onClick={onSuccess}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.YES_UPDATE} />
        </Button>
      </div>
    </div>
  );
};
