import { FC, ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ArrowIcon } from '../../../assets/icons';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import Button from '../../../components/Button';
import Loader from '../../../components/Loader';
import { Company, EventHooks, useFormat, useModalState, useNavigate } from '../../../hooks';
import { eventFormType, EventTypeBackend, EventUnion } from '../../../types/events.types';
import { PageRoute } from '../../../types/pageTypes';
import { FilesModal } from '../Modals';
import { Tag, TagVariant } from '../Tag';
import { Buyback } from './TransactionCardVariants/Buyback';
import { Conversion } from './TransactionCardVariants/Conversion';
import { FundraisingRound } from './TransactionCardVariants/FundraisingRound';
import { Grant } from './TransactionCardVariants/Grant';
import { NewPlan } from './TransactionCardVariants/NewPlan';
import { NewPool } from './TransactionCardVariants/NewPool';
import { Secondaries } from './TransactionCardVariants/Secondaries';
import { ShareIssuance } from './TransactionCardVariants/ShareIssuance';
import { CardProps } from './TransactionCardVariants/type';
import { Valuation } from './TransactionCardVariants/Valuation';

const transactionCards: Record<EventTypeBackend, (props: CardProps) => ReactNode> = {
  [EventTypeBackend.CONVERSION]: (props) => <Conversion {...props} />,
  [EventTypeBackend.GRANT]: (props) => <Grant {...props} />,
  [EventTypeBackend.BUYBACK]: (props) => <Buyback {...props} />,
  [EventTypeBackend.FUNDRAISING_ROUND]: (props) => <FundraisingRound {...props} />,
  [EventTypeBackend.NEW_PLAN]: (props) => <NewPlan {...props} />,
  [EventTypeBackend.NEW_POOL]: (props) => <NewPool {...props} />,
  [EventTypeBackend.SHARE_ISSUANCE]: (props) => <ShareIssuance {...props} />,
  [EventTypeBackend.SECONDARIES]: (props) => <Secondaries {...props} />,
  [EventTypeBackend.VALUATION]: (props) => <Valuation {...props} />,
};

export const TransactionsList: FC = () => {
  const navigate = useNavigate(PageRoute.EVENTS);
  const { id } = useParams();
  const [fileLinksStore, setFileLinks] = useState<string[]>([]);
  const { format } = useFormat();
  const { selectedCompanyId } = Company.useSelected();
  const { event, isLoading } = EventHooks.useEvent({
    companyId: selectedCompanyId,
    eventId: id || '',
  });

  const mergedEventUnion = event as CombineUnion<EventUnion> | undefined;

  const originalName = mergedEventUnion?.valuation?.name || mergedEventUnion?.name;
  const name = originalName || (mergedEventUnion?.type && eventFormType?.[mergedEventUnion?.type]);

  const handleDocumentClick = (link: string) => {
    window.open(link, '_blank');
  };

  const handleDownloadAllDocuments = () => {
    fileLinksStore.forEach((link) => {
      window.open(link, '_blank');
    });
  };

  const {
    isOpen: isOpenFilesModal,
    handleCloseModal: handleCloseFilesModal,
    handleSuccessModal: handleSuccessFilesModal,
    handleOpenModal: handleOpenFilesModal,
    toggler: filesModalToggler,
  } = useModalState<never, never, string[]>({
    onSuccess: () => {
      handleDownloadAllDocuments();
      setFileLinks([]);
    },
    onOpen: (fileLinks) => fileLinks && setFileLinks(fileLinks),
    onClose: () => setFileLinks([]),
  });

  if (!event || isLoading) return <Loader />;
  const eventUnion = event as CombineUnion<EventUnion>;

  return (
    <>
      <AlertDialogWrapper control={{ onOpenChange: filesModalToggler, open: isOpenFilesModal }}>
        <FilesModal
          createdAt={event?.createdAt && format(event.createdAt, 'dd/MM/yyyy')}
          filesLinks={event?.filesLinks}
          name={name || ''}
          onClose={handleCloseFilesModal}
          onDownloadFile={handleDocumentClick}
          onSuccess={handleSuccessFilesModal}
        />
      </AlertDialogWrapper>
      <div className="w-full p-[1px]">
        <div className="flex gap-1 py-4">
          <span
            className="cursor-pointer text-xs font-[450] text-brand-700"
            onClick={() => navigate()}
          >
            Event History
          </span>
          <span className="text-xs font-[450] text-gray-400">
            / {eventUnion.name || eventFormType[eventUnion.type]}
          </span>
        </div>
        <div className="flex items-center justify-between border-y border-gray-100 py-3">
          <div className="flex items-center gap-2">
            <Button
              className="centered size-9 rounded-full"
              onClick={() => navigate()}
              style={{
                boxShadow: '0px 0px 24px 0px #00000014',
                border: '1px solid #F2F2F2',
              }}
              styleType="NONE"
            >
              <ArrowIcon />
            </Button>
            <div className="flex flex-col">
              <span className="text-base font-semibold text-gray-700">
                {eventUnion.name || eventFormType[eventUnion.type]}
              </span>
              <span className="text-label-sm font-medium text-gray-500">
                {format(eventUnion.date || eventUnion.createdAt, 'dd/MM/yyyy')}
              </span>
            </div>
          </div>
          {event.type === EventTypeBackend.FUNDRAISING_ROUND && event.isOpen && (
            <Tag title="Open Round" variant={TagVariant.GREEN} />
          )}
          {event.type === EventTypeBackend.FUNDRAISING_ROUND && !event.isOpen && (
            <Tag title="Closed Round" variant={TagVariant.RED} />
          )}
        </div>
        <div className="flex flex-col gap-4 pb-1 pt-6">
          {transactionCards[event.type]({
            event,
            companyId: selectedCompanyId,
            openFileUploadModal: handleOpenFilesModal,
          })}
        </div>
      </div>
    </>
  );
};
