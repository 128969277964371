import { StringKey } from '../lang';
import { Company } from './companyTypes';
import { Country } from './countryTypes';

export enum StakeholderRole {
  STAKEHOLDER = 'stakeholder',
  CONTRIBUTOR = 'contributor',
  ADMIN = 'admin',
}

export enum StakeholderType {
  ANGEL = 'angel',
  EMPLOYEE = 'employee',
  FOUNDER = 'founder',
  ADVISOR = 'advisor',
  BOARD_MEMBERS = 'board-members',
  VCS = 'vcs',
  INVESTMENT_BANKS = 'investment-banks',
  PES = 'pes',
  FAMILY_OFFICE = 'family-office',
  OTHER_INST = 'other-inst',
}

export const b2bStakeholderTypes: StakeholderType[] = [
  StakeholderType.ANGEL,
  StakeholderType.FOUNDER,
  StakeholderType.ADVISOR,
  StakeholderType.EMPLOYEE,
  StakeholderType.BOARD_MEMBERS,
];
export const b2cStakeholderTypes: StakeholderType[] = [
  StakeholderType.INVESTMENT_BANKS,
  StakeholderType.PES,
  StakeholderType.FAMILY_OFFICE,
  StakeholderType.OTHER_INST,
  StakeholderType.VCS,
];

export enum StakeholderTypeGroup {
  HUMAN = 'human',
  COMPANY = 'company',
}

export enum StakeholderOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum StakeholderSortBy {
  FULL_NAME = 'fullName',
  TYPE = 'type',
}

export interface Stakeholder {
  address?: string;
  birthday?: string;
  capTableAccess: boolean;
  capitalChangeAccess: boolean;
  company?: Company;
  companyTableAccess: boolean;
  countryOfInc?: Country;
  createdAt: string;
  email: string;
  fullName: string;
  id: string;
  incDate?: string;
  isAccessRevoked: boolean;
  isActive: boolean;
  isInvited: boolean;
  lastActive: string;
  nationalityCountry?: Country;
  passportExpDate?: string;
  passportNo?: string;
  phone?: string;
  phoneCountryIso2?: string;
  positionAccess: boolean;
  role: StakeholderRole;
  stakeholderCompanyId?: string;
  type: StakeholderType;
}

export enum StakeholderFilter {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export type FilterVariant = 'all' | StakeholderFilter;

export const filterVariant: Record<FilterVariant, StringKey> = {
  all: StringKey.FILTER_ALL,
  [StakeholderFilter.ACTIVE]: StringKey.FILTER_ACTIVE,
  [StakeholderFilter.INACTIVE]: StringKey.FILTER_INACTIVE,
};

export const filterVariantMap = Object.entries(filterVariant);

export type HumanStakeholderDto = {
  fullName: string;
  email: string;
  type: StakeholderType;
  passportNo?: string;
  passportExpDate?: string;
  nationality?: string;
  birthday?: string;
  phone?: string;
  phoneCountryIso2?: string;
  address?: string;
  capTableAccess?: boolean;
  positionAccess?: boolean;
  capitalChangeAccess?: boolean;
  companyTableAccess?: boolean;
};

export type CompanyStakeholderDto = {
  fullName: string;
  email: string;
  type: StakeholderType;
  countryOfInc?: string;
  stakeholderCompanyId?: string;
  incDate?: string;
  phone?: string;
  phoneCountryIso2?: string;
  address?: string;
  capTableAccess?: boolean;
  positionAccess?: boolean;
  capitalChangeAccess?: boolean;
  companyTableAccess?: boolean;
};

export enum StakeholderBulkAction {
  INVITE = 'invite',
  CANCEL_INVITATION = 'cancel-invitation',
  TOGGLE_CONTRIBUTOR = 'toggle-contributor',
  TOGGLE_ACCESS = 'toggle-access',
  DELETE = 'delete',
}

export type StakeholderBulkActionDto = {
  stakeholderIds: string[];
};
