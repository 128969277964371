import React, { FC, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { SharePlan, useModalState, useReactForm } from '../../../../hooks';
import {
  PatchSharePlanDto,
  SharePlanType,
  SharePlanVestingType,
} from '../../../../types/pool-plans.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { toBackendDateFormat } from '../../../../utils/toBackendDateFormat';
import { UpdateConfirmationModal } from '../../UpdateConfirmationModal';
import { StepFour, StepOne, StepThree, StepTwo } from '../Steps';
import { EditPlanPreview } from '../Steps/EditPlanPreview';
import { ChildrenFormProps, EditPlanStepVariant } from '../type';
import { FormSchema, formSchema } from '../Validation';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  onEditClick: (props: { id: string; step: EditPlanStepVariant }) => void;
  step: EditPlanStepVariant;
  handleTerminate: (state: { id: string; date: Date }) => void;
  planId: string;
  previewMode?: true;
};

const formSteps: ((
  props: {
    onEditClick: (step: EditPlanStepVariant) => void;
    handleTerminate: (date: Date) => void;
  } & ChildrenFormProps,
) => JSX.Element)[] = [
  (props) => <StepOne {...props} />,
  (props) => <StepTwo {...props} />,
  (props) => <StepThree {...props} />,
  (props) => <StepFour {...props} />,
  (props) => <EditPlanPreview {...props} />,
];

export const EditPlanModal: FC<ModalProps> = ({
  companyId,
  isOpenModal,
  handleClose,
  invalidateQuery,
  onEditClick,
  planId,
  step,
  previewMode,
  handleTerminate,
}) => {
  const { sharePlan } = SharePlan.useSharePlan({ companyId, planId });

  const {
    control,
    reset,
    setValue,
    getValues,
    getFieldState,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useReactForm({
    schema: formSchema,
  });
  useEffect(() => {
    if (!sharePlan) return;

    const files: {
      docLink: string;
      loadProgress: 100;
      abort: () => void;
      id: string;
      doc: {
        size: number;
        type: string;
        name: string;
      };
    }[] = sharePlan?.filesLinks?.map((link) => {
      const originalFileName = getS3FileOriginalName(link);

      return {
        abort: () => '',
        loadProgress: 100,
        doc: {
          name: originalFileName || '',
          size: 10,
          type: 'application/pdf',
        },
        docLink: link,
        id: uuidv4(),
      };
    });
    reset({
      stepOne: {
        name: sharePlan?.name,
        pool: {
          id: sharePlan?.pool?.id,
          name: sharePlan?.pool?.name,
        },
        date: sharePlan?.date,
        type: sharePlan?.type,
        financialDetails: {
          [SharePlanType.GROWTH_HURDLE]:
            sharePlan?.type === SharePlanType.GROWTH_HURDLE
              ? { enabled: true, value: sharePlan?.hardleValue }
              : undefined,
          [SharePlanType.STOCK_OPTIONS]:
            sharePlan?.type === SharePlanType.STOCK_OPTIONS
              ? {
                  enabled: true,
                  value: sharePlan?.strikePrice,
                  conversionRatio: sharePlan?.conversionRatio || undefined,
                }
              : undefined,
          [SharePlanType.WARRANTS]:
            sharePlan?.type === SharePlanType.WARRANTS
              ? {
                  enabled: true,
                  value: sharePlan?.warrantPrice,
                  conversionRatio: sharePlan?.conversionRatio || undefined,
                }
              : undefined,
          [SharePlanType.SAR]:
            sharePlan?.type === SharePlanType.SAR
              ? { enabled: true, value: sharePlan?.sarBasePrice }
              : undefined,
          [SharePlanType.RSA]:
            sharePlan?.type === SharePlanType.RSA
              ? {
                  enabled: true,
                  value: sharePlan?.rsaPurchasePrice,
                  conversionRatio: sharePlan?.conversionRatio || undefined,
                }
              : undefined,
          [SharePlanType.RSU]:
            sharePlan?.type === SharePlanType.RSU
              ? {
                  enabled: true,
                  conversionRatio: sharePlan?.conversionRatio || undefined,
                }
              : undefined,
          [SharePlanType.PHANTOM]:
            sharePlan?.type === SharePlanType.PHANTOM
              ? {
                  enabled: true,
                  conversionRatio: sharePlan?.conversionRatio || undefined,
                }
              : undefined,
          [SharePlanType.COMMON_STOCK]:
            sharePlan?.type === SharePlanType.COMMON_STOCK
              ? {
                  enabled: true,
                }
              : undefined,
          [SharePlanType.BSPCE]:
            sharePlan?.type === SharePlanType.BSPCE
              ? {
                  enabled: true,
                  value: sharePlan?.strikePrice,
                  conversionRatio: sharePlan?.conversionRatio,
                }
              : undefined,
        },
      },
      stepTwo: {
        vesting: sharePlan?.vesting
          ? {
              enabled: true,
              vestingMechanism: sharePlan?.vesting?.type,
              timeBasedStandard:
                sharePlan?.vesting.type === SharePlanVestingType.TIME_BASED_STANDARD
                  ? {
                      vestingOn: sharePlan?.vesting?.vestingOn,
                      vestingDuration: sharePlan?.vesting?.durationMonths,
                      vestingFrequency: sharePlan?.vesting?.frequencyMonths,
                      cliffPeriod: sharePlan?.vesting?.cliffMonths,
                      accelerationDate: sharePlan?.vesting?.accelarationDate || undefined,
                    }
                  : undefined,
              timeBasedDynamic:
                sharePlan?.vesting.type === SharePlanVestingType.TIME_BASED_DYNAMIC
                  ? {
                      enabled: true,
                      vestingOn: sharePlan?.vesting?.vestingOn,
                      dynamicVestings: sharePlan?.vesting?.periods.map(
                        ({ planAllocation, durationMonths, frequencyMonths }) => ({
                          vestingDuration: durationMonths,
                          vestingFrequency: frequencyMonths,
                          planAllocation,
                        }),
                      ),
                      accelerationDate: sharePlan?.vesting?.accelarationDate || undefined,
                    }
                  : undefined,
              targetBasedStandard:
                sharePlan?.vesting.type === SharePlanVestingType.TARGET_BASED
                  ? {
                      enabled: true,
                      targetVestings: sharePlan?.vesting?.milestones,
                    }
                  : undefined,
            }
          : undefined,
        expiryDate:
          sharePlan?.type === SharePlanType.WARRANTS
            ? {
                enabled: true,
                date: sharePlan?.expiryDate,
              }
            : undefined,
      },
      stepThree: sharePlan?.terminationLogic
        ? {
            terminationLogic: {
              enabled: true,
              resignation: sharePlan?.terminationLogic?.resignation
                ? {
                    enabled: true,
                    interval: sharePlan.terminationLogic.resignation.interval,
                    value: sharePlan.terminationLogic.resignation.value,
                  }
                : { enabled: false },
              termination: sharePlan?.terminationLogic?.termination
                ? {
                    enabled: true,
                    interval: sharePlan.terminationLogic.termination.interval,
                    value: sharePlan.terminationLogic.termination.value,
                  }
                : { enabled: false },
              terminationWithCause: sharePlan?.terminationLogic?.['termination-with-cause']
                ? {
                    enabled: true,
                    interval: sharePlan.terminationLogic['termination-with-cause'].interval,
                    value: sharePlan.terminationLogic['termination-with-cause'].value,
                  }
                : { enabled: false },
              death: sharePlan?.terminationLogic?.death
                ? {
                    enabled: true,
                    interval: sharePlan.terminationLogic.death.interval,
                    value: sharePlan.terminationLogic.death.value,
                  }
                : { enabled: false },
              retirement: sharePlan.terminationLogic?.retirement
                ? {
                    enabled: true,
                    interval: sharePlan.terminationLogic.retirement.interval,
                    value: sharePlan.terminationLogic.retirement.value,
                  }
                : { enabled: false },
            },
          }
        : undefined,
      stepFour: { additionalNotes: sharePlan?.additionalNotes || undefined, files: files },
    });
  }, [sharePlan, reset]);

  const { update } = SharePlan.useUpdate();

  const handleCloseModal = useCallback(() => {
    handleClose();
    invalidateQuery();
    reset();
  }, [handleClose, invalidateQuery, reset]);

  const handleUpdateData = useCallback(
    (sharePlan: FormSchema) => {
      const { stepOne, stepTwo, stepThree, stepFour } = sharePlan;
      const timeBasedDynamicVesting = stepTwo?.vesting &&
        stepTwo.vesting.vestingMechanism === SharePlanVestingType.TIME_BASED_DYNAMIC && {
          accelarationDate: stepTwo.vesting.timeBasedDynamic?.accelerationDate
            ? toBackendDateFormat(stepTwo.vesting.timeBasedDynamic?.accelerationDate)
            : undefined,
          vestingOn: stepTwo.vesting?.timeBasedDynamic?.vestingOn,
          periods: stepTwo.vesting?.timeBasedDynamic?.dynamicVestings?.map(
            ({ planAllocation, vestingDuration, vestingFrequency }) => ({
              durationMonths: vestingDuration,
              frequencyMonths: vestingFrequency,
              planAllocation,
            }),
          ),
        };

      const timeBasedStandardVesting = stepTwo?.vesting &&
        stepTwo.vesting.vestingMechanism === SharePlanVestingType.TIME_BASED_STANDARD && {
          vestingOn: stepTwo.vesting?.timeBasedStandard?.vestingOn,
          durationMonths: stepTwo.vesting?.timeBasedStandard?.vestingDuration,
          frequencyMonths: stepTwo.vesting?.timeBasedStandard?.vestingFrequency,
          cliffMonths: stepTwo.vesting?.timeBasedStandard?.cliffPeriod,
          accelarationDate: stepTwo.vesting.timeBasedStandard?.accelerationDate
            ? toBackendDateFormat(stepTwo.vesting.timeBasedStandard?.accelerationDate)
            : undefined,
        };

      const targetBasedStandardVesting = stepTwo?.vesting &&
        stepTwo.vesting.vestingMechanism === SharePlanVestingType.TARGET_BASED && {
          milestones: stepTwo.vesting?.targetBasedStandard?.targetVestings?.map(
            ({ targetDate, name, weight }) => ({
              weight,
              name,
              targetDate: toBackendDateFormat(targetDate as Date),
            }),
          ),
        };

      const vestingChecked =
        targetBasedStandardVesting || timeBasedStandardVesting || timeBasedDynamicVesting;

      const vesting: PatchSharePlanDto['vesting'] =
        (stepTwo?.vesting && {
          type: stepTwo?.vesting?.vestingMechanism,
          ...vestingChecked,
        }) ||
        null;

      const conversionRatio = Object.values(stepOne.financialDetails).find(
        (plan) => plan?.enabled,
      ) as { conversionRatio?: number } | undefined;

      const terminationLogic: PatchSharePlanDto['terminationLogic'] = stepThree?.terminationLogic
        ? {
            'termination-with-cause':
              stepThree.terminationLogic?.terminationWithCause &&
              stepThree.terminationLogic?.terminationWithCause.enabled
                ? {
                    value: stepThree.terminationLogic?.terminationWithCause?.value,
                    interval: stepThree.terminationLogic?.terminationWithCause?.interval,
                  }
                : null,
            death:
              stepThree.terminationLogic?.death && stepThree.terminationLogic?.death.enabled
                ? {
                    interval: stepThree.terminationLogic?.death?.interval,
                    value: stepThree.terminationLogic?.death?.value,
                  }
                : null,
            resignation:
              stepThree.terminationLogic?.resignation &&
              stepThree.terminationLogic?.resignation.enabled
                ? {
                    interval: stepThree.terminationLogic?.resignation?.interval,
                    value: stepThree.terminationLogic?.resignation?.value,
                  }
                : null,
            retirement:
              stepThree.terminationLogic?.retirement &&
              stepThree.terminationLogic?.retirement.enabled
                ? {
                    interval: stepThree.terminationLogic?.retirement?.interval,
                    value: stepThree.terminationLogic?.retirement?.value,
                  }
                : null,
            termination:
              stepThree.terminationLogic?.termination &&
              stepThree.terminationLogic?.termination.enabled
                ? {
                    interval: stepThree.terminationLogic?.termination?.interval,
                    value: stepThree.terminationLogic?.termination?.value,
                  }
                : null,
          }
        : null;

      update(
        {
          companyId,
          planId,
          data: {
            poolId: stepOne.pool.id,
            filesLinks:
              stepFour?.files?.reduce<string[]>((prev, curr) => [...prev, curr.docLink], []) || [],
            name: stepOne.name,
            date: toBackendDateFormat(stepOne.date),
            type: stepOne.type,
            hardleValue: stepOne.financialDetails['growth-hurdle']?.value || undefined,
            strikePrice:
              stepOne.financialDetails['stock-options']?.value ||
              stepOne.financialDetails['bspce']?.value ||
              undefined,
            rsaPurchasePrice: stepOne.financialDetails['rsa']?.value || undefined,
            sarBasePrice: stepOne.financialDetails['sar']?.value || undefined,
            additionalNotes: stepFour?.additionalNotes,
            terminationLogic,
            expiryDate: stepTwo?.expiryDate?.date
              ? toBackendDateFormat(stepTwo?.expiryDate?.date)
              : null,
            conversionRatio: conversionRatio?.conversionRatio || undefined,
            warrantPrice: stepOne.financialDetails.warrants?.value,
            vesting,
          },
        },
        {
          onSuccess: () => {
            toast.success('Plan successfully updated');
            invalidateQuery();
            handleCloseModal();
          },
        },
      );
    },
    [companyId, handleCloseModal, invalidateQuery, planId, update],
  );

  const {
    toggler: confirmationModalToggler,
    isOpen: isOpenConfirmationModal,
    handleOpenModal: handleOpenConfirmationModal,
    handleCloseModal: handleCloseConfirmationModal,
    handleSuccessModal: handleSuccessConfirmationModal,
  } = useModalState({
    onSuccess: () => {
      handleSubmit(handleUpdateData)();
      handleCloseModal();
    },
  });

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <AlertDialogWrapper
          control={{ onOpenChange: confirmationModalToggler, open: isOpenConfirmationModal }}
        >
          <UpdateConfirmationModal
            onClose={handleCloseConfirmationModal}
            onSuccess={handleSuccessConfirmationModal}
            text="plan"
          />
        </AlertDialogWrapper>
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-[550] text-gray-700">
              {previewMode ? sharePlan?.name : 'Edit Plan'}
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>

          <div className="flex h-full flex-col gap-4 overflow-hidden">
            {formSteps[step - 1]({
              companyId,
              formData: getValues,
              nextFormStep: handleOpenConfirmationModal,
              prevFormStep: () => {},
              setFormData: setValue,
              control,
              handleCloseModal,
              filedState: getFieldState,
              errors,
              lockMode: true,
              previewMode,
              handleTerminate: (date) => handleTerminate({ date, id: planId }),
              isTerminated: sharePlan?.isTerminated,
              terminationDate: sharePlan?.terminationDate,
              onEditClick: (step) => onEditClick({ id: planId, step }),
              setError,
              clearErrors,
            })}
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
