import React, { memo, ReactElement } from 'react';
import { Control, FieldPathByValue, FieldValues, PathValue, useController } from 'react-hook-form';

import Radio, { RadioProps } from './RadioGroupItem';

export type FormRadioProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
> = {
  control: Control<TFieldValues>;
  defaultValue?: PathValue<TFieldValues, TPath>;
  name: TPath;
} & Omit<RadioProps, 'defaultValue' | 'onBlur' | 'onChange'>;

const FormRadio = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
>({
  control,
  defaultValue,
  name,
  ...props
}: FormRadioProps<TFieldValues, TPath>): ReactElement | null => {
  const { field } = useController({
    control,
    defaultValue,
    name,
  });

  return <Radio {...field} {...props} />;
};

export default memo(FormRadio) as typeof FormRadio;
