import { useQueryClient } from '@tanstack/react-query';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useSetSelected = () => {
  const { invalidateQueries } = useQueryClient();

  const invalidateQuery = () => {
    invalidateQueries({
      queryKey: [QueryKey.GET_SELECTED_COMPANY],
    });
  };

  const { mutate: setSelected, isPending } = useAppMutation([QueryKey.PATCH_SELECTED_COMPANY], {
    mutationFn: (id: string) =>
      ApiService.post({ endpoint: BackendRoute.COMPANIES, routePath: [id, 'select'] }),
    defaultErrorHandling: false,
  });
  return {
    setSelected,
    isPending,
    invalidateQuery,
  };
};
