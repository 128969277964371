import React, { FC, memo } from 'react';
import { Control } from 'react-hook-form';

import { ChevronDownIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../components/Combobox';
import { useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  companyTypeTranslation,
  listOfCompanyTypeTranslation,
} from '../../../../translations/companyTranslation';
import { FormSchema } from './Validation';

export type CompanyTypeItemsListProps = {
  control: Control<FormSchema>;
};

const CompanyTypeItemsList: FC<CompanyTypeItemsListProps> = memo(({ control }) => (
  <>
    {listOfCompanyTypeTranslation.map(([companyTypeKey, companyType]) => (
      <FormComboboxOption control={control} key={companyTypeKey} name="type" value={companyTypeKey}>
        <span className="text-sm font-normal text-gray-700">
          <AppFormattedMessage id={companyType} />
        </span>
      </FormComboboxOption>
    ))}
  </>
));

CompanyTypeItemsList.displayName = 'CompanyTypeItemsList';

export type CompanyTypeComboboxProps = {
  control: Control<FormSchema>;
};

const CompanyTypeCombobox: FC<CompanyTypeComboboxProps> = memo(({ control }) => {
  const { messagesLocale } = useLocale();

  return (
    <FormCombobox className="relative" control={control} name={'type'}>
      <div className="relative">
        <FormComboboxInput
          className={'absolute z-0 bg-gray-900'}
          control={control}
          customValue={(value) => (value ? messagesLocale[companyTypeTranslation[value]] : value)}
          icon={<ChevronDownIcon className="mt-3" />}
          name={'type'}
          placeholder={<AppFormattedMessage id={StringKey.COMPANY_TYPE} />}
          readOnly
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <CompanyTypeItemsList control={control} />
      </ComboboxOptions>
    </FormCombobox>
  );
});

CompanyTypeCombobox.displayName = 'CompanyTypeCombobox';

export default CompanyTypeCombobox;
