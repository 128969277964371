import React, { FC, ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../../assets/icons';
import Button from '../../../../components/Button';

type DropDownProps = {
  title: string;
  children: ReactNode;
  className?: string;
  handleRemove: () => void;
  showRemoveButton: boolean;
};
export const FormDropDown: FC<DropDownProps> = ({
  title,
  children,
  className,
  handleRemove,
  showRemoveButton,
}) => {
  const [isOpen, setOpen] = useState(true);
  return (
    <div className={twMerge('flex w-full flex-col transition-all', className)}>
      <div
        className={twMerge(
          'flex w-full items-center justify-between px-4 py-[10px]',
          isOpen ? 'rounded-t-md bg-gray-50' : 'rounded-md border-[1px] border-gray-100 bg-gray-25',
        )}
      >
        <span className="text-sm font-[450] text-gray-700">{title}</span>
        <div className="flex items-center gap-4">
          {showRemoveButton && (
            <Button
              className="text-xs font-[550] text-fireside-600"
              onClick={handleRemove}
              styleType="NONE"
            >
              Remove
            </Button>
          )}

          <div
            className="h-fit w-fit cursor-pointer p-1 transition-colors hover:bg-gray-50"
            onClick={() => {
              setOpen((prev) => !prev);
            }}
          >
            <ChevronDownIcon
              className={twMerge('size-4', isOpen ? 'rotate-180' : '', 'transition-transform')}
              iconColor="#344054"
            />
          </div>
        </div>
      </div>
      <div
        className={twMerge(
          'grid grid-flow-row transition-all duration-200',
          isOpen ? 'grid-rows-[1fr] rounded-md px-4 py-3 shadow-sm' : 'grid-rows-[0fr]',
        )}
      >
        <div className={twMerge(isOpen ? '' : 'overflow-hidden')}>{children}</div>
      </div>
    </div>
  );
};
