import React from 'react';
import { Control, FieldPathByValue, FieldValues, useController } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../components/Combobox';
import { useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  arrayOfStakeholderTypeGroupTranslation,
  stakeholderTypeGroupTranslation,
} from '../../../../translations/stakeholderTranslation';
import { StakeholderTypeGroup } from '../../../../types/stakeholderTypes';

interface StakeholderFormValues extends FieldValues {
  stakeholderTypeGroup: StakeholderTypeGroup;
}

export type StakeholderTypeGroupItemsListProps<
  TFieldValues extends StakeholderFormValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
> = {
  control: Control<TFieldValues>;
  name: TPath;
};

const StakeholderTypeGroupItemsList = <
  TFieldValues extends StakeholderFormValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
>({
  control,
  name,
}: StakeholderTypeGroupItemsListProps<TFieldValues, TPath>) => (
  <>
    {arrayOfStakeholderTypeGroupTranslation.map(([stakeholderTypeKey, stakeholderType]) => (
      <FormComboboxOption
        control={control}
        key={stakeholderTypeKey}
        name={name}
        value={stakeholderTypeKey as any}
      >
        <span className="text-sm font-normal text-gray-700">
          <AppFormattedMessage id={stakeholderType} />
        </span>
      </FormComboboxOption>
    ))}
  </>
);

export type StakeholderTypeGroupComboboxProps<
  TFieldValues extends StakeholderFormValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
> = {
  control: Control<TFieldValues>;
  name: TPath;
  onSelect?: () => void;
};

const StakeholderTypeGroupCombobox = <
  TFieldValues extends StakeholderFormValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
>({
  control,
  onSelect,
  name,
}: StakeholderTypeGroupComboboxProps<TFieldValues, TPath>) => {
  const { formState } = useController({ name, control });
  const { messagesLocale } = useLocale();
  return (
    <FormCombobox className="relative" control={control} name={name} onSelect={() => onSelect?.()}>
      <div className="relative">
        <FormComboboxInput
          className={'absolute z-0 bg-gray-900'}
          control={control}
          customValue={(value) =>
            value ? messagesLocale[stakeholderTypeGroupTranslation[value]] : value
          }
          icon={<ChevronDownIcon className={twMerge('mt-3', formState.disabled && 'hidden')} />}
          name={name}
          placeholder={<AppFormattedMessage id={StringKey.STAKEHOLDER_TYPE} />}
          readOnly
          wrapperClassName={formState.disabled && 'border-none'}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <StakeholderTypeGroupItemsList control={control} name={name} />
      </ComboboxOptions>
    </FormCombobox>
  );
};

export default StakeholderTypeGroupCombobox;
