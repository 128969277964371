import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export const SummaryItem = ({
  title,
  value,
  className,
}: {
  title: ReactNode;
  value: number | string | undefined | ReactNode;
  className?: string | false;
}) => (
  <div className={twMerge('flex w-full gap-3 py-3', className)}>
    <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">{title}:</span>
    <span className="max-w-[250px] truncate text-xs font-[450] text-gray-700">{value}</span>
  </div>
);
