import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

type EditIconProps = IconProps;

const EditIcon: FC<EditIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99998 13.3337H14M2 13.3336H3.11636C3.44248 13.3336 3.60554 13.3336 3.75899 13.2968C3.89504 13.2641 4.0251 13.2103 4.1444 13.1372C4.27895 13.0547 4.39425 12.9394 4.62486 12.7088L13 4.33365C13.5523 3.78136 13.5523 2.88593 13 2.33365C12.4477 1.78136 11.5523 1.78136 11 2.33365L2.62484 10.7088C2.39424 10.9394 2.27894 11.0547 2.19648 11.1893C2.12338 11.3086 2.0695 11.4386 2.03684 11.5747C2 11.7281 2 11.8912 2 12.2173V13.3336Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

export default EditIcon;
