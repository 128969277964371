import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type TargetBasedStandardIconProps = IconProps;

const TargetBasedStandardIcon: FC<TargetBasedStandardIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.3332 8V5L19.3332 2L20.3332 4L22.3332 5L19.3332 8H16.3332ZM16.3332 8L12.3332 11.9999M22.3333 12C22.3333 17.5228 17.8561 22 12.3333 22C6.8104 22 2.33325 17.5228 2.33325 12C2.33325 6.47715 6.8104 2 12.3333 2M17.3333 12C17.3333 14.7614 15.0947 17 12.3333 17C9.57183 17 7.33325 14.7614 7.33325 12C7.33325 9.23858 9.57183 7 12.3333 7"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default TargetBasedStandardIcon;
