import React, { FC, useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { CloseModalButton } from '../../../components/CloseModalButton';
import { FormInput } from '../../../components/Input';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { Stakeholder, useReactForm } from '../../../hooks';
import { StringKey } from '../../../lang';
import { b2bStakeholderTypes, b2cStakeholderTypes } from '../../../types/stakeholderTypes';
import { transformDatetoISOString } from '../Stakeholders';
import BirthdayDateSelect from './DateCombobox/BirthdayDateSelect';
import IncorporationCompanyDateSelect from './DateCombobox/IncorporationCompanyDateSelect';
import PassportExpiryDateSelect from './DateCombobox/PassportExpiryDateSelect';
import IncorporationCountryCombobox from './IncorporationCountryCombobox';
import NationalityCombobox from './NationalityCombobox';
import PhoneNumberCombobox from './PhoneNumberCombobox';
import StakeholderTypeCombobox from './StakeholderTypeCombobox';
import { FormSchema, formSchema } from './Validation';

export type AddStakeholderModalProps = {
  isOpenModal: boolean;
  setOpen: (state: boolean) => void;
  companyId: string;
  invalidateQuery: () => void;
};

const AddStakeholderModal: FC<AddStakeholderModalProps> = ({
  isOpenModal,
  setOpen,
  companyId,
  invalidateQuery,
}) => {
  const [isOpenEquityOptions, setOpenEquityOptions] = useState(true);
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    reset,
    resetField,
    formState: { isValid },
  } = useReactForm({
    schema: formSchema,
    defaultValues: {
      access: {
        capTable: true,
        companyTable: true,
        equity: true,
        position: true,
      },
    },
  });

  const handleCloseModal = useCallback(() => {
    reset();
    setOpen(false);
  }, [reset, setOpen]);

  const { createCompany, createHuman } = Stakeholder.useCreate();

  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { address, email, fullName, stakeholderType, phoneNumber, access, phoneCountry } = data;
      if (b2bStakeholderTypes.includes(stakeholderType)) {
        createHuman(
          {
            companyId,
            data: {
              birthday: data.human?.birthdayDate
                ? transformDatetoISOString(
                    data.human?.birthdayDate.year,
                    data.human?.birthdayDate.month + 1,
                    data.human?.birthdayDate.day,
                  )
                : undefined,
              address: address || undefined,
              email,
              fullName,
              nationality: data.human?.nationality?.iso2Code || undefined,
              passportExpDate: data.human?.passportExpiryDate
                ? transformDatetoISOString(
                    data.human?.passportExpiryDate.year,
                    data.human?.passportExpiryDate.month + 1,
                    data.human?.passportExpiryDate.day,
                  )
                : undefined,
              passportNo: data.human?.passportNo.toString() || undefined,
              type: stakeholderType,
              phone: phoneCountry?.prefix ? phoneCountry?.prefix + phoneNumber : undefined,
              capTableAccess: access?.capTable,
              positionAccess: access?.position,
              capitalChangeAccess: access?.equity,
              companyTableAccess: access?.companyTable,
            },
          },
          {
            onSuccess: () => {
              handleCloseModal();
              toast.success(
                <AppFormattedMessage id={StringKey.STAKEHOLDER_SUCCESSFULLY_CREATED} />,
              );
              invalidateQuery();
            },
          },
        );

        return;
      }

      if (!data.company) return;
      return createCompany(
        {
          companyId,
          data: {
            email,
            stakeholderCompanyId: data.company?.identifier.toString() || undefined,
            fullName,
            countryOfInc: data.company?.incorporationCountry?.iso2Code || undefined,
            incDate: data.company?.incorporationDate
              ? transformDatetoISOString(
                  data.company?.incorporationDate.year,
                  data.company?.incorporationDate.month + 1,
                  data.company?.incorporationDate.day,
                )
              : undefined,
            address: address || undefined,
            type: stakeholderType,
            phone: phoneCountry?.prefix ? phoneCountry?.prefix + phoneNumber : undefined,
            phoneCountryIso2: phoneCountry?.iso2Code || undefined,
            capTableAccess: access?.capTable,
            positionAccess: access?.position,
            capitalChangeAccess: access?.equity,
            companyTableAccess: access?.companyTable,
          },
        },
        {
          onSuccess: () => {
            handleCloseModal();
            toast.success(<AppFormattedMessage id={StringKey.STAKEHOLDER_SUCCESSFULLY_CREATED} />);
            invalidateQuery();
          },
        },
      );
    },
    [createCompany, companyId, createHuman, invalidateQuery, handleCloseModal],
  );

  const selectedNationality = watch('human.nationality');

  const selectedIncorporationCountry = watch('company.incorporationCountry');

  const selectedPhoneNumber = watch('phoneNumber');

  const selectedPhoneCountry = watch('phoneCountry');

  const selectedStakeholderType = watch('stakeholderType');

  const isB2BStakeholderType = useMemo(
    () => b2bStakeholderTypes.includes(selectedStakeholderType),
    [selectedStakeholderType],
  );

  const isB2CStakeholderType = useMemo(
    () => b2cStakeholderTypes.includes(selectedStakeholderType),
    [selectedStakeholderType],
  );

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-semibold text-gray-700">
              <AppFormattedMessage id={StringKey.ADD_STAKEHOLDER} />
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <form
            className="flex h-full flex-col gap-4 overflow-hidden"
            onSubmit={handleSubmit(submitHandler)}
          >
            <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
              <FormInput
                control={control}
                name="fullName"
                placeholder={<AppFormattedMessage id={StringKey.FULL_NAME} />}
              />
              <FormInput
                control={control}
                name="email"
                placeholder={<AppFormattedMessage id={StringKey.EMAIL} />}
              />
              <StakeholderTypeCombobox
                control={control}
                name="stakeholderType"
                onSelect={() => {
                  resetField('company');
                  resetField('human');
                  resetField('phoneNumber');
                  resetField('address');
                }}
              />

              {isB2BStakeholderType && (
                <>
                  <FormInput
                    control={control}
                    name="human.passportNo"
                    placeholder={<AppFormattedMessage id={StringKey.PASSPORT_NO_OPTIONAL} />}
                  />
                  <PassportExpiryDateSelect control={control} setValue={setValue} />
                  <NationalityCombobox
                    control={control}
                    selectedNationality={selectedNationality}
                  />
                  <BirthdayDateSelect control={control} setValue={setValue} />
                </>
              )}
              {isB2CStakeholderType && (
                <>
                  <IncorporationCountryCombobox
                    control={control}
                    selectedIncorporationCountry={selectedIncorporationCountry}
                  />
                  <FormInput
                    control={control}
                    name="company.identifier"
                    placeholder={<AppFormattedMessage id={StringKey.COMPANY_IDENTIFIER} />}
                  />

                  <IncorporationCompanyDateSelect control={control} setValue={setValue} />
                </>
              )}

              {(isB2CStakeholderType || isB2BStakeholderType) && (
                <>
                  <PhoneNumberCombobox
                    onPhoneChange={(phoneNumber) =>
                      setValue('phoneNumber', phoneNumber, {
                        shouldValidate: true,
                      })
                    }
                    onSelectCountry={(country) => setValue('phoneCountry', country)}
                    selectedPhoneNumber={{
                      number: selectedPhoneNumber || '',
                      country: selectedPhoneCountry,
                    }}
                  />
                  <FormInput
                    control={control}
                    name="address"
                    placeholder={
                      <>
                        <AppFormattedMessage id={StringKey.ADDRESS_OPTIONAL} />
                      </>
                    }
                  />
                  <div className="flex h-fit w-full flex-col gap-3 rounded bg-gray-50 p-3 pl-2">
                    <span className="text-base font-semibold text-gray-700">
                      <AppFormattedMessage id={StringKey.ACCESS_AND_PERMISSIONS} />
                    </span>
                    <div className="flex flex-col gap-3">
                      <div className="flex items-center gap-1">
                        <Checkbox
                          checked={watch('access.capTable')}
                          onChange={(e) => {
                            setValue('access.capTable', e);
                          }}
                        />
                        <span className="text-sm font-[450] text-gray-700">Cap Table</span>
                      </div>
                      <Button
                        className="flex h-fit w-fit items-center gap-1"
                        onClick={() => setOpenEquityOptions((prev) => !prev)}
                        styleType="NONE"
                        type="button"
                      >
                        <Checkbox checked={isOpenEquityOptions} disabled />
                        <span className="text-sm font-[450] text-gray-700">
                          <AppFormattedMessage id={StringKey.MY_HOLDING_EQUITY} />
                        </span>
                        <ChevronDownIcon
                          className={twMerge(
                            'size-4 transition-all',
                            isOpenEquityOptions && 'rotate-180',
                          )}
                        />
                      </Button>
                      <div
                        className={twMerge(
                          'ml-[30px] flex h-0 flex-col gap-2 overflow-hidden transition-all duration-500',
                          isOpenEquityOptions && 'h-fit',
                        )}
                      >
                        <div className="flex items-center gap-1">
                          <Checkbox
                            checked={watch('access.position')}
                            onChange={(e) => setValue('access.position', e)}
                          />
                          <span className="text-sm font-[450] text-gray-700">
                            Your position (Issued) vs Cap Table
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <Checkbox
                            checked={watch('access.equity')}
                            onChange={(e) => setValue('access.equity', e)}
                          />
                          <span className="text-sm font-[450] text-gray-700">
                            <AppFormattedMessage id={StringKey.EQUITY_AND_CAPITAL_CHANGE} />
                          </span>
                        </div>
                        <div className="flex items-center gap-1">
                          <Checkbox
                            checked={watch('access.companyTable')}
                            onChange={(e) => setValue('access.companyTable', e)}
                          />
                          <span className="text-sm font-[450] text-gray-700">
                            Company Table (of{' '}
                            <i>
                              <AppFormattedMessage id={StringKey.EQUITY_AND_CAPITAL_CHANGE} />
                            </i>
                            )
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="flex h-9 w-full justify-end gap-3 px-6">
              <Button
                className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
                onClick={() => setOpen(false)}
                styleType="NONE"
                type="button"
              >
                <AppFormattedMessage id={StringKey.CANCEL} />
              </Button>
              <Button
                className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
                disabled={!isValid}
                type="submit"
              >
                <AppFormattedMessage id={StringKey.ADD_STAKEHOLDER} />
              </Button>
            </div>
          </form>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default AddStakeholderModal;
