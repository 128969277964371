import React, { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { useSelectedCompany, useSharePlans } from '../../../../../hooks';
import { Pool, PoolPlansTab } from '../../../../../types/pool-plans.types';
import { PoolPlansPopover } from '../../PoolPlansPopover';
import { ScrollablePlanItem } from './ScrollablePlanItem';

export type ScrollablePoolItemProps = {
  pool: Pool;
  isSelected: boolean;
  selectedPlan: string;
  selectedTab: PoolPlansTab;
  onEditClick: (id: string) => void;
  onViewClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
  onEditTerminationDateClick: (id: string) => void;
  onEditPlanTerminationDateClick: (id: string) => void;
};

export const ScrollablePoolItem: FC<ScrollablePoolItemProps> = ({
  pool,
  isSelected,
  selectedPlan,
  selectedTab,
  onEditClick,
  onViewClick,
  onDeleteClick,
  onEditPlanClick,
  onViewPlanClick,
  onDeletePlanClick,
  onEditTerminationDateClick,
  onEditPlanTerminationDateClick,
}) => {
  const { selectedCompany } = useSelectedCompany();
  const poolAvailablePool = 0;
  const poolPoolGranted = 0;
  const poolDilutedSharesCount =
    pool?.dillutedSharesCount && Number(pool.dillutedSharesCount).toLocaleString('en-US');
  const companyId = selectedCompany?.id || '';

  const { sharePlans, isEmpty } = useSharePlans({
    companyId,
    poolId: pool?.id || '',
    selectedTab,
  });

  const isTerminatedDatePassed = useMemo(() => {
    return pool?.terminationDate
      ? new Date(pool.terminationDate).getTime() < new Date().getTime()
      : false;
  }, [pool?.terminationDate]);
  return (
    <>
      <tr
        className={twMerge(
          'h-[72px] w-full border-b-[1px] border-b-gray-100 bg-white transition-colors',
          isSelected && 'bg-brand-25',
        )}
      >
        <td className="p-4 text-sm text-gray-700">{poolDilutedSharesCount || '-'}</td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !poolAvailablePool && 'text-gray-300',
            )}
          >
            {poolAvailablePool || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !poolPoolGranted && 'text-gray-300',
            )}
          >
            {poolPoolGranted || '-'}
          </span>
        </td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="relative">
          <PoolPlansPopover
            id={pool.id}
            isCanDeleted={pool.sharePlansCount === 0}
            isTerminated={isTerminatedDatePassed}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            onEditTerminationDateClick={onEditTerminationDateClick}
            onViewClick={onViewClick}
            sharePlans={sharePlans || []}
            text="Pool"
          />
        </td>
      </tr>
      {!isEmpty && sharePlans && isSelected && (
        <>
          {sharePlans.map((plan) => (
            <ScrollablePlanItem
              isSelected={selectedPlan === plan.id}
              key={plan.id}
              onDeletePlanClick={onDeletePlanClick}
              onEditPlanClick={onEditPlanClick}
              onEditPlanTerminationDateClick={onEditPlanTerminationDateClick}
              onViewPlanClick={onViewPlanClick}
              plan={plan}
            />
          ))}
        </>
      )}
    </>
  );
};
