import React, { FC } from 'react';

import { CheckIcon, CloseIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { useFormat, useLocale } from '../../../../hooks';
import { Locale, StringKey } from '../../../../lang';

export type ChangeBillingReportModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  price: number;
  currency: string;
  renewDate: number;
};

const ChangeBillingReportModal: FC<ChangeBillingReportModalProps> = ({
  onClose,
  onSuccess,
  currency,
  price,
  renewDate,
}) => {
  const { format } = useFormat();
  const renewFormattedDate = new Date(renewDate * 1000);
  renewFormattedDate.setFullYear(renewFormattedDate.getFullYear() + 1);
  const { locale } = useLocale();

  return (
    <div className="relative flex max-w-[550px] flex-col gap-8 rounded bg-white p-10 text-center shadow-xl">
      <span className="text-4xl font-bold text-gray-700">
        <AppFormattedMessage id={StringKey.SWITCH_TO_ANNUAL_BILLING} />
      </span>
      <div className="flex flex-col gap-4 text-start">
        <div className="flex items-center gap-2">
          <CheckIcon className="size-[16px]" iconColor="#344054" />
          <span className="text-sm font-[550] text-gray-700">
            <AppFormattedMessage
              id={StringKey.SWITCH_TO_ANNUAL_PLAN_CHARGE_MESSAGE}
              values={{ price: price / 100, currency: currency.toUpperCase() }}
            />
          </span>
        </div>
        <div className="flex items-center gap-2">
          <CheckIcon className="size-[16px]" iconColor="#344054" />
          <span className="text-sm font-[550] text-gray-700">
            <AppFormattedMessage
              id={StringKey.SWITCH_TO_ANNUAL_PLAN_MESSAGE}
              values={{
                date: format(renewFormattedDate, locale === Locale.EN ? 'MMMM d, y' : 'd MMMM y'),
              }}
            />
          </span>
        </div>
        <div className="flex items-center gap-2">
          <CheckIcon className="size-[16px]" iconColor="#344054" />
          <span className="text-sm font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.SWITCH_TO_ANNUAL_PLAN_ACTIVE_MESSAGE} />
          </span>
        </div>
      </div>
      <Button onClick={onSuccess}>
        <AppFormattedMessage id={StringKey.CONFIRM} />
      </Button>
      <Button
        className="absolute right-4 top-4 flex size-8 items-center justify-center rounded-full shadow-sm"
        onClick={onClose}
        styleType="NONE"
      >
        <CloseIcon iconColor="#172335" />
      </Button>
    </div>
  );
};

ChangeBillingReportModal.displayName = 'ChangeBillingReportModal';

export default ChangeBillingReportModal;
