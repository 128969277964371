import React, { FC } from 'react';

import { GoogleIcon } from '../../assets/icons';
import { StringKey } from '../../lang';
import { AppFormattedMessage } from '../AppFormattedMessage';

interface GoogleAuthButtonProps {
  onClick?: () => void;
}

const GoogleAuthButton: FC<GoogleAuthButtonProps> = ({ onClick }) => {
  return (
    <div
      className="flex cursor-pointer select-none items-center justify-center gap-2.5 rounded-[40px] bg-[#F2F2F2] py-3"
      onClick={onClick}
    >
      <GoogleIcon />
      <div className="font-roboto text-sm font-[550] text-[#1F1F1F]">
        <AppFormattedMessage id={StringKey.CONTINUE_WITH_GOOGLE} />
      </div>
    </div>
  );
};

export default GoogleAuthButton;
