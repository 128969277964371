import React, { FC, useCallback, useMemo, useState } from 'react';

import { TrashBinIcon, UploadIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormFileInput, FormInput } from '../../../../components/Input';
import { BackendRoute } from '../../../../config';
import { Company, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import { accessTokenStorage } from '../../../../storage/accessTokenStorage';
import { ChildrenFormProps } from '../CompanySetupForm';
import { INPUT_IMAGE_ACCEPT } from '../Validations';
import CityOfStateCombobox from './CityOfStateCombobox';
import CountryOfCompanyCombobox from './CountryOfCompanyCombobox';
import ReportingCurrencyCombobox from './ReportingCurrencyCombobox';
import StateOfCountryCombobox from './StateOfCountryCombobox';
import { FormSchema, formSchema } from './Validation';

export const filterArray = <T,>({
  array,
  filterFunc,
  query,
}: {
  query: string;
  array: T[];
  filterFunc: (item: T) => boolean;
}) => (query ? array.filter(filterFunc) : array);

export const useFilteredData = <T,>({
  data = [],
  filterFunc,
  inputValue,
}: {
  data?: T[];
  inputValue: string;
  filterFunc: (item: T) => boolean;
}) =>
  useMemo(
    () => filterArray({ array: data, query: inputValue, filterFunc }),
    [data, filterFunc, inputValue],
  );

const CompanySetupStepTwo: FC<ChildrenFormProps> = ({
  formData,
  setFormData,
  prevFormStep,
  nextFormStep,
  companyId,
}) => {
  const [isLoading, setLoading] = useState(false);
  const { stepTwo } = useMemo(() => formData(), [formData]);

  const { patch, isPending } = Company.useUpdate();

  const {
    control,
    handleSubmit,
    resetField,
    setValue,
    watch,

    formState: { isValid },
  } = useReactForm({
    schema: formSchema,
    defaultValues: stepTwo,
  });

  const submitHandler = useCallback(
    async (data: FormSchema) => {
      setLoading(true);
      const logoData = new FormData();

      const isNeedToDelete = stepTwo.logoImgSrc?.link && !data.logoImgSrc?.link;

      await ApiService.refreshTokens();
      const accessToken = accessTokenStorage.get();

      logoData.append('file', data.logoImgSrc as File);

      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}${BackendRoute.COMPANIES}/${companyId}?deleteLogo=${isNeedToDelete}`,
        {
          body: logoData,
          method: 'PATCH',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      patch(
        {
          companyId,
          data: {
            countryIso2: data.country.iso2Code,
            currencyIso3: data.currency.iso3Code,
            currencyCountryIso2Code: data.currency.countryIso2Code,
            stateIso2: data.state.iso2Code,
            stateCountryIso2: data.country.iso2Code,
            city: data.city,
            zipCode: data.zipCode,
            address: data.address,
          },
        },
        {
          onSuccess: () => {
            setLoading(false);
            setFormData('stepTwo', data);
            nextFormStep();
          },
        },
      );
    },
    [companyId, nextFormStep, patch, setFormData, stepTwo.logoImgSrc?.link],
  );

  const selectedCountry = watch('country');
  const selectedCurrency = watch('currency');
  const selectedState = watch('state');
  const selectedLogo = watch('logoImgSrc');

  return (
    <form
      className="flex w-full min-w-[330px] max-w-[400px] flex-col gap-8 lg:pb-4"
      onSubmit={handleSubmit(submitHandler)}
    >
      <div className="flex flex-col gap-2 text-center">
        <span
          className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
          style={{
            background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
          }}
        >
          <AppFormattedMessage id={StringKey.ADD_NEW_COMPANY} />
        </span>
        <span className="text-sm font-normal text-gray-500">
          <AppFormattedMessage id={StringKey.WHERERE_LOCATED} />
        </span>
      </div>
      <div className="flex flex-col gap-4">
        <CountryOfCompanyCombobox
          control={control}
          selectedCountry={selectedCountry}
          setValue={setValue}
        />

        <ReportingCurrencyCombobox
          control={control}
          selectedCurrency={selectedCurrency}
          setValue={setValue}
        />

        <StateOfCountryCombobox
          control={control}
          resetField={resetField}
          selectedCountry={selectedCountry}
          setValue={setValue}
        />

        <div className="flex justify-between gap-4">
          <CityOfStateCombobox
            control={control}
            selectedCountry={selectedCountry}
            selectedState={selectedState}
          />
          <FormInput
            control={control}
            name="zipCode"
            placeholder={<AppFormattedMessage id={StringKey.ZIP_CODE} />}
            wrapperClassName="w-[100px] shrink-0"
          />
        </div>

        <FormInput
          control={control}
          name="address"
          placeholder={<AppFormattedMessage id={StringKey.COMPANY_ADDRESS} />}
        />
        <FormFileInput
          accept={INPUT_IMAGE_ACCEPT}
          control={control}
          description={
            <AppFormattedMessage
              id={StringKey.MAXIMUM_PHOTO_SIZE}
              values={{ rules: '2MB-PNG, JPEG & SVG' }}
            />
          }
          errorIcon={<TrashBinIcon className="size-6" iconColor="#F04438" />}
          icon={<UploadIcon />}
          iconWrapper="mt-1"
          name="logoImgSrc"
          onIconClick={() => {
            resetField('logoImgSrc');
            setValue('logoImgSrc', undefined, { shouldValidate: true });
          }}
          placeholder={<AppFormattedMessage id={StringKey.COMPANY_LOGO_OPTIONAL} />}
          successIcon={
            <>
              {selectedLogo?.link && (
                <div className="size-[20px] overflow-hidden rounded-full">
                  {formData('stepTwo.logoImgSrc') && (
                    <img
                      alt=""
                      className="size-[20px] rounded-full object-cover"
                      src={formData('stepTwo.logoImgSrc')?.link}
                    />
                  )}
                </div>
              )}
              <TrashBinIcon className="size-6 p-[1px]" iconColor="#344054" />
            </>
          }
        />
        <div className="flex gap-4">
          <Button
            className="border-[1px] border-gray-300 text-sm text-gray-700 max-lg:hidden"
            onClick={prevFormStep}
            styleType="DEFAULT_ROUNDED"
          >
            <AppFormattedMessage id={StringKey.BACK} />
          </Button>
          <Button disabled={!isValid} isLoading={isPending || isLoading} type="submit">
            <AppFormattedMessage id={StringKey.CONTINUE} />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CompanySetupStepTwo;
