import React, { memo, ReactElement, useCallback } from 'react';
import {
  Control,
  FieldPathByValue,
  FieldValues,
  PathValue,
  useController,
  useWatch,
} from 'react-hook-form';

import Input, { InputProps } from './Input';

export type FormInputProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, null | undefined | unknown>,
> = {
  control: Control<TFieldValues>;
  defaultValue?: PathValue<TFieldValues, TPath>;
  name: TPath;
  onBlur?: () => void;
  onChange?: (value: string) => void;
  customValue?: (value?: PathValue<TFieldValues, TPath>) => string | undefined;
} & Omit<InputProps, 'defaultValue' | 'onBlur' | 'onChange' | 'value'>;

const FormInput = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, null | undefined | unknown>,
>({
  control,
  defaultValue,
  name,
  onBlur,
  customValue,
  onChange,
  errorMessage,
  ...props
}: FormInputProps<TFieldValues, TPath>): ReactElement | null => {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
  });

  const latestValue = useWatch({
    control,
    name,
    defaultValue,
  });

  const handleChange = useCallback(
    (value: string) => {
      onChange?.(value);
      field.onChange(value);
    },
    [field, onChange],
  );

  const handleBlur = useCallback(() => {
    field?.onBlur();
    onBlur?.();
  }, [field, onBlur]);

  return (
    <Input
      {...props}
      {...field}
      errorMessage={errorMessage || (fieldState.error?.message ?? fieldState.error?.type)}
      onBlur={handleBlur}
      onChange={handleChange}
      value={customValue ? (customValue(latestValue) ?? '') : latestValue || ''}
    />
  );
};

export default memo(FormInput) as typeof FormInput;
