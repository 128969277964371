import { Content } from '@radix-ui/react-alert-dialog';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { AlertDialogOverlay } from './AlertDialogOverlay';
import { AlertDialogPortal } from './AlertDialogPortal';

export type AlertDialogContentProps = ComponentPropsWithoutRef<typeof Content>;

export const AlertDialogContent = forwardRef<ElementRef<typeof Content>, AlertDialogContentProps>(
  ({ className, ...props }, ref) => (
    <AlertDialogPortal>
      <AlertDialogOverlay />
      <Content
        className={twMerge(
          'centered fixed left-[50%] top-[50%] z-[61] flex h-fit max-h-[90vh] w-full max-w-full translate-x-[-50%] translate-y-[-50%] overflow-y-auto overflow-x-hidden p-2 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
          className,
        )}
        ref={ref}
        {...props}
      />
    </AlertDialogPortal>
  ),
);
AlertDialogContent.displayName = Content.displayName;
