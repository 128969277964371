import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type PaginationArrowIconProps = IconProps;

const PaginationArrowIcon: FC<PaginationArrowIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="14"
    viewBox="0 0 14 14"
    width="14"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8333 7.00033H1.16663M1.16663 7.00033L6.99996 12.8337M1.16663 7.00033L6.99996 1.16699"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

export default PaginationArrowIcon;
