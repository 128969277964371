import React, { FC } from 'react';

import { CapQuestLogo } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { useScreenSize } from '../../hooks';
import { StringKey } from '../../lang';

const Error: FC = () => {
  const { width } = useScreenSize();
  const isMobile = width <= 1023;
  return (
    <div className="flex h-full w-full items-center justify-center overflow-y-auto">
      <div className="flex max-h-full max-lg:h-full max-lg:w-full">
        <div className="flex h-fit w-full flex-col gap-8 rounded-lg p-4 max-lg:pt-16 lg:w-fit lg:p-8 lg:shadow-sm">
          <div className="flex flex-col gap-8 border-b border-gray-200 pb-8">
            <div className="flex flex-col justify-between gap-8 lg:items-center lg:text-center">
              <CapQuestLogo className={isMobile ? 'size-12' : 'size-16'} />
              <div className="flex flex-col gap-4">
                <span
                  className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
                  style={{
                    background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
                  }}
                >
                  <AppFormattedMessage id={StringKey.OOPS} />
                </span>
                <div className="flex flex-col">
                  <span className="text-sm font-[450] text-gray-500">
                    <AppFormattedMessage id={StringKey.INCORRECT_LINK_PT_1} />
                  </span>
                  <span className="text-sm font-[450] text-gray-500">
                    <AppFormattedMessage id={StringKey.INCORRECT_LINK_PT_2} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-6 border-b border-gray-200 pb-8">
              <span className="text-base font-[450] text-black">
                <AppFormattedMessage id={StringKey.HERE_ARE_A_FEW_THING_TO_TRY} />,
              </span>
              <div className="flex items-center gap-3">
                <div className="size-[10px] shrink-0 rounded-full bg-gray-500" />
                <span className="text-sm font-medium text-gray-500">
                  <AppFormattedMessage id={StringKey.CHECK_THE_URL} />
                </span>
              </div>

              <div className="flex items-center gap-3">
                <div className="size-[10px] shrink-0 rounded-full bg-gray-500" />
                <span className="text-sm font-[450] text-gray-500">
                  <AppFormattedMessage id={StringKey.IF_YOU_RECEIVED_LINK_IN_EMAIL} />
                </span>
              </div>

              <div className="flex items-center gap-3">
                <div className="size-[10px] shrink-0 rounded-full bg-gray-500" />
                <span className="text-sm font-[450] text-gray-500">
                  <AppFormattedMessage id={StringKey.MISC_ADVISE_3} />
                </span>
              </div>
            </div>
            <div className="flex flex-wrap gap-2">
              <span className="text-sm font-[450] text-gray-700">
                <AppFormattedMessage id={StringKey.IN_THE_MEANTIME_FEEL_FREE_TO} />
              </span>
              <a
                className="text-sm font-[450] text-brand-700 underline"
                href="https://capquest-client-stage-3a3033e7e181.herokuapp.com"
              >
                website
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Error.displayName = 'Error';

export default Error;
