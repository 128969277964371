import React, { FC } from 'react';
import { Control, useController } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { StringKey } from '../../../lang';
import {
  companyIndustryTranslation,
  listOfCompanyIndustryTranslation,
} from '../../../translations/companyTranslation';
import { FormSchema } from './Validation';

export type CompanyIndustryItemsListProps = {
  control: Control<FormSchema>;
};

const CompanyIndustryItemsList: FC<CompanyIndustryItemsListProps> = ({ control }) => (
  <>
    {listOfCompanyIndustryTranslation.map(([companyIndustryKey, companyIndustry]) => (
      <FormComboboxOption
        control={control}
        key={companyIndustryKey}
        name="industry"
        value={companyIndustryKey}
      >
        <span className="text-sm font-normal text-gray-700">{companyIndustry}</span>
      </FormComboboxOption>
    ))}
  </>
);

export type CompanyIndustryComboboxProps = {
  control: Control<FormSchema>;
};

const CompanyIndustryCombobox: FC<CompanyIndustryComboboxProps> = ({ control }) => {
  const { formState } = useController({
    control,
    name: 'industry',
  });

  return (
    <FormCombobox className="relative" control={control} name={'industry'}>
      <div className="relative">
        <FormComboboxInput
          className={'absolute z-0 bg-gray-900'}
          control={control}
          customValue={(value) => (value ? companyIndustryTranslation[value] : value)}
          icon={<ChevronDownIcon className={twMerge('mt-3', formState.disabled && 'hidden')} />}
          name={'industry'}
          placeholder={<AppFormattedMessage id={StringKey.INDUSTRY} />}
          readOnly
          wrapperClassName={formState.disabled && 'border-none'}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <CompanyIndustryItemsList control={control} />
      </ComboboxOptions>
    </FormCombobox>
  );
};

export default CompanyIndustryCombobox;
