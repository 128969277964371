import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { twMerge } from 'tailwind-merge';

import { ArrowIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { PaginationController } from '../../components/PaginationController';
import { QueryKey } from '../../constants';
import { Pool, SharePlan, useModalState, useSelectedCompany } from '../../hooks';
import { StringKey } from '../../lang';
import { PageRoute } from '../../types/pageTypes';
import { PoolPlansTab } from '../../types/pool-plans.types';
import { EditPlanModal } from '../PoolPlans/PlanForm/Edit';
import { EditPoolModal, PoolTerminationDateModal } from '../PoolPlans/PoolForm';
import { SelectedPlan, SelectedShareClass } from '../PoolPlans/PoolPlans';
import { PoolMobileDatePicker } from '../PoolPlans/PoolPlansMobile';
import { PlanCard } from '../PoolPlans/PoolPlansMobile/Cards/PlanCard';
import { PoolPlansPopover } from '../PoolPlans/PoolPlansTable';

const PoolDetails: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { poolId } = useParams();
  const [selectedTab, setTab] = useState<PoolPlansTab>(PoolPlansTab.ACTIVE);
  const [date, setDate] = useState<Date | undefined>(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const numberOfFetchedItems = 5;
  const { selectedCompany } = useSelectedCompany();
  const [selectedPool, setSelectedPool] = useState<SelectedShareClass | null>({
    id: '',
    step: 1,
    mode: 'EDIT',
  });
  const [selectedPlan, setSelectedPlan] = useState<SelectedPlan | null>({
    id: '',
    step: 1,
    mode: 'EDIT',
  });

  const companyId = selectedCompany?.id || '';
  const currentPath = location.pathname;

  const {
    handleCloseModal: handleCloseEditPoolModal,
    handleOpenModal: handleOpenEditPoolModal,
    isOpen: isOpenEditPoolModal,
  } = useModalState({});
  const {
    handleCloseModal: handleCloseEditPlanModal,
    handleOpenModal: handleOpenEditPlanModal,
    isOpen: isOpenEditPlanModal,
  } = useModalState({});
  const {
    handleCloseModal: handleClosePoolTerminationDateModal,
    handleOpenModal: handleOpenPoolTerminationDateModal,
    isOpen: isOpenPoolTerminationDateModal,
  } = useModalState({});

  const queryClient = useQueryClient();

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_POOL] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SHARE_PLANS] });
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_SHARE_PLAN] });
  }, [queryClient]);

  const { pool: currentPool } = Pool.usePool({ companyId, poolId: poolId || '' });
  const { terminate: terminatePlan } = SharePlan.useTerminate();
  const { deletePlan } = SharePlan.useDelete();
  const { deletePool } = Pool.useDelete();
  const { terminate: terminatePool } = Pool.useTerminate();

  const { isLoading, sharePlans, totalPages } = SharePlan.useSharePlans({
    companyId,
    poolId: poolId || '',
    currentPage,
    selectedTab,
    take: numberOfFetchedItems,
  });

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      setCurrentPage(pageNumber);
      invalidateQuery();
    },
    [invalidateQuery],
  );

  const isTerminated = currentPool?.isTerminated ?? false;

  if (!sharePlans || isLoading) return <Loader />;

  return (
    <>
      <EditPoolModal
        companyId={companyId}
        handleClose={handleCloseEditPoolModal}
        handleTerminate={({ date, id }) =>
          terminatePool({ companyId, date, poolId: id }, { onSuccess: invalidateQuery })
        }
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditPoolModal}
        onEditClick={({ id, step }) => {
          setSelectedPool({ id, mode: 'EDIT', step });
        }}
        poolId={selectedPool?.id || ''}
        previewMode={selectedPool?.mode === 'VIEW' || undefined}
        step={selectedPool?.step || 1}
      />
      <EditPlanModal
        companyId={companyId}
        handleClose={handleCloseEditPlanModal}
        handleTerminate={({ date, id }) =>
          terminatePlan({ date, companyId, planId: id }, { onSuccess: invalidateQuery })
        }
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenEditPlanModal}
        onEditClick={({ id, step }) => setSelectedPlan({ id, mode: 'EDIT', step })}
        planId={selectedPlan?.id || ''}
        previewMode={selectedPlan?.mode === 'VIEW' || undefined}
        step={selectedPlan?.step || 1}
      />
      <PoolTerminationDateModal
        companyId={companyId}
        handleClose={handleClosePoolTerminationDateModal}
        handleTerminate={({ date, id }) =>
          terminatePool({ companyId, date, poolId: id }, { onSuccess: invalidateQuery })
        }
        invalidateQuery={invalidateQuery}
        isOpenModal={isOpenPoolTerminationDateModal}
        poolId={selectedPool?.id || ''}
      />
      <div className="flex h-fit min-h-full w-full flex-col gap-4 rounded-md lg:p-4 lg:shadow-sm">
        <div className="flex h-[36px] w-full gap-4 border-b-[1px] border-gray-200">
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
              selectedTab === PoolPlansTab.ACTIVE && 'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(PoolPlansTab.ACTIVE);
              setCurrentPage(1);
            }}
            styleType="NONE"
          >
            <AppFormattedMessage id={StringKey.ACTIVE} />
          </Button>
          <Button
            className={twMerge(
              'flex h-full w-fit rounded-none border-b-2 border-transparent text-sm font-[450] text-gray-400',
              selectedTab === PoolPlansTab.TERMINATED &&
                'border-brand-700 font-[550] text-brand-700',
            )}
            onClick={() => {
              setTab(PoolPlansTab.TERMINATED);
              setCurrentPage(1);
            }}
            styleType="NONE"
          >
            <AppFormattedMessage id={StringKey.TERMINATED} />
          </Button>
        </div>
        <div className="flex w-full items-center justify-center xlg:hidden">
          <div className="flex min-w-[300px] max-w-[440px] flex-col border-gray-200">
            {currentPool && sharePlans && (
              <>
                <PoolMobileDatePicker className="mb-0" date={date} setDate={setDate} />
                <div className="align-center flex gap-2 py-4 pr-4">
                  <NavLink
                    className="shrink-0 text-xs font-[450] text-brand-700"
                    to={PageRoute.POOL_PLANS}
                  >
                    Pool & Plans
                  </NavLink>
                  <span className="text-xs font-[450] text-gray-400">/</span>
                  <NavLink className="truncate text-xs font-[450] text-gray-400" to={currentPath}>
                    {currentPool?.name}
                  </NavLink>
                </div>
                <div className="relative flex w-full items-center justify-between border-y border-[#F2F2F2] pr-6">
                  <div className="flex w-full items-center gap-3">
                    <Button
                      className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full shadow-buttonShadow"
                      onClick={() => navigate(PageRoute.POOL_PLANS)}
                      styleType="NONE"
                    >
                      <ArrowIcon />
                    </Button>
                    <div className="flex w-full flex-col overflow-hidden py-3">
                      <span className="truncate text-base font-[550] text-gray-700">
                        {currentPool?.name}
                      </span>
                      <span className="truncate text-xs font-[450] text-gray-400">
                        {currentPool?.shareClass?.name}
                      </span>
                    </div>
                  </div>
                  <PoolPlansPopover
                    companyId={companyId}
                    id={currentPool?.id || ''}
                    isCanDeleted={currentPool.sharePlansCount === 0}
                    isTerminated={isTerminated}
                    onDeleteClick={(id) =>
                      deletePool(
                        { companyId, poolId: id },
                        { onSuccess: () => toast.success('Pool successfully deleted') },
                      )
                    }
                    onEditClick={(id) => {
                      handleOpenEditPoolModal();
                      setSelectedPool({
                        id,
                        mode: 'EDIT',
                        step: 3,
                      });
                    }}
                    onEditTerminationDateClick={(id) => {
                      handleOpenPoolTerminationDateModal();
                      setSelectedPool({
                        id,
                        mode: 'EDIT',
                        step: 3,
                      });
                    }}
                    onViewClick={(id) => {
                      handleOpenEditPoolModal();

                      setSelectedPool({
                        id,
                        mode: 'VIEW',
                        step: 3,
                      });
                    }}
                    text="pool"
                  />
                </div>

                <div className="flex min-h-full flex-col items-center px-[1px] pt-4">
                  {sharePlans && currentPool && (
                    <>
                      {sharePlans.map((plan) => (
                        <PlanCard
                          key={plan.id}
                          navigationLink={`${PageRoute.POOL_PLANS}/${currentPool.id}/${plan.id}`}
                          onDeletePlanClick={(id) =>
                            deletePlan(
                              { companyId, planId: id },
                              {
                                onSuccess: () => {
                                  toast.success('Plan successfully deleted');
                                  const isLastItemOnPage = sharePlans.length === 1;
                                  const isFirstPage = currentPage === 1;

                                  if (isLastItemOnPage && !isFirstPage) {
                                    setCurrentPage((prev) => prev - 1);
                                  }
                                  invalidateQuery();
                                },
                              },
                            )
                          }
                          onEditPlanClick={(id) => {
                            handleOpenEditPlanModal();
                            setSelectedPlan({
                              id,
                              mode: 'EDIT',
                              step: 5,
                            });
                          }}
                          onViewPlanClick={(id) => {
                            handleOpenEditPlanModal();

                            setSelectedPlan({
                              id,
                              mode: 'VIEW',
                              step: 5,
                            });
                          }}
                          plan={plan}
                        />
                      ))}
                      <PaginationController
                        className="shrink-0 max-lg:px-4 lg:mx-6"
                        currentPage={currentPage}
                        onClick={handlePageChange}
                        totalPages={totalPages || 0}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PoolDetails;
