import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CLoseIconProps = IconProps;

const CloseIcon: FC<CLoseIconProps> = ({ iconColor = '#172335', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="10"
    viewBox="0 0 10 10"
    width="10"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1L1 9M1 1L9 9"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default CloseIcon;
