import { Cancel } from '@radix-ui/react-alert-dialog';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const AlertDialogCancel = forwardRef<
  ElementRef<typeof Cancel>,
  ComponentPropsWithoutRef<typeof Cancel>
>(({ className, ...props }, ref) => (
  <Cancel className={twMerge('sm:mt-0 mt-2', className)} ref={ref} {...props} />
));
AlertDialogCancel.displayName = Cancel.displayName;
