import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

type CapQuestLogoProps = {
  size?: number;
} & Omit<IconProps, 'iconColor'>;

const CapQuestLogo: FC<CapQuestLogoProps> = ({ size, className, ...props }) => {
  return (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height={`${size || 32}`}
      viewBox="0 0 32 32"
      width={`${size || 32}`}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6371_62137)">
        <path
          d="M21.2054 2.60554C21.3164 2.32177 21.1766 2.00074 20.8889 1.90027C18.6846 1.13042 16.3285 0.884726 14.0081 1.18614C11.5051 1.51126 9.1245 2.46248 7.08666 3.95177C5.04883 5.44106 3.41956 7.4203 2.34963 9.70635C1.27969 11.9924 0.803659 14.5114 0.965577 17.0302C1.1275 19.5491 1.92213 21.9864 3.27596 24.1166C4.62978 26.2469 6.49906 28.0012 8.7108 29.2173C10.9225 30.4334 13.4053 31.072 15.9293 31.074C18.2693 31.0758 20.5746 30.5305 22.6621 29.4847C22.9346 29.3483 23.0321 29.012 22.8857 28.7448L21.2127 25.6906C21.0663 25.4234 20.7315 25.3269 20.4569 25.4589C19.0476 26.1366 17.5015 26.4895 15.9329 26.4882C14.1802 26.4869 12.4561 26.0434 10.9203 25.1989C9.38442 24.3544 8.08637 23.1362 7.14625 21.6569C6.20614 20.1777 5.65433 18.4852 5.5419 16.736C5.42946 14.9869 5.76002 13.2377 6.503 11.6503C7.24597 10.0628 8.37736 8.68838 9.79246 7.6542C11.2076 6.62001 12.8607 5.95948 14.5988 5.7337C16.1542 5.53166 17.7328 5.68364 19.2173 6.17531C19.5066 6.27111 19.8262 6.13251 19.9372 5.84874L21.2054 2.60554Z"
          fill="#083D90"
        />
        <path
          d="M26.1197 28.9532C26.3011 29.198 26.6474 29.2501 26.8853 29.0597C28.9643 27.3966 30.5636 25.21 31.5187 22.7248C31.628 22.4404 31.4734 22.1262 31.1852 22.0274L26.0286 20.2604C25.7404 20.1616 25.4283 20.3161 25.311 20.5973C24.7962 21.8305 23.9963 22.9242 22.977 23.7884C22.7446 23.9855 22.6919 24.3297 22.8734 24.5744L26.1197 28.9532Z"
          fill="#B3B3B3"
        />
        <path
          d="M30.0685 19.1276C30.3663 19.1921 30.661 19.003 30.7145 18.7031C31.2277 15.825 30.8896 12.8551 29.7354 10.1604C28.5812 7.46564 26.6647 5.17181 24.2273 3.55754C23.9733 3.38929 23.6331 3.47222 23.4743 3.73227L21.6593 6.70449C21.5005 6.96454 21.5836 7.30289 21.8349 7.47522C23.4609 8.59039 24.7406 10.1466 25.5199 11.966C26.2991 13.7854 26.5427 15.7854 26.228 17.7318C26.1794 18.0326 26.367 18.3262 26.6648 18.3906L30.0685 19.1276Z"
          fill="#B3B3B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_6371_62137">
          <rect fill="white" height={`${size || 32}`} width={`${size || 32}`} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CapQuestLogo;
