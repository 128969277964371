import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type TableTdProps = { value: string | ReactNode; className?: string };

export const Td: FC<TableTdProps> = ({ value, className }) => (
  <td className={twMerge('px-4 py-6', className)}>
    <span className="text-sm font-[450] text-gray-700">{value}</span>
  </td>
);
