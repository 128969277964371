import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CloudDownloadIconProps = IconProps;

export const CloudDownloadIcon: FC<CloudDownloadIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.3335 11.3333L8.00016 14M8.00016 14L10.6668 11.3333M8.00016 14V8M13.3335 11.1619C14.1478 10.4894 14.6668 9.47196 14.6668 8.33333C14.6668 6.30829 13.0252 4.66667 11.0002 4.66667C10.8545 4.66667 10.7182 4.59066 10.6442 4.46516C9.77487 2.98989 8.16978 2 6.3335 2C3.57207 2 1.3335 4.23858 1.3335 7C1.3335 8.3774 1.89046 9.62472 2.79146 10.529"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.125"
    />
  </svg>
);
