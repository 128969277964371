import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PaymentData } from '../../types/companyTypes';
import { useAppQuery } from '../useAppQuery';

export const usePaymentDetails = ({
  companyId,
  enabled = true,
}: {
  companyId: string;
  enabled?: boolean;
}) => {
  const { invalidateQueries } = useQueryClient();
  const { data: paymentData, isLoading } = useAppQuery<PaymentData>({
    queryKey: [QueryKey.GET_PAYMENT_DATA],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'payment-details'],
      }),
    enabled: !!companyId && enabled,
  });

  const invalidateQuery = useCallback(() => {
    invalidateQueries({ queryKey: [QueryKey.GET_PAYMENT_DATA] });
  }, [invalidateQueries]);
  return { paymentData, invalidateQuery, isLoading };
};
