import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDelete = () => {
  const { mutate: deleteUser, isPending } = useAppMutation([QueryKey.PATCH_USER], {
    mutationFn: () => ApiService.delete({ endpoint: BackendRoute.USERS }),
  });
  return { deleteUser, isPending };
};
