import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import Loader from '../../components/Loader';
import { QueryKey } from '../../constants';
import { Company } from '../../hooks';
import BillingForm from './BillingForm';

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const Billing: FC = () => {
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { clientSecret } = Company.useClientSecret({ companyId: id || '' });
  const { company } = Company.useCompany({ companyId: id || '' });

  const handleSuccess = useCallback(() => {
    toast.success('Payment successfully completed');
    setLoading(true);
    const timeout = setTimeout(() => {
      navigate('/');
      setLoading(false);
    }, 3000);
    queryClient.invalidateQueries({ queryKey: [QueryKey.GET_COMPANIES] });

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate, queryClient]);

  if (isLoading) return <Loader />;

  return (
    <div className="flex h-full w-full items-center justify-center">
      <Elements
        options={{
          clientSecret: clientSecret,
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap',
            },
          ],
        }}
        stripe={stripe}
      >
        <BillingForm
          clientSecret={clientSecret || ''}
          onSuccess={handleSuccess}
          selectedPlanInfo={company?.plan}
        />
      </Elements>
    </div>
  );
};

Billing.displayName = 'Billing';

export default Billing;
