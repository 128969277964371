import React, { FC } from 'react';
import { Control } from 'react-hook-form';

import { ChevronDownIcon } from '../../../assets/icons';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../components/Combobox';
import { SharePlanType, sharePlanTypeTitle } from '../../../types/pool-plans.types';
import { FormSchema } from './Validation';

export type SharePlanTypeItemsListProps = {
  control: Control<FormSchema>;
};

const SharePlanTypeItemsList: FC<SharePlanTypeItemsListProps> = ({ control }) => {
  return (
    <>
      {Object.entries(sharePlanTypeTitle).map(([key, value]) => (
        <FormComboboxOption control={control} key={key} name="stepOne.type" value={key}>
          <span className="text-sm text-gray-700">{value}</span>
        </FormComboboxOption>
      ))}
    </>
  );
};

export type SharePlanTypeComboboxProps = {
  control: Control<FormSchema>;
  onSelect?: (state: FormSchema['stepOne']['type']) => void;
};

export const SharePlanTypeCombobox: FC<SharePlanTypeComboboxProps> = ({ control, onSelect }) => {
  const customValue = (key?: string) => sharePlanTypeTitle[key as SharePlanType];
  return (
    <FormCombobox className="relative" control={control} name={'stepOne.type'} onSelect={onSelect}>
      <div className="relative">
        <FormComboboxInput
          className={'bg-gray-900'}
          control={control}
          customValue={customValue}
          icon={<ChevronDownIcon className="mt-2" />}
          name={'stepOne.type'}
          placeholder="Equity plan type"
          readOnly
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <SharePlanTypeItemsList control={control} />
      </ComboboxOptions>
    </FormCombobox>
  );
};
