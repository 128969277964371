import React, { FC } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { FormSchema } from '../Validation';
import DayCombobox from './DayCombobox';
import MonthCombobox from './MonthCombobox';
import YearCombobox from './YearCombobox';

export type BirthdayDateSelectProps = {
  control: Control<FormSchema>;
  setValue: UseFormSetValue<FormSchema>;
  isNeedDefaultValue?: boolean;
};

const BirthdayDateSelect: FC<BirthdayDateSelectProps> = ({
  setValue,
  control,
  isNeedDefaultValue = false,
}) => {
  const { format } = useFormat();
  const { human } = useWatch<FormSchema>({
    control,
  });
  const birthdayDate = human?.birthdayDate;

  const selectedDay = birthdayDate?.day;
  const selectedMonth = birthdayDate?.month;
  const selectedYear = birthdayDate?.year;

  return (
    <div className="flex w-full flex-col gap-3">
      <span className="text-xs font-medium text-gray-500">
        <AppFormattedMessage id={StringKey.BIRTHDAY_OPTIONAL} />
      </span>
      <div className="flex w-full gap-4">
        <DayCombobox
          defaultValue={isNeedDefaultValue && selectedDay ? selectedDay : undefined}
          onSelect={(day) => {
            setValue('human.birthdayDate.day', day, {
              shouldValidate: true,
            });
          }}
          selectedMonth={selectedMonth}
          selectedYear={selectedYear}
        />
        <MonthCombobox
          defaultValue={
            isNeedDefaultValue && typeof selectedMonth === 'number'
              ? {
                  index: selectedMonth,
                  month: format(new Date(2024, selectedMonth, 1), 'MMMM'),
                }
              : undefined
          }
          onSelect={(month) =>
            setValue('human.birthdayDate.month', month.index, {
              shouldValidate: true,
            })
          }
        />
        <YearCombobox
          defaultValue={isNeedDefaultValue && selectedYear ? selectedYear : undefined}
          maxYear={new Date().getFullYear()}
          minYear={1900}
          onSelect={(year) =>
            setValue('human.birthdayDate.year', year, {
              shouldValidate: true,
            })
          }
        />
      </div>
    </div>
  );
};

BirthdayDateSelect.displayName = 'BirthdaySelect';

export default BirthdayDateSelect;
