import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type MinusIconProps = IconProps;

const MinusIcon: FC<MinusIconProps> = ({ iconColor = '#ffffff', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="2"
    viewBox="0 0 10 2"
    width="10"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 1H8.5"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

export default MinusIcon;
