import * as flagsComponentMap from 'country-flag-icons/react/3x2';
import React, { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { convertIso3toIso2 } from '../../utils/convertIso3toIso2';

export type FlagCode = keyof typeof flagsComponentMap;

export type FlagProps = {
  countryCode: string;
} & Parameters<flagsComponentMap.FlagComponent>[0];

export const Flag: FC<FlagProps> = ({ countryCode, className, ...props }) => {
  const iso2Code = countryCode ? convertIso3toIso2(countryCode) : '';
  const FlagComponent = useMemo(
    () => iso2Code && flagsComponentMap[iso2Code as FlagCode],
    [iso2Code],
  );

  if (!FlagComponent) return <></>;

  return (
    <FlagComponent
      className={twMerge('max-h-3 w-4 shrink-0 overflow-hidden', className)}
      {...props}
    />
  );
};
