import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { DateRange } from 'react-day-picker';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import {
  BuybackEventItem,
  ConversionEventItem,
  EventTypeBackend,
  FundraisingEventItem,
  GrantEventItem,
  SecondariesEventItem,
  ShareIssuanceEventItem,
  SortByDate,
} from '../../types/events.types';
import { useFormat } from '../useFormat';
import { dateFilterVariant } from './useEvents';

type EventTransactionsResponse<
  TEvent extends
    | GrantEventItem
    | FundraisingEventItem
    | ShareIssuanceEventItem
    | SecondariesEventItem
    | BuybackEventItem
    | ConversionEventItem,
> = {
  items: TEvent[];
  totalPages: number;
};

export const useTransactions = <
  TEvent extends
    | GrantEventItem
    | FundraisingEventItem
    | ShareIssuanceEventItem
    | SecondariesEventItem
    | BuybackEventItem
    | ConversionEventItem,
>({
  companyId,
  eventId,
  eventType,
  currentPage = 0,
  take = 13,
  customDateFilter,
  dateFilter = SortByDate.ALL_TIME,
}: {
  companyId: string;
  eventId: string;
  eventType: EventTypeBackend;
  take?: number;
  currentPage?: number;
  dateFilter?: SortByDate;
  customDateFilter?: DateRange;
}) => {
  const { invalidateQueries } = useQueryClient();
  const { format } = useFormat();

  const dateRange = dateFilterVariant[dateFilter]({
    from: customDateFilter?.from,
    to: customDateFilter?.to,
  });

  const dateRangeTo = dateRange.to ? format(dateRange.to, 'yyyy-MM-dd') : null;
  const dateRangeFrom = dateRange.from ? format(dateRange.from, 'yyyy-MM-dd') : null;

  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<
    EventTransactionsResponse<TEvent>
  >({
    queryKey: [
      QueryKey.GET_EVENT_TRANSACTIONS,
      { eventId, eventType, take, currentPage, to: dateRangeTo, from: dateRangeFrom },
    ],
    queryFn: ({ pageParam = 0 }) =>
      ApiService.get(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'events', eventId, eventType, 'transactions'],
        },
        {
          queryParams: {
            take,
            skip: currentPage ? skip : pageParam,
            'start-date': dateRangeFrom,
            'end-date': dateRangeTo,
          },
        },
      ),

    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.totalPages > allPages.length ? allPages.length * take : undefined,
    enabled: !!companyId && !!eventId,
  });

  const items = useMemo(
    () =>
      data?.pages?.reduce<EventTransactionsResponse<TEvent>['items']>((acc, page) => {
        return [...acc, ...page.items];
      }, []),
    [data?.pages],
  );

  const invalidateQuery = useCallback(() => {
    invalidateQueries({ queryKey: [QueryKey.GET_EVENT_TRANSACTIONS] });
  }, [invalidateQueries]);

  return {
    invalidateQuery,
    items: items || [],
    totalPages: data?.pages[0]?.totalPages || 0,
    hasNextPage,
    fetchNextPage,
    isLoading,
  };
};
