import { StringKey } from '../../lang';
import { FilterVariant, StakeholderFilter } from '../../types/stakeholderTypes';

export enum StakeholderCard {
  TERMINATED = 'terminated',
  STAKEHOLDERS = 'stakeholders',
}

export const stakeholderCardTranslation: Record<
  StakeholderCard,
  Record<FilterVariant, StringKey>
> = {
  [StakeholderCard.STAKEHOLDERS]: {
    [StakeholderFilter.ACTIVE]: StringKey.ACTIVE_STAKEHOLDERS,
    [StakeholderFilter.INACTIVE]: StringKey.INACTIVE_STAKEHOLDERS,
    all: StringKey.ALL_STAKEHOLDERS,
  },
  [StakeholderCard.TERMINATED]: {
    all: StringKey.ALL_TERMINATED,
    [StakeholderFilter.ACTIVE]: StringKey.ACTIVE_TERMINATED,
    [StakeholderFilter.INACTIVE]: StringKey.INACTIVE_TERMINATED,
  },
};
export const stakeholderCardTabMap: Record<StakeholderCard, StringKey> = {
  [StakeholderCard.STAKEHOLDERS]: StringKey.STAKEHOLDERS_TAB,
  [StakeholderCard.TERMINATED]: StringKey.TERMINATED_TAB,
};

export const arrayOfStakeholderCardTab = Object.entries(stakeholderCardTabMap);
