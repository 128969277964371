import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CapQuestLogo, EyeIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { Input } from '../../components/Input';
import Loader from '../../components/Loader';
import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { useAppMutation, useAppQuery, User } from '../../hooks';
import { StringKey } from '../../lang';
import { ApiService } from '../../services';
import { passwordRules } from '../RegisterVerify/RegisterVerify';

const PasswordRecovery = () => {
  const navigate = useNavigate();
  const [isPasswordShown, setPasswordShown] = useState(false);
  const [isPasswordVerifyShown, setPasswordVerifyShown] = useState(false);

  const { id } = useParams();

  const [recoverPasswordDto, setRecoverPasswordDto] = useState({
    password: '',
    repeatPassword: '',
  });

  const { isPending: isLoadingIdCheck } = useAppQuery({
    retry: false,
    queryKey: [QueryKey.PASSWORD_RECOVERY_ID_CHECK, id],
    queryFn: () =>
      ApiService.get({ endpoint: BackendRoute.RECOVERY, routePath: ['check', id || ''] }),
    defaultErrorHandling: false,
    onError: () => navigate('/error-page'),
  });

  const { logoutUser } = User.useLogout({ reloadWindow: false });

  const changePasswordMutation = useAppMutation<void, HttpException>([], {
    mutationFn: async () => {
      await ApiService.post(
        { endpoint: BackendRoute.AUTH_RECOVER_PASSWORD },
        {
          body: {
            ...recoverPasswordDto,
            recoveryId: id,
          },
        },
      );
    },
    onSuccess: () => {
      logoutUser();
    },
  });

  const isPasswordValid = passwordRules.every(({ check }) => check(recoverPasswordDto));

  if (isLoadingIdCheck) return <Loader />;

  return (
    <div className="flex h-full items-center justify-center overflow-y-auto bg-gray-50 font-inter">
      <div className="max-h-full max-lg:h-full max-lg:w-full">
        <div
          className="flex h-fit min-h-full w-full flex-col gap-8 rounded-lg bg-white px-4 pb-8 pt-16 lg:h-fit lg:w-[471px] lg:items-center lg:px-8 lg:pt-8 lg:text-center"
          style={{
            filter:
              'drop-shadow(0px 1px 2px #1018280F) drop-shadow(0px 2px 3px #1018281A) drop-shadow(0px -1px 3px #1018280A)',
          }}
        >
          <CapQuestLogo size={48} />

          <div className="flex flex-col gap-2">
            <div
              className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
              style={{
                background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
              }}
            >
              {changePasswordMutation.isSuccess ? (
                <AppFormattedMessage id={StringKey.PASSWORD_CHANGED} />
              ) : (
                <AppFormattedMessage id={StringKey.CHANGE_YOUR_PASSWORD} />
              )}
            </div>
            <div className="text-sm text-gray-500">
              {changePasswordMutation.isSuccess ? (
                <AppFormattedMessage id={StringKey.PASSWORD_SUCCESSFULLY_CHANGED} />
              ) : (
                <AppFormattedMessage id={StringKey.ENTER_A_NEW_PASSWORD_BELOW} />
              )}
            </div>
          </div>

          {!changePasswordMutation.isSuccess && (
            <>
              <div className="flex w-full flex-col gap-4">
                <Input
                  icon={<EyeIcon variant={isPasswordShown ? 'ACTIVE' : 'CLOSE'} />}
                  isError={changePasswordMutation.isError}
                  onChange={(value) =>
                    setRecoverPasswordDto({
                      ...recoverPasswordDto,
                      password: value,
                    })
                  }
                  onIconClick={() => setPasswordShown((prev) => !prev)}
                  placeholder={<AppFormattedMessage id={StringKey.NEW_PASSWORD} />}
                  type={isPasswordShown ? 'text' : 'password'}
                  value={recoverPasswordDto.password}
                />
                <Input
                  errorMessage={changePasswordMutation.error?.body?.message}
                  icon={<EyeIcon variant={isPasswordVerifyShown ? 'ACTIVE' : 'CLOSE'} />}
                  isError={changePasswordMutation.isError}
                  onChange={(value) =>
                    setRecoverPasswordDto({
                      ...recoverPasswordDto,
                      repeatPassword: value,
                    })
                  }
                  onIconClick={() => setPasswordVerifyShown((prev) => !prev)}
                  placeholder={<AppFormattedMessage id={StringKey.REENTER_NEW_PASSWORD} />}
                  type={isPasswordVerifyShown ? 'text' : 'password'}
                  value={recoverPasswordDto.repeatPassword}
                />
              </div>
              <div className="flex w-full flex-col gap-4 border border-gray-200 p-4 font-inter text-xs text-gray-700">
                <div className="text-left text-sm">
                  <AppFormattedMessage id={StringKey.PASSWORD_RECOMMENDATION} />
                </div>
                <div className="flex flex-col gap-3">
                  {passwordRules.map(({ title, check }, i) => (
                    <div
                      className={`flex items-center gap-2 ${check(recoverPasswordDto) ? 'text-forest-500' : ''}`}
                      key={i}
                    >
                      <svg
                        fill="none"
                        height="16"
                        viewBox="0 0 16 16"
                        width="16"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.3333 4L6 11.3333L2.66667 8"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.33333"
                        />
                      </svg>
                      <div>{title}</div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          )}

          {changePasswordMutation.isSuccess ? (
            <Button onClick={() => navigate('/login')}>
              <AppFormattedMessage id={StringKey.SIGN_IN_TO_CAPQEUST} />
            </Button>
          ) : (
            <div className="mt-2 w-full">
              <Button
                disabled={!isPasswordValid}
                isLoading={changePasswordMutation.isPending}
                onClick={() => changePasswordMutation.mutate()}
              >
                <AppFormattedMessage id={StringKey.CHANGE_PASSWORD} />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordRecovery;
