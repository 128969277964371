import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDelete = () => {
  const { mutate: deleteEvent, isPending } = useAppMutation([QueryKey.DELETE_EVENT], {
    defaultErrorHandling: false,
    mutationFn: ({ companyId, eventId }: { companyId: string; eventId: string }) =>
      ApiService.delete({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'events', eventId],
      }),
  });
  return { deleteEvent, isPending };
};
