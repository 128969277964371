import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export enum RefreshIconVariant {
  DEFAULT,
  EVENTS,
}

export type RefreshIconProps = { variant?: RefreshIconVariant } & IconProps;

const refreshIconVariant: Record<
  RefreshIconVariant,
  (props: Except<RefreshIconProps, 'variant'>) => ReactNode
> = {
  [RefreshIconVariant.DEFAULT]: ({ iconColor = '#344054', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="16"
      viewBox="0 0 15 14"
      width="16"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.33331 5.66667C1.33331 5.66667 2.66997 3.84548 3.75587 2.75883C4.84177 1.67218 6.34238 1 7.99998 1C11.3137 1 14 3.68629 14 7C14 10.3137 11.3137 13 7.99998 13C5.26458 13 2.95672 11.1695 2.23449 8.66667M1.33331 5.66667V1.66667M1.33331 5.66667H5.33331"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </svg>
  ),
  [RefreshIconVariant.EVENTS]: ({ iconColor = '#344054', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="20"
      viewBox="0 0 22 20"
      width="22"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7 11.5L18.7005 9.5L16.7 11.5M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C13.3019 1 16.1885 2.77814 17.7545 5.42909M10 5V10L13 12"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  ),
};

const RefreshIcon: FC<RefreshIconProps> = ({ variant = RefreshIconVariant.DEFAULT, ...props }) =>
  refreshIconVariant[variant](props);

export default RefreshIcon;
