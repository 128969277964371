import { FlagCode } from '../components/Flag';

const iso3ToIso2Map: Record<string, FlagCode> = {
  afn: 'AF', // Afghan Afghani
  sdg: 'SD', // Sudanese Pound
  bif: 'BI', // Burundian Franc
  mxn: 'MX', // Mexican Peso
  cuc: 'CU', // Cuban Convertible Peso
  cup: 'CU', // Cuban Peso
  rsd: 'RS', // Serbian Dinar
  eur: 'EU', // Euro
  btn: 'BT', // Bhutanese Ngultrum
  inr: 'IN', // Indian Rupee
  gyd: 'GY', // Guyanese Dollar
  shp: 'SH', // Saint Helena Pound
  bam: 'BA', // Bosnian Convertible Mark
  bnd: 'BN', // Brunei Dollar
  sgd: 'SG', // Singapore Dollar
  pkr: 'PK', // Pakistani Rupee
  kes: 'KE', // Kenyan Shilling
  usd: 'US', // United States Dollar
  sos: 'SO', // Somali Shilling
  nok: 'NO', // Norwegian Krone
  sll: 'SL', // Sierra Leonean Leone
  xpf: 'PF', // CFP Franc
  azn: 'AZ', // Azerbaijani Manat
  ckd: 'CK', // Cook Islands Dollar
  nzd: 'NZ', // New Zealand Dollar
  pen: 'PE', // Peruvian Nuevo Sol
  aoa: 'AO', // Angolan Kwanza
  xaf: 'CM', // Central African CFA Franc
  ssp: 'SS', // South Sudanese Pound
  try: 'TR', // Turkish Lira
  xcd: 'LC', // East Caribbean Dollar
  awg: 'AW', // Aruban Florin
  twd: 'TW', // New Taiwan Dollar
  sek: 'SE', // Swedish Krona
  lrd: 'LR', // Liberian Dollar
  ves: 'VE', // Venezuelan Bolívar
  bmd: 'BM', // Bermudian Dollar
  all: 'AL', // Albanian Lek
  hkd: 'HK', // Hong Kong Dollar
  ern: 'ER', // Eritrean Nakfa
  cop: 'CO', // Colombian Peso
  mnt: 'MN', // Mongolian Tugrik
  yer: 'YE', // Yemeni Rial
  lbp: 'LB', // Lebanese Pound
  vnd: 'VN', // Vietnamese Dong
  gbp: 'GB', // British Pound Sterling
  jep: 'JE', // Jersey Pound
  ttd: 'TT', // Trinidad and Tobago Dollar
  ils: 'IL', // Israeli New Shekel
  bgn: 'BG', // Bulgarian Lev
  gip: 'GI', // Gibraltar Pound
  ang: 'AW', // Netherlands Antillean Guilder
  sar: 'SA', // Saudi Riyal
  ghs: 'GH', // Ghanaian Cedi
  mdl: 'MD', // Moldovan Leu
  aad: 'AD', // Andorran Peseta
  pgk: 'PG', // Papua New Guinean Kina
  bzd: 'BZ', // Belize Dollar
  kmf: 'KM', // Comorian Franc
  xof: 'BF', // West African CFA Franc
  dkk: 'DK', // Danish Krone
  fok: 'FO', // Faroese Króna
  gnf: 'GN', // Guinean Franc
  tnd: 'TN', // Tunisian Dinar
  idr: 'ID', // Indonesian Rupiah
  tjs: 'TJ', // Tajikistani Somoni
  cdf: 'CD', // Congolese Franc
  ron: 'RO', // Romanian Leu
  qar: 'QA', // Qatari Rial
  cny: 'CN', // Chinese Yuan
  aud: 'AU', // Australian Dollar
  mru: 'MR', // Mauritanian Ouguiya
  uzs: 'UZ', // Uzbekistani Som
  isk: 'IS', // Icelandic Króna
  omr: 'OM', // Omani Rial
  mkd: 'MK', // Macedonian Denar
  chf: 'CH', // Swiss Franc
  pyg: 'PY', // Paraguayan Guarani
  bhd: 'BH', // Bahraini Dinar
  jpy: 'JP', // Japanese Yen
  iqd: 'IQ', // Iraqi Dinar
  imp: 'IM', // Isle of Man Pound
  mmk: 'MM', // Myanmar Kyat
  vuv: 'VU', // Vanuatu Vatu
  zwl: 'ZW', // Zimbabwean Dollar
  php: 'PH', // Philippine Peso
  szl: 'SZ', // Swazi Lilangeni
  zar: 'ZA', // South African Rand
  rwf: 'RW', // Rwandan Franc
  ugx: 'UG', // Ugandan Shilling
  irr: 'IR', // Iranian Rial
  uyu: 'UY', // Uruguayan Peso
  srd: 'SR', // Surinamese Dollar
  uah: 'UA', // Ukrainian Hryvnia
  czk: 'CZ', // Czech Koruna
  hnl: 'HN', // Honduran Lempira
  wst: 'WS', // Samoan Tala
  lak: 'LA', // Laotian Kip
  cve: 'CV', // Cape Verdean Escudo
  etb: 'ET', // Ethiopian Birr
  bdt: 'BD', // Bangladeshi Taka
  byn: 'BY', // Belarusian Ruble
  hrk: 'HR', // Croatian Kuna
  kwd: 'KW', // Kuwaiti Dinar
  mad: 'MA', // Moroccan Dirham
  rub: 'RU', // Russian Ruble
  lkr: 'LK', // Sri Lankan Rupee
  pln: 'PL', // Polish Zloty
  mga: 'MG', // Malagasy Ariary
  crc: 'CR', // Costa Rican Colón
  mop: 'MO', // Macanese Pataca
  nad: 'NA', // Namibian Dollar
  scr: 'SC', // Seychellois Rupee
  pab: 'PA', // Panamanian Balboa
  htg: 'HT', // Haitian Gourde
  ars: 'AR', // Argentine Peso
  mwk: 'MW', // Malawian Kwacha
  kid: 'KI', // Kiribati Dollar
  syp: 'SY', // Syrian Pound
  huf: 'HU', // Hungarian Forint
  kyd: 'KY', // Cayman Islands Dollar
  bob: 'BO', // Bolivian Boliviano
  djf: 'DJ', // Djiboutian Franc
  ngn: 'NG', // Nigerian Naira
  stn: 'ST', // São Tomé and Príncipe Dobra
  nio: 'NI', // Nicaraguan Córdoba
  bsd: 'BS', // Bahamian Dollar
  clp: 'CL', // Chilean Peso
  myr: 'MY', // Malaysian Ringgit
  tvd: 'TV', // Tuvaluan Dollar
  sbd: 'SB', // Solomon Islands Dollar
  tzs: 'TZ', // Tanzanian Shilling
  kpw: 'KP', // North Korean Won
  egp: 'EG', // Egyptian Pound
  jod: 'JO', // Jordanian Dinar
  jmd: 'JM', // Jamaican Dollar
  khr: 'KH', // Cambodian Riel
  mur: 'MU', // Mauritian Rupee
  gmd: 'GM', // Gambian Dalasi
  lsl: 'LS', // Lesotho Loti
  dzd: 'DZ', // Algerian Dinar
  aed: 'AE', // United Arab Emirates Dirham
  mzn: 'MZ', // Mozambican Metical
  zmw: 'ZM', // Zambian Kwacha
  gtq: 'GT', // Guatemalan Quetzal
  krw: 'KR', // South Korean Won
  kgs: 'KG', // Kyrgyzstani Som
  fkp: 'FK', // Falkland Islands Pound
  kzt: 'KZ', // Kazakhstani Tenge
  bwp: 'BW', // Botswana Pula
  bbd: 'BB', // Barbadian Dollar
  top: 'TO', // Tongan Paʻanga
  thb: 'TH', // Thai Baht
  cad: 'CA', // Canadian Dollar
  gel: 'GE', // Georgian Lari
  fjd: 'FJ', // Fijian Dollar
  amd: 'AM', // Armenian Dram
  dop: 'DO', // Dominican Peso
  ggp: 'GG', // Guernsey Pound
  tmt: 'TM', // Turkmenistani Manat
  npr: 'NP', // Nepalese Rupee
  mvr: 'MV', // Maldivian Rufiyaa
  lyd: 'LY', // Libyan Dinar
  brl: 'BR', // Brazilian Real
};

export const convertIso3toIso2 = (countryCode: string): FlagCode => {
  return iso3ToIso2Map[countryCode.toLowerCase()] || countryCode.toUpperCase();
};
