import React, { FC } from 'react';

import {
  AddContributorIcon,
  ArrowIcon,
  CloseCircleIconSmall,
  CrossIcon,
  EditIcon,
  EyeIconSmall,
  GrantAccessIcon,
  RevokeAccessIcon,
  SendInviteIcon,
  SuccessCircleIcon,
  ThreeDotsIcon,
  TrashBinIcon,
} from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { FormattedDateDistance } from '../../components/FormattedDateDistance';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../components/Popover';
import { StringKey } from '../../lang';
import { stakeholderTypeTranslation } from '../../translations/stakeholderTranslation';
import { Stakeholder, StakeholderRole } from '../../types/stakeholderTypes';
import { getStakeholderCommonActions } from './StakeholderPermissions';
import StakeholderStatus, { StakeholderActionStatus } from './StakeholderStatus';

type StakeholderItemProps = {
  votingRights?: number;
  lastActive?: string;
  handleEdit: (id: string) => void;
  handleView: (id: string) => void;
  handleDelete: (id: string) => void;
  handleMakeContributor: (id: string) => void;
  handleRemoveContributorRole: (id: string) => void;
  handleResendInvitation: (id: string) => void;
  handleCancelInvitation: (id: string) => void;
  handleSendInvitation: (id: string) => void;
  handleRevokeAccess: (id: string) => void;
  handleTerminate: (id: string) => void;
  handleGrantAccess: (id: string) => void;
  roleInCompany?: StakeholderRole;
  accountId: string;
} & Stakeholder;

export const StakeholderItem: FC<StakeholderItemProps> = ({
  isAccessRevoked,
  role,
  type,
  isActive,
  isInvited,
  lastActive,
  votingRights = 0,
  fullName,
  email,
  id,
  handleView,
  handleEdit,
  handleDelete,
  handleCancelInvitation,
  handleGrantAccess,
  handleMakeContributor,
  handleRemoveContributorRole,
  handleResendInvitation,
  handleRevokeAccess,
  handleTerminate,
  handleSendInvitation,
  roleInCompany,
  accountId,
}) => {
  const {
    canDelete,
    canTerminate,
    cancelInvitation,
    grantAccess,
    makeContributor,
    resendInvitation,
    revokeAccess,
    revokeContributor,
  } = getStakeholderCommonActions({
    role,
    isInvited,
    isActive,
    isAccessRevoked,
  });

  return (
    <div className="flex h-fit w-full min-w-[300px] max-w-[440px] flex-col divide-y-[1px] divide-gray-100 rounded-md shadow-sm">
      <div className="flex items-center justify-between px-3 py-4">
        <div className="flex gap-3">
          <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
            {fullName[0]}
          </div>
          <div className="flex h-full flex-col justify-between">
            {accountId === id ? (
              <div className="flex items-center gap-1">
                <span className="max-w-[155px] truncate text-sm font-[450] text-gray-700">
                  {fullName}
                </span>
                <span className="text-sm font-[450] text-gray-700">(You)</span>
              </div>
            ) : (
              <span className="max-w-[200px] truncate text-sm font-[450] text-gray-700">
                {fullName}
              </span>
            )}
            <span className="max-w-[200px] truncate text-sm text-gray-700">{email}</span>
          </div>
        </div>
        <Popover>
          <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
            <div className="flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
              <ThreeDotsIcon />
            </div>
          </PopoverTrigger>
          <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl">
            <PopoverClose>
              <div
                className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                onClick={() => handleView(id)}
              >
                <EyeIconSmall className="size-4" />
                <span className="text-sm font-normal text-gray-700">
                  <AppFormattedMessage id={StringKey.VIEW_DETAILS} />
                </span>
              </div>
              {(role !== StakeholderRole.ADMIN || roleInCompany === StakeholderRole.ADMIN) && (
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleEdit(id)}
                >
                  <EditIcon className="size-4" />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.EDIT_DETAILS} />
                  </span>
                </div>
              )}

              {resendInvitation && (
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleResendInvitation(id)}
                >
                  <SendInviteIcon className="size-4" />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.RESEND_INVITATION} />
                  </span>
                </div>
              )}
              {cancelInvitation && (
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleCancelInvitation(id)}
                >
                  <CloseCircleIconSmall className="size-4" iconColor="#344054" />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.CANCEL_INVITATION} />
                  </span>
                </div>
              )}
              {grantAccess && (
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleGrantAccess(id)}
                >
                  <GrantAccessIcon className="size-4" />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.GRANT_ACCESS} />
                  </span>
                </div>
              )}

              {revokeAccess && accountId !== id && (
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleRevokeAccess(id)}
                >
                  <RevokeAccessIcon className="size-4" />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.REVOKE_ACCESS} />
                  </span>
                </div>
              )}
              {makeContributor && (
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleMakeContributor(id)}
                >
                  <AddContributorIcon className="size-4" />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.MAKE_CONTRIBUTOR} />
                  </span>
                </div>
              )}
              {revokeContributor && accountId !== id && (
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleRemoveContributorRole(id)}
                >
                  <RevokeAccessIcon className="size-4" />
                  <span className="text-sm font-normal text-gray-700">
                    <AppFormattedMessage id={StringKey.REVOKE_CONTRIBUTOR_ROLE} />
                  </span>
                </div>
              )}
              {canTerminate && (
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                  onClick={() => handleTerminate(id)}
                >
                  <CrossIcon className="size-4" iconColor="#F04438" />
                  <span className="text-sm font-normal text-fireside-500">
                    <AppFormattedMessage id={StringKey.TERMINATE} />
                  </span>
                </div>
              )}
              {canDelete && accountId !== id && (
                <div
                  className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-fireside-500 hover:bg-gray-50"
                  onClick={() => handleDelete(id)}
                >
                  <TrashBinIcon className="size-4" iconColor="#F04438" />
                  <span className="text-sm font-normal text-fireside-500">
                    <AppFormattedMessage id={StringKey.DELETE} />
                  </span>
                </div>
              )}
            </PopoverClose>
          </PopoverContent>
        </Popover>
      </div>
      <div className="p-3 text-xs font-[450] text-gray-700">{`${stakeholderTypeTranslation[type]} with ${votingRights}% voting rights`}</div>
      <div className="p-3 text-xs font-[450] text-gray-700">
        {isActive ? (
          <>
            <span>
              <AppFormattedMessage id={StringKey.LAST_ACTIVE} />
            </span>
            <FormattedDateDistance date={new Date(lastActive)} />
          </>
        ) : (
          '-'
        )}
      </div>
      <div className="flex gap-3 overflow-x-auto p-3">
        <StakeholderStatus
          type={isActive ? StakeholderActionStatus.ACTIVE : StakeholderActionStatus.INACTIVE}
        />
        {isActive && <StakeholderStatus type={role} />}

        {!isActive && (
          <>
            {isAccessRevoked && <StakeholderStatus type={StakeholderActionStatus.ACCESS_REVOKED} />}
            {isInvited ? (
              !isAccessRevoked && (
                <StakeholderStatus
                  className="flex-row-reverse"
                  icon={<SuccessCircleIcon />}
                  type={StakeholderActionStatus.INVITED}
                />
              )
            ) : (
              <StakeholderStatus
                className="cursor-pointer"
                icon={<ArrowIcon className={'rotate-180'} iconColor="#344054" />}
                onClick={() => handleSendInvitation(id)}
                type={StakeholderActionStatus.INVITE_USER}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
