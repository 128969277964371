import { Transition } from '@headlessui/react';
import React, { Dispatch, FC, Fragment, memo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon, PlusIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { Radio, RadioGroup } from '../../../components/RadioGroup';
import { Select, SelectButton, SelectOption, SelectOptions } from '../../../components/Select';
import { Company, useNavigate } from '../../../hooks';
import { StringKey } from '../../../lang';
import { filterVariant, FilterVariant, filterVariantMap } from '../../../types/companyTypes';
import { PageRoute } from '../../../types/pageTypes';

export type MyCompaniesHeaderProps = {
  onFilterSelect?: () => void;
  setSelectedFilter: Dispatch<React.SetStateAction<FilterVariant>>;
  selectedFilter: FilterVariant;
};

const MyCompaniesHeader: FC<MyCompaniesHeaderProps> = memo(
  ({ setSelectedFilter, onFilterSelect, selectedFilter }) => {
    const [selected, setSelected] = useState<FilterVariant>('all');
    const navigate = useNavigate(PageRoute.COMPANY_SETUP);
    const handleFilterSelect = (filter: FilterVariant) => {
      setSelectedFilter(filter);
      setSelected(filter);
      onFilterSelect?.();
    };

    const { create } = Company.useCreate();

    return (
      <div className="flex max-h-16 w-full items-center justify-between lg:p-4">
        <RadioGroup
          className="h-fit divide-x divide-gray-200 overflow-hidden rounded border border-gray-200"
          onChange={handleFilterSelect}
          value={selectedFilter}
          wrapperClassName={'max-lg:hidden h-9'}
        >
          {filterVariantMap.map(([filterKey, filter]) => (
            <Radio
              className="flex h-9 items-center justify-center px-4 text-sm font-[450] text-[#858593] data-[checked]:bg-brand-25 data-[checked]:font-[550] data-[checked]:text-[#172335]"
              key={filterKey}
              styleType="NONE"
              value={filterKey}
            >
              <span>
                <AppFormattedMessage id={filter} />
              </span>
            </Radio>
          ))}
        </RadioGroup>
        <Select onChange={handleFilterSelect} value={selected}>
          {({ open }) => (
            <>
              <div className="relative rounded border-gray-200 px-[6px] py-2 lg:hidden">
                <SelectButton>
                  <span className="text-sm font-[550] text-[#172335]">
                    <AppFormattedMessage id={filterVariant[selected]} />
                  </span>
                  <ChevronDownIcon className="h-4 w-4 text-gray-400" />
                </SelectButton>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={open}
                >
                  <SelectOptions>
                    {filterVariantMap.map(([filterKey, filter]) => (
                      <SelectOption key={filterKey} value={filterKey}>
                        {({ selected }) => (
                          <span
                            className={twMerge(
                              selected ? 'font-[550]' : 'font-normal',
                              'block text-sm text-gray-700',
                            )}
                          >
                            <AppFormattedMessage id={filter} />
                          </span>
                        )}
                      </SelectOption>
                    ))}
                  </SelectOptions>
                </Transition>
              </div>
            </>
          )}
        </Select>
        <Button
          className="w-fit gap-1 px-4 py-2 max-lg:size-9"
          onClick={() =>
            create(undefined, {
              onSuccess: ({ id }) => {
                navigate([id]);
              },
            })
          }
        >
          <div>
            <PlusIcon />
          </div>
          <span className="max-lg:hidden">
            <AppFormattedMessage id={StringKey.ADD_NEW_COMPANY} />
          </span>
        </Button>
      </div>
    );
  },
);

MyCompaniesHeader.displayName = 'MyCompaniesHeader';

export default MyCompaniesHeader;
