import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { CompanyStakeholderDto, HumanStakeholderDto } from '../../types/stakeholderTypes';
import { useAppMutation } from '../useAppMutation';
import { useLocale } from '../useLocale';

export const useUpdate = () => {
  const { messagesLocale } = useLocale();

  const { mutate: update, isPending } = useAppMutation([QueryKey.PATCH_STAKEHOLDER], {
    mutationFn: ({
      companyId,
      data,
      stakeholderId,
    }: {
      data: CompanyStakeholderDto | HumanStakeholderDto;
      companyId: string;
      stakeholderId: string;
    }) =>
      ApiService.patch(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'stakeholders', stakeholderId] },
        { body: data },
      ),
  });
  return { update, isPending };
};
