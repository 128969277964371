import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { CloseIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { BillingPlanCard, SelectButtonTextVariant } from '../../../../components/BillingPlanCard';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import { BackendRoute } from '../../../../config';
import { QueryKey } from '../../../../constants';
import { HttpException } from '../../../../exceptions';
import { Plan as PlanHook, useAppQuery, useLocale } from '../../../../hooks';
import { Locale, StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import {
  enterpriseBenefits,
  growthBenefits,
  startUpBenefits,
} from '../../../../translations/planTranslation';
import { Plan, SubscriptionDuration, SubscriptionType } from '../../../../types/planTypes';
import { Stakeholder } from '../../../../types/stakeholderTypes';

export type ChangeBillingPlanModalProps = {
  onClose: () => void;
  onSuccess?: (props: Plan) => void;
  handleChangePlan: (props: Plan) => void;
  handleSwitchPlan: (props: Plan) => void;
  selectedPlan: Plan | undefined;
  companyId: string;
};

const cardAvgPxWidth = 300;
const cardListPxGap = 16;

type GetCompaniesStakeholderResponse = {
  stakeholders: Stakeholder[];
  totalPages: number;
  totalStakeholders: number;
};

const ChangeBillingPlanModal: FC<ChangeBillingPlanModalProps> = ({
  onClose,
  selectedPlan,
  handleSwitchPlan,
  handleChangePlan,
  companyId,
}) => {
  const { messagesLocale, locale } = useLocale();
  const { data: stakeholdersData, isLoading } = useAppQuery<
    GetCompaniesStakeholderResponse,
    HttpException
  >({
    queryKey: [QueryKey.GET_STAKEHOLDERS],
    queryFn: () =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'stakeholders'] },
        {
          queryParams: {
            take: 0,
            skip: 0,
          },
        },
      ),
    enabled: !!companyId,
  });

  const totalStakeholders = stakeholdersData?.totalStakeholders;

  const navigate = useNavigate();
  const [selectedPlanDuration, setSelectedPlanDuration] = useState(
    selectedPlan?.duration || SubscriptionDuration.MONTHLY,
  );
  const { plans } = PlanHook.usePlans();

  const sortedData = plans?.reduce<Record<SubscriptionDuration, Plan[]>>(
    (acc, plan) => {
      const planDuration = plan.duration;
      if (!acc[planDuration]) {
        acc[planDuration] = [];
      }
      acc[planDuration].push(plan);
      return acc;
    },
    {} as Record<SubscriptionDuration, Plan[]>,
  );

  const planListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = planListRef.current;
    const selectedCardBillingCardId =
      plans?.findIndex((plan) => plan.title === selectedPlan?.title) || 0;

    const result = (selectedCardBillingCardId - 1) * cardAvgPxWidth;

    element?.scrollTo({
      left: result,
      behavior: 'smooth',
    });
  }, [plans, selectedPlan?.title]);

  const handleCardMode = ({
    planVariant,
    selectedPlan,
    onSwitch,
    onBlockedStakeholders,
    onBlockedMonth,
    onSelected,
    onUpgrade,
    onDowngrade,
  }: {
    selectedPlan: Plan | undefined;
    planVariant: Plan;
    onSelected: () => keyof typeof SelectButtonTextVariant | void;
    onUpgrade: () => keyof typeof SelectButtonTextVariant | void;
    onBlockedStakeholders: () => keyof typeof SelectButtonTextVariant | void;
    onBlockedMonth: () => keyof typeof SelectButtonTextVariant | void;
    onSwitch: () => keyof typeof SelectButtonTextVariant | void;
    onDowngrade: () => keyof typeof SelectButtonTextVariant | void;
  }) => {
    if (
      selectedPlan?.duration === SubscriptionDuration.ANNUALY &&
      planVariant.duration === SubscriptionDuration.MONTHLY
    )
      return onBlockedMonth();

    if (
      (totalStakeholders || 0) <= planVariant.stakeholdersCount &&
      selectedPlan?.type !== SubscriptionType.START_UP &&
      selectedPlan?.type !== planVariant.type
    )
      return onDowngrade();

    if (
      selectedPlan?.duration === SubscriptionDuration.MONTHLY &&
      selectedPlan?.type === planVariant.type
    )
      return onSwitch();

    if (selectedPlan?.type === planVariant.type) return onSelected();

    if ((selectedPlan?.stakeholdersCount || 0) > planVariant.stakeholdersCount)
      return onBlockedStakeholders();

    return onUpgrade();
  };

  if (isLoading) return <Loader />;

  return (
    <div className="relative flex w-full max-w-fit flex-col gap-10 rounded-2xl bg-white p-3 py-4 shadow-xl max-lg:pt-20 lg:p-10">
      <div className="m-auto flex h-11 w-full min-w-[330px] max-w-fit gap-1 rounded-full border-2 border-brand-700 p-[2px]">
        <Button
          className={twMerge(
            'w-fit gap-1 text-nowrap rounded-full bg-white p-2 text-sm font-[550] text-brand-900',
            selectedPlanDuration === 'monthly' && 'bg-brand-700 text-white',
          )}
          onClick={() => setSelectedPlanDuration(SubscriptionDuration.MONTHLY)}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.PAY_MONTHLY} />
        </Button>
        <Button
          className={twMerge(
            'w-full gap-1 text-nowrap rounded-full bg-white p-2 text-sm font-[550] text-brand-900',
            selectedPlanDuration === 'annualy' && 'bg-brand-700 text-white',
          )}
          onClick={() => setSelectedPlanDuration(SubscriptionDuration.ANNUALY)}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.PAY_YEARLY} />
          <span
            className={twMerge(
              'text-sm font-[550] text-forest-600',
              selectedPlanDuration === 'annualy' && 'text-white',
              locale === Locale.FR && 'max-lg:hidden',
            )}
          >
            <AppFormattedMessage id={StringKey.SAVE_APPROX} />
          </span>
        </Button>
      </div>
      <div className="flex overflow-x-auto" ref={planListRef} style={{ gap: `${cardListPxGap}px` }}>
        {sortedData?.[selectedPlanDuration]
          .sort((a, b) => a.stakeholdersCount - b.stakeholdersCount)
          .map((plan, i) => (
            <BillingPlanCard
              key={plan.id}
              {...plan}
              cardMode={
                (selectedPlan &&
                  handleCardMode({
                    selectedPlan,
                    planVariant: plan,
                    onBlockedStakeholders: () => 'BLOCKED_STAKEHOLDERS',
                    onBlockedMonth: () => 'BLOCKED_MONTH',
                    onSelected: () => 'SELECTED',
                    onSwitch: () => 'CAN_SWITCH',
                    onUpgrade: () => 'CAN_CHANGE',
                    onDowngrade: () => 'CAN_DOWNGRADE',
                  })) ||
                'DEFAULT'
              }
              includes={i === 0 ? '' : 'Everything in Startup plan +'}
              listOfBenefits={i === 0 ? startUpBenefits : growthBenefits}
              onSelectClick={(plan) => {
                handleCardMode({
                  selectedPlan,
                  planVariant: plan,
                  onBlockedStakeholders: () => {},
                  onBlockedMonth: () => {},
                  onSelected: () => {},
                  onDowngrade: () => {
                    handleChangePlan(plan);
                  },
                  onSwitch: () => {
                    const annualPlan = sortedData.annualy.find(({ title }) => title === plan.title);
                    if (!annualPlan) return;
                    handleSwitchPlan(annualPlan);
                  },
                  onUpgrade: () => handleChangePlan(plan),
                });
              }}
              selectSpecialText={
                selectedPlan?.title === plan.title &&
                selectedPlan.duration === SubscriptionDuration.MONTHLY
                  ? messagesLocale[StringKey.SAVE_APPROX_W_ANNUAL_BILLING]
                  : ''
              }
            />
          ))}
        <BillingPlanCard
          cardMode="SPECIAL"
          cardStyleType="SPECIAL"
          description={<AppFormattedMessage id={StringKey.FOR_CUSTOM_PRICING} />}
          includes="Everything in Growth plan +"
          listOfBenefits={enterpriseBenefits}
          onSpecialSelectClick={() => navigate('/')}
          selectButtonStyleType="SPECIAL"
          specialMessage={messagesLocale[StringKey.LETS_TALK]}
          title={<AppFormattedMessage id={StringKey.ENTERPRISE_AND_FI} />}
          type={SubscriptionType.LETS_TALK}
        />
      </div>
      <Button
        className="absolute right-4 top-4 flex size-8 items-center justify-center rounded-full shadow-sm"
        onClick={onClose}
        styleType="NONE"
      >
        <CloseIcon iconColor="#172335" />
      </Button>
    </div>
  );
};

ChangeBillingPlanModal.displayName = 'ChangeBillingPlanModal';

export default ChangeBillingPlanModal;
