import React, { FC, useCallback } from 'react';

import { EditIcon, HelpIcon } from '../../../assets/icons';
import { TrashBinIcon, UploadIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { FormFileInput, FormInput } from '../../../components/Input';
import { FormRadio, FormRadioGroup } from '../../../components/RadioGroup';
import { BackendRoute } from '../../../config';
import { useReactForm } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ApiService } from '../../../services';
import { accessTokenStorage } from '../../../storage/accessTokenStorage';
import {
  listOfCompanyAnnualRevenueTranslation,
  listOfCompanyNumberOfEmployeesTranslation,
} from '../../../translations/companyTranslation';
import { DeepPartial } from '../../../utils/deepPartial';
import { getS3FileOriginalName } from '../../../utils/getS3FileOriginalName';
import { INPUT_IMAGE_ACCEPT } from '../../CompanySetup/CompanySetupForm/Validations';
import { CompanySettingsCardComponentProps } from '../CompanySettings';
import CompanyIndustryCombobox from './CompanyIndustryCombobox';
import CompanyTypeCombobox from './CompanyTypeCombobox';
import ReportingCurrencyCombobox from './ReportingCurrencyCombobox';
import { FormSchema, formSchema, imageSchemaMessage } from './Validation';

const DetailsFormCard: FC<CompanySettingsCardComponentProps> = ({
  data,
  setEditing,
  isEditing,
  invalidateQuery,
  updateData,
  companyId,
}) => {
  const detailsData: DeepPartial<FormSchema> = {
    industry: data.industry,
    logoImgSrc: {
      size: 10,
      type: 'image/png',
      name: getS3FileOriginalName(data?.logoImgSrc) || '',
      link: data?.logoImgSrc,
    },
    name: data.name,
    currency: {
      iso3Code: data.currency?.iso3 || '',
      countryIso2Code: data.currencyCountryIso2Code || '',
      name: data.currency?.name || '',
    },
    registrationNumber: data.companyId || '',
    type: data.type,
    url: data.url ?? '',
    numberOfEmployees: data.numberOfEmployees,
    annualRevenue: data.annualRevenue,
  };

  const {
    control,
    handleSubmit,
    getFieldState,
    getValues,
    setValue,
    reset,
    watch,
    formState: { isValid },
  } = useReactForm({
    mode: 'all',
    schema: formSchema,
    disabled: !isEditing,
    defaultValues: detailsData,
    values: detailsData as FormSchema,
  });

  const submitHandler = useCallback(
    async (data: FormSchema) => {
      const logoData = new FormData();

      const isNeedToDelete = detailsData.logoImgSrc?.link && !data.logoImgSrc?.link;

      await ApiService.refreshTokens();
      const accessToken = accessTokenStorage.get();

      logoData.append('file', data.logoImgSrc as File);

      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}${BackendRoute.COMPANIES}/${companyId}?deleteLogo=${isNeedToDelete}`,
        {
          body: logoData,
          method: 'PATCH',
          credentials: 'include',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      updateData(
        {
          name: data.name,
          industry: data.industry,
          url: data.url,
          type: data.type,
          annualRevenue: data.annualRevenue,
          numberOfEmployees: data.numberOfEmployees,
          currencyIso3: data.currency.iso3Code,
          currencyCountryIso2Code: data.currency.countryIso2Code,
          companyId: data.registrationNumber,
        },
        {
          onSuccess: () => {
            setEditing(false);
            invalidateQuery();
          },
        },
      );
    },
    [companyId, detailsData.logoImgSrc?.link, invalidateQuery, setEditing, updateData],
  );
  const selectedCurrency = watch('currency');
  const selectedLogo = watch('logoImgSrc');

  return (
    <div className="flex w-fit flex-col gap-4 rounded-md lg:p-4 lg:shadow-sm">
      <div className="flex max-w-[850px] items-center justify-between">
        <span className="text-nowrap text-base font-[550] text-gray-700">
          <AppFormattedMessage id={StringKey.MY_COMPANY} />
        </span>
        <Button
          className="size-8 rounded transition-colors hover:bg-gray-100"
          onClick={() => setEditing(true)}
          styleType="NONE"
        >
          <EditIcon height={'24'} iconColor="#2565C8" width="24" />
        </Button>
      </div>

      <form
        className="flex w-full max-w-[850px] flex-wrap gap-6"
        onSubmit={handleSubmit(submitHandler)}
      >
        <div className="flex w-full flex-wrap gap-6">
          <div className="flex w-full min-w-[232px] flex-1 grow flex-col gap-6">
            <FormInput
              control={control}
              name="name"
              placeholder={<AppFormattedMessage id={StringKey.COMPANY_NAME} />}
            />
            <ReportingCurrencyCombobox
              control={control}
              selectedCurrency={selectedCurrency}
              setValue={setValue}
            />
            <CompanyIndustryCombobox control={control} />
          </div>
          <div className="flex w-full min-w-[232px] flex-1 grow flex-col gap-6">
            <FormInput
              control={control}
              name="url"
              placeholder={<AppFormattedMessage id={StringKey.COMPANY_URL_OPTIONAL} />}
            />
            <CompanyTypeCombobox control={control} />
            <FormFileInput
              accept={INPUT_IMAGE_ACCEPT}
              control={control}
              description={imageSchemaMessage}
              errorIcon={<TrashBinIcon iconColor="#F04438" />}
              icon={isEditing && <UploadIcon />}
              iconWrapper="mt-1"
              name="logoImgSrc"
              onIconClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                const { isDirty } = getFieldState('logoImgSrc');
                const value = getValues('logoImgSrc');
                if (isDirty || value) {
                  return setValue('logoImgSrc', undefined);
                }
              }}
              placeholder={<AppFormattedMessage id={StringKey.COMPANY_LOGO_OPTIONAL} />}
              successIcon={
                <>
                  <div className="size-[20px] overflow-hidden rounded-full">
                    {selectedLogo?.link && (
                      <img
                        alt=""
                        className="size-[20px] rounded-full object-cover"
                        src={selectedLogo?.link}
                      />
                    )}
                  </div>
                  <div>{isEditing && <TrashBinIcon />}</div>
                </>
              }
            />
          </div>
        </div>
        <div className="mt-3 flex flex-col gap-4">
          <div className="flex w-full flex-wrap gap-6">
            <FormInput
              control={control}
              name="registrationNumber"
              placeholder={<AppFormattedMessage id={StringKey.REGISTRATION_NUMBER_OPTIONAL} />}
              wrapperClassName="flex-1 grow w-full min-w-[232px]"
            />
            <div className="w-full min-w-[232px] flex-1 grow" />
          </div>
          <div className="flex flex-col gap-2 rounded-[4px] bg-gray-50 p-4">
            <HelpIcon />
            <span className="text-xs font-normal text-gray-700">
              <AppFormattedMessage id={StringKey.COMPANY_ID_EXPLANATION} />
            </span>
          </div>
        </div>
        <div className="flex w-full flex-wrap justify-between gap-6">
          <FormRadioGroup
            control={control}
            label={<AppFormattedMessage id={StringKey.ANNUAL_REVENUE} />}
            name="annualRevenue"
            wrapperClassName="w-full grow flex-1"
          >
            {listOfCompanyAnnualRevenueTranslation.map(([annualRevenueKey, annualRevenue]) => (
              <FormRadio
                control={control}
                key={annualRevenueKey}
                name="annualRevenue"
                value={annualRevenueKey}
              >
                <span className="text-nowrap text-label-md">{annualRevenue}</span>
              </FormRadio>
            ))}
          </FormRadioGroup>

          <FormRadioGroup
            control={control}
            label={<AppFormattedMessage id={StringKey.NO_OF_EMPLOYEES} />}
            name="numberOfEmployees"
            wrapperClassName="w-full grow flex-1"
          >
            {listOfCompanyNumberOfEmployeesTranslation.map(
              ([numberOfEmployeesKey, numberOfEmployees]) => (
                <FormRadio
                  control={control}
                  key={numberOfEmployeesKey}
                  name="numberOfEmployees"
                  value={numberOfEmployeesKey}
                >
                  <span className="text-nowrap text-label-md">{numberOfEmployees}</span>
                </FormRadio>
              ),
            )}
          </FormRadioGroup>
        </div>
        {isEditing && (
          <div className="flex w-full justify-end gap-4 border-t-[1px] border-gray-200 pt-6">
            <Button
              className="border-[1px] border-gray-100 px-3 py-[6px] text-sm text-gray-700 transition-colors hover:bg-gray-100"
              onClick={() => {
                setEditing(false);
                reset();
              }}
              styleType="DEFAULT_ROUNDED"
              type="button"
            >
              <AppFormattedMessage id={StringKey.CANCEL} />
            </Button>
            <Button
              className="w-full max-w-[280px] px-4 py-[10px] text-sm font-[550] text-gray-25 transition-colors hover:bg-brand-600"
              disabled={!isValid}
              type="submit"
            >
              <AppFormattedMessage id={StringKey.UPDATE} />
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default DetailsFormCard;
