export enum GraphVariant {
  VALUATION = 'valuation',
  OWNERSHIP = 'ownership',
  OWNERSHIP_PERCENT = 'ownership-percent',
}

export const graphVariantTitle: Record<GraphVariant, string> = {
  [GraphVariant.VALUATION]: 'Valuation history',
  [GraphVariant.OWNERSHIP]: 'Ownership history',
  [GraphVariant.OWNERSHIP_PERCENT]: 'Ownership history %',
};

export const graphVariantTitleMobile: Record<GraphVariant, string> = {
  [GraphVariant.VALUATION]: 'Valuation',
  [GraphVariant.OWNERSHIP]: 'Ownership',
  [GraphVariant.OWNERSHIP_PERCENT]: 'Ownership %',
};

export const graphVariantMap = Object.entries(graphVariantTitle);
export const graphVariantMobileMap = Object.entries(graphVariantTitleMobile);
