import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';

export const headerContext = createContext({
  isShownCompanySelect: false,
  showCompanySelect: () => {},
  hideCompanySelect: () => {},
});
type SideBarContextProps = {
  children: ReactNode;
};

export const HeaderContextProvider: FC<SideBarContextProps> = ({ children }) => {
  const [isShownCompanySelect, setCompanySelectState] = useState(true);
  const showCompanySelect = useCallback(() => {
    setCompanySelectState(true);
  }, []);

  const hideCompanySelect = useCallback(() => {
    setCompanySelectState(false);
  }, []);

  return (
    <headerContext.Provider value={{ isShownCompanySelect, showCompanySelect, hideCompanySelect }}>
      {children}
    </headerContext.Provider>
  );
};

export const useHeaderContext = () => useContext(headerContext);
