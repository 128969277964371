import { FC, useState } from 'react';

import { InfoCircle } from '../../../../assets/icons';
import { InfoCircleVariant } from '../../../../assets/icons/InfoCircle';
import { Radio, RadioGroup } from '../../../../components/RadioGroup';
import { GraphVariant, graphVariantMap } from '../../variables';

export const GraphsDesk: FC = () => {
  const [selectedGraphVariant, setGraphVariant] = useState(GraphVariant.VALUATION);
  return (
    <div className="flex h-fit w-full flex-col gap-12 rounded-md p-6 shadow-sm">
      <div className="flex w-full items-center justify-between">
        <div className="flex flex-col">
          <span className="text-sm font-[550] text-gray-700">Valuation history</span>
          <span className="text-label-sm font-medium text-gray-500">By Shares</span>
          <div className="mt-2 flex w-full gap-1 border-t border-dashed border-gray-200 pt-2">
            <span className="text-label-md font-medium text-gray-500">
              Currency: USD (millions)
            </span>
            <InfoCircle variant={InfoCircleVariant.EVENTS} />
          </div>
        </div>
        <div className="rounded-3xl border border-gray-200 p-1">
          <RadioGroup
            onChange={setGraphVariant}
            value={selectedGraphVariant}
            wrapperClassName="w-full grow flex-1"
          >
            {graphVariantMap.map(([graphKey, graphTitle]) => (
              <Radio
                className="h-8 rounded-[32px] border-transparent bg-transparent !px-3 !py-2 text-xs font-[450] text-gray-700 data-[checked]:font-[550] data-[checked]:text-brand-25"
                key={graphKey}
                value={graphKey}
              >
                <span className="text-nowrap">{graphTitle}</span>
              </Radio>
            ))}
          </RadioGroup>
        </div>
      </div>
      <div className="h-[350px] w-full bg-gray-200"></div>
    </div>
  );
};
