import React, { FC, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { SummaryItem } from '../../../../components/SummaryItem';
import { Pool, useFormat, useModalState } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { DilutionCondition } from '../../../../types/pool-plans.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { UpdateConfirmationModal } from '../../UpdateConfirmationModal';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  handleTerminate: (state: { id: string; date: Date }) => void;
  poolId: string;
};

export const PoolTerminationDateModal: FC<ModalProps> = ({
  companyId,
  isOpenModal,
  handleClose,
  invalidateQuery,
  poolId,
  handleTerminate,
}) => {
  const { pool } = Pool.usePool({ companyId, poolId });
  const { format } = useFormat();
  const [terminatingDate, setTerminatingDate] = useState<Date | null>(null);
  const [filesLinks, setFilesLinks] = useState<
    | {
        docLink: string;
        loadProgress: 100;
        abort: () => void;
        id: string;
        doc: {
          size: number;
          type: string;
          name: string;
        };
      }[]
    | null
  >(null);

  useEffect(() => {
    if (!pool) return;
    setTerminatingDate(pool.terminationDate ?? null);

    const files: {
      docLink: string;
      loadProgress: 100;
      abort: () => void;
      id: string;
      doc: {
        size: number;
        type: string;
        name: string;
      };
    }[] = pool?.filesLinks?.map((link) => {
      const originalFileName = getS3FileOriginalName(link);

      return {
        abort: () => '',
        loadProgress: 100,
        doc: {
          name: originalFileName || '',
          size: 10,
          type: 'application/pdf',
        },
        docLink: link,
        id: uuidv4(),
      };
    });
    setFilesLinks(files);
  }, [pool]);

  const handleCloseModal = useCallback(() => {
    handleClose();
    invalidateQuery();
  }, [handleClose, invalidateQuery]);

  const {
    toggler: confirmationModalToggler,
    isOpen: isOpenConfirmationModal,
    handleOpenModal: handleOpenConfirmationModal,
    handleCloseModal: handleCloseConfirmationModal,
    handleSuccessModal: handleSuccessConfirmationModal,
  } = useModalState({
    onSuccess: () => {
      if (terminatingDate) {
        handleTerminate({ id: poolId, date: terminatingDate });
      }
      handleCloseModal();
      toast.success('Termination date successfully updated');
    },
  });

  const handleDocumentClick = (link: string) => {
    window.open(link, '_blank');
  };

  const dilutionCondition = pool?.capTableInclusion
    ? DilutionCondition.INCLUDE_CAP_TABLE
    : DilutionCondition.EXCLUDE_CAP_TABLE;

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <AlertDialogWrapper
          control={{ onOpenChange: confirmationModalToggler, open: isOpenConfirmationModal }}
        >
          <UpdateConfirmationModal
            onClose={handleCloseConfirmationModal}
            onSuccess={handleSuccessConfirmationModal}
            text="pool"
          />
        </AlertDialogWrapper>

        <div className="flex h-full w-full flex-col overflow-hidden rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="truncate text-xl font-[550] text-gray-700">Edit Termination Date</span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <div className="flex h-full flex-col gap-4 overflow-hidden">
            {pool && (
              <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
                <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
                  <div className="flex w-full justify-between px-4">
                    <span className="text-sm font-[550] text-gray-700">General</span>
                  </div>
                  <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
                    <SummaryItem className="pt-0" title="Pool name" value={pool?.name} />

                    <SummaryItem
                      className="py-3"
                      title="Amount of fully diluted shares"
                      value={pool?.dillutedSharesCount}
                    />
                    <SummaryItem
                      className="py-3"
                      title="Share class"
                      value={pool?.shareClass?.name || '-'}
                    />
                    <SummaryItem
                      className="py-3"
                      title="Date"
                      value={pool?.date ? format(pool?.date, 'dd/MM/yyyy') : '-'}
                    />
                    <SummaryItem
                      className="pb-0"
                      title="Dilution Condition"
                      value={
                        dilutionCondition === DilutionCondition.INCLUDE_CAP_TABLE
                          ? 'Include in Cap Table'
                          : 'Exclude from Cap Table'
                      }
                    />
                  </div>
                </div>
                <div className="flex w-full items-center justify-between rounded-lg border border-gray-100 bg-white px-4">
                  <div className={'flex gap-3 text-nowrap py-2'}>
                    <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
                      Documents:
                    </span>
                    <div className="flex max-w-[220px] flex-col gap-2">
                      {(filesLinks?.length || 0) > 0 ? (
                        filesLinks?.map(({ doc, docLink }) => (
                          <div
                            className="cursor-pointer truncate text-xs font-[450] text-brand-700 underline"
                            key={docLink}
                            onClick={() => handleDocumentClick(docLink)}
                          >
                            {doc?.name}
                          </div>
                        ))
                      ) : (
                        <span className="truncate text-xs font-[450] text-gray-700">-</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex w-full items-center justify-between rounded-lg border border-gray-100 bg-white px-4">
                  <SummaryItem
                    className="py-2"
                    title="Additional notes"
                    value={pool?.additionalNotes || '-'}
                  />
                </div>

                <div className="flex flex-col gap-3 p-3 pt-5">
                  <div className="flex flex-col gap-1">
                    <span className="text-sm font-[550] text-fireside-600">Terminate Pool</span>
                    <div className="flex items-center gap-2">
                      <span className="text-xs font-[450] text-gray-700">
                        Current Termination Date:
                      </span>
                      <span className="text-xs font-[450] text-fireside-600">
                        {pool?.terminationDate ? format(pool.terminationDate, 'dd/MM/yy') : ''}
                      </span>
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
                    <FormDatePicker
                      calendar={{ fromDate: new Date() }}
                      inputValue={terminatingDate ? format(terminatingDate, 'dd/MM/yy') : ''}
                      inputWrapperClassName="cursor-pointer"
                      onSelect={(date) => setTerminatingDate(date)}
                      placeholder="New termination date"
                      value={terminatingDate}
                    />
                    <DropDown
                      answer="Pool termination refers to the process of ending an employee stock option plan, stock purchase plan, or any other equity compensation plan."
                      className="rounded-lg bg-gray-50"
                      question="How does pool termination works?"
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="flex h-9 w-full justify-between gap-3 px-6">
              <Button
                className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
                onClick={handleCloseModal}
                styleType="NONE"
              >
                <AppFormattedMessage id={StringKey.CANCEL} />
              </Button>
              <div className="flex gap-3">
                <Button
                  className="h-full w-fit bg-fireside-600 px-4 py-[6px] text-sm font-[550] text-white"
                  disabled={!terminatingDate}
                  onClick={handleOpenConfirmationModal}
                  type="button"
                >
                  <AppFormattedMessage id={StringKey.UPDATE} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
