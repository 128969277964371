import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type EventValuationProps = IconProps;

export const EventValuation: FC<EventValuationProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2.50008H13.5C14.9002 2.50008 15.6002 2.50008 16.135 2.77257C16.6054 3.01225 16.9879 3.3947 17.2275 3.86511C17.5 4.39988 17.5 5.09995 17.5 6.50008V13.5001C17.5 14.9002 17.5 15.6003 17.2275 16.1351C16.9879 16.6055 16.6054 16.9879 16.135 17.2276C15.6002 17.5001 14.9002 17.5001 13.5 17.5001H6.50002C5.09989 17.5001 4.39982 17.5001 3.86504 17.2276C3.39464 16.9879 3.01219 16.6055 2.7725 16.1351C2.50002 15.6003 2.50002 14.9002 2.50002 13.5001V10.0001M6.66669 10.8334V14.1667M13.3334 9.16675V14.1667M10 5.83341V14.1667M1.66669 4.16675L4.16669 1.66675M4.16669 1.66675L6.66669 4.16675M4.16669 1.66675L4.16669 6.66675"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);
