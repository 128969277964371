import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

type LoaderIconProps = { bgIconColor?: string } & IconProps;

const LoaderIcon: FC<LoaderIconProps> = ({
  iconColor = '#2565C8',
  bgIconColor = '#D1E3FF',
  className,
  ...props
}) => {
  return (
    <svg
      className={twMerge('shrink-0 animate-spin', className)}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4816 21.4944C17.7679 21.9903 17.5996 22.6289 17.0808 22.8713C14.8944 23.8932 12.4422 24.2355 10.0453 23.8397C7.30607 23.3875 4.80844 21.9995 2.97792 19.9121C1.1474 17.8248 0.0972615 15.1674 0.00642493 12.3926C-0.0844117 9.61785 0.789678 6.89742 2.47976 4.69486C4.16984 2.4923 6.57135 0.943899 9.27509 0.313476C11.9788 -0.316947 14.8175 0.00961747 17.3075 1.23753C19.7974 2.46544 21.7846 4.51872 22.9304 7.04752C23.933 9.2604 24.2371 11.7176 23.8159 14.094C23.716 14.6578 23.1427 14.9857 22.5896 14.8375C22.0365 14.6893 21.714 14.1209 21.8037 13.5554C22.1068 11.6449 21.8461 9.67902 21.0415 7.90334C20.0937 5.81154 18.45 4.11308 16.3903 3.09737C14.3306 2.08165 11.9825 1.81152 9.74597 2.333C7.50946 2.85448 5.52295 4.13531 4.12493 5.95725C2.72691 7.77918 2.00387 10.0295 2.07901 12.3248C2.15415 14.6201 3.02282 16.8183 4.53701 18.5449C6.0512 20.2715 8.11722 21.4196 10.383 21.7937C12.3065 22.1113 14.2728 21.8544 16.0398 21.0671C16.5628 20.834 17.1953 20.9985 17.4816 21.4944Z"
        fill={iconColor}
      />
      <path
        d="M22.8565 10.4742C23.4235 10.3945 23.9525 10.7898 23.983 11.3616C24.0482 12.5854 23.9256 13.8141 23.6178 15.0046C23.2232 16.5302 22.532 17.9632 21.5836 19.2218C20.6352 20.4803 19.4483 21.5397 18.0905 22.3396C17.031 22.9636 15.8837 23.4201 14.6893 23.6948C14.1313 23.8231 13.6055 23.4235 13.5258 22.8565C13.4461 22.2894 13.8432 21.7705 14.3988 21.6321C15.3242 21.4016 16.2132 21.0386 17.038 20.5528C18.1611 19.8912 19.143 19.0149 19.9275 17.9738C20.712 16.9327 21.2838 15.7474 21.6101 14.4853C21.8498 13.5586 21.9537 12.604 21.9202 11.651C21.9 11.0787 22.2894 10.5539 22.8565 10.4742Z"
        fill={bgIconColor}
      />
    </svg>
  );
};

export default LoaderIcon;
