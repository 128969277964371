import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PatchCompanyDto } from '../../types/companyTypes';
import { useAppMutation } from '../useAppMutation';

export const useUpdate = () => {
  const { mutate: update, isPending } = useAppMutation([QueryKey.PATCH_COMPANY], {
    mutationFn: ({ companyId, data }: { data: PatchCompanyDto; companyId: string }) => {
      return ApiService.patch(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId] },
        { body: { ...data, currencyCountryIso2Code: data.currencyCountryIso2Code || undefined } },
      );
    },
  });
  return { patch: update, isPending };
};
