export enum InputStatus {
  IDLE_EMPTY = 'idle-empty',
  IDLE_EMPTY_DASHED = 'idle-empty-dashed',
  IDLE_FULL = 'idle-full',
  FOCUSED = 'focused',
  ERROR = 'error',
  SUCCESS = 'success',
  WITHOUT_BORDER = 'without-border',
}

export const inputStatusToWrapperClassNamesMap: Record<InputStatus, string> = {
  [InputStatus.IDLE_EMPTY]: 'border-b-[1px] border-gray-600',
  [InputStatus.IDLE_FULL]: 'border-b-[1px] border-gray-700',
  [InputStatus.FOCUSED]: 'border-b-[2px] border-brand-700',
  [InputStatus.ERROR]: 'border-b-[2px] border-fireside-600',
  [InputStatus.SUCCESS]: 'border-b-[2px] border-forest-500',
  [InputStatus.IDLE_EMPTY_DASHED]: '',
  [InputStatus.WITHOUT_BORDER]: '',
};

export const inputStatusToPlaceholderClassNamesMap: Record<InputStatus, string> = {
  [InputStatus.IDLE_EMPTY]: '!text-gray-400',
  [InputStatus.IDLE_EMPTY_DASHED]: '!text-gray-400',
  [InputStatus.IDLE_FULL]: '!text-gray-500',
  [InputStatus.FOCUSED]: '!text-brand-700',
  [InputStatus.ERROR]: '!text-fireside-600',
  [InputStatus.SUCCESS]: '!text-forest-500',
  [InputStatus.WITHOUT_BORDER]: '!text-gray-500',
};
