import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Pool } from '../../types/pool-plans.types';
import { useAppQuery } from '../useAppQuery';

export const usePool = ({ companyId, poolId }: { companyId: string; poolId: string }) => {
  const { invalidateQueries } = useQueryClient();

  const { data: pool, isLoading } = useAppQuery<Pool>({
    queryKey: [QueryKey.GET_POOL, companyId, poolId],
    queryFn: () =>
      ApiService.get({ endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'pools', poolId] }),
    enabled: !!companyId && !!poolId,
  });

  const invalidateQuery = useCallback(() => {
    invalidateQueries({ queryKey: [QueryKey.GET_POOL] });
  }, [invalidateQueries]);

  return {
    invalidateQuery,
    pool,
    isLoading,
  };
};
