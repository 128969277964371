import { Title } from '@radix-ui/react-dialog';
import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const SheetTitle = forwardRef<
  ElementRef<typeof Title>,
  ComponentPropsWithoutRef<typeof Title>
>(({ className, ...props }, ref) => (
  <Title className={twMerge('text-lg font-[550]', className)} ref={ref} {...props} />
));
SheetTitle.displayName = Title.displayName;
