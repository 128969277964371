import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PoolPlansTab, SharePlanUnionType } from '../../types/pool-plans.types';
import { useAppQuery } from '../useAppQuery';

type SharePlans = {
  companyId: string;
  poolId: string;
  selectedTab: PoolPlansTab;
  take: number;
  currentPage: number;
};

export const useSharePlans = ({
  companyId,
  poolId,
  currentPage,
  selectedTab,
  take,
}: NullableFields<SharePlans, 'currentPage' | 'selectedTab' | 'take'>) => {
  const { invalidateQueries } = useQueryClient();

  const { data: sharePlans, isLoading } = useAppQuery<{
    sharePlans: SharePlanUnionType[];
    totalPages: number;
  }>({
    queryKey: [QueryKey.GET_SHARE_PLANS, companyId, poolId, { selectedTab, currentPage }],
    queryFn: () =>
      ApiService.get(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'pools', poolId, 'share-plans'],
        },
        {
          queryParams: {
            ...(selectedTab
              ? {
                  isTerminated: selectedTab === PoolPlansTab.TERMINATED,
                  take,
                  skip: (currentPage >= 1 ? currentPage - 1 : 0) * take,
                }
              : {}),
          },
        },
      ),
    enabled: !!companyId && !!poolId,
  });

  const invalidateQuery = useCallback(() => {
    invalidateQueries({ queryKey: [QueryKey.GET_SHARE_PLANS] });
  }, [invalidateQueries]);

  return {
    invalidateQuery,
    ...sharePlans,
    sharePlans: sharePlans?.sharePlans || [],
    isLoading,
  };
};
