export enum StringKey {
  NO_GO_BACK = 'no-go-back',
  NO_KEEP_1 = 'no-keep-1',
  NO_KEEP_2 = 'no-keep-2',
  NO_KEEP_3 = 'no-keep-3',
  NO_KEEP_4 = 'no-keep-4',
  NO_KEEP_5 = 'no-keep-5',
  CAN_REACTIVATE = 'can-reactivate',
  SAVE_APPROX = 'save-approx',
  BETWEEN_SYMBOL = 'between-symbol',
  COUNT_STAKEHOLDERS = 'count-stakeholders',
  ACTIVE = 'active',
  ACTIVE_UNTIL = 'active-until',
  ACTIVITY = 'activity',
  ADD_NEW_COMPANY = 'add-new-company',
  ADD_STAKEHOLDER = 'add-stakeholder',
  ADDRESS = 'address',
  ADDRESS_OPTIONAL = 'address-optional',
  INACTIVE = 'inactive',
  ROWS_SELECTED = 'rows-selected',
  ACCESS_AND_PERMISSIONS = 'access-and-permissions',
  ACCESS_REVOKED = 'access-revoked',
  ACCOUNT = 'account',
  ADMIN = 'admin',
  ADVISOR = 'advisor',
  ALL = 'all',
  ALL_ASSOCIATED_STAKEHOLDER_DATA_WILL_BE_LOST = 'all-associated-stakeholder-data-will-be-lost',
  ALL_STAKEHOLDERS = 'all-stakeholders',
  ALREADY_HAVE_ACCOUNT = 'already-have-account',
  AMOUNT_TO_PAY_TODAY = 'amount-to-pay-today',
  ANGEL = 'angel',
  ANGELS = 'angels',
  ANNUAL = 'annual',
  ANNUAL_REVENUE = 'annual-revenue',
  ANOTHER_BENEFIT = 'another-benefit',
  ANY_ADDITIONAL_FEEDBACK = 'any-additional-feedback',
  ARE_YOU_SURE_YOU_WANT_CANCEL = 'are-you-sure-you-want-cancel',
  ARE_YOU_SURE_YOU_WANT_DEACTIVATE = 'are-you-sure-you-want-deactivate',
  LOSE_ACCESS_CAUTION = 'lose-access-caution',
  ARE_YOU_SURE_YOU_WANT_DELETE_ACCOUNT = 'are-you-sure-you-want-delete-account',
  ARE_YOU_SURE_YOU_WANT_GRANT_ACCESS = 'are-you-sure-you-want-grant-access',
  ARE_YOU_SURE_YOU_WANT_MAKE_CONTRIBUTOR = 'are-you-sure-you-want-make-contributor',
  AT_LEAST_ONE_LOWER_CASE_LETTER = 'at-least-one-lower-case-letter',
  AT_LEAST_ONE_SPECIAL_CHAR = 'at-least-one-special-char',
  AT_LEAST_ONE_UPPER_CASE_LETTER = 'at-least-one-upper-case-letter',
  AT_LEAST_EIGHT_CHARS = 'at-least-eight-chars',
  AUTO_RENEW_ON = 'auto-renew-on',
  BACK = 'back',
  BACK_TO_SIGN_IN = 'back-to-sign-in',
  BEFORE_YOU_START_YOUR_JOURNEY_CONFIRMATION = 'before-you-start-your-journey-confirmation',
  BILLED_ANNUALY = 'billed-annualy',
  ANNUALY = 'annualy',
  MAXIMUM_FILE_SIZE = 'maximum-file-size',
  CARD_ENDING_NUMBERS = 'card-ending-numbers',
  LETS_TALK = 'lets-talk',
  LAST_UPDATE = 'last-update',
  INDUSTRY = 'industry',
  BILLED_MONTHLY = 'billed-monthly',
  BILLED_MONTHLY_PLAN = 'billed-monthly-plan',
  PAY_MONTHLY = 'PAY_MONTHLY',
  BIRTHDAY = 'birthday',
  MAXIMUM_PHOTO_SIZE = 'MAXIMUM_PHOTO_SIZE',
  BIRTHDAY_OPTIONAL = 'birthday-optional',
  BULK_MODIFY = 'bulk-modify',
  BULK_MODIFY_STAKEHOLDERS = 'bulk-modify-stakeholders',
  BY_PROCEEDING_YOU_AGREE_TO = 'by-proceeding-you-agree-to',
  TERMS_OF_SERVICE = 'terms-of-service',
  AND = 'and',
  PRIVACY_POLICY = 'privacy-policy',
  CANCEL = 'cancel',
  CANCEL_INVITATION = 'cancel-invitation',
  CANCEL_SUBSCRIPTION = 'cancel-subscription',
  CANNOT_DOWNGRADE = 'cannot-downgrade',
  CAP_TABLE_MANAGEMENT = 'cap-table-management',
  MISSING_FEATURES = 'missing-features',
  TOO_EXPENSIVE = 'too-expensive',
  CARD_NUMBER = 'card-number',
  CARDHOLDER_NAME = 'cardholder-name',
  CHANGE_PASSWORD = 'change-password',
  CHANGE_PLAN = 'change-plan',
  CHANGE_YOUR_PASSWORD = 'change-your-password',
  CHECK_THE_URL = 'check-the-url',
  CHECK_YOUR_EMAIL = 'check-your-email',
  CHECK_YOUR_INBOX = 'check-your-inbox',
  CITY = 'city',
  CLICK_TO_UPLOAD = 'click-to-upload',
  OR_DRAG_AND_DROP = 'or-drag-and-drop',
  CLOSE = 'close',
  COLLAPSE = 'collapse',
  COMPANY_ADDRESS = 'company-address',
  COMPANY_ID_NUMBER_OPTIONAL = 'company-id-number-optional',
  COMPANY_IDENTIFIER = 'company-identifier',
  COMPANY_IDENTIFIER_OPTIONAL = 'company-identifier-optional',
  COMPANY_LOGO_OPTIONAL = 'company-logo-optional',
  COMPANY_NAME = 'company-name',
  COMPANY_REGISTRATION_DETAILS = 'company-registration-details',
  COMPANY_SETTINGS = 'company-settings',
  COMPANY_SETUP = 'company-setup',
  COMPANY_TYPE = 'company-type',
  COMPANY_URL = 'company-url',
  COMPANY_URL_OPTIONAL = 'company-url-optional',
  CONFIRM = 'confirm',
  CONFIRM_PASSWORD = 'confirm-password',
  FILTER_ALL = 'filter-all',
  FILTER_ACTIVE = 'filter-active',
  FILTER_INACTIVE = 'filter-inactive',
  FILTER_IN_USE = 'filter-in-use',
  FILTER_NOT_USED = 'filter-not-used',
  KILOBYTE = 'kilobyte',
  MINUTES_AGO = 'minutes-ago',
  SINGULAR_MIN = 'singular-min',
  PLURAL_MIN = 'plural-min',
  HOURS_AGO = 'hours-ago',
  SINGULAR_HOUR = 'singular-hour',
  PLURAL_HOUR = 'plural-hour',
  DAYS_AGO = 'days-ago',
  SINGULAR_DAY = 'singular-day',
  PLURAL_DAY = 'plural-day',
  MONTHS_AGO = 'months-ago',
  SINGULAR_MONTH = 'singular-month',
  PLURAL_MONTH = 'plural-month',
  LESS_THAN_MIN_AGO = 'less-than-min-ago',
  ZIP_CODE = 'zip-code',
  SWITCH_TO_ANNUAL_PLAN_MESSAGE = 'switch-to-annual-plan-message',
  SWITCH_TO_ANNUAL_PLAN_CHARGE_MESSAGE = 'switch-to-annual-plan-charge-message',
  SWITCH_TO_ANNUAL_PLAN_ACTIVE_MESSAGE = 'switch-to-annual-plan-active-message',
  COMPANY_SETUP_EXIT = 'company-setup-exit',
  PRIVATE_EQUITY = 'private-equity',
  STAKEHOLDER_COMPANY_GROUP = 'stakeholder-company-group',
  STAKEHOLDER_HUMAN_GROUP = 'stakeholder-human-group',
  MONTH = 'month',
  MONTHLY = 'monthly',
  PLAN_SOON_EXPIRE = 'plan-soon-expire',
  STAKEHOLDERS_LIMIT = 'stakeholders-limit',
  // Translations for this keys is missing
  EMPLOYEE = 'employee',
  FOUNDER = 'founder',
  BOARD_MEMBERS = 'board-members',
  VCS = 'vcs',
  INVESTMENT_BANKS = 'investment-banks',
  PES = 'pes',
  FAMILY_OFFICE = 'family-office',
  VENTURE_CAPITAL = 'venture-capital',
  SPV = 'spv',
  COMPANY = 'company',
  OTHER = 'other',
  OTHER_INST = 'other-inst',
  CONTRIBUTOR = 'contributor',
  STAKEHOLDER = 'stakeholder',
  INVITE_USER = 'invite-user',
  INVITED = 'invited',
  ACTIVE_STAKEHOLDERS = 'active-stakeholders',
  INACTIVE_STAKEHOLDERS = 'inactive-stakeholders',
  ALL_TERMINATED = 'all-terminated',
  ACTIVE_TERMINATED = 'active-terminated',
  INACTIVE_TERMINATED = 'inactive-terminated',
  STAKEHOLDERS_TAB = 'stakeholders-tab',
  TERMINATED_TAB = 'terminated-tab',
  CONGRATS_ON_YOUR_GROWTH = 'congrats-on-your-growth',
  CONTINUE = 'continue',
  CONTINUE_TO_PLANS = 'continue-to-plans',
  CONTINUE_TO_USE_CAPQUEST_UNTIL = 'continue-to-use-capquest-until',
  CONTINUE_WITH_GOOGLE = 'continue-with-google',
  COUNTRY_OF_COMPANY = 'country-of-company',
  CREATE_MY_ACCOUNT = 'create-my-account',
  CREATING_YOUR_ACCOUNT = 'creating-your-account',
  CURRENT_PLAN = 'current-plan',
  CURRENTLY_SELECTED = 'currently-selected',
  DASHBOARD = 'dashboard',
  DATE_CREATED = 'date-created',
  DATE_OF_INCORPORATION = 'date-of-incorporation',
  DATE_OF_INCORPORATION_OPTIONAL = 'date-of-incorporation-optional',
  DAY = 'day',
  DEACTIVATE = 'deactivate',
  DEACTIVATE_COMPANY = 'deactivate-company',
  DELETE = 'delete',
  DELETE_ACCOUNT = 'delete-account',
  DELETE_STAKEHOLDER = 'delete-stakeholder',
  DELETE_YOUR_CAPQUEST_ACCOUNT = 'delete-your-capquest-account',
  DEMO = 'demo',
  DETAILS = 'details',
  DONT_HAVE_AN_ACCOUNT_YET = 'dont-have-an-account-yet',
  DONT_WORRY_YOUR_DATA_IS_SAVED = 'dont-worry-your-data-is-saved',
  DOWNGRADE = 'downgrade',
  YOURE_SAVE_APPROX_WITH_ANNUAL = 'save-approx-with-annual',
  UPGRADE = 'upgrade',
  SWITCH_ANNUAL = 'switch-annual',
  SELECT = 'default-plan-text',
  SPACIAL_PLAN_TEXT = 'spacial-plan-text',
  CANNOT_DOWNGRADE_STAKEHOLDERS = 'cannot-downgrade-stakeholders',
  CANCEL_SUBSCRIPTION_PROBLEM_1 = 'cancel-subscription-problem-1',
  EXIT = 'exit',
  CONFIRM_DELETE = 'confirm-delete',
  CONFIRM_CANCEL = 'confirm-cancel',
  CONFIRM_DEACTIVATE = 'confirm-deactivate',
  YEAR = 'year',
  WHERERE_LOCATED = 'wherere-located',
  WELCOME = 'welcome',
  WELCOME_TO_CAPQUEST = 'welcome-to-capquest',
  WELCOME_BACK = 'welcome-back',
  WELCOME_ABOARD = 'welcome-aboard',
  FEW_DETAILS = 'few-details',
  SENDED_RECEIPT_TO_EMAIL = 'sended-receipt-to-email',
  SENDED_LOGIN_VERIFY_LINK = 'sended-login-verify-link',
  WANT_EXIT = 'want-exit',
  VOTING_RIGHTS = 'voting-rights',
  VIEW_MODIFICATION_GUID = 'view-modification-guid',
  VIEW_IMPORT_GUID = 'view-import-guid',
  VIEW_DETAILS = 'view-details',
  DOWNLOAD_STAKEHOLDER_INFO_PART1 = 'download-stakeholder-info-part1',
  DOWNLOAD_STAKEHOLDER_INFO_PART2 = 'download-stakeholder-info-part2',
  DOWNLOAD_STAKEHOLDER_INFO_PART3 = 'download-stakeholder-info-part3',
  DOWNLOAD_TEMPLATE = 'download-template',
  EDIT_DETAILS = 'edit-details',
  EDIT_STAKEHOLDER = 'edit-stakeholder',
  EFFICIENTLY_HANDLE_MANAGEMENT = 'efficiently-handle-management',
  EMAIL = 'email',
  EMAIL_ADDRESS = 'email-address',
  ENTER_A_NEW_PASSWORD_BELOW = 'enter-a-new-password-below',
  ENTER_YOUR_EMAIL_ADDRESS_AND_WE_WILL = 'enter-your-email-address-and-we-will',
  ENTERPRISE_AND_FI = 'enterprise-and-fi',
  EQUITY_AND_CAPITAL_CHANGE = 'equity-and-capital-change',
  EVENTS = 'events',
  EVERYTHING_FROM_STARTER_PLAN = 'everything-from-starter-plan',
  EXPIRY = 'expiry',
  EXPORT = 'export',
  FILE_FORMAT_NOT_SUPPORTED = 'file-format-not-supported',
  FILE_PROCESSED_SUCCESSFULLY = 'file-processed-successfully',
  FOR_CUSTOM_PRICING = 'for-custom-pricing',
  FORGOT_PASSWORD = 'forgot-password',
  FULL_NAME = 'full-name',
  GENERATING_INVITATION_AGAIN_WILL_VOID = 'generating-invitation-again-will-void',
  GRANT_ACCESS = 'grant-access',
  HELP_ME_SETUP = 'help-me-setup',
  HELP_US_MAKE_CAPQUEST_BETTTER = 'help-us-make-capquest-bettter',
  HERE_ARE_A_FEW_THING_TO_TRY = 'here-are-a-few-thing-to-try',
  I_FOUND_BETTER_ALTERNATIVE = 'i-found-better-alternative',
  I_HAVE_SEEN_MY_INFO = 'i-have-seen-my-info',
  I_NO_LONGER_NEED_IT = 'i-no-longer-need-it',
  IF_YOU_CANCEL = 'if-you-cancel',
  IF_YOU_RECEIVED_LINK_IN_EMAIL = 'if-you-received-link-in-email',
  IMPORT = 'import',
  IMPORT_STAKEHOLDERS = 'import-stakeholders',
  IN_THE_MEANTIME_FEEL_FREE_TO = 'in-the-meantime-feel-free-to',
  INACTIVE_COMPANY = 'inactive-company',
  INCOMPLETE_SETUP = 'incomplete-setup',
  LAST_ACTIVE = 'last-active',
  LET_US_HELP_TO_SETUP = 'let-us-help-to-setup',
  LET_US_KNOW_ABOUT_YOUR_COMPANY_DETAILS = 'let-us-know-about-your-company-details',
  LOCATION = 'location',
  LOG_OUT = 'log-out',
  LOG_IN = 'log-in',
  LOSE_SPECIAL_OFFER_PRICE = 'lose-special-offer-price',
  MAKE_CONTRIBUTOR = 'make-contributor',
  MY_BUSINESS_IS_NO_LONGER_ACTIVE = 'my-business-is-no-longer-active',
  VIEW_MY_COMPANIES = 'view-my-companies',
  MY_COMPANIES = 'my-companies-title',
  MY_COMPANY = 'my-company',
  MY_HOLDING_EQUITY = 'my-holding-equity',
  MY_HOLDINGS = 'my-holdings',
  MY_PROFILE = 'my-profile',
  NAME = 'name',
  NATIONALITY = 'nationality',
  NATIONALITY_OPTIONAL = 'nationality-optional',
  NEW_PASSWORD = 'new-password',
  NEXT = 'next',
  VAT_GST = 'vat-gst',
  USER_ROLE = 'user-role',
  MISC_ADVISE_3 = 'misc-advise-3',
  UPLOAD_AGAIN = 'upload-again',
  UPGRADE_PLAN_NOW = 'upgrade-plan-now',
  UPDATE = 'update',
  UNABLE_DELETE = 'unable-delete',
  TYPE = 'type',
  TERMINATION = 'termination',
  TERMINATED = 'terminated',
  TERMINATE = 'terminate',
  SWITCH_TO_ANNUAL_BILLING = 'switch-to-annual-billing',
  SWITCH_COMPANY = 'switch-company',
  SUPPORTED_FORMATS = 'supported-formats',
  SUBSCRIPTION_CYCLE = 'subscription-cycle',
  SUBSCRIPTION_CANCELED = 'subscription-canceled',
  SUBMIT = 'submit',
  STATUS = 'status',
  STATE = 'state',
  STAKEHOLDERS = 'stakeholders',
  STAKEHOLDER_UPDATE_TOAST = 'stakeholder-update',
  STAKEHOLDER_TYPE = 'stakeholder-type',
  STAKEHOLDER_DELETED_TOAST = 'stakeholder-deleted-toast',
  STAKEHOLDER_CREATED_TOAST = 'stakeholder-created-toast',
  SOMETHING_ELSE = 'something-else',
  SKYROCKETING = 'skyrocketing',
  SKIP = 'skip',
  SIGN_UP = 'sign-up',
  SIGN_UP_TO_CAPQUEST = 'sign-up-to-capquest',
  SIGN_IN = 'sign-in',
  SIGN_IN_TO_CAPQEUST = 'sign-in-to-capqeust',
  SHARE_CLASSES = 'SHARE_CLASSES',
  POOL_PLANS_POOL = 'POOL_PLANS_POOL',
  POOL_PLANS_PLAN = 'POOL_PLANS_PLAN',
  POOL_PLANS = 'POOL_PLANS',
  CAP_TABLE = 'CAP_TABLE',
  SETTINGS = 'SETTINGS',
  ACCEPT_INVITATION = 'ACCEPT_INVITATION',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  REGISTER_VERIFY = 'REGISTER_VERIFY',
  PASSWORD_RECOVERY = 'PASSWORD_RECOVERY',
  BILLING = 'BILLING',
  ERROR = 'ERROR',
  SEND_EMAIL = 'send-email',
  SEARCH = 'search',
  SAVED_APPROX_V2 = 'save-approx-v2',
  SAVE_APPROX_W_ANNUAL_BILLING = 'save-approx-w_annual-billing',
  SAVE = 'save',
  REVOKE_CONTRIBUTOR_ROLE = 'revoke-contributor-role',
  REVOKE_ACCESS = 'revoke-access',
  RESUME_COMPANY_SETUP = 'resume-company-setup',
  RESUME = 'RESUME',
  RESIGNATION = 'RESIGNATION',
  RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL',
  RESEND_INVITATION = 'RESEND_INVITATION',
  RESEND_EMAIL = 'RESEND_EMAIL',
  NO = 'no',
  NO_OF_EMPLOYEES = 'no-of-employees',
  NO_RESULTS_MATCHS = 'no-results-matchs',
  NO_REV = 'no-rev',
  NO_THANKS = 'no-thanks',
  NOTE_THAT_YOUR_INFORMATION_STILL_SAVED = 'note-that-your-information-still-saved',
  OOPS = 'oops',
  OR = 'or',
  PASSPORT_EXPIRY_DATE = 'passport-expiry-date',
  PASSPORT_EXPIRY_DATE_OPTIONAL = 'passport-expiry-date-optional',
  PASSPORT_NO = 'passport-no',
  PASSPORT_NO_OPTIONAL = 'passport-no-optional',
  PASSWORD = 'password',
  PASSWORD_CHANGED = 'password-changed',
  PAY_NOW = 'pay-now',
  PAY_YEARLY = 'pay-yearly',
  PAYMENT_METHOD = 'payment-method',
  PER_MONTH = 'per-month',
  PERSONAL_INFO = 'personal-info',
  PHONE_NUMBER = 'phone-number',
  PHONE_NUMBER_OPTIONAL = 'phone-number-optional',
  PLAN_BILLING = 'plan-billing',
  PLAN_FOR_ALL_SIZES = 'plan-for-all-sizes',
  PLAN_SELECTED = 'plan-selected',
  PLEASE_CHECK_THE_EMAIL = 'please-check-the-email',
  PLEASE_TRY_AGAIN = 'please-try-again',
  POOLS_PLANS = 'pools-plans',
  PREVIOUS = 'previos',
  REACTIVATE = 'reactivate',
  REACTIVATE_SUBSCRIPTION = 'reactivate-subscription',
  REENTER_NEW_PASSWORD = 'reenter-new-password',
  REGISTRATION_NUMBER_OPTIONAL = 'registration-number-optional',
  REPORTING_CURRENCY = 'reporting-currency',
  YES = 'yes',
  YES_CANCEL = 'YES_CANCEL',
  YES_DEACTIVATE = 'YES_DEACTIVATE',
  YES_DELETE = 'YES_DELETE',
  YES_EXIT = 'YES_EXIT',
  YES_GRANT = 'YES_GRANT',
  YES_RESEND = 'YES_RESEND',
  YES_REVOKE = 'YES_REVOKE',
  YES_UPDATE = 'YES_UPDATE',
  YES_TERMINATE = 'YES_TERMINATE',
  UPDATE_STAKEHOLDER = 'UPDATE_STAKEHOLDER',
  UPDATE_TERMINATION = 'UPDATE_TERMINATION',
  SIGN_UP_MESSAGE = 'SIGN_UP_MESSAGE',
  COMPANY_ID_EXPLANATION = 'COMPANY_ID_EXPLANATION',
  UPDATE_INFORMATION_MESSAGE = 'UPDATE_INFORMATION_MESSAGE',
  INCORRECT_LINK_PT_1 = 'INCORRECT_LINK_PT_1',
  INCORRECT_LINK_PT_2 = 'INCORRECT_LINK_PT_2',
  NOT_ABLE_TO_USE_STAKEHOLDER = 'NOT_ABLE_TO_USE_STAKEHOLDER',
  STAKEHOLDER_CANCEL_INVITE_DESCRIPTION = 'STAKEHOLDER_CANCEL_INVITE_DESCRIPTION',
  GREETING_COMPANY_SETUP = 'GREETING_COMPANY_SETUP',
  PASSWORD_RECOMMENDATION = 'PASSWORD_RECOMMENDATION',
  PASSWORD_SUCCESSFULLY_CHANGED = 'PASSWORD_SUCCESSFULLY_CHANGED',
  TERMINATE_STAKEHOLDER = 'terminate-stakeholder',
  STAKEHOLDER_SUCCESSFULLY_CREATED = 'stakeholder-successfully-created',
  SAVE_APPROX_V2 = 'SAVE_APPROX_V2',
  FRENCH = 'french',
  ENGLISH = 'english',
}
