import { toast } from 'react-toastify';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PageRoute } from '../../types/pageTypes';
import { useAppMutation } from '../useAppMutation';
import { useNavigate } from '../useNavigate';

export const useReactivate = () => {
  const navigate = useNavigate(PageRoute.BILLING);
  const { mutate: reactivate, isPending } = useAppMutation([QueryKey.REACTIVATE_COMPANY], {
    defaultErrorHandling: false,
    onError: (error, { companyId }) => {
      const { status } = error.response;
      if (status !== 402) return toast.error(error.body?.message);

      navigate([companyId || '']);
    },
    mutationFn: ({ companyId }: { companyId: string }) =>
      ApiService.patch({ endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'reactivate'] }),
  });

  return { reactivate, isPending };
};
