import { FC } from 'react';

import { AddDocumentIcon } from '../../../../assets/icons';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../components/HoverCard';
import { useFormat } from '../../../../hooks';
import { eventFormType, EventTypeBackend } from '../../../../types/events.types';
import { sharePlanTypeTitle } from '../../../../types/pool-plans.types';
import { getS3FileOriginalName } from '../../../../utils/getS3FileOriginalName';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const NewPlan: FC<CardProps> = ({ event, openFileUploadModal }) => {
  const { format } = useFormat();
  if (event.type !== EventTypeBackend.NEW_PLAN) return <></>;
  const { type, pool, createdAt, filesLinks, sharePlan } = event;
  return (
    <div className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm">
      <TransactionTitleItem createdAt={format(createdAt, 'dd/MM/yyyy')} name={sharePlan?.name} />
      <TransactionOverviewItem title={'Transaction Type'} value={eventFormType[type]} />
      <TransactionOverviewItem title={'Equity Pool'} value={pool?.name} />
      <TransactionOverviewItem
        title={'Equity Plan Type'}
        value={sharePlanTypeTitle?.[sharePlan?.type]}
      />

      {filesLinks.length > 0 && (
        <TransactionOverviewItem
          title="Documents"
          value={
            <HoverCard>
              <HoverCardTrigger onClick={() => openFileUploadModal(filesLinks)}>
                <AddDocumentIcon className="cursor-pointer" />
              </HoverCardTrigger>

              <HoverCardContent className="max-w-[360px] rounded-lg bg-[#101828] px-3 py-2">
                <span className="block w-full truncate p-0 text-label-md font-[550] text-white">
                  {filesLinks.length === 1
                    ? getS3FileOriginalName(filesLinks[0])
                    : `Download ${filesLinks.length} files`}
                </span>
              </HoverCardContent>
            </HoverCard>
          }
        />
      )}
    </div>
  );
};
