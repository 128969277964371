import { MutateOptions } from '@tanstack/react-query';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';

import { AlertDialogWrapper } from '../../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import { CloseModalButton } from '../../../../components/CloseModalButton';
import { Sheet, SheetContent } from '../../../../components/Sheet';
import { HttpException } from '../../../../exceptions';
import { Stakeholder, useLocale, useModalState } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { CompanyStakeholderDto, HumanStakeholderDto } from '../../../../types/stakeholderTypes';
import UpdateStakeholderModal from '../../modals/UpdateStakeholderModal';
import EditStakeholderForm from './EditStakeholderForm';

export type EditStakeholderModalProps = {
  isOpenModal: boolean;
  setClose: () => void;
  companyId: string;
  stakeholderId: string;
  invalidateQuery: () => void;
  isDefaultEditing: boolean;
};

const EditStakeholderModal: FC<EditStakeholderModalProps> = ({
  isOpenModal,
  setClose,
  companyId,
  stakeholderId,
  invalidateQuery,
  isDefaultEditing,
}) => {
  const [isEditing, setEditing] = useState(false);
  const { stakeholder, isLoading } = Stakeholder.useStakeholder({ companyId, stakeholderId });
  const { update } = Stakeholder.useUpdate();
  const { messagesLocale } = useLocale();
  const handleCloseModal = () => {
    setEditing(false);
    setClose();
  };

  const [context, setContext] = useState<{
    variables: CompanyStakeholderDto | HumanStakeholderDto;
    options:
      | MutateOptions<unknown, HttpException, CompanyStakeholderDto | HumanStakeholderDto, unknown>
      | undefined;
  }>();

  const { isOpen, handleOpenModal, handleSuccessModal, handleDismissModal, toggler } =
    useModalState({
      onSuccess: () => {
        if (!context) return;
        const { options, variables } = context;

        update(
          { companyId, stakeholderId, data: variables },
          {
            onSuccess: (data, variables, context) => {
              toast.success(messagesLocale[StringKey.STAKEHOLDER_UPDATE_TOAST]);
              options?.onSuccess?.(data, variables.data, context);
              invalidateQuery();
              handleCloseModal();
            },
          },
        );
      },
    });
  if (stakeholderId && (isLoading || !stakeholder)) return <></>;

  return (
    <Sheet open={isOpenModal}>
      <SheetContent
        className="w-full max-w-[485px] border-transparent bg-transparent p-2 shadow-none"
        onInteractOutside={handleCloseModal}
        side="RIGHT"
      >
        <AlertDialogWrapper control={{ open: isOpen, onOpenChange: toggler }}>
          <UpdateStakeholderModal onDismiss={handleDismissModal} onSuccess={handleSuccessModal} />
        </AlertDialogWrapper>

        <div className="flex h-full w-full flex-col overflow-hidden overflow-y-auto rounded-lg border-[1px] border-gray-300 bg-gray-100 pb-4">
          <div className="flex h-fit w-full items-center justify-between bg-white px-6 py-3">
            <span className="text-xl font-[550] text-gray-700">
              {isDefaultEditing || isEditing ? (
                <AppFormattedMessage id={StringKey.EDIT_STAKEHOLDER} />
              ) : (
                'View Stakeholder'
              )}
            </span>
            <CloseModalButton onClose={handleCloseModal} />
          </div>
          <EditStakeholderForm
            companyId={companyId}
            defaultData={stakeholder}
            invalidateQuery={invalidateQuery}
            isEditing={isDefaultEditing || isEditing}
            mutateStakeholder={(variables, options) => {
              setContext({ variables, options });
              handleOpenModal();
            }}
            setCloseModal={handleCloseModal}
            setEditing={setEditing}
            stakeholderId={stakeholderId || ''}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default EditStakeholderModal;
