import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type NotificationIconProps = IconProps;

const NotificationIcon: FC<NotificationIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="17"
    viewBox="0 0 18 17"
    width="18"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.25581 1.49719C5.65349 1.83031 2.833 4.86084 2.833 8.55013C2.833 9.34179 2.96287 10.1031 3.20248 10.814C3.29264 11.0815 3.33772 11.2152 3.34586 11.318C3.35389 11.4195 3.34782 11.4906 3.32271 11.5893C3.29729 11.6891 3.24116 11.793 3.12891 12.0008L1.76586 14.5238C1.57144 14.8836 1.47422 15.0636 1.49598 15.2024C1.51493 15.3234 1.58612 15.4299 1.69065 15.4937C1.81065 15.5669 2.01408 15.5458 2.42095 15.5038L6.68847 15.0626C6.81765 15.0493 6.88233 15.0426 6.94122 15.0449C6.99915 15.0471 7.04004 15.0525 7.09653 15.0655C7.15396 15.0788 7.22618 15.1066 7.37063 15.1622C8.16067 15.4666 9.01902 15.6335 9.91633 15.6335C13.6087 15.6335 16.6412 12.8083 16.9701 9.20174M16.2674 2.19903C17.2437 3.17534 17.2437 4.75825 16.2674 5.73456C15.2911 6.71087 13.7082 6.71087 12.7319 5.73456C11.7556 4.75825 11.7556 3.17534 12.7319 2.19903C13.7082 1.22272 15.2911 1.22272 16.2674 2.19903Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

NotificationIcon.displayName = 'NotificationIcon';

export default NotificationIcon;
