import React, { FC, useCallback, useMemo } from 'react';

import { HelpIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { FormInput } from '../../../../components/Input';
import { Company, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ChildrenFormProps } from '../CompanySetupForm';
import { FormSchema, formSchema } from './Validation';

const CompanySetupStepThree: FC<ChildrenFormProps> = ({
  formData,
  setFormData,
  prevFormStep,
  nextFormStep,
  companyId,
}) => {
  const { stepThree } = useMemo(() => formData(), [formData]);
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useReactForm({
    schema: formSchema,
    defaultValues: stepThree,
  });
  const { patch, isPending } = Company.useUpdate();

  const submitHandler = useCallback(
    async (data: FormSchema) => {
      patch(
        {
          companyId,
          data,
        },

        {
          onSuccess: () => {
            setFormData('stepThree', data);
            nextFormStep();
          },
        },
      );
    },
    [companyId, nextFormStep, patch, setFormData],
  );

  return (
    <form
      className="flex w-full min-w-[330px] max-w-[400px] flex-col gap-8 lg:pb-4"
      onSubmit={handleSubmit(submitHandler)}
    >
      <div className="flex flex-col gap-2 text-center">
        <span
          className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
          style={{
            background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
          }}
        >
          <AppFormattedMessage id={StringKey.ADD_NEW_COMPANY} />
        </span>
        <span className="text-sm font-normal text-gray-500">
          <AppFormattedMessage id={StringKey.COMPANY_REGISTRATION_DETAILS} />
        </span>
      </div>
      <FormInput
        control={control}
        name="companyId"
        placeholder={<AppFormattedMessage id={StringKey.COMPANY_ID_NUMBER_OPTIONAL} />}
      />
      <div className="flex flex-col gap-3 rounded bg-brand-25 p-4 text-xs font-normal text-gray-700">
        <HelpIcon />
        <span>
          <AppFormattedMessage id={StringKey.COMPANY_ID_EXPLANATION} />
        </span>
      </div>
      <div className="flex gap-4">
        <Button
          className="border-[1px] border-gray-300 text-sm text-gray-700 hover:bg-gray-50 max-lg:hidden"
          onClick={prevFormStep}
          styleType="DEFAULT_ROUNDED"
        >
          <AppFormattedMessage id={StringKey.BACK} />
        </Button>
        <Button disabled={!isValid} isLoading={isPending} type="submit">
          <AppFormattedMessage id={StringKey.CONTINUE_TO_PLANS} />
        </Button>
      </div>
    </form>
  );
};

export default CompanySetupStepThree;
