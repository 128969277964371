import { UseMutateFunction } from '@tanstack/react-query';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { Checkbox } from '../../../../components/Checkbox';
import { FormInput, Input } from '../../../../components/Input';
import { HttpException } from '../../../../exceptions';
import { useFormat, useReactForm } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import {
  b2bStakeholderTypes,
  b2cStakeholderTypes,
  CompanyStakeholderDto,
  HumanStakeholderDto,
  Stakeholder,
} from '../../../../types/stakeholderTypes';
import { DeepPartial } from '../../../../utils/deepPartial';
import { transformDatetoISOString } from '../../Stakeholders';
import BirthdayDateSelect from '../DateCombobox/BirthdayDateSelect';
import IncorporationCompanyDateSelect from '../DateCombobox/IncorporationCompanyDateSelect';
import PassportExpiryDateSelect from '../DateCombobox/PassportExpiryDateSelect';
import IncorporationCountryCombobox from '../IncorporationCountryCombobox';
import NationalityCombobox from '../NationalityCombobox';
import PhoneNumberCombobox from '../PhoneNumberCombobox';
import StakeholderTypeCombobox from '../StakeholderTypeCombobox';
import { FormSchema, formSchema } from '../Validation';

export type EditStakeholderFormProps = {
  isEditing: boolean;
  setEditing: (state: boolean) => void;
  companyId: string;
  stakeholderId: string;
  defaultData: Stakeholder | undefined;
  invalidateQuery: () => void;
  setCloseModal: () => void;
  mutateStakeholder: UseMutateFunction<
    unknown,
    HttpException,
    CompanyStakeholderDto | HumanStakeholderDto
  >;
};

const EditStakeholderForm: FC<EditStakeholderFormProps> = ({
  isEditing,
  setEditing,
  mutateStakeholder,
  defaultData,
  setCloseModal,
}) => {
  const [isOpenEquityOptions, setOpenEquityOptions] = useState(true);

  const defaultFormData: DeepPartial<FormSchema> = useMemo(
    () => ({
      email: defaultData?.email,
      fullName: defaultData?.fullName,
      address: defaultData?.address || undefined,
      phoneNumber: defaultData?.phone || undefined,
      phoneCountry: {
        iso2Code: defaultData?.phoneCountryIso2 || '',
        name: '',
        prefix: '',
      },
      access: {
        capTable: defaultData?.capTableAccess,
        position: defaultData?.positionAccess,
        equity: defaultData?.capitalChangeAccess,
        companyTable: defaultData?.companyTableAccess,
      },
      stakeholderType: defaultData?.type,
      human: {
        birthdayDate: defaultData?.birthday
          ? {
              day: new Date(defaultData?.birthday).getDate(),
              month: new Date(defaultData?.birthday).getMonth(),
              year: new Date(defaultData?.birthday).getFullYear(),
            }
          : undefined,
        nationality: defaultData?.nationalityCountry
          ? {
              iso2Code: defaultData?.nationalityCountry.iso2,
              name: defaultData?.nationalityCountry.name,
            }
          : undefined,
        passportExpiryDate: defaultData?.passportExpDate
          ? {
              day: new Date(defaultData?.passportExpDate).getDate(),
              month: new Date(defaultData?.passportExpDate).getMonth(),
              year: new Date(defaultData?.passportExpDate).getFullYear(),
            }
          : undefined,
        passportNo: defaultData?.passportNo || undefined,
      },
      company: {
        identifier: defaultData?.stakeholderCompanyId || undefined,
        incorporationCountry: defaultData?.countryOfInc
          ? {
              iso2Code: defaultData.countryOfInc.iso2,
              name: defaultData?.countryOfInc.name,
            }
          : undefined,
        incorporationDate: defaultData?.incDate
          ? {
              day: new Date(defaultData?.incDate).getDate(),
              month: new Date(defaultData?.incDate).getMonth(),
              year: new Date(defaultData?.incDate).getFullYear(),
            }
          : undefined,
      },
    }),
    [defaultData],
  );

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    resetField,
    formState: { defaultValues, isValid },
  } = useReactForm({
    schema: formSchema,
    disabled: !isEditing,
    defaultValues: defaultFormData,
  });
  const { format } = useFormat();
  const submitHandler = useCallback(
    (data: FormSchema) => {
      const { address, email, fullName, stakeholderType, phoneNumber, access, phoneCountry } = data;
      if (!data.human || !data.company) return;
      const { birthdayDate, nationality, passportExpiryDate, passportNo } = data.human;

      const { identifier, incorporationCountry, incorporationDate } = data.company;

      mutateStakeholder(
        {
          countryOfInc: incorporationCountry?.iso2Code || undefined,
          stakeholderCompanyId: identifier,
          address: address,
          incDate: incorporationDate
            ? transformDatetoISOString(
                incorporationDate.year,
                incorporationDate.month + 1,
                incorporationDate.day,
              )
            : undefined,
          birthday: birthdayDate
            ? transformDatetoISOString(birthdayDate.year, birthdayDate.month + 1, birthdayDate.day)
            : undefined,
          email,
          fullName,
          nationality: nationality?.iso2Code || undefined,
          passportExpDate: passportExpiryDate
            ? transformDatetoISOString(
                passportExpiryDate.year,
                passportExpiryDate.month + 1,
                passportExpiryDate.day,
              )
            : undefined,
          passportNo: passportNo.toString(),
          type: stakeholderType,
          phone: phoneCountry?.prefix ? phoneCountry?.prefix + phoneNumber : undefined,
          phoneCountryIso2: phoneCountry?.iso2Code || undefined,
          capTableAccess: access?.capTable,
          positionAccess: access?.position,
          capitalChangeAccess: access?.equity,
          companyTableAccess: access?.companyTable,
        },
        {
          onSuccess: () => setCloseModal(),
        },
      );

      return;
    },
    [mutateStakeholder, setCloseModal],
  );

  const selectedNationality = watch('human.nationality');

  const selectedPhoneNumber = watch('phoneNumber');

  const selectedPhoneCountry = watch('phoneCountry');

  const selectedStakeholderType = watch('stakeholderType');

  const selectedIncorporationCountry = watch('company.incorporationCountry');

  const isB2BStakeholderType = useMemo(
    () => b2bStakeholderTypes.includes(selectedStakeholderType),
    [selectedStakeholderType],
  );

  const isB2CStakeholderType = useMemo(
    () => b2cStakeholderTypes.includes(selectedStakeholderType),
    [selectedStakeholderType],
  );

  const formattedCompanyIncorporationDate = useMemo(
    () => defaultData?.incDate && format(new Date(defaultData?.incDate), 'dd MMMM yyyy'),
    [defaultData?.incDate, format],
  );

  const formattedBirthdayDate = useMemo(
    () => defaultData?.birthday && format(new Date(defaultData?.birthday), 'dd MMMM yyyy'),
    [defaultData?.birthday, format],
  );
  const formattedPassportExpDate = useMemo(
    () =>
      defaultData?.passportExpDate &&
      format(new Date(defaultData?.passportExpDate), 'dd MMMM yyyy'),
    [defaultData?.passportExpDate, format],
  );

  return (
    <form className="flex h-full flex-col gap-4 overflow-hidden">
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <FormInput
          control={control}
          name="fullName"
          placeholder={<AppFormattedMessage id={StringKey.FULL_NAME} />}
        />
        <FormInput
          control={control}
          name="email"
          placeholder={<AppFormattedMessage id={StringKey.EMAIL} />}
        />
        <StakeholderTypeCombobox
          control={control}
          name="stakeholderType"
          onSelect={() => {
            resetField('company');
            resetField('human');
            resetField('phoneNumber');
          }}
        />

        {isB2BStakeholderType && (
          <>
            {(defaultData?.passportNo || isEditing) && (
              <FormInput
                control={control}
                name="human.passportNo"
                placeholder={<AppFormattedMessage id={StringKey.PASSPORT_NO_OPTIONAL} />}
              />
            )}

            {isEditing ? (
              <PassportExpiryDateSelect control={control} isNeedDefaultValue setValue={setValue} />
            ) : (
              defaultData?.passportExpDate && (
                <Input
                  disabled
                  onChange={() => ''}
                  placeholder={<AppFormattedMessage id={StringKey.PASSPORT_NO_OPTIONAL} />}
                  value={formattedPassportExpDate || ''}
                  wrapperClassName="w-full"
                />
              )
            )}
            {(defaultData?.nationalityCountry?.iso2 || isEditing) && (
              <>
                <NationalityCombobox control={control} selectedNationality={selectedNationality} />
              </>
            )}

            {isEditing ? (
              <BirthdayDateSelect control={control} isNeedDefaultValue setValue={setValue} />
            ) : (
              defaultData?.birthday && (
                <>
                  <Input
                    disabled
                    onChange={() => ''}
                    placeholder={<AppFormattedMessage id={StringKey.BIRTHDAY_OPTIONAL} />}
                    value={formattedBirthdayDate || ''}
                    wrapperClassName="w-full"
                  />
                </>
              )
            )}
          </>
        )}
        {isB2CStakeholderType && (
          <>
            {(defaultData?.countryOfInc || isEditing) && (
              <IncorporationCountryCombobox
                control={control}
                selectedIncorporationCountry={selectedIncorporationCountry}
              />
            )}
            {(defaultData?.stakeholderCompanyId || isEditing) && (
              <FormInput
                control={control}
                name="company.identifier"
                placeholder={<AppFormattedMessage id={StringKey.COMPANY_IDENTIFIER_OPTIONAL} />}
              />
            )}

            {isEditing ? (
              <IncorporationCompanyDateSelect
                control={control}
                isNeedDefaultValue
                setValue={setValue}
              />
            ) : (
              defaultData?.incDate && (
                <Input
                  disabled
                  onChange={() => ''}
                  placeholder={
                    <AppFormattedMessage id={StringKey.DATE_OF_INCORPORATION_OPTIONAL} />
                  }
                  value={formattedCompanyIncorporationDate || ''}
                  wrapperClassName="w-full"
                />
              )
            )}
          </>
        )}

        <>
          {(defaultValues?.phoneNumber || isEditing) && (
            <PhoneNumberCombobox
              disabled={!isEditing}
              onPhoneChange={(phoneNumber) =>
                setValue('phoneNumber', phoneNumber, {
                  shouldValidate: true,
                })
              }
              onSelectCountry={(country) => setValue('phoneCountry', country)}
              selectedPhoneNumber={{
                number: selectedPhoneNumber || '',
                country: selectedPhoneCountry,
              }}
            />
          )}
          {(defaultValues?.address || isEditing) && (
            <FormInput
              control={control}
              name="address"
              placeholder={
                <>
                  <AppFormattedMessage id={StringKey.ADDRESS_OPTIONAL} />
                </>
              }
            />
          )}

          <div className="flex h-fit w-full flex-col gap-3 rounded bg-gray-50 p-3 pl-2">
            <span className="text-base font-[550] text-gray-700">Access & Permissions</span>
            <div className="flex flex-col gap-3">
              <div className="flex items-center gap-1">
                <Checkbox
                  checked={watch('access.capTable')}
                  disabled={!isEditing}
                  onChange={(e) => setValue('access.capTable', e)}
                />
                <span className="text-sm font-[450] text-gray-700">Cap Table</span>
              </div>
              <Button
                className="flex h-fit w-fit items-center gap-1"
                onClick={() => setOpenEquityOptions((prev) => !prev)}
                styleType="NONE"
                type="button"
              >
                <Checkbox checked={isOpenEquityOptions} disabled />
                <span className="text-sm font-[450] text-gray-700">
                  <AppFormattedMessage id={StringKey.MY_HOLDING_EQUITY} />
                </span>
                <ChevronDownIcon
                  className={twMerge('size-4 transition-all', isOpenEquityOptions && 'rotate-180')}
                />
              </Button>
              <div
                className={twMerge(
                  'ml-[30px] flex h-0 flex-col gap-2 overflow-hidden transition-all duration-500',
                  isOpenEquityOptions && 'h-fit',
                )}
              >
                <div className="flex items-center gap-1">
                  <Checkbox
                    checked={watch('access.position')}
                    disabled={!isEditing}
                    onChange={(e) => setValue('access.position', e)}
                  />
                  <span className="text-sm font-[450] text-gray-700">
                    Your position (Issued) vs Cap Table
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <Checkbox
                    checked={watch('access.equity')}
                    disabled={!isEditing}
                    onChange={(e) => setValue('access.equity', e)}
                  />
                  <span className="text-sm font-[450] text-gray-700">
                    <AppFormattedMessage id={StringKey.EQUITY_AND_CAPITAL_CHANGE} />
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <Checkbox
                    checked={watch('access.companyTable')}
                    disabled={!isEditing}
                    onChange={(e) => setValue('access.companyTable', e)}
                  />
                  <span className="text-sm font-[450] text-gray-700">
                    Company Table (of{' '}
                    <i>
                      <AppFormattedMessage id={StringKey.EQUITY_AND_CAPITAL_CHANGE} />
                    </i>
                    )
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>

      <div className="flex h-9 w-full justify-end gap-3 px-6">
        <Button
          className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
          onClick={() => {
            setCloseModal();
          }}
          styleType="NONE"
          type="button"
        >
          {isEditing ? (
            <AppFormattedMessage id={StringKey.CANCEL} />
          ) : (
            <AppFormattedMessage id={StringKey.CLOSE} />
          )}
        </Button>
        <Button
          className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
          disabled={isEditing && !isValid}
          onClick={() => {
            isEditing ? handleSubmit(submitHandler)() : setEditing(true);
          }}
          type={'button'}
        >
          {isEditing ? (
            <AppFormattedMessage id={StringKey.UPDATE} />
          ) : (
            <AppFormattedMessage id={StringKey.EDIT_STAKEHOLDER} />
          )}
        </Button>
      </div>
    </form>
  );
};

export default EditStakeholderForm;
