import { z } from 'zod';

import { StakeholderType } from '../../../types/stakeholderTypes';

const phoneRegex = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/);

const humanSchema = z
  .object({
    nationality: z
      .object({
        name: z.string().min(1, { message: 'Required' }),
        iso2Code: z.string(),
      })
      .optional(),
    passportNo: z.string().or(z.literal('')).default(''),
    passportExpiryDate: z
      .object({
        day: z.coerce.number(),
        month: z.coerce.number(),
        year: z.coerce.number(),
      })
      .optional(),
    birthdayDate: z
      .object({
        day: z.coerce.number(),
        month: z.coerce.number(),
        year: z.coerce.number(),
      })
      .optional(),
  })
  .optional();

const companySchema = z
  .object({
    incorporationCountry: z
      .object({
        name: z.string().min(1, { message: 'Required' }),
        iso2Code: z.string(),
      })
      .optional(),
    identifier: z.string().or(z.literal('')).default(''),
    incorporationDate: z
      .object({
        day: z.coerce.number(),
        month: z.coerce.number(),
        year: z.coerce.number(),
      })
      .optional(),
  })
  .optional();

export const formSchema = z
  .object({
    fullName: z.string().trim().min(1),
    email: z.string().email(),
    stakeholderType: z.nativeEnum(StakeholderType),

    human: humanSchema.optional(),

    company: companySchema.optional(),

    access: z
      .object({
        capTable: z.boolean().optional().default(true),
        position: z.boolean().optional().default(true),
        equity: z.boolean().optional().default(true),
        companyTable: z.boolean().optional().default(true),
      })
      .optional(),

    phoneCountry: z
      .object({
        iso2Code: z.string(),
        name: z.string(),
        prefix: z.string(),
      })
      .optional(),
    phoneNumber: z.string().regex(phoneRegex, 'Invalid Number!').or(z.literal('')).default(''),
    address: z.string().trim().min(1).or(z.literal('')).default(''),
  })
  .refine(() => {
    return true;
  });

export type FormSchema = z.infer<typeof formSchema>;
