import { HttpException } from '../exceptions';
import { QueryParams, QueryParamsBuilder } from './QueryParamBuilder';

export class HttpService {
  private queryParamsBuilder = new QueryParamsBuilder();

  public buildQueryParam(url: string, queryParams?: QueryParams) {
    if (!queryParams) return url;
    return `${url}${this.queryParamsBuilder.buildQuery(queryParams)}`;
  }

  protected async _delete<T, B extends BodyInit | null | undefined>(
    url: string,
    { body, queryParams, accessToken }: { body?: B; queryParams: QueryParams; accessToken: string },
  ) {
    return this.requestWithAuth<T>(url, {
      init: {
        method: 'DELETE',
        body,
      },
      queryParams,
      accessToken,
    });
  }

  protected async _get<T>(
    url: string,
    { accessToken, queryParams }: { accessToken?: string; queryParams: QueryParams },
  ) {
    return this.requestWithAuth<T>(url, { init: undefined, accessToken, queryParams });
  }

  protected async _patch<T, B extends BodyInit | null | undefined>(
    url: string,
    { body, queryParams, accessToken }: { body: B; accessToken?: string; queryParams: QueryParams },
  ) {
    return this.requestWithAuth<T>(url, {
      init: { method: 'PATCH', body },
      accessToken,
      queryParams,
    });
  }

  protected async _post<T, B extends BodyInit | null | undefined>(
    url: string,
    { body, queryParams, accessToken }: { body: B; accessToken?: string; queryParams: QueryParams },
  ) {
    return this.requestWithAuth<T>(url, {
      init: { method: 'POST', body },
      accessToken,
      queryParams,
    });
  }

  protected async request<T>(url: string, init?: RequestInit | undefined) {
    let res: Response;

    try {
      res = await fetch(url, init);
    } catch (e) {
      console.error(e);
      throw e;
    }

    const body = (
      res.headers.get('Content-Type')?.includes('application/json')
        ? await res.json()
        : await res.text()
    ) as T;

    if (!res.ok) throw new HttpException(res, body as { message?: string });

    return body;
  }

  protected async requestWithAuth<T>(
    url: string,
    {
      accessToken,
      init,
      queryParams,
    }: {
      init?: RequestInit | undefined;
      accessToken?: string;
      queryParams?: QueryParams;
    } = {},
  ) {
    return this.request<T>(this.buildQueryParam(url, queryParams), {
      credentials: 'include',
      ...init,
      headers: { Authorization: `Bearer ${accessToken}`, ...init?.headers },
    });
  }
}

const httpService = new HttpService();

export default httpService;
