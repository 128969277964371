import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import LoaderIcon from '../../assets/icons/LoaderIcon';

type LoaderProps = {
  className?: string;
};

const Loader: FC<LoaderProps> = ({ className }) => (
  <div
    className={twMerge(
      'flex h-full w-full items-center justify-center max-lg:absolute max-lg:-ml-6 max-lg:-mt-12',
      className,
    )}
  >
    <LoaderIcon className="size-16" />
  </div>
);

export default Loader;
