import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { HttpException } from '../exceptions';

type AppQueryProps<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = {
  defaultErrorHandling?: boolean;
  onError?: (error: TError) => void;
} & UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>;

export const useAppQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>({
  onError,
  defaultErrorHandling = true,
  ...params
}: AppQueryProps<TQueryFnData, TError, TData, TQueryKey>) => {
  const response = useQuery({
    ...params,
  });

  useEffect(() => {
    if (response.isError) {
      if (!defaultErrorHandling || !(response.error instanceof HttpException)) {
        return onError?.(response.error);
      }

      onError?.(response.error);
      toast(`${response.error?.body?.message}`, {
        position: 'bottom-center',
        type: 'error',
        theme: 'light',
      });

      return;
    }
  }, [onError, response.isError, response.error, defaultErrorHandling]);

  return response;
};
