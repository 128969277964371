import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { StakeholderTypeGroup } from '../../types/stakeholderTypes';
import { useAppMutation } from '../useAppMutation';

export const useStakeholderInfo = () => {
  const { mutate: downloadStakeholderInfo, isPending } = useAppMutation(
    [QueryKey.STAKEHOLDERS_DOWNLOAD_INFO],
    {
      mutationFn: ({
        companyId,
        typeGroup,
      }: {
        companyId: string;
        typeGroup: StakeholderTypeGroup;
      }) =>
        ApiService.downloadFile({
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'stakeholders', typeGroup, 'xlsx'],
        }),
    },
  );
  return { downloadStakeholderInfo, isPending };
};
