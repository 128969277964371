import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type HeartIconProps = IconProps;

const HeartIcon: FC<HeartIconProps> = ({ iconColor = '#D92D20', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="18"
    viewBox="0 0 20 18"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M9.99413 3.27985C8.328 1.332 5.54963 0.808035 3.46208 2.59168C1.37454 4.37532 1.08064 7.35748 2.72 9.467C4.08302 11.2209 8.20798 14.9201 9.55992 16.1174C9.71117 16.2513 9.7868 16.3183 9.87501 16.3446C9.95201 16.3676 10.0363 16.3676 10.1132 16.3446C10.2015 16.3183 10.2771 16.2513 10.4283 16.1174C11.7803 14.9201 15.9052 11.2209 17.2683 9.467C18.9076 7.35748 18.6496 4.35656 16.5262 2.59168C14.4028 0.826798 11.6603 1.332 9.99413 3.27985Z"
      fill={iconColor}
      fillRule="evenodd"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

HeartIcon.displayName = 'HeartIcon';

export default HeartIcon;
