import { StringKey } from '../lang';

export enum SubscriptionDuration {
  MONTHLY = 'monthly',
  ANNUALY = 'annualy',
}

export const subscriptionDurationMap: Record<SubscriptionDuration, StringKey> = {
  [SubscriptionDuration.ANNUALY]: StringKey.ANNUALY,
  [SubscriptionDuration.MONTHLY]: StringKey.MONTHLY,
};

export enum SubscriptionType {
  START_UP = 'startup',
  GROWTH = 'growth',
  LETS_TALK = 'lets-talk',
}

export type Plan = {
  id: string;
  stripePriceId: string;
  title: string;
  duration: SubscriptionDuration;
  type: SubscriptionType;
  stakeholdersCount: number;
  priceInCents: number;
  localPrice: number;
  localCurrency: string;
};
