import React, { ReactElement, ReactNode, useCallback } from 'react';
import {
  Control,
  FieldPathByValue,
  FieldValues,
  PathValue,
  useController,
  useWatch,
} from 'react-hook-form';

import FileInput, { FileInputProps } from './FileInput';

export type FormFileInputProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
> = {
  control: Control<TFieldValues>;
  defaultValue?: PathValue<TFieldValues, TPath>;
  name: TPath;
  successIcon?: ReactNode;
  errorIcon?: ReactNode;
  keepIconOnSuccess?: boolean;
} & Omit<FileInputProps, 'defaultValue' | 'onBlur' | 'onChange' | 'value'>;

const FormFileInput = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
>({
  control,
  defaultValue,
  name,
  errorIcon,
  successIcon,
  icon,
  keepIconOnSuccess,
  ...props
}: FormFileInputProps<TFieldValues, TPath>): ReactElement | null => {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
  });

  const data = useWatch<TFieldValues>({ control });
  const file: (File & (number | readonly string[] | string)) | undefined = (data?.[name] as File)
    ?.name
    ? data?.[name]
    : undefined;

  const iconVariants = useCallback(() => {
    if (fieldState.error || fieldState.invalid) return errorIcon;

    const isValueValid = !fieldState.invalid && fieldState.isDirty && file;

    if (isValueValid || file) {
      if (keepIconOnSuccess)
        return (
          <>
            {icon}
            {successIcon}
          </>
        );
      return successIcon;
    }

    return icon;
  }, [
    errorIcon,
    fieldState.error,
    fieldState.invalid,
    fieldState.isDirty,
    file,
    icon,
    keepIconOnSuccess,
    successIcon,
  ]);
  return (
    <FileInput
      {...props}
      {...field}
      errorMessage={fieldState.error?.message ?? fieldState.error?.type}
      icon={iconVariants()}
      value={file}
    />
  );
};

export default FormFileInput;
