import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import Loader from '../../components/Loader';
import { Company } from '../../hooks';
import CompanySetupForm from './CompanySetupForm';

const CompanySetup: FC = () => {
  const { id } = useParams();
  const { company, isLoading } = Company.useCompany({ companyId: id || '' });
  if (!company || isLoading) return <Loader />;

  return <CompanySetupForm initialData={company} />;
};

CompanySetup.displayName = 'CompanySetup';

export default CompanySetup;
