import React, { type FC, type ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { Sheet, SheetContent, SheetContentProps, SheetProps } from '../Sheet';

export type ModalVariantProps = { className?: string } & SheetProps;

export type ModalWrapperProps = {
  control?: Except<SheetProps, 'children'>;
  children: ReactNode;
  onBgClick?: () => void;
  disabledBgAutoClose?: true;
  className?: string;
} & Except<SheetContentProps, 'className' | 'onInteractOutside'>;

export const ModalWrapper: FC<ModalWrapperProps> = ({
  children,
  className,
  control,
  onBgClick,
  disabledBgAutoClose,
  ...props
}) => {
  return (
    <Sheet {...control}>
      <SheetContent
        className={twMerge('border-none bg-transparent p-0', className)}
        onInteractOutside={(e) => {
          if (disabledBgAutoClose) e.preventDefault();
          onBgClick?.();
        }}
        {...props}
      >
        {children}
      </SheetContent>
    </Sheet>
  );
};
