import React, { FC } from 'react';

import { CloseIcon } from '../../../assets/icons';
import rocket from '../../../assets/images/rocket.jpeg';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { DeprecatedModalWrapper } from '../../../components/Modal';
import { StringKey } from '../../../lang';
import { StakeholderRole } from '../../../types/stakeholderTypes';

export type UpgradePlanModalProps = {
  planName: string;
  onDismiss: () => void;
  onSuccess: () => void;
  isOpen: boolean;
  stakeholderRole: StakeholderRole;
};

const UpgradePlanModal: FC<UpgradePlanModalProps> = ({
  planName,
  onDismiss,
  onSuccess,
  isOpen,
  stakeholderRole,
}) => {
  return (
    <DeprecatedModalWrapper isOpen={isOpen}>
      <div className="relative flex w-full max-w-[360px] flex-col overflow-hidden rounded bg-white shadow-3xl">
        <div className="absolute left-0 top-4 flex w-full items-center justify-between px-4">
          <span className="w-fit rounded bg-[#34405480] px-2 py-1 text-xs text-white">
            <AppFormattedMessage id={StringKey.CURRENT_PLAN} />: {planName}
          </span>
          <Button
            className="flex size-6 items-center justify-center rounded-full bg-[#34405480]"
            onClick={onDismiss}
            styleType="NONE"
          >
            <CloseIcon iconColor="#ffffff" />
          </Button>
        </div>
        <img alt="" className="h-[270px] w-full object-cover" src={rocket} />
        <div className="flex h-full w-full flex-col items-center gap-6 px-4 py-8 text-center">
          <span className="text-xl font-[550] text-gray-700">
            <AppFormattedMessage id={StringKey.SKYROCKETING} />
          </span>
          <span className="text-xs font-[450] text-gray-700">
            <AppFormattedMessage id={StringKey.CONGRATS_ON_YOUR_GROWTH} />
          </span>
          {stakeholderRole === StakeholderRole.ADMIN && (
            <Button className="w-fit px-4 py-[6px]" onClick={onSuccess}>
              <AppFormattedMessage id={StringKey.UPGRADE_PLAN_NOW} />
            </Button>
          )}
        </div>
      </div>
    </DeprecatedModalWrapper>
  );
};

export default UpgradePlanModal;
