import React, { FC, useState } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import { Radio, RadioGroup } from '../../../components/RadioGroup';
import { useFormat } from '../../../hooks';
import { ActivityOverview } from '../ActivityOverview';
import { GraphVariant, graphVariantMobileMap } from '../variables';
import { DatePicker } from './DatePicker';

type EventsMobileProps = {
  companyId: string;
};

export const EventsMobile: FC<EventsMobileProps> = ({ companyId }) => {
  const [selectedGraphVariant, setGraphVariant] = useState(GraphVariant.VALUATION);
  const { format } = useFormat();
  return (
    <div className="flex w-full flex-col gap-4 rounded-md bg-white p-3 shadow-sm">
      <ActivityOverview companyId={companyId} />
      <div className="rounded-3xl border border-gray-200 p-1">
        <RadioGroup
          onChange={setGraphVariant}
          value={selectedGraphVariant}
          wrapperClassName="w-full grow flex-1"
        >
          {graphVariantMobileMap.map(([graphKey, graphTitle]) => (
            <Radio
              className="h-8 rounded-[32px] border-transparent bg-transparent !px-3 !py-2 text-xs font-[450] text-gray-700 data-[checked]:font-[550] data-[checked]:text-brand-25"
              key={graphKey}
              value={graphKey}
            >
              <span className="text-nowrap">{graphTitle}</span>
            </Radio>
          ))}
        </RadioGroup>
      </div>
      <div className="flex w-full flex-col gap-2 rounded-lg p-2 shadow-sm">
        <DatePicker
          className="h-[56px] items-center justify-between border-none"
          icon={<ChevronDownIcon />}
          placeholder="Event date"
        />
        <div className="flex w-full gap-2">
          <div className="flex w-full flex-col gap-2 rounded-lg bg-brand-25 px-2 py-3">
            <span className="font-semibold leading-4 text-[10] text-gray-700">series b</span>
            <span className="text-sm font-[450] text-gray-700">
              {format(new Date(), 'dd/MM/yyyy')}
            </span>
          </div>
          <div className="flex w-full flex-col gap-2 rounded-lg bg-forest-25 px-2 py-3">
            <span className="font-semibold leading-4 text-[10] text-gray-700">Post money val</span>
            <span className="text-sm font-[450] text-forest-700">
              {(59519919231).toLocaleString('en-US')}
            </span>
          </div>
        </div>
        <div className="w-full overflow-x-auto">
          <table className="w-full overflow-hidden rounded-md">
            <thead className="h-9 rounded-t-md border-b border-[#F2F2F2] bg-[#F9FAFB]">
              <tr>
                <th className="px-4 text-left">
                  <span className="text-label-md font-medium text-[#172335]">Shareholder</span>
                </th>
                <th className="px-4 text-left">
                  <span className="text-label-md font-medium text-[#172335]">Shares</span>
                </th>
                <th className="px-4 text-left">
                  <span className="text-label-md font-medium text-[#172335]">Value</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100">
              <tr>
                <td className="max-w-[250px] overflow-hidden truncate px-4 py-3">
                  <span className="truncate text-sm font-[450] text-gray-700">
                    {'Oliviaasdadadadadasdadasdadasda'}
                  </span>
                </td>
                <td className="px-4 py-3">
                  <div className="max-w-[120px] overflow-hidden truncate">
                    <span className="trun text-sm font-[450] text-gray-700">
                      {(1800).toLocaleString('en-US')}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-3">
                  <div className="max-w-[120px] overflow-hidden truncate">
                    <span className="text-sm font-[450] text-gray-700">
                      {(1000000000).toLocaleString('en-US')}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
