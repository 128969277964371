import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { useSelectedCompany } from '../../../../../hooks';
import { Pool } from '../../../../../types/pool-plans.types';
import { Stakeholder } from '../../../../../types/stakeholderTypes';
import { ScrollablePoolPlanItem } from './ScrollablePoolPlanItem';

export type ScrollableStakeholderItemProps = {
  stakeholder: Stakeholder;
  isSelected: boolean;
  selectedPlan: string;
  onEditClick: (id: string) => void;
  onViewClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
  onEditTerminationDateClick: (id: string) => void;
};

export const ScrollableStakeholderItem: FC<ScrollableStakeholderItemProps> = ({
  stakeholder,
  isSelected,
  selectedPlan,
  onEditClick,
  onViewClick,
  onDeleteClick,
  onEditPlanClick,
  onViewPlanClick,
  onDeletePlanClick,
  onEditTerminationDateClick,
}) => {
  const { selectedCompany } = useSelectedCompany();
  const companyId = selectedCompany?.id || '';

  const stakeholderAvailablePool = 0;
  const stakeholderPoolGranted = 0;
  const stakeholderDilutedShares = 0;

  const pools: Pool[] = [];
  return (
    <>
      <tr
        className={twMerge(
          'h-[72px] w-full border-b-[1px] border-b-gray-100 bg-white transition-colors',
          isSelected && 'bg-brand-25',
        )}
      >
        <td className="p-4 text-sm text-gray-700">{stakeholderDilutedShares || '-'}</td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !stakeholderAvailablePool && 'text-gray-300',
            )}
          >
            {stakeholderAvailablePool || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !stakeholderPoolGranted && 'text-gray-300',
            )}
          >
            {stakeholderPoolGranted}
          </span>
        </td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="relative"></td>
      </tr>
      {stakeholder && isSelected && pools && (
        <>
          {pools.map((pool) => (
            <ScrollablePoolPlanItem
              companyId={companyId}
              isSelected={selectedPlan === pool.id}
              key={pool.id}
              onDeleteClick={onDeleteClick}
              onDeletePlanClick={onDeletePlanClick}
              onEditClick={onEditClick}
              onEditPlanClick={onEditPlanClick}
              onEditTerminationDateClick={onEditTerminationDateClick}
              onViewClick={onViewClick}
              onViewPlanClick={onViewPlanClick}
              pool={pool}
            />
          ))}
        </>
      )}
    </>
  );
};
