import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type AddDocumentIconProps = IconProps;

export const AddDocumentIcon: FC<AddDocumentIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4167 1.66669H12.6667C14.0668 1.66669 14.7669 1.66669 15.3016 1.93917C15.772 2.17885 16.1545 2.56131 16.3942 3.03171C16.6667 3.56649 16.6667 4.26656 16.6667 5.66669V14.3334C16.6667 15.7335 16.6667 16.4335 16.3942 16.9683C16.1545 17.4387 15.772 17.8212 15.3016 18.0609C14.7669 18.3334 14.0668 18.3334 12.6667 18.3334H7.33333C5.9332 18.3334 5.23314 18.3334 4.69836 18.0609C4.22795 17.8212 3.8455 17.4387 3.60582 16.9683C3.33333 16.4335 3.33333 15.7335 3.33333 14.3334V13.75M13.3333 10.8334H9.58333M13.3333 7.50002H10.4167M13.3333 14.1667H6.66667M5 8.33335V3.75002C5 3.05966 5.55964 2.50002 6.25 2.50002C6.94036 2.50002 7.5 3.05966 7.5 3.75002V8.33335C7.5 9.71406 6.38071 10.8334 5 10.8334C3.61929 10.8334 2.5 9.71407 2.5 8.33335V5.00002"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);
