import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Control, useFieldArray, UseFormSetValue, useWatch } from 'react-hook-form';

import { CalendarIcon } from '../../../../assets/icons';
import Button from '../../../../components/Button';
import { FormInput } from '../../../../components/Input';
import { useFormat } from '../../../../hooks';
import { EventFormType } from '../../../../types/events.types';
import { DatePicker } from '../../../PoolPlans/PoolForm/DatePicker';
import { FormDropDown, SharePlanCombobox, StakeholderCombobox } from '../Components';
import { FormSchema } from '../validation';

export type GrantStepOneProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
};

export const GrantStepOne: FC<GrantStepOneProps> = ({ control, setFormData, companyId }) => {
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const [firstDuplicateIndex, setFirstDuplicateIndex] = useState<number | null>(null);
  const itemRefs = useRef<(HTMLDivElement | null)[]>([]);

  const { initialStep, stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();

  const defaultDate = new Date(+0);
  const availableShares = 1000;
  const watchedGrantItems = useWatch({
    control,
    name: 'stepOne.eventDetails.grant.grantItems',
  });

  const {
    fields: grantItemsFields,
    append: grantItemsAppend,
    remove: grantItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.eventDetails.grant.grantItems',
  });

  const handleRemoveGrantItems = useCallback(
    (index: number) => {
      grantItemsRemove(index);
    },
    [grantItemsRemove],
  );

  const handleAppendGrantItems = useCallback(() => {
    grantItemsAppend({
      grantDate: defaultDate,
      sharePlan: { id: '', name: '' },
      stakeholder: { id: '', fullName: '' },
      shares: 0,
    });
  }, [grantItemsAppend]);

  const checkForDuplicates = useCallback(() => {
    const combinations = new Set<string>();
    let duplicatesFound = false;
    let firstDuplicateIdx: number | null = null;

    if (watchedGrantItems) {
      watchedGrantItems.forEach((field, index) => {
        const combination = `${field?.stakeholder?.id}-${field?.sharePlan?.id}`;
        if (combinations.has(combination) && field?.stakeholder?.id && field?.sharePlan?.id) {
          duplicatesFound = true;
          if (firstDuplicateIdx === null) {
            firstDuplicateIdx = index;
          }
        } else {
          combinations.add(combination);
        }
      });
    }

    setHasDuplicates(duplicatesFound);
    setFirstDuplicateIndex(firstDuplicateIdx);
  }, [watchedGrantItems]);

  const scrollToFirstDuplicate = useCallback(() => {
    if (firstDuplicateIndex !== null && itemRefs.current[firstDuplicateIndex]) {
      itemRefs.current[firstDuplicateIndex - 1]?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [firstDuplicateIndex]);

  const getFullTitle = useCallback(
    (index: number) => {
      const truncateName = (name: string) => {
        return name.length > 25 ? `${name.slice(0, 25)}...` : name;
      };

      const stakeholderName =
        stepOne?.eventDetails?.grant?.grantItems?.[index]?.stakeholder?.fullName;

      if (stakeholderName) {
        return `Grant - ${truncateName(stakeholderName)}`;
      }

      return 'Grant';
    },
    [stepOne?.eventDetails?.grant?.grantItems],
  );

  useEffect(() => {
    if (initialStep?.type === EventFormType.GRANT && grantItemsFields.length === 0) {
      handleAppendGrantItems();
    }
  }, [initialStep?.type]);

  useEffect(() => {
    checkForDuplicates();
  }, [checkForDuplicates, grantItemsFields]);

  return (
    <>
      {grantItemsFields.map((field, index) => (
        <>
          <FormDropDown
            handleRemove={() => handleRemoveGrantItems(index)}
            key={field.id}
            showRemoveButton={grantItemsFields.length > 1}
            title={getFullTitle(index)}
          >
            <div
              className="flex w-full flex-col gap-3"
              ref={(el) => (itemRefs.current[index] = el)}
            >
              <div className="flex w-full items-center justify-between">
                <span className="text-sm font-[450] text-gray-700">Grant date</span>
                <DatePicker
                  onSelect={(date) =>
                    setFormData(`stepOne.eventDetails.grant.grantItems.${index}.grantDate`, date)
                  }
                  wrapperClassName="w-[224px]"
                >
                  <FormInput
                    autoComplete="off"
                    control={control}
                    customValue={(value) => {
                      return value && new Date(value).getTime() !== defaultDate.getTime()
                        ? format(value, 'dd/MM/yyyy')
                        : '';
                    }}
                    icon={<CalendarIcon className="mr-2 size-6" iconColor="#98A2B3" />}
                    name={`stepOne.eventDetails.grant.grantItems.${index}.grantDate`}
                    numberOnly
                    placeholder="Date"
                    wrapperClassName="w-full"
                  />
                </DatePicker>
              </div>
              <div className="flex w-full items-center justify-between">
                <span className="text-sm font-[450] text-gray-700">Stakeholder</span>
                <StakeholderCombobox
                  companyId={companyId}
                  control={control}
                  name={`stepOne.eventDetails.grant.grantItems.${index}.stakeholder`}
                />
              </div>
              <div className="flex w-full items-center justify-between">
                <span className="text-sm font-[450] text-gray-700">Equity Plan</span>
                <SharePlanCombobox companyId={companyId} control={control} index={index} />
              </div>
              <div className="flex w-full items-center justify-between">
                <span className="text-sm font-[450] text-gray-700">No of Shares</span>
                <div className="flex flex-col items-start gap-2">
                  <FormInput
                    control={control}
                    name={`stepOne.eventDetails.grant.grantItems.${index}.shares`}
                    numberOnly
                    placeholder="Value"
                    shouldFormatNumber
                    wrapperClassName="w-[224px]"
                  />
                  <span className="text-xs text-gray-600">
                    Available: {availableShares.toLocaleString('en-US')}
                  </span>
                </div>
              </div>
            </div>
          </FormDropDown>
          {hasDuplicates && firstDuplicateIndex === index && (
            <span className="rounded bg-fireside-50 p-3 text-xs font-[450] text-gray-700">
              You already have a Grant associated with this Stakeholder and Plan. To edit that
              Grant, please{' '}
              <span
                className="cursor-pointer font-[550] text-brand-700 underline"
                onClick={scrollToFirstDuplicate}
              >
                click here
              </span>
              .
            </span>
          )}
        </>
      ))}
      <Button
        className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
        onClick={handleAppendGrantItems}
        styleType="NONE"
      >
        + Add Another Grant
      </Button>
    </>
  );
};
