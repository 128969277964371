import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PatchShareClassDto } from '../../types/share-classes.types';
import { useAppMutation } from '../useAppMutation';

export const useUpdate = () => {
  const { mutate: update, isPending } = useAppMutation([QueryKey.PATCH_SHARE_CLASS], {
    mutationFn: ({
      companyId,
      data,
      shareClassId,
    }: {
      data: PatchShareClassDto;
      companyId: string;
      shareClassId: string;
    }) =>
      ApiService.patch(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'share-classes', shareClassId],
        },
        {
          body: data,
        },
      ),
  });
  return { update, isPending };
};
