import React, { memo, ReactElement, useCallback } from 'react';
import {
  Control,
  FieldPathByValue,
  FieldValues,
  PathValue,
  useController,
  useWatch,
} from 'react-hook-form';

import RadioGroup, { RadioGroupProps } from './RadioGroup';

export type FormRadioGroupProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
> = {
  control: Control<TFieldValues>;
  defaultValue?: PathValue<TFieldValues, TPath>;
  name: TPath;
  onSelect?: (value: PathValue<TFieldValues, TPath>) => void;
} & Omit<RadioGroupProps, 'defaultValue' | 'onBlur' | 'onChange' | 'value' | 'onSelect'>;

const FormRadioGroup = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
>({
  control,
  defaultValue,
  name,
  onSelect,
  ...props
}: FormRadioGroupProps<TFieldValues, TPath>): ReactElement | null => {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
  });

  const latestValue = useWatch({
    control,
    name,
    defaultValue,
  });

  const handleChange = useCallback(
    (value: PathValue<TFieldValues, TPath>) => {
      onSelect?.(value);
      field.onChange(value);
    },
    [field, onSelect],
  );
  return (
    <RadioGroup
      {...props}
      {...field}
      errorMessage={fieldState.isTouched && (fieldState.error?.message ?? fieldState.error?.type)}
      onChange={handleChange}
      value={latestValue || ''}
    />
  );
};

export default memo(FormRadioGroup) as typeof FormRadioGroup;
