import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useToggleAccess = () => {
  const { mutate: toggleAccess, isPending } = useAppMutation([QueryKey.TOGGLE_STAKEHOLDER_ACCESS], {
    mutationFn: ({ companyId, stakeholderId }: { companyId: string; stakeholderId: string }) =>
      ApiService.post({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'stakeholders', stakeholderId, 'toggle-access'],
      }),
  });
  return { toggleAccess, isPending };
};
