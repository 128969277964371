import React, { FC, memo, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ReactPortal } from './ReactPortal';

enum ModalWrapperPositionVariant {
  CENTRED = 'justify-center items-center',
  NONE = '',
}

enum ModalWrapperMode {
  BLUR = 'backdrop-blur-sm',
  SHADING = 'backdrop-brightness-50',
  NONE = '',
}

type ModalWrapperProps = {
  children: ReactNode;
  className?: string;
  initialPortalId?: string;
  position?: keyof typeof ModalWrapperPositionVariant;
  mode?: keyof typeof ModalWrapperMode;
  isOpen: boolean;
  onBgClick?: () => void;
};

export const DeprecatedModalWrapper: FC<ModalWrapperProps> = memo(
  ({
    children,
    className,
    initialPortalId,
    position = 'CENTRED',
    mode = 'SHADING',
    isOpen,
    onBgClick,
  }) => {
    if (!isOpen) return null;
    return (
      <ReactPortal initialPortalId={initialPortalId}>
        <div
          className={twMerge(
            'absolute left-0 top-0 z-[80] flex h-full w-screen overflow-y-auto p-1',
            ModalWrapperPositionVariant[position],
            ModalWrapperMode[mode],
            className,
          )}
          onClick={onBgClick}
        >
          {children}
        </div>
      </ReactPortal>
    );
  },
);

DeprecatedModalWrapper.displayName = 'ModalWrapper';
