import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

type ReactPortalProps = {
  children: ReactNode;
  initialPortalId?: string;
};

export const ReactPortal = ({ children, initialPortalId = 'modal' }: ReactPortalProps) => {
  const parent = document.getElementById(initialPortalId);
  if (!parent) return null;

  return createPortal(children, parent);
};
