import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type SendInviteIconProps = IconProps;

const SendInviteIcon: FC<SendInviteIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.16256 1.75564L14.1812 5.01778C14.3586 5.13305 14.4472 5.19068 14.5115 5.26756C14.5684 5.33561 14.6111 5.41433 14.6371 5.4991C14.6666 5.59486 14.6666 5.70061 14.6666 5.91212V10.7999C14.6666 11.92 14.6666 12.4801 14.4486 12.9079C14.2569 13.2842 13.9509 13.5902 13.5746 13.7819C13.1467 13.9999 12.5867 13.9999 11.4666 13.9999H4.53325C3.41315 13.9999 2.85309 13.9999 2.42527 13.7819C2.04895 13.5902 1.74299 13.2842 1.55124 12.9079C1.33325 12.4801 1.33325 11.92 1.33325 10.7999V5.91212C1.33325 5.70061 1.33325 5.59486 1.3627 5.4991C1.38877 5.41433 1.43148 5.33561 1.48835 5.26756C1.55259 5.19068 1.64126 5.13305 1.8186 5.01778L6.83728 1.75564M9.16256 1.75564C8.74173 1.4821 8.53132 1.34533 8.3046 1.29211C8.1042 1.24508 7.89563 1.24508 7.69523 1.29211C7.46852 1.34533 7.25811 1.4821 6.83728 1.75564M9.16256 1.75564L13.9786 4.88608C14.2079 5.03512 14.3226 5.10964 14.3623 5.20413C14.397 5.28672 14.397 5.37979 14.3623 5.46238C14.3226 5.55687 14.2079 5.63139 13.9786 5.78042L9.16256 8.91087C8.74174 9.18441 8.53132 9.32118 8.3046 9.37439C8.1042 9.42143 7.89563 9.42143 7.69523 9.37439C7.46852 9.32118 7.25811 9.18441 6.83728 8.91087L2.02121 5.78042C1.79192 5.63139 1.67728 5.55687 1.63758 5.46238C1.60288 5.37979 1.60288 5.28672 1.63758 5.20413C1.67728 5.10964 1.79192 5.03512 2.02121 4.88608L6.83728 1.75564"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);

export default SendInviteIcon;
