import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

type FilterIconProps = IconProps;

export const FilterIcon: FC<FilterIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="17"
    viewBox="0 0 18 17"
    width="18"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.666687 2.83333C0.666687 2.36662 0.666687 2.13327 0.757515 1.95501C0.837409 1.79821 0.964893 1.67072 1.12169 1.59083C1.29995 1.5 1.53331 1.5 2.00002 1.5H16C16.4667 1.5 16.7001 1.5 16.8783 1.59083C17.0351 1.67072 17.1626 1.79821 17.2425 1.95501C17.3334 2.13327 17.3334 2.36662 17.3334 2.83333V3.39116C17.3334 3.61516 17.3334 3.72716 17.306 3.8313C17.2817 3.92359 17.2418 4.01103 17.1879 4.0898C17.1272 4.17869 17.0425 4.25204 16.8733 4.39875L11.5434 9.01792C11.3742 9.16462 11.2895 9.23797 11.2288 9.32687C11.1749 9.40564 11.135 9.49308 11.1107 9.58536C11.0834 9.68951 11.0834 9.80151 11.0834 10.0255V14.382C11.0834 14.5449 11.0834 14.6264 11.0571 14.6969C11.0338 14.7591 10.9961 14.8149 10.9469 14.8596C10.8912 14.9102 10.8156 14.9404 10.6643 15.001L7.83095 16.1343C7.52466 16.2568 7.37152 16.3181 7.24858 16.2925C7.14107 16.2702 7.04673 16.2063 6.98606 16.1148C6.91669 16.0101 6.91669 15.8452 6.91669 15.5153V10.0255C6.91669 9.80151 6.91669 9.68951 6.88932 9.58536C6.86506 9.49308 6.82513 9.40564 6.77128 9.32687C6.7105 9.23797 6.62587 9.16462 6.45659 9.01792L1.12678 4.39875C0.957507 4.25204 0.872871 4.17869 0.812097 4.0898C0.758241 4.01103 0.718315 3.92359 0.694059 3.8313C0.666687 3.72716 0.666687 3.61516 0.666687 3.39116V2.83333Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);
