import React, { FC, useCallback, useEffect } from 'react';
import { Control, useFieldArray, UseFormSetValue, useWatch } from 'react-hook-form';

import { CalendarIcon } from '../../../../assets/icons';
import Button from '../../../../components/Button';
import { FormInput } from '../../../../components/Input';
import { Stakeholder, useFormat } from '../../../../hooks';
import { EventFormType } from '../../../../types/events.types';
import { DatePicker } from '../../../PoolPlans/PoolForm/DatePicker';
import { FormDropDown, ShareClassCombobox, StakeholderCombobox } from '../Components';
import { FormSchema } from '../validation';

export type BuyBackStepOneProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  companyId: string;
};

export const BuyBackStepOne: FC<BuyBackStepOneProps> = ({ control, setFormData, companyId }) => {
  const { initialStep } = useWatch<FormSchema>({ control });

  const defaultDate = new Date(+0);

  const {
    fields: buyBackItemsFields,
    append: buyBackItemsAppend,
    remove: buyBackItemsRemove,
  } = useFieldArray({
    control,
    name: 'stepOne.eventDetails.buyback.buyBackItems',
  });

  const handleRemoveBuyBackItems = useCallback(
    (index: number) => {
      buyBackItemsRemove(index);
    },
    [buyBackItemsRemove],
  );

  const handleAppendBuyBackItems = useCallback(() => {
    buyBackItemsAppend({
      date: defaultDate,
      balance: 0,
      stakeholder: { id: '', fullName: '' },
      shareClass: { id: '', name: '' },
      shares: 0,
    });
  }, [buyBackItemsAppend]);

  useEffect(() => {
    if (initialStep?.type === EventFormType.BUYBACK && buyBackItemsFields.length === 0) {
      handleAppendBuyBackItems();
    }
  }, [initialStep?.type]);

  return (
    <>
      {buyBackItemsFields.map((field, index) => (
        <BuyBackItem
          companyId={companyId}
          control={control}
          handleRemoveBuyBackItems={handleRemoveBuyBackItems}
          index={index}
          key={field.id}
          setFormData={setFormData}
          showRemoveButton={buyBackItemsFields.length > 1}
        />
      ))}
      <Button
        className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
        onClick={handleAppendBuyBackItems}
        styleType="NONE"
      >
        + Add Another Buyback
      </Button>
    </>
  );
};

const BuyBackItem: FC<
  BuyBackStepOneProps & {
    index: number;
    handleRemoveBuyBackItems: (index: number) => void;
    showRemoveButton: boolean;
  }
> = ({ control, setFormData, companyId, index, handleRemoveBuyBackItems, showRemoveButton }) => {
  const { stepOne } = useWatch<FormSchema>({ control });

  const stakeholderId =
    stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.stakeholder?.id || '';
  const shareClassId = stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.shareClass?.id || '';
  const initialShares =
    stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.initialShares || undefined;

  const { balance } = Stakeholder.useBalance({ companyId, stakeholderId, shareClassId });

  useEffect(() => {
    if (balance) {
      setFormData(
        `stepOne.eventDetails.buyback.buyBackItems.${index}.balance`,
        initialShares ? Number(balance) + initialShares : Number(balance),
      );
    }
  }, [balance, index, setFormData]);

  const { format } = useFormat();

  const defaultDate = new Date(+0);

  const getFullTitle = useCallback(
    (index: number) => {
      const truncateName = (name: string) => {
        return name.length > 25 ? `${name.slice(0, 25)}...` : name;
      };

      const stakeholderName =
        stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.stakeholder?.fullName;

      if (stakeholderName) {
        return `Stakeholder - ${truncateName(stakeholderName)}`;
      }

      return 'Stakeholder';
    },
    [stepOne?.eventDetails?.buyback?.buyBackItems],
  );

  const calculateBalance = useCallback(
    (index: number) => {
      if (balance) {
        const currentItem = stepOne?.eventDetails?.buyback?.buyBackItems?.[index];

        if (!currentItem) {
          return Number(balance);
        }

        const filteredItems =
          stepOne?.eventDetails?.buyback?.buyBackItems &&
          stepOne?.eventDetails?.buyback?.buyBackItems.filter((_, i) => i !== index);

        if (!filteredItems || filteredItems.length === 0) {
          return Number(balance);
        }

        const matchingItems = filteredItems.filter(
          (item) =>
            item.stakeholder?.id === currentItem.stakeholder?.id &&
            item.shareClass?.id === currentItem.shareClass?.id,
        );

        if (matchingItems.length === 0) {
          return Number(balance);
        }

        const totalShares = matchingItems.reduce((sum, item) => sum + (item.shares || 0), 0);

        return Number(balance - totalShares);
      }
      return 0;
    },
    [balance, stepOne?.eventDetails?.buyback?.buyBackItems],
  );

  const calculateAvailableShares = useCallback(
    (index: number) => {
      const shares = stepOne?.eventDetails?.buyback?.buyBackItems?.[index]?.shares || 0;
      const balance = calculateBalance(index) + (initialShares || 0);

      return balance - shares;
    },
    [calculateBalance, initialShares, stepOne?.eventDetails?.buyback?.buyBackItems],
  );

  return (
    <FormDropDown
      handleRemove={() => handleRemoveBuyBackItems(index)}
      showRemoveButton={showRemoveButton}
      title={getFullTitle(index)}
    >
      <div className="flex w-full flex-col gap-3">
        <div className="flex w-full items-center justify-between">
          <span className="text-sm font-[450] text-gray-700">Event date</span>
          <DatePicker
            onSelect={(date) =>
              setFormData(`stepOne.eventDetails.buyback.buyBackItems.${index}.date`, date)
            }
            wrapperClassName="w-[224px]"
          >
            <FormInput
              autoComplete="off"
              control={control}
              customValue={(value) => {
                return value && new Date(value).getTime() !== defaultDate.getTime()
                  ? format(value, 'dd/MM/yyyy')
                  : '';
              }}
              icon={<CalendarIcon className="mr-2 size-6" iconColor="#98A2B3" />}
              name={`stepOne.eventDetails.buyback.buyBackItems.${index}.date`}
              numberOnly
              placeholder="Date"
              wrapperClassName="w-full"
            />
          </DatePicker>
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="text-sm font-[450] text-gray-700">Stakeholder</span>
          <StakeholderCombobox
            companyId={companyId}
            control={control}
            name={`stepOne.eventDetails.buyback.buyBackItems.${index}.stakeholder`}
          />
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="text-sm font-[450] text-gray-700">Share Class</span>
          <ShareClassCombobox
            companyId={companyId}
            control={control}
            fieldName={`stepOne.eventDetails.buyback.buyBackItems.${index}.shareClass`}
          />
        </div>
        <div className="flex w-full items-center justify-between">
          <span className="text-sm font-[450] text-gray-700">Shares</span>
          <div className="flex flex-col items-start gap-2">
            <FormInput
              control={control}
              name={`stepOne.eventDetails.buyback.buyBackItems.${index}.shares`}
              numberOnly
              placeholder="Value"
              shouldFormatNumber
              wrapperClassName="w-[224px]"
            />
            {balance && (
              <span className="text-xs text-gray-600">
                Available: {Math.max(calculateAvailableShares(index), 0).toLocaleString('en-US')}
              </span>
            )}
          </div>
        </div>
      </div>
    </FormDropDown>
  );
};
