import { FC, useState } from 'react';

import { PaginationController } from '../../../../components/PaginationController';
import { EventHooks, useFormat } from '../../../../hooks';
import { BuybackEventItem, eventFormType, EventTypeBackend } from '../../../../types/events.types';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Buyback: FC<CardProps> = ({ event, companyId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { format } = useFormat();
  const { items, totalPages } = EventHooks.useTransactions<BuybackEventItem>({
    eventId: event.id,
    eventType: EventTypeBackend.BUYBACK,
    companyId: companyId,
    currentPage,
  });

  if (event.type !== EventTypeBackend.BUYBACK) return <></>;
  const { type } = event;
  return (
    <>
      {items.map(({ date, shareClass, sharesCount, stakeholder }, i) => (
        <div
          className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm"
          key={i + shareClass.id}
        >
          <TransactionTitleItem
            createdAt={format(date, 'dd/MM/yyyy')}
            name={stakeholder?.fullName}
          />
          <TransactionOverviewItem title={'Transaction Type'} value={eventFormType[type]} />
          <TransactionOverviewItem title={'Investments'} value={shareClass.name} />
          <TransactionOverviewItem
            title={'No of Shares'}
            value={sharesCount.toLocaleString('en-US')}
          />
        </div>
      ))}
      <PaginationController
        className="mt-6 shrink-0"
        currentPage={currentPage}
        onClick={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};
