import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { CreateShareClassDto } from '../../types/share-classes.types';
import { useAppMutation } from '../useAppMutation';

export const useCreate = () => {
  const { mutate: create, isPending } = useAppMutation([QueryKey.CREATE_SHARE_CLASS], {
    mutationFn: ({ companyId, data }: { data: CreateShareClassDto; companyId: string }) =>
      ApiService.post(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'share-classes'] },
        { body: data },
      ),
  });
  return { create, isPending };
};
