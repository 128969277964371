import { FC, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { twMerge } from 'tailwind-merge';

import { ArrowIcon, CloseIcon, FilterIcon, RefreshIcon } from '../../../assets/icons';
import { RefreshIconVariant } from '../../../assets/icons/RefreshIcon';
import Button from '../../../components/Button';
import { Checkbox } from '../../../components/Checkbox';
import { PaginationController } from '../../../components/PaginationController';
import { Sheet, SheetContent } from '../../../components/Sheet';
import { EventHooks, useFormat, useModalState, useNavigate } from '../../../hooks';
import {
  eventFormType,
  EventTypeBackend,
  EventUnion,
  SortByDate,
  sortByDateMap,
  SortByType,
  sortByTypeMap,
} from '../../../types/events.types';
import { PageRoute } from '../../../types/pageTypes';
import { EmptyEventHistory } from '../EmptyEventHistory';
import { Tag, TagVariant } from '../Tag';
import { DatePicker } from './DatePicker';

const sortByTypeValues = Object.values(SortByType);

type EventsMobileHistoryProps = {
  companyId: string;
};

const itemsToFetch = 13;

export const EventsMobileHistory: FC<EventsMobileHistoryProps> = ({ companyId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate(PageRoute.EVENTS);
  const [selectedTypeFilters, setTypeFilters] = useState<SortByType[]>(sortByTypeValues);
  const [selectedFilter, setSelectedFilter] = useState<'ByDate' | 'ByType'>('ByType');
  const [selectedDateFilter, setDateFilter] = useState(SortByDate.LAST_30_DAYS);
  const [selectedCustomDate, setCustomDate] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  const [selectedFilters, setSelectedFilters] = useState<{
    types: SortByType[];
    dateFilter: SortByDate;
    customDate: DateRange | null;
  }>({ customDate: null, dateFilter: SortByDate.LAST_30_DAYS, types: [] });

  const { events, totalPages, isEmpty, isSearchResultEmpty } = EventHooks.useEvents({
    companyId,
    dateFilter: selectedFilters.dateFilter,
    typesFilter: selectedFilters.types,
    customDateFilter: selectedFilters.customDate || { from: undefined, to: undefined },
    currentPage: currentPage,
    take: itemsToFetch,
  });

  const { format } = useFormat();
  const { isOpen, toggler, handleOpenModal, handleCloseModal, handleSuccessModal } =
    useModalState();

  const handleResetFilters = () => {
    setSelectedFilters({
      customDate: null,
      dateFilter: SortByDate.LAST_30_DAYS,
      types: sortByTypeValues,
    });
    setTypeFilters(sortByTypeValues);
    setDateFilter(SortByDate.LAST_30_DAYS);
  };

  const handleApplyFilters = () => {
    setSelectedFilters({
      customDate: selectedCustomDate,
      dateFilter: selectedDateFilter,
      types: selectedTypeFilters,
    });
    handleSuccessModal();
  };
  const isSelectedAllTypeFilters = selectedTypeFilters.length === sortByTypeValues.length;

  return (
    <>
      <div className="flex w-full flex-col gap-4 rounded-md bg-white p-3 shadow-sm">
        <div className="flex w-full justify-between border-b border-gray-100 pb-4">
          <div className="flex items-center gap-1">
            <RefreshIcon variant={RefreshIconVariant.EVENTS} />
            <span className="text-base font-semibold text-gray-700">Event History</span>
          </div>
          <Button
            className="centered flex !size-9 h-fit w-fit rounded border border-gray-100 bg-white"
            onClick={handleOpenModal}
            styleType="NONE"
          >
            <FilterIcon />
          </Button>
        </div>
        {!isEmpty ? (
          events.map((event) => {
            const { createdAt, type, eventId } = event;

            const mergedEventUnion = event as CombineUnion<EventUnion>;

            const transactions = mergedEventUnion.items || [];

            const originalName = mergedEventUnion.valuation?.name || mergedEventUnion.name;
            const name = originalName || eventFormType[type];

            const shouldRenderPrePostValuation =
              type === EventTypeBackend.FUNDRAISING_ROUND ||
              type === EventTypeBackend.SHARE_ISSUANCE;

            const postMoneyValuation: number =
              (mergedEventUnion.valuation?.sharePrice || 0) *
              (mergedEventUnion.valuation?.issuedShares || 0);

            const preValuation: number =
              (mergedEventUnion.valuation?.sharePrice || 0) *
              (mergedEventUnion.valuation?.issuedSharesOnStart || 0);

            return (
              <div className="flex w-full flex-col divide-y divide-gray-100 overflow-hidden rounded-md pt-3 shadow-sm">
                <div className="flex w-full items-center justify-between gap-1 px-3 pb-3">
                  <div className="flex max-w-[calc(100%-100px)] flex-grow flex-col">
                    <span className="truncate text-base font-semibold text-gray-700">{name}</span>
                    <span className="text-label-sm font-medium text-gray-500">
                      {event.type === EventTypeBackend.NEW_PLAN && event.sharePlan.name + '-'}
                      {event.type === EventTypeBackend.NEW_POOL && event.pool.name + '-'}
                      {format(createdAt, 'dd/MM/yyyy')}
                    </span>
                  </div>
                  {event.type === EventTypeBackend.FUNDRAISING_ROUND && event.isOpen && (
                    <Tag title="Open Round" variant={TagVariant.GREEN} />
                  )}
                  {event.type === EventTypeBackend.FUNDRAISING_ROUND && !event.isOpen && (
                    <Tag title="Closed Round" variant={TagVariant.RED} />
                  )}
                </div>
                {shouldRenderPrePostValuation && (
                  <div className="mt-2 flex justify-between p-3">
                    <span className="text-xs font-[450] text-gray-700">Pre-Money Valuation</span>
                    <span className="text-sm font-[450] text-gray-700">
                      {preValuation.toLocaleString('en-US')}
                    </span>
                  </div>
                )}
                {shouldRenderPrePostValuation && (
                  <div className="mt-2 flex justify-between p-3">
                    <span className="text-xs font-[450] text-gray-700">Post-Money Valuation</span>
                    <span className="text-sm font-[450] text-gray-700">
                      {postMoneyValuation.toLocaleString('en-US')}
                    </span>
                  </div>
                )}
                <Button
                  className="flex items-center justify-between bg-brand-25 p-3"
                  onClick={() => navigate([eventId])}
                  styleType="NONE"
                >
                  <span className="text-sm font-[450] text-brand-700">
                    View {transactions.length || 1} transactions(s)
                  </span>
                  <ArrowIcon className="size-4 rotate-180" iconColor="#2565C8" />
                </Button>
              </div>
            );
          })
        ) : (
          <div className="centered flex">
            <EmptyEventHistory
              isDisplayedAddEvent={false}
              text={
                isSearchResultEmpty
                  ? 'No results match this search term Please try again'
                  : undefined
              }
              title={isSearchResultEmpty ? 'No events found' : undefined}
            />
          </div>
        )}
        <PaginationController
          className="shrink-0 max-lg:px-4 lg:mx-6"
          currentPage={currentPage}
          onClick={setCurrentPage}
          totalPages={totalPages}
        />
        <Sheet onOpenChange={toggler} open={isOpen}>
          <SheetContent className="w-full rounded-t-lg bg-white p-4" side="BOTTOM">
            <div className="flex h-[56px] w-full items-center justify-between">
              <span className="text-base font-semibold text-gray-700">Filters</span>
              <Button
                className="centered flex !size-7 h-fit w-fit rounded-full"
                onClick={handleCloseModal}
                style={{
                  boxShadow: '0px 0px 18.67px 0px #00000014',
                  border: '0.78px solid #F2F2F2',
                }}
                styleType="NONE"
              >
                <CloseIcon />
              </Button>
            </div>
            <div className="flex divide-x divide-gray-100">
              <div className="flex w-[144px] flex-col pr-4">
                <Button
                  className={twMerge(
                    'justify-start px-2 py-[6px] text-sm text-gray-700',
                    selectedFilter === 'ByType' && 'rounded bg-gray-50 font-[550]',
                  )}
                  onClick={() => setSelectedFilter('ByType')}
                  styleType="NONE"
                >
                  Types
                </Button>
                <Button
                  className={twMerge(
                    'justify-start px-2 py-[6px] text-sm text-gray-700',
                    selectedFilter === 'ByDate' && 'rounded bg-gray-50 font-[550]',
                  )}
                  onClick={() => setSelectedFilter('ByDate')}
                  styleType="NONE"
                >
                  Date
                </Button>
              </div>
              <div className="flex w-full flex-col pl-4">
                {selectedFilter === 'ByType' ? (
                  <>
                    <div className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700">
                      <Checkbox
                        checked={isSelectedAllTypeFilters}
                        onChange={() => {
                          setTypeFilters(isSelectedAllTypeFilters ? [] : sortByTypeValues);
                        }}
                      />
                      <span className="text-sm font-normal text-gray-700">All Types</span>
                    </div>
                    {sortByTypeMap.map(([sortKey, sortTitle]) => (
                      <div className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700">
                        <Checkbox
                          checked={selectedTypeFilters.includes(sortKey)}
                          onChange={(state) =>
                            setTypeFilters((prev) =>
                              state
                                ? [...prev, sortKey]
                                : prev.filter((sortVariant) => sortVariant !== sortKey),
                            )
                          }
                        />
                        <span className="text-nowrap text-sm font-normal text-gray-700">
                          {sortTitle}
                        </span>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    {sortByDateMap.map(([sortKey, sortTitle]) => (
                      <div className="flex w-full items-center gap-2 rounded p-[6px] pr-8 text-sm text-gray-700">
                        <Checkbox
                          checked={sortKey === selectedDateFilter}
                          className="rounded-full"
                          onChange={() => setDateFilter(sortKey)}
                        />
                        <span className="text-sm font-normal text-gray-700">{sortTitle}</span>
                      </div>
                    ))}
                    {selectedDateFilter === SortByDate.CUSTOM_DATE && (
                      <div className="flex flex-col gap-[21px] border-t border-gray-100 pt-4">
                        <div className="flex flex-col gap-2">
                          <span className="text-label-sm font-medium text-gray-700">
                            Start date
                          </span>
                          <DatePicker
                            onSelect={(dateFrom) =>
                              setCustomDate((prev) => ({ ...prev, from: dateFrom }))
                            }
                            toDate={selectedCustomDate.to}
                            value={selectedCustomDate.from}
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className="text-label-sm font-medium text-gray-700">End date</span>
                          <DatePicker
                            fromDate={selectedCustomDate.from}
                            onSelect={(dateTo) =>
                              setCustomDate((prev) => ({ ...prev, to: dateTo }))
                            }
                            value={selectedCustomDate.to}
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="mt-6 flex w-full gap-4">
              <Button
                className="w-[137px] border border-gray-100 text-sm font-[450] text-gray-700"
                onClick={handleResetFilters}
                styleType="DEFAULT_ROUNDED"
              >
                Reset
              </Button>
              <Button
                className="w-full px-4 py-[10px] text-sm font-[550] text-white"
                onClick={handleApplyFilters}
              >
                Apply Filter
              </Button>
            </div>
          </SheetContent>
        </Sheet>
      </div>
    </>
  );
};
