import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { StringKey } from '../../../lang';

export type DeleteInactiveCompanyModalProps = {
  onClose: () => void;
  onSuccess: () => void;
};

const DeleteInactiveCompanyModal: FC<DeleteInactiveCompanyModalProps> = ({
  onClose,
  onSuccess,
}) => {
  return (
    <div className="flex w-full max-w-[450px] flex-col rounded bg-white p-4 text-xs font-normal text-gray-500 shadow-xl">
      <span className="text-xl font-[550] text-gray-700">Delete inactive Company?</span>
      <span>Are you sure you want to delete inactive company?</span>
      <div className="mt-8 flex justify-end gap-2">
        <Button
          className="rounded border-[1px] border-gray-300 text-sm text-gray-700"
          onClick={onClose}
          styleType="DEFAULT_ROUNDED"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <Button
          className="rounded border-gray-300 bg-fireside-600 text-sm font-[550] text-gray-25"
          onClick={onSuccess}
          styleType="DEFAULT_ROUNDED"
        >
          <AppFormattedMessage id={StringKey.CONFIRM_DELETE} />
        </Button>
      </div>
    </div>
  );
};

DeleteInactiveCompanyModal.displayName = 'DeleteInactiveCompanyModal';

export default DeleteInactiveCompanyModal;
