import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../../../assets/icons';
import Button from '../../../../../components/Button';
import { useSelectedCompany } from '../../../../../hooks';
import { Pool } from '../../../../../types/pool-plans.types';
import { Stakeholder } from '../../../../../types/stakeholderTypes';
import { StickyPoolPlanItem } from './StickyPoolPlanItem';

type StickyStakeholderItemProps = {
  stakeholder: Stakeholder;
  isSelected: boolean;
  setSelectedPool: (state: string) => void;
  selectedPlan: string;
  setSelectedPlan: (state: string) => void;
};

export const StickyStakeholderItem: FC<StickyStakeholderItemProps> = ({
  stakeholder,
  isSelected,
  setSelectedPool,
  selectedPlan,
  setSelectedPlan,
}) => {
  const { selectedCompany } = useSelectedCompany();
  const companyId = selectedCompany?.id || '';

  const handlePoolSelected = () => {
    setSelectedPool(stakeholder.id);
    setSelectedPlan('');
  };

  const handlePoolUnselected = () => {
    setSelectedPool('');
    setSelectedPlan('');
  };

  const stakeholderPools: Pool[] = [];

  return (
    <>
      <tr
        className={twMerge(
          'flex h-[72px] w-full border-l-[2px] border-l-white bg-white transition-colors',
          isSelected && 'border-l-brand-700 bg-brand-25',
        )}
      >
        <td className="relative h-[72px] border-b-[1px] border-b-gray-100">
          <div className="flex h-full w-full items-center justify-center">
            {stakeholder && stakeholderPools && (
              <Button
                className="h-[72px] p-4 transition-colors"
                onClick={isSelected ? handlePoolUnselected : handlePoolSelected}
                styleType="NONE"
              >
                <ChevronDownIcon
                  className={twMerge('transition-transform', isSelected ? 'rotate-180' : '')}
                  iconColor={isSelected ? '#2565C8' : '#344054'}
                />
              </Button>
            )}
          </div>
        </td>
        <td className="flex h-[72px] w-full items-center justify-between gap-4 overflow-hidden text-nowrap border-b-[1px] border-b-gray-100 px-6 py-4">
          <div className="flex items-center gap-3">
            <div className="flex size-10 shrink-0 items-center justify-center rounded-full bg-gray-200 text-sm font-[450] text-gray-700">
              {stakeholder.fullName[0]}
            </div>
            <span className="text-sm font-[450] text-gray-700">{stakeholder.fullName}</span>
          </div>
        </td>
      </tr>
      {isSelected && stakeholderPools && (
        <>
          {stakeholderPools.map((pool) => (
            <StickyPoolPlanItem
              companyId={companyId}
              isSelected={selectedPlan === pool.id}
              key={pool.id}
              pool={pool}
              setSelectedPlan={setSelectedPlan}
            />
          ))}
        </>
      )}
    </>
  );
};
