import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { UpgradeCompanyDto } from '../../types/companyTypes';
import { useAppMutation } from '../useAppMutation';

export const useUpgradePlan = () => {
  const { mutate: upgradePlan, isPending } = useAppMutation([QueryKey.CHANGE_PLAN], {
    mutationFn: ({ companyId, data }: { data: UpgradeCompanyDto; companyId: string }) =>
      ApiService.patch(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'upgrade'] },
        { body: data },
      ),
  });

  return {
    upgradePlan,
    isPending,
  };
};
