import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type EventIssueSharesProps = IconProps;

export const EventIssueShares: FC<EventIssueSharesProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6667 18.3334C17.9 18.3334 18.0167 18.3334 18.1058 18.288C18.1843 18.2481 18.248 18.1843 18.2879 18.1059C18.3334 18.0168 18.3334 17.9001 18.3334 17.6667V9.00008C18.3334 8.76673 18.3334 8.65005 18.2879 8.56092C18.248 8.48252 18.1843 8.41878 18.1058 8.37883C18.0167 8.33342 17.9 8.33342 17.6667 8.33342L15.6667 8.33342C15.4333 8.33342 15.3167 8.33342 15.2275 8.37883C15.1491 8.41878 15.0854 8.48252 15.0454 8.56092C15 8.65005 15 8.76673 15 9.00008V11.0001C15 11.2334 15 11.3501 14.9546 11.4392C14.9147 11.5176 14.8509 11.5814 14.7725 11.6213C14.6834 11.6667 14.5667 11.6667 14.3334 11.6667H12.3334C12.1 11.6667 11.9833 11.6667 11.8942 11.7122C11.8158 11.7521 11.752 11.8159 11.7121 11.8943C11.6667 11.9834 11.6667 12.1001 11.6667 12.3334V14.3334C11.6667 14.5668 11.6667 14.6834 11.6213 14.7726C11.5813 14.851 11.5176 14.9147 11.4392 14.9547C11.3501 15.0001 11.2334 15.0001 11 15.0001H9.00002C8.76667 15.0001 8.64999 15.0001 8.56086 15.0455C8.48246 15.0854 8.41871 15.1492 8.37877 15.2276C8.33335 15.3167 8.33335 15.4334 8.33335 15.6667V17.6667C8.33335 17.9001 8.33335 18.0168 8.37877 18.1059C8.41871 18.1843 8.48246 18.2481 8.56086 18.288C8.64999 18.3334 8.76667 18.3334 9.00002 18.3334L17.6667 18.3334Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
    <path
      d="M8.33335 5.66675C8.33335 5.43339 8.33335 5.31671 8.37877 5.22759C8.41871 5.14918 8.48246 5.08544 8.56086 5.0455C8.64999 5.00008 8.76667 5.00008 9.00002 5.00008H11C11.2334 5.00008 11.3501 5.00008 11.4392 5.0455C11.5176 5.08544 11.5813 5.14918 11.6213 5.22759C11.6667 5.31671 11.6667 5.43339 11.6667 5.66675V7.66675C11.6667 7.9001 11.6667 8.01678 11.6213 8.10591C11.5813 8.18431 11.5176 8.24805 11.4392 8.288C11.3501 8.33341 11.2334 8.33341 11 8.33341H9.00002C8.76667 8.33341 8.64999 8.33341 8.56086 8.288C8.48246 8.24805 8.41871 8.18431 8.37877 8.10591C8.33335 8.01678 8.33335 7.9001 8.33335 7.66675V5.66675Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
    <path
      d="M2.50002 10.6667C2.50002 10.4334 2.50002 10.3167 2.54543 10.2276C2.58538 10.1492 2.64912 10.0854 2.72752 10.0455C2.81665 10.0001 2.93333 10.0001 3.16669 10.0001H5.16669C5.40004 10.0001 5.51672 10.0001 5.60585 10.0455C5.68425 10.0854 5.74799 10.1492 5.78794 10.2276C5.83335 10.3167 5.83335 10.4334 5.83335 10.6667V12.6667C5.83335 12.9001 5.83335 13.0168 5.78794 13.1059C5.74799 13.1843 5.68425 13.2481 5.60585 13.288C5.51672 13.3334 5.40004 13.3334 5.16669 13.3334H3.16669C2.93333 13.3334 2.81665 13.3334 2.72752 13.288C2.64912 13.2481 2.58538 13.1843 2.54543 13.1059C2.50002 13.0168 2.50002 12.9001 2.50002 12.6667V10.6667Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
    <path
      d="M1.66669 2.33341C1.66669 2.10006 1.66669 1.98338 1.7121 1.89425C1.75205 1.81585 1.81579 1.75211 1.89419 1.71216C1.98332 1.66675 2.1 1.66675 2.33335 1.66675H4.33335C4.56671 1.66675 4.68339 1.66675 4.77252 1.71216C4.85092 1.75211 4.91466 1.81585 4.95461 1.89425C5.00002 1.98338 5.00002 2.10006 5.00002 2.33341V4.33341C5.00002 4.56677 5.00002 4.68345 4.95461 4.77258C4.91466 4.85098 4.85092 4.91472 4.77252 4.95467C4.68339 5.00008 4.56671 5.00008 4.33335 5.00008H2.33335C2.1 5.00008 1.98332 5.00008 1.89419 4.95467C1.81579 4.91472 1.75205 4.85098 1.7121 4.77258C1.66669 4.68345 1.66669 4.56677 1.66669 4.33341V2.33341Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);
