import React, {
  DetailedHTMLProps,
  FocusEvent,
  forwardRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { twMerge } from 'tailwind-merge';

import { InfoCircle } from '../../assets/icons';
import { useForwardRef } from '../../hooks/useForwardRef';
import {
  InputStatus,
  inputStatusToPlaceholderClassNamesMap,
  inputStatusToWrapperClassNamesMap,
} from './constants';

export type InputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: ReactNode;
  type?: HTMLInputTypeAttribute;
  isError?: boolean;
  errorMessage?: false | string;
  isSuccess?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  iconBeforeInput?: ReactNode;
  wrapperClassName?: string;
  onIconClick?: () => void;
  onIconBeforeInputClick?: () => void;
  customStatus?: InputStatus;
  disabledInput?: boolean;
  isShownErrorMessage?: boolean;
  numberOnly?: true;
  maxDecimalPlaces?: number;
  placeholderClassName?: string;
  shouldFormatNumber?: boolean;
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'inputMode' | 'placeholder'
>;

const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(
    (
      {
        value,
        onChange,
        placeholder,
        type,
        isError,
        errorMessage,
        isSuccess,
        disabled,
        icon,
        wrapperClassName,
        onBlur,
        onFocus,
        onIconClick,
        onIconBeforeInputClick,
        iconBeforeInput,
        customStatus,
        disabledInput,
        isShownErrorMessage = true,
        numberOnly,
        maxDecimalPlaces,
        placeholderClassName,
        shouldFormatNumber = false,
        ...props
      },
      ref,
    ) => {
      const formatNumber = useCallback((value: string): string => {
        const number = parseFloat(value);
        if (isNaN(number)) return value;
        return new Intl.NumberFormat('en-US').format(number);
      }, []);

      const inputRef = useForwardRef<HTMLInputElement>(ref);
      const [isFocused, setIsFocused] = useState(false);
      const [status, setStatus] = useState(InputStatus.IDLE_EMPTY);
      const [displayValue, setDisplayValue] = useState(value);

      useEffect(() => {
        if (isError || errorMessage) {
          setStatus(InputStatus.ERROR);
          return;
        }

        if (isSuccess) {
          setStatus(InputStatus.SUCCESS);
          return;
        }

        if (isFocused) {
          setStatus(InputStatus.FOCUSED);
          return;
        }

        setStatus(value ? InputStatus.IDLE_FULL : InputStatus.IDLE_EMPTY);
      }, [errorMessage, inputRef, isError, isFocused, isSuccess, value]);

      useEffect(() => {
        if (shouldFormatNumber && formatNumber) {
          setDisplayValue(formatNumber(value));
        } else {
          setDisplayValue(value);
        }
      }, [value, shouldFormatNumber, formatNumber]);

      const handleFocus = useCallback(
        (event: FocusEvent<HTMLInputElement, Element>) => {
          setIsFocused(true);
          onFocus?.(event);
        },
        [onFocus, setIsFocused],
      );

      const handleBlur = useCallback(
        (event: FocusEvent<HTMLInputElement, Element>) => {
          setIsFocused(false);
          onBlur?.(event);
        },
        [setIsFocused, onBlur],
      );

      const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
          target: { value },
        } = e;

        const regex = new RegExp(`^\\d*\\.?\\d{0,${maxDecimalPlaces}}$`);
        const numberOnlyRegexp = shouldFormatNumber ? new RegExp(`[^0-9,]`) : new RegExp(`[^0-9]`);

        if (
          (numberOnly && numberOnlyRegexp.test(value)) ||
          (maxDecimalPlaces && !regex?.test(value))
        ) {
          e.preventDefault();
          return;
        }

        const parsedValue = shouldFormatNumber ? value.replace(/,/g, '') : value;
        onChange(parsedValue);
      };

      const handleClick = useCallback(() => {
        inputRef.current?.focus();
      }, [inputRef]);

      return (
        <div
          className={twMerge(
            'flex cursor-text flex-col gap-3 disabled:cursor-default',
            wrapperClassName,
          )}
          onClick={handleClick}
        >
          <div
            className={`relative flex h-14 items-center gap-2.5 rounded-t bg-gray-50 px-2.5 ${inputStatusToWrapperClassNamesMap[customStatus ?? status]} ${disabled && 'cursor-default !border-transparent'}`}
          >
            {iconBeforeInput && (
              <div
                className={`${onIconClick ? 'cursor-pointer' : ''}`}
                onClick={onIconBeforeInputClick}
              >
                {iconBeforeInput}
              </div>
            )}

            {placeholder && (
              <div
                className={`pointer-events-none absolute left-2.5 top-1/2 -translate-y-1/2 font-inter text-sm font-[450] transition-all duration-75 ${inputStatusToPlaceholderClassNamesMap[status]} ${isFocused || value ? '!top-[8px] !translate-y-0 !text-xs' : ''} ${placeholderClassName}`}
              >
                {placeholder}
              </div>
            )}
            <div className="flex h-full w-full flex-col justify-end pb-2">
              <input
                className="z-1 w-full !bg-transparent pr-3 font-inter text-sm font-[450] text-gray-700 !outline-none"
                disabled={disabledInput || disabled}
                onBlur={handleBlur}
                onChange={handleChange}
                onFocus={handleFocus}
                ref={inputRef}
                type={type}
                value={displayValue || ''}
                {...props}
              />
            </div>
            {icon && (
              <div className={`${onIconClick ? 'cursor-pointer' : ''}`} onClick={onIconClick}>
                {icon}
              </div>
            )}
          </div>
          {errorMessage && isShownErrorMessage && (
            <div className="flex items-center gap-1">
              <InfoCircle />
              <div className="whitespace-pre-line text-left font-inter text-xs font-[450] text-fireside-600">
                {errorMessage}
              </div>
            </div>
          )}
        </div>
      );
    },
  ),
);

Input.displayName = 'Input';

export default Input;
