import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type TrashBinIconProps = IconProps;

const TrashBinIcon: FC<TrashBinIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3333 4.49984V4.03317C10.3333 3.37978 10.3333 3.05308 10.2062 2.80351C10.0943 2.58399 9.91584 2.40552 9.69632 2.29366C9.44676 2.1665 9.12006 2.1665 8.46667 2.1665H7.53333C6.87994 2.1665 6.55324 2.1665 6.30368 2.29366C6.08416 2.40552 5.90568 2.58399 5.79383 2.80351C5.66667 3.05308 5.66667 3.37978 5.66667 4.03317V4.49984M2.75 4.49984H13.25M12.0833 4.49984V11.0332C12.0833 12.0133 12.0833 12.5033 11.8926 12.8777C11.7248 13.2069 11.4571 13.4747 11.1278 13.6424C10.7535 13.8332 10.2634 13.8332 9.28333 13.8332H6.71667C5.73657 13.8332 5.24653 13.8332 4.87218 13.6424C4.5429 13.4747 4.27518 13.2069 4.10741 12.8777C3.91667 12.5033 3.91667 12.0133 3.91667 11.0332V4.49984"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

export default TrashBinIcon;
