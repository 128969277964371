export enum QueryKey {
  //CRUD STAKEHOLDERS
  CREATE_HUMAN_STAKEHOLDER = 'create-human-stakeholder',
  CREATE_COMPANY_STAKEHOLDER = 'create-company-stakeholder',
  GET_STAKEHOLDER = 'get-stakeholder',
  GET_STAKEHOLDERS = 'get-stakeholders',
  PATCH_STAKEHOLDER = 'patch-stakeholder',
  DELETE_STAKEHOLDER = 'delete-stakeholder',
  GET_STAKEHOLDER_BALANCE = 'get-stakeholder-balance',

  //CRUD SHARE CLASSES
  GET_SHARE_CLASSES = 'get-share-classes',
  CREATE_SHARE_CLASS = 'create-share-class',
  GET_SHARE_CLASS = 'get-share-class',
  PATCH_SHARE_CLASS = 'patch-share-class',
  DELETE_SHARE_CLASS = 'delete-share-class',
  SET_SHARE_CLASS_DOC = 'set-share-class-doc',

  //CRUD POOL
  GET_POOL = 'get-pool',
  GET_POOLS = 'get-pools',
  CREATE_POOL = 'create-pool',
  PATCH_POOL = 'patch-pool',
  TERMINATE_POOL = 'terminate-pool',
  GET_TERMINATED_POOLS = 'get-terminated-pools',
  DELETE_POOL = 'delete-pool',
  GET_POOLS_BY_STAKEHOLDER = 'get-pools-by-stakeholder',
  POOL_NAME_CHECK = 'pool-name-check',

  //STAKEHOLDER ACTIONS
  GET_INVITATION = 'get-invitation',
  ACCEPT_INVITE_STAKEHOLDER = 'accept-invite-stakeholder',
  INVITE_STAKEHOLDER = 'invite-stakeholder',
  CANCEL_STAKEHOLDER_INVITATION = 'cancel-stakeholder-invitation',
  TOGGLE_STAKEHOLDER_STATUS = 'toggle-stakeholder-status',
  TOGGLE_STAKEHOLDER_ACCESS = 'toggle-stakeholder-access',
  BULK_STAKEHOLDER_ACTIONS = 'bulk-stakeholder-actions',
  STAKEHOLDERS_BULK_MODIFY = 'stakeholders-bulk-modify',
  STAKEHOLDERS_BULK_IMPORT = 'stakeholders-bulk-import',
  STAKEHOLDERS_DOWNLOAD_INFO = 'stakeholders-download-info',
  STAKEHOLDERS_DOWNLOAD_TEMPLATE = 'stakeholders-download-template',
  STAKEHOLDERS_EXPORT = 'stakeholders-export',

  //STRIPE
  GET_RETRIEVE_PAYMENT_INTENT = 'get-retrieve-payment-intent',
  GET_CLIENT_SECRET = 'get-client-secret',
  GET_PAYMENT_DATA = 'get-payment-data',
  GET_PAYMENT_SESSION_ID = 'get-payment-session-id',
  SUBSCRIPTION_PAYMENT = 'subscription-payment',

  //CRUD USER
  GET_USER = 'get-user',
  PATCH_USER = 'patch-user',
  DELETE_USER = 'delete-user',

  //USER ACTIONS
  GET_LOGOUT = 'get-logout',

  //CHECK
  PASSWORD_RECOVERY_ID_CHECK = 'password-recovery-id-check',
  VERIFICATION_ID_CHECK = 'verification-id-check',
  AUTH_CHECK = 'auth-check',

  //VERIFY
  REGISTER_VERIFY = 'register-verify',

  //COUNTRY
  GET_COUNTRY = 'get-country',
  GET_CITY = 'get-city',
  GET_STATE = 'get-state',

  //CURRENCY
  GET_CURRENCY = 'get-currency',

  GET_PLANS = 'get-plans',

  //CRUD SHARE PLAN
  GET_SHARE_PLANS = 'get-share-plans',
  GET_ALL_SHARE_PLANS = 'get-all-share-plans',
  GET_SHARE_PLAN = 'get-plan',
  CREATE_SHARE_PLAN = 'create-share-plan',
  PATCH_SHARE_PLAN = 'patch-share-plan',
  TERMINATE_SHARE_PLAN = 'terminate-share-plan',
  DELETE_SHARE_PLAN = 'delete-share-plan',
  SHARE_PLAN_NAME_CHECK = 'share-plan-name-check',

  EXPORT_POOLS_PLANS = 'export-pools-plans',

  //CRUD EVENT
  GET_EVENTS = 'get-events',
  GET_EVENT = 'get-event',
  BULK_GRANT_DOWNLOAD_TEMPLATE = 'bulk-grant-download-template',
  GRANT_BULK_IMPORT = 'grant-bulk-import',
  CREATE_EVENT = 'create-event',
  PATCH_EVENT = 'patch-event',
  DELETE_EVENT = 'delete-event',
  EXPORT_EVENTS = 'export-events',

  //PLAN ACTION
  CHANGE_PLAN = 'change-plan',

  //CRUD COMPANY
  GET_COMPANY = 'get-company',
  GET_COMPANIES = 'get-companies',
  GET_SELECTED_COMPANY = 'get-selected-company',
  GET_COMPANY_DETAILS = 'get-company-details',
  CREATE_COMPANY = 'create-company',
  PATCH_COMPANY = 'patch-company',
  PATCH_SELECTED_COMPANY = 'patch-selected-company',
  DELETE_COMPANY = 'delete-company',
  GET_EVENT_TRANSACTIONS = 'get-event-transactions',

  //COMPANY ACTIONS
  REACTIVATE_COMPANY = 'reactivate-company',
  DEACTIVATE_COMPANY = 'deactivate-company',
  SELECT_COMPANY = 'select-company',
  GET_ISSUED_SHARES = 'get-issued-shares',
}
