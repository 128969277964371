import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { CreateEventDto } from '../../types/events.types';
import { useAppMutation } from '../useAppMutation';

export const useUpdate = () => {
  const { mutate: update, isPending } = useAppMutation([QueryKey.PATCH_EVENT], {
    mutationFn: ({
      companyId,
      data,
      eventId,
    }: {
      data: CreateEventDto;
      companyId: string;
      eventId: string;
    }) =>
      ApiService.patch(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'events', eventId] },
        {
          body: data,
        },
      ),
  });
  return { update, isPending };
};
