import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { CompanyStakeholderDto, HumanStakeholderDto } from '../../types/stakeholderTypes';
import { useAppMutation } from '../useAppMutation';
import { useLocale } from '../useLocale';

export const useCreate = () => {
  const { locale } = useLocale();

  const { mutate: createHuman, isPending: isPendingHuman } = useAppMutation(
    [QueryKey.CREATE_HUMAN_STAKEHOLDER],
    {
      mutationFn: ({ companyId, data }: { data: HumanStakeholderDto; companyId: string }) =>
        ApiService.post(
          { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'stakeholders', 'human'] },
          { body: data, locale },
        ),
    },
  );

  const { mutate: createCompany, isPending: isPendingCompany } = useAppMutation(
    [QueryKey.CREATE_COMPANY_STAKEHOLDER],
    {
      mutationFn: ({ companyId, data }: { data: CompanyStakeholderDto; companyId: string }) =>
        ApiService.post(
          { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'stakeholders', 'company'] },
          { body: data, locale },
        ),
    },
  );

  return {
    createHuman,
    createCompany,
    isPendingCompany,
    isPendingHuman,
  };
};
