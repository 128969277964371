import { FC } from 'react';

import { useFormat } from '../../../../../hooks';
import { eventFormType, EventTypeBackend } from '../../../../../types/events.types';
import { BasicTable } from './BasicTable';
import { Td } from './TableTd';
import { TableBodyProps } from './types';

export const NewPool: FC<TableBodyProps> = (props) => {
  const { format } = useFormat();
  if (props.type !== EventTypeBackend.NEW_POOL) return <></>;
  const { pool, type } = props;

  return (
    <BasicTable eventType={EventTypeBackend.NEW_POOL}>
      <tr>
        <Td value={format(pool?.date, 'dd/MM/yyyy')} />
        <Td value={eventFormType[type]} />
        <Td value={pool?.name} />
        <Td value={pool?.dillutedSharesCount?.toLocaleString('en-US')} />
        <Td value={pool?.shareClass?.name} />
        <Td className="w-12" value="" />
      </tr>
    </BasicTable>
  );
};
