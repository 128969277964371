import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../config';
import { QueryKey } from '../constants';
import { ApiService } from '../services';
import { PoolPlansTab, SharePlanUnionType } from '../types/pool-plans.types';

export const useSharePlans = ({
  companyId,
  numberOfFetchedItems = 50,
  poolId,
  selectedTab = PoolPlansTab.ACTIVE,
  enabled = true,
}: {
  numberOfFetchedItems?: number;
  companyId: string;
  poolId: string;
  selectedTab?: PoolPlansTab;
  enabled?: boolean;
}) => {
  const {
    data: sharePlansData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<{
    sharePlans: SharePlanUnionType[];
    totalPages: number;
  }>({
    queryKey: [QueryKey.GET_SHARE_PLANS, companyId, poolId, selectedTab],
    queryFn: ({ pageParam = 0 }) => {
      return ApiService.get(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'pools', poolId, 'share-plans'],
        },
        {
          queryParams: {
            take: numberOfFetchedItems,
            skip: pageParam,
            isTerminated: selectedTab === PoolPlansTab.TERMINATED,
          },
        },
      );
    },
    enabled: !!poolId && !!companyId && enabled,
    initialPageParam: 0,
    refetchOnReconnect: true,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.totalPages > allPages.length ? allPages.length * numberOfFetchedItems : undefined,
  });
  const sharePlans = useMemo(
    () =>
      sharePlansData?.pages?.reduce<SharePlanUnionType[]>((acc, page) => {
        return [...acc, ...page.sharePlans];
      }, []),
    [sharePlansData?.pages],
  );

  return {
    sharePlans: sharePlans || [],
    hasNextPage,
    fetchNextPage,
    sharePlansData,
    isEmpty: sharePlans?.length === 0,
    numberOfFetchedItems,
  };
};
