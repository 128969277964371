import React, { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useWatch } from 'react-hook-form';

import {
  CircleCheckIcon,
  CloseIcon,
  CloudUploadIcon,
  InfoCircle,
  UploadedFileIcon,
  UploadIcon,
} from '../../../../assets/icons';
import LoaderIcon from '../../../../assets/icons/LoaderIcon';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { BackendRoute } from '../../../../config';
import { QueryKey } from '../../../../constants';
import { HttpException } from '../../../../exceptions';
import { useAppMutation } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ApiService } from '../../../../services';
import { ChildrenFormProps } from '../type';
import { FormSchema, fromSchemaStepFour } from '../Validation';

export type StepFourProps = ChildrenFormProps;

export const StepFour: FC<StepFourProps> = ({
  setFormData,
  control,
  handleCloseModal,
  nextFormStep,
  prevFormStep,
  lockMode,
  companyId,
  setError,
  errors: { stepFour: errors },
}) => {
  const { stepFour } = useWatch<FormSchema>({ control });
  const { mutate, isPending } = useAppMutation<string, HttpException, File>(
    [QueryKey.SET_SHARE_CLASS_DOC, companyId],
    {
      mutationFn: (file: File) => {
        const { callback } = ApiService.uploadFile<string>();

        return callback(
          { endpoint: BackendRoute.SHARE_CLASSES, routePath: ['file'] },
          ['file', file],
          {
            onUploadProgress: (n) => {
              setFormData('stepFour.progress', n);
            },
          },
        );
      },
      onSuccess: (data) => {
        setFormData('stepFour.docLink', data);
      },
    },
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const doc = acceptedFiles[0];

      const { success, error } = fromSchemaStepFour.safeParse({ doc, docLink: '', progress: 0 });
      if (!success) {
        setFormData('stepFour', undefined);
        setError('stepFour.doc', { type: 'validate', message: error?.errors[0].message });

        return;
      }

      setFormData('stepFour.doc', doc, { shouldValidate: true });
      mutate(doc);
    },
    [mutate, setError, setFormData],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noClick: false,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg'],
    },
  });

  const handleDeleteFile = () => {
    setFormData('stepFour', undefined);
  };
  const isValidFileState = !!stepFour?.docLink && !errors?.doc;
  const file = stepFour?.doc;
  return (
    <form className="flex h-full flex-col gap-4 overflow-hidden">
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <span className="text-xs font-[450] text-gray-500">Board Resolution</span>
        <input {...getInputProps()} accept=".xlsx" className="hidden" type="file" />
        <label
          {...getRootProps()}
          className="relative flex w-full cursor-pointer flex-col items-center gap-6 rounded-lg border border-dashed border-gray-200 py-6"
        >
          {!errors?.doc ? (
            isPending ? (
              <LoaderIcon className="size-14" />
            ) : isValidFileState ? (
              <CircleCheckIcon />
            ) : (
              <CloudUploadIcon />
            )
          ) : (
            <InfoCircle className="size-14" thinIconVariant />
          )}

          <div className="flex flex-col items-center gap-4 text-center">
            <div className="flex justify-center">
              {isPending ? (
                <span className="text-sm font-[550] text-brand-700">
                  {(stepFour?.progress || 0)?.toFixed(0)}% uploaded ...
                </span>
              ) : errors?.doc ? (
                <span className="text-sm font-[550] text-fireside-600">
                  {errors?.doc.message?.toString()}
                </span>
              ) : isValidFileState ? (
                <span className="text-sm font-[550] text-forest-500">
                  <AppFormattedMessage id={StringKey.FILE_PROCESSED_SUCCESSFULLY} />
                </span>
              ) : (
                <p className="text-sm font-medium text-gray-700">
                  <span className="underline">
                    <AppFormattedMessage id={StringKey.CLICK_TO_UPLOAD} />{' '}
                  </span>
                  <AppFormattedMessage id={StringKey.OR_DRAG_AND_DROP} />
                </p>
              )}
            </div>

            <div className="flex flex-col gap-1 text-label-sm font-[450] text-gray-500">
              {(isPending || isValidFileState) && (
                <>
                  <span>{file?.name}</span>
                  <span>
                    {((file?.size || 0) / 1024).toFixed(3)}
                    <AppFormattedMessage id={StringKey.KILOBYTE} />
                  </span>
                </>
              )}

              {!isValidFileState && !isPending && (
                <>
                  <span>
                    <AppFormattedMessage
                      id={StringKey.SUPPORTED_FORMATS}
                      values={{
                        formats: 'pdf, doc/docs, xls/xlsx, csv, png/jpeg',
                      }}
                    />
                  </span>
                  <span>
                    <AppFormattedMessage id={StringKey.MAXIMUM_FILE_SIZE} values={{ size: 10 }} />
                  </span>
                </>
              )}
            </div>
            {errors?.doc && (
              <span className="flex h-9 w-fit items-center gap-1 rounded border border-gray-100 bg-gray-25 px-3 py-[6px] text-sm font-[450] text-gray-700">
                <UploadIcon />
                <AppFormattedMessage id={StringKey.UPLOAD_AGAIN} />
              </span>
            )}
          </div>
        </label>
        {file && !isPending && (
          <div className="flex justify-between rounded border border-brand-50 bg-brand-25 p-3">
            <div className="flex items-center gap-2">
              <UploadedFileIcon />
              <span className="text-xs font-[450] text-gray-700">{file?.name}</span>
            </div>
            <Button
              className="w-fit p-1 hover:bg-gray-50"
              onClick={handleDeleteFile}
              styleType="NONE"
              type="button"
            >
              <CloseIcon className="size-2" />
            </Button>
          </div>
        )}
      </div>
      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          {!lockMode && (
            <Button
              className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={prevFormStep}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.BACK} />
            </Button>
          )}
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={isPending}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : isValidFileState ? (
              <AppFormattedMessage id={StringKey.NEXT} />
            ) : (
              <AppFormattedMessage id={StringKey.SKIP} />
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};
