import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type TimeBasedStandardIconProps = IconProps;

const TimeBasedStandardIcon: FC<TimeBasedStandardIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="22"
    viewBox="0 0 22 22"
    width="22"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5V11L15 13M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default TimeBasedStandardIcon;
