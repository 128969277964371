import { useQueryClient } from '@tanstack/react-query';
import React, { FC, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Loader from '../../components/Loader';
import { QueryKey } from '../../constants';
import { Company } from '../../hooks';
import { CompanyFilter } from '../../types/companyTypes';
import { PageRoute } from '../../types/pageTypes';
import { StakeholderRole } from '../../types/stakeholderTypes';
const Home: FC = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { companies, isLoading: isLoadingCompanies } = Company.useCompanies({
    filter: CompanyFilter.ACTIVE,
  });
  const { setSelected, isPending: isPendingCompanySelect } = Company.useSetSelected();

  const invalidateQuery = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_SELECTED_COMPANY],
    });
    queryClient.invalidateQueries({
      queryKey: [QueryKey.GET_COMPANIES],
    });
  }, [queryClient]);

  useEffect(() => {
    if (!companies || isLoadingCompanies) return;

    const hasOnlyOneCompany = companies.length === 1;
    if (!hasOnlyOneCompany) return navigate('/my-companies');

    setSelected(companies[0].id, {
      onSuccess: () => {
        invalidateQuery();
        navigate(
          companies[0].roleInCompany === StakeholderRole.STAKEHOLDER
            ? PageRoute.MY_HOLDINGS
            : PageRoute.DASHBOARD,
        );
      },
    });
  }, [companies, companies.length, invalidateQuery, isLoadingCompanies, navigate, setSelected]);

  if (isPendingCompanySelect || isLoadingCompanies) return <Loader />;

  return <></>;
};
export default Home;
