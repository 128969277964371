import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { ChevronDownIcon } from '../../../../assets/icons';
import Button from '../../../../components/Button';
import {
  ComboboxButton,
  ComboboxOptions,
  FormCombobox,
  FormComboboxInput,
  FormComboboxOption,
} from '../../../../components/Combobox';
import { ShareClass as ShareClassHook } from '../../../../hooks';
import { ShareClass } from '../../../../types/share-classes.types';
import { FormSchema } from '../validation';

export type ShareClassValue = {
  name: string;
  id: string;
  conversionRatio: string;
};

export type ShareClassesItemsListProps = {
  data: ShareClass[];
  control: Control<FormSchema>;
  isLoading: boolean;
  fieldName: string;
};

const ShareClassesItemsList: FC<ShareClassesItemsListProps> = ({
  data,
  control,
  isLoading,
  fieldName,
}) => {
  const navigate = useNavigate();
  if (!data || isLoading) return <span>Loading ...</span>;
  return (
    <>
      {data.length > 0 ? (
        <>
          {data.map(({ name, id, conversionRatio }, i) => (
            <FormComboboxOption
              control={control}
              key={`${name}_${i}`}
              name={fieldName as keyof FormSchema}
              value={{ id, name, conversionRatio } as unknown as FormSchema[keyof FormSchema]}
            >
              <span className="text-sm text-gray-700">{name}</span>
            </FormComboboxOption>
          ))}
        </>
      ) : (
        <span className="ml-2 px-2 py-[6px] text-sm font-normal text-gray-700">
          No results found
        </span>
      )}

      <Button
        className="h-[37px] w-full justify-start rounded bg-brand-25 px-[6px] text-start text-sm font-[450] text-brand-700"
        onClick={() => navigate('/share-classes?openAddModal=true')}
        styleType="NONE"
      >
        + Add a new Share class
      </Button>
    </>
  );
};

export type ShareClassComboboxProps = {
  control: Control<FormSchema>;
  companyId: string;
  fieldName: string;
  commonStockIsAllowed?: boolean;
  shareClassFromId?: string;
};

export const ShareClassCombobox: FC<ShareClassComboboxProps> = ({
  control,
  companyId,
  fieldName,
  commonStockIsAllowed = true,
  shareClassFromId,
}) => {
  const { shareClasses, isLoading } = ShareClassHook.useShareClasses({ companyId, filter: 'all' });

  if (!shareClasses) return;

  const filteredShareClasses = !commonStockIsAllowed
    ? shareClasses.filter(({ name }) => name !== 'Common Stock')
    : shareClassFromId
      ? shareClasses.filter(({ id }) => id !== shareClassFromId)
      : shareClasses;

  return (
    <FormCombobox
      className="relative w-[224px]"
      control={control}
      name={fieldName as keyof FormSchema}
    >
      <div className="relative">
        <FormComboboxInput
          className={'bg-gray-900'}
          control={control}
          customValue={(value) => (value as ShareClassValue)?.name}
          icon={<ChevronDownIcon className="mt-2" />}
          name={fieldName as keyof FormSchema}
          placeholder="Share Class"
          readOnly
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-[224px]">
        <ShareClassesItemsList
          control={control}
          data={filteredShareClasses || []}
          fieldName={fieldName}
          isLoading={isLoading}
        />
      </ComboboxOptions>
    </FormCombobox>
  );
};
