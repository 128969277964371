export const replaceIdWithPath = (path: string) => {
  return ('/' + path.split('/')[1]) as PageRoute;
};

export enum PageRoute {
  HOME = '/',
  PROFILE = '/profile',
  MY_COMPANIES = '/my-companies',
  COMPANY_SETUP = '/company-setup',
  COMPANY_SETTINGS = '/company-settings',
  DASHBOARD = '/dashboard',
  STAKEHOLDERS = '/stakeholders',
  SHARE_CLASSES = '/share-classes',
  POOL_PLANS = '/pool-plans',
  POOL_PLANS_POOL = '/pool-plans/:poolId',
  POOL_PLANS_PLAN = '/pool-plans/:poolId/:planId',
  EVENTS = '/events',
  CAP_TABLE = '/cap-table',
  MY_HOLDINGS = '/holdings',
  SETTINGS = '/settings',
  ACCEPT_INVITATION = '/accept-invitation/:id',
  LOGIN = '/login',
  REGISTER = '/register',
  REGISTER_VERIFY = '/register/verify/:id',
  FORGOT_PASSWORD = '/forgot-password',
  PASSWORD_RECOVERY = '/password-recovery/:id',
  BILLING = '/billing',
  TEST = '/test',
  ERROR = '*',
}
