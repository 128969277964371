import React, { FC } from 'react';

import { ShareClass } from '../../../../hooks';
import { EditShareClassStepVariant } from '../type';
import { EditShareClassForm } from './EditShareClass.form';

type ModalProps = {
  isOpenModal: boolean;
  handleClose: () => void;
  companyId: string;
  invalidateQuery: () => void;
  onEditClick: (props: { id: string; step: EditShareClassStepVariant }) => void;
  step: EditShareClassStepVariant;
  shareClassId: string;
};

export const EditShareClassModal: FC<ModalProps> = (props) => {
  const { companyId, shareClassId } = props;

  const { shareClass, isLoading } = ShareClass.useShareClass({ companyId, shareClassId });

  if (!shareClass || isLoading) return <></>;

  return <EditShareClassForm data={shareClass} {...props} />;
};
