import React, { type FC, type ReactNode } from 'react';

import { AlertDialog, AlertDialogProps } from './AlertDialog';
import { AlertDialogContent, AlertDialogContentProps } from './AlertDialogContent';

export type ModalVariantProps = { className?: string } & AlertDialogProps;

export type ModalWrapperProps = {
  control?: Except<AlertDialogProps, 'children'>;
  children: ReactNode;
  className?: string;
} & AlertDialogContentProps;

export const AlertDialogWrapper: FC<ModalWrapperProps> = ({ children, control, ...props }) => (
  <AlertDialog {...control}>
    <AlertDialogContent {...props}>{children}</AlertDialogContent>
  </AlertDialog>
);
