import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CapTableIconProps = IconProps;

const CapTableIcon: FC<CapTableIconProps> = ({ iconColor = '#98A2B3', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="19"
    viewBox="0 0 20 19"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0006 13.1666C10.0006 15.4678 11.866 17.3333 14.1672 17.3333C16.4684 17.3333 18.3339 15.4678 18.3339 13.1666C18.3339 10.8655 16.4684 8.99997 14.1672 8.99997C11.866 8.99997 10.0006 10.8655 10.0006 13.1666ZM10.0006 13.1666C10.0006 12.2284 10.3106 11.3627 10.8339 10.6662V3.16664M10.0006 13.1666C10.0006 13.8544 10.1672 14.5033 10.4623 15.0751C9.76029 15.6681 8.13877 16.0833 6.25057 16.0833C3.71926 16.0833 1.66724 15.3371 1.66724 14.4166V3.16664M10.8339 3.16664C10.8339 4.08711 8.78187 4.8333 6.25057 4.8333C3.71926 4.8333 1.66724 4.08711 1.66724 3.16664M10.8339 3.16664C10.8339 2.24616 8.78187 1.49997 6.25057 1.49997C3.71926 1.49997 1.66724 2.24616 1.66724 3.16664M1.66724 10.6666C1.66724 11.5871 3.71926 12.3333 6.25057 12.3333C8.07474 12.3333 9.65 11.9458 10.3878 11.3848M10.8339 6.91664C10.8339 7.83711 8.78187 8.5833 6.25057 8.5833C3.71926 8.5833 1.66724 7.83711 1.66724 6.91664"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

CapTableIcon.displayName = 'CapTableIcon';

export default CapTableIcon;
