import { FC, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '../../../../../assets/icons';
import { PaginationController } from '../../../../../components/PaginationController';
import {
  Select,
  SelectButton,
  SelectOption,
  SelectOptions,
} from '../../../../../components/Select';
import { AmountOfFetchedItems, numberOfFetchedItemsSelectVariant } from './types';

export type PaginationItemProps = {
  handleFetchAmountChange: (items: AmountOfFetchedItems) => void;
  itemsToFetch: AmountOfFetchedItems;
  currentPage: number;
  handleChangeCurrentPage: (page: number) => void;
  totalPages: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  itemsCount: number;
};

export const PaginationItem: FC<PaginationItemProps> = ({
  handleFetchAmountChange,
  itemsToFetch,
  currentPage,
  handleChangeCurrentPage,
  totalPages,
  setCurrentPage,
  itemsCount,
}) => {
  useEffect(() => {
    if (totalPages > 0 && currentPage > totalPages) {
      setCurrentPage(1);
    }
  }, [itemsToFetch, totalPages]);

  if (totalPages <= 1 && itemsCount <= 3) return <></>;

  return (
    <div className="flex w-full items-center justify-between pb-3 pt-4">
      <Select
        onChange={(fetchItems: AmountOfFetchedItems) => handleFetchAmountChange(fetchItems)}
        value={itemsToFetch}
      >
        {({ open }) => (
          <div className="relative rounded border-gray-200">
            <SelectButton className="flex items-center gap-4 bg-gray-25 py-[10px] pl-[12px] pr-[6px]">
              <span className="text-m font-[450] text-gray-700">{itemsToFetch}</span>
              <ChevronDownIcon
                className={twMerge(
                  'h-[13px] w-[13px] transition-transform duration-200',
                  open && 'rotate-180',
                )}
              />
            </SelectButton>
            <SelectOptions
              className={twMerge(
                'absolute left-0 right-0 top-[-6px] mt-1 max-h-60 w-[58px] -translate-y-full transform overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
              )}
            >
              {numberOfFetchedItemsSelectVariant.map((select) => (
                <SelectOption key={select} value={select}>
                  <span className="block text-m font-normal text-gray-700">{select}</span>
                </SelectOption>
              ))}
            </SelectOptions>
          </div>
        )}
      </Select>
      {totalPages > 1 && (
        <PaginationController
          className="shrink-0"
          currentPage={currentPage}
          onClick={handleChangeCurrentPage}
          totalPages={totalPages}
        />
      )}
    </div>
  );
};
