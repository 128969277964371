import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CloseCircleIconProps = IconProps;

const CloseCircleIcon: FC<CloseCircleIconProps> = ({
  iconColor = '#D92D20',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);

CloseCircleIcon.displayName = 'CloseCircleIcon';

export default CloseCircleIcon;
