import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { useAppQuery } from '../../hooks';
import { ApiService } from '../../services';
import { inviteStakeholderStore } from '../../storage/invitedStakeholder';
import { Invitation } from '../../types/invitation';

const AcceptInvitation: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: invitation,
    isPending,
    error,
  } = useAppQuery({
    queryKey: [QueryKey.GET_INVITATION, id],
    queryFn: () =>
      ApiService.get<Invitation>({ endpoint: BackendRoute.INVITATIONS, routePath: [id || ''] }),
    enabled: !!id,
    defaultErrorHandling: false,
    retry: false,
  });

  useEffect(() => {
    if (isPending) return;

    if (!id || !invitation || error) {
      navigate('/error-page');
      return;
    }

    inviteStakeholderStore.set({
      id,
      email: invitation.email,
      isRegistered: invitation.isUserRegistered,
      isAccepted: false,
    });

    navigate('/');
  }, [error, id, invitation, isPending, navigate]);

  return <></>;
};

export default AcceptInvitation;
