import { Content, Portal } from '@radix-ui/react-popover';
import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

export const PopoverContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content>
>(({ align = 'end', className, sideOffset = 5, ...props }, ref) => (
  <Portal>
    <Content
      align={align}
      className={twMerge(
        '-right-4 z-50 flex w-72 items-center text-nowrap rounded-md border bg-white p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      ref={ref}
      side="bottom"
      sideOffset={sideOffset}
      {...props}
    />
  </Portal>
));
PopoverContent.displayName = Content.displayName;
