import React, { FC, useCallback, useEffect } from 'react';
import { Control, useFieldArray, useWatch } from 'react-hook-form';

import Button from '../../../../components/Button';
import { FormInput } from '../../../../components/Input';
import { EventFormType } from '../../../../types/events.types';
import { FormDropDown, ShareClassCombobox, StakeholderCombobox } from '../Components';
import { FormSchema } from '../validation';

export type ShareIssuanceOptionalStepProps = {
  control: Control<FormSchema>;
  companyId: string;
};

export const ShareIssuanceOptionalStep: FC<ShareIssuanceOptionalStepProps> = ({
  control,
  companyId,
}) => {
  const { initialStep, optionalStep } = useWatch<FormSchema>({ control });

  const {
    fields: issuanceItemsFields,
    append: issuanceItemsAppend,
    remove: issuanceItemsRemove,
  } = useFieldArray({
    control,
    name: 'optionalStep.share-issuance.issuanceItems',
  });

  const handleRemoveIssuanceItems = useCallback(
    (index: number) => {
      issuanceItemsRemove(index);
    },
    [issuanceItemsRemove],
  );

  const handleAppendIssuanceItems = useCallback(() => {
    issuanceItemsAppend({
      shareClass: { id: '', name: '' },
      stakeholder: { id: '', fullName: '' },
      shares: 0,
    });
  }, [issuanceItemsAppend]);

  const getFullTitle = useCallback(
    (index: number) => {
      const truncateName = (name: string) => {
        return name.length > 25 ? `${name.slice(0, 25)}...` : name;
      };

      const stakeholderName =
        optionalStep?.['share-issuance']?.issuanceItems?.[index]?.stakeholder?.fullName;

      if (stakeholderName) {
        return `Issuance - ${truncateName(stakeholderName)}`;
      }

      return 'Issuance';
    },
    [optionalStep],
  );

  useEffect(() => {
    if (initialStep?.type === EventFormType.SHARE_ISSUANCE && issuanceItemsFields.length === 0) {
      handleAppendIssuanceItems();
    }
  }, [initialStep?.type]);

  return (
    <>
      {issuanceItemsFields.map((field, index) => (
        <FormDropDown
          handleRemove={() => handleRemoveIssuanceItems(index)}
          key={index}
          showRemoveButton={issuanceItemsFields.length > 1}
          title={getFullTitle(index)}
        >
          <div className="flex w-full flex-col gap-3" key={field.id}>
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-[450] text-gray-700">Stakeholder</span>
              <StakeholderCombobox
                companyId={companyId}
                control={control}
                name={`optionalStep.share-issuance.issuanceItems.${index}.stakeholder`}
              />
            </div>
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-[450] text-gray-700">Share Class</span>
              <ShareClassCombobox
                companyId={companyId}
                control={control}
                fieldName={`optionalStep.share-issuance.issuanceItems.${index}.shareClass`}
              />
            </div>
            <div className="flex w-full items-center justify-between">
              <span className="text-sm font-[450] text-gray-700">No of Shares</span>
              <FormInput
                control={control}
                name={`optionalStep.share-issuance.issuanceItems.${index}.shares`}
                numberOnly
                placeholder="Value"
                shouldFormatNumber
                wrapperClassName="w-[224px]"
              />
            </div>
          </div>
        </FormDropDown>
      ))}
      <Button
        className="w-fit px-4 py-2 text-sm font-[450] text-brand-700 underline disabled:border-transparent disabled:bg-transparent"
        onClick={handleAppendIssuanceItems}
        styleType="NONE"
      >
        + Add Another Issuance
      </Button>
    </>
  );
};
