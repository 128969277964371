import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type EventClassConversionProps = IconProps;

export const EventClassConversion: FC<EventClassConversionProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6666 14.1667H3.33331M3.33331 14.1667L6.66665 10.8333M3.33331 14.1667L6.66665 17.5M3.33331 5.83333H16.6666M16.6666 5.83333L13.3333 2.5M16.6666 5.83333L13.3333 9.16667"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);
