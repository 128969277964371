import { z } from 'zod';

import { EventFormType } from '../../../types/events.types';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const defaultSharePrice = 1.000000000000001;

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg',
];

export const ACCEPTED_GRANT_FILE_MIME_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const fileSchemaMessage = 'File size is too large';

const grantFileSchema = z
  .custom<File>()
  .refine(
    (files) => ACCEPTED_GRANT_FILE_MIME_TYPES.includes(files?.type),
    'File format is not supported',
  )
  .refine((files) => {
    return files?.size <= MAX_FILE_SIZE;
  }, fileSchemaMessage);

export const initialStep = z.object({
  type: z.nativeEnum(EventFormType),
  id: z.string().optional(),
});

const grant = z.object({
  enabled: z.boolean(),
  grantItems: z
    .array(
      z
        .object({
          id: z.string().optional(),
          grantDate: z.coerce.date(),
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          sharePlan: z.object({
            name: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          shares: z.coerce.number().min(1, { message: 'Required' }),
        })
        .refine(
          ({ grantDate }) => {
            return grantDate.getTime() !== new Date(+0).getTime();
          },
          {
            message: 'Invalid grant date',
            path: ['grantDate'],
          },
        ),
    )
    .min(1, { message: 'Required' }),
});

const bulkGrant = z.object({
  enabled: z.boolean(),
  file: grantFileSchema,
});

const fundraisingRoundOne = z
  .object({
    enabled: z.boolean(),
    isOpen: z.boolean(),
    event: z
      .object({
        name: z
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .max(50, { message: 'Character limit is 50' }),
        id: z.string().trim().min(1, { message: 'Required' }),
        sharePrice: z.coerce.number().min(0.01, { message: 'Required' }).default(defaultSharePrice),
        issuedSharesOnStart: z.coerce.number().default(0),
      })
      .refine(
        ({ sharePrice }) => {
          return sharePrice !== defaultSharePrice;
        },
        {
          message: '',
          path: ['sharePrice'],
        },
      ),
    date: z.coerce.date(),
  })
  .refine(
    ({ date }) => {
      return date.getTime() !== new Date(+0).getTime();
    },
    {
      message: 'Invalid date',
      path: ['date'],
    },
  );

const fundraisingRoundTwo = z
  .object({
    closeDate: z.coerce.date().optional(),
    investmentItems: z
      .array(
        z
          .object({
            id: z.string().optional(),
            sharePrice: z.coerce.number().min(0.01, { message: 'Required' }).default(1),
            stakeholder: z.object({
              fullName: z.string().trim().min(1, { message: 'Required' }),
              id: z.string().trim().min(1, { message: 'Required' }),
            }),
            investment: z.coerce.number().min(1, { message: 'Required' }),
            shareClass: z.object({
              name: z.string().trim().min(1, { message: 'Required' }),
              id: z.string().trim().min(1, { message: 'Required' }),
            }),
            investmentDate: z.coerce.date(),
          })
          .refine(
            ({ sharePrice, investment }) => {
              return investment % sharePrice === 0;
            },
            {
              message: '',
              path: ['investment'],
            },
          )
          .refine(
            ({ investmentDate }) => {
              return investmentDate.getTime() !== new Date(+0).getTime();
            },
            {
              message: 'Invalid investment date',
              path: ['investmentDate'],
            },
          ),
      )
      .min(1, { message: 'Required' }),
  })
  .superRefine((data, ctx) => {
    const { closeDate, investmentItems } = data;

    if (closeDate) {
      investmentItems.forEach((item, index) => {
        if (item.investmentDate > closeDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Investment date must be on or before the close date',
            path: ['investmentItems', index, 'investmentDate'],
          });
        }
      });
    }
  });

const issueSharesOne = z.object({
  enabled: z.boolean(),
  event: z
    .object({
      name: z
        .string()
        .trim()
        .min(1, { message: 'Required' })
        .max(50, { message: 'Character limit is 50' }),
      id: z.string().trim().min(1, { message: 'Required' }),
      sharePrice: z.coerce.number().min(0.01, { message: 'Required' }).default(defaultSharePrice),
      issuedSharesOnStart: z.coerce.number().default(0),
    })
    .refine(
      ({ sharePrice }) => {
        return sharePrice !== defaultSharePrice;
      },
      {
        message: '',
        path: ['sharePrice'],
      },
    ),
  date: z.coerce.date(),
});

const issueSharesTwo = z.object({
  issuanceItems: z
    .array(
      z.object({
        id: z.string().optional(),
        stakeholder: z.object({
          fullName: z.string().trim().min(1, { message: 'Required' }),
          id: z.string().trim().min(1, { message: 'Required' }),
        }),
        shareClass: z.object({
          name: z.string().trim().min(1, { message: 'Required' }),
          id: z.string().trim().min(1, { message: 'Required' }),
        }),
        shares: z.coerce.number().min(1, { message: 'Required' }),
      }),
    )
    .min(1, { message: 'Required' }),
});

const buyBack = z.object({
  enabled: z.boolean(),
  buyBackItems: z
    .array(
      z
        .object({
          id: z.string().optional(),
          date: z.coerce.date(),
          balance: z.coerce.number(),
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          shareClass: z.object({
            name: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          shares: z.coerce.number().min(1, { message: 'Required' }),
          initialShares: z.coerce.number().optional(),
        })
        .refine(
          ({ shares, balance }) => {
            return shares <= balance;
          },
          {
            message: 'Shares must be less than or equal to balance',
            path: ['shares'],
          },
        )
        .refine(
          ({ date }) => {
            return date.getTime() !== new Date(+0).getTime();
          },
          {
            message: 'Invalid date',
            path: ['date'],
          },
        ),
    )
    .min(1, { message: 'Required' }),
});

const secondaries = z.object({
  enabled: z.boolean(),
  secondariesItems: z
    .array(
      z
        .object({
          id: z.string().optional(),
          date: z.coerce.date(),
          balance: z.coerce.number(),
          stakeholderFrom: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          stakeholderTo: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          shareClass: z.object({
            name: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          shares: z.coerce.number().min(1, { message: 'Required' }),
          shareValue: z.coerce.number().min(1, { message: 'Required' }),
          initialShares: z.coerce.number().optional(),
        })
        .refine(
          ({ shares, balance }) => {
            return shares <= balance;
          },
          {
            message: 'Shares must be less than or equal to balance',
            path: ['shares'],
          },
        )
        .refine(
          ({ date }) => {
            return date.getTime() !== new Date(+0).getTime();
          },
          {
            message: 'Invalid date',
            path: ['date'],
          },
        )
        .refine(({ stakeholderFrom, stakeholderTo }) => stakeholderFrom.id !== stakeholderTo.id, {
          message: 'stakeholderFrom and stakeholderTo must be different',
          path: ['stakeholderTo', 'id'],
        }),
    )
    .min(1, { message: 'Required' }),
});

const classConversion = z.object({
  enabled: z.boolean(),
  classConversionItems: z
    .array(
      z
        .object({
          id: z.string().optional(),
          date: z.coerce.date(),
          balance: z.coerce.number(),
          stakeholder: z.object({
            fullName: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          shareClassFrom: z.object({
            name: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
            conversionRatio: z.number(),
          }),
          shareClassTo: z.object({
            name: z.string().trim().min(1, { message: 'Required' }),
            id: z.string().trim().min(1, { message: 'Required' }),
          }),
          shares: z.coerce.number().min(1, { message: 'Required' }),
          initialShares: z.coerce.number().optional(),
        })
        .refine(
          ({ shares, balance }) => {
            return shares <= balance;
          },
          {
            message: 'Shares must be less than or equal to balance',
            path: ['shares'],
          },
        )
        .refine(
          ({ date }) => {
            return date.getTime() !== new Date(+0).getTime();
          },
          {
            message: 'Invalid date',
            path: ['date'],
          },
        )
        .refine(({ shareClassFrom, shareClassTo }) => shareClassFrom.id !== shareClassTo.id, {
          message: 'shareClassFrom and shareClassTo must be different',
          path: ['shareClassTo', 'id'],
        }),
    )
    .min(1, { message: 'Required' }),
});

const valuation = z.object({
  enabled: z.boolean(),
  name: z
    .string()
    .trim()
    .min(1, { message: 'Required' })
    .max(50, { message: 'Character limit is 50' }),
  date: z.coerce.date(),
  sharePrice: z.coerce.number().min(0.01, { message: 'Required' }),
  issuedSharesOnStart: z.coerce.number().optional(),
});

export const stepOne = z.object({
  eventDetails: z.object({
    grant: grant.optional(),
    'bulk-import-grant': bulkGrant.optional(),
    'fundraising-round': fundraisingRoundOne.optional(),
    'share-issuance': issueSharesOne.optional(),
    buyback: buyBack.optional(),
    secondaries: secondaries.optional(),
    conversion: classConversion.optional(),
    valuation: valuation.optional(),
  }),
});

export const optionalStep = z
  .object({
    'fundraising-round': fundraisingRoundTwo.optional(),
    'share-issuance': issueSharesTwo.optional(),
  })
  .optional();

export const stepTwo = z
  .object({
    additionalNotes: z.string().max(3000, 'Maximum 3000 characters').optional(),
    files: z
      .object({
        docLink: z.string(),
        loadProgress: z.number().max(100),
        abort: z.function(),
        id: z.string(),
        doc: z
          .custom<File>()
          .or(
            z.object({
              size: z.number(),
              type: z.string(),
              name: z.string(),
            }),
          )
          .refine(
            ({ type }) => ACCEPTED_FILE_MIME_TYPES.includes(type),
            'File format is not supported',
          )
          .refine(({ size }) => size <= MAX_FILE_SIZE, fileSchemaMessage),
      })
      .array()

      .optional(),
  })
  .optional();

export const formSchema = z.object({
  initialStep,
  optionalStep,
  stepOne,
  stepTwo,
});

export type FormSchema = z.infer<typeof formSchema>;
