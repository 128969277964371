import React, { memo, ReactElement } from 'react';
import { Control, FieldPathByValue, FieldValues, PathValue, useController } from 'react-hook-form';

import Textarea, { TextareaProps } from './Textarea';

export type FormAppTextareaProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
> = {
  control: Control<TFieldValues>;
  defaultValue?: PathValue<TFieldValues, TPath>;
  maxLength?: number;
  name: TPath;
} & Omit<TextareaProps, 'defaultValue' | 'onBlur' | 'onChange' | 'value'>;

const FormTextarea = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
>({
  control,
  defaultValue,
  maxLength,
  name,
  ...props
}: FormAppTextareaProps<TFieldValues, TPath>): ReactElement | null => {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
  });

  return (
    <Textarea
      {...props}
      {...field}
      errorMessage={fieldState.isTouched && (fieldState.error?.message ?? fieldState.error?.type)}
      maxLength={maxLength}
    />
  );
};

export default memo(FormTextarea) as typeof FormTextarea;
