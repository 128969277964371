import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import {
  Stakeholder,
  StakeholderOrder,
  StakeholderSortBy,
  StakeholderType,
} from '../../types/stakeholderTypes';
import { FilterVariant } from './useStakeholders';

export const useInfiniteStakeholders = ({
  companyId,
  numberOfFetchedItems = 50,
  enabled = true,
  filter,
  orderBy,
  search,
  searchType,
  sortBy,
}: {
  numberOfFetchedItems?: number;
  companyId: string;
  enabled?: boolean;
  search: string;
  searchType: StakeholderType[];
  filter: FilterVariant;
  orderBy: StakeholderOrder | null;
  sortBy: StakeholderSortBy | null;
}) => {
  const {
    data: stakeholdersData,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery<{
    stakeholders: Stakeholder[];
    totalPages: number;
  }>({
    queryKey: [
      QueryKey.GET_STAKEHOLDERS,
      {
        filter,
        sortBy,
        orderBy,
        search,
      },
    ],
    queryFn: ({ pageParam = 0 }) => {
      return ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'stakeholders'] },
        {
          queryParams: {
            take: numberOfFetchedItems,
            skip: pageParam,
            search,
            searchType: searchType.length > 0 ? searchType.join(',') : '',
            filter: filter === 'all' ? '' : filter,
            ...(orderBy && sortBy ? { sortBy, sortOrder: orderBy } : {}),
          },
        },
      );
    },
    enabled: !!companyId && enabled,
    initialPageParam: 0,
    refetchOnReconnect: true,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.totalPages > allPages.length ? allPages.length * numberOfFetchedItems : undefined,
  });
  const stakeholders = useMemo(
    () =>
      stakeholdersData?.pages?.reduce<Stakeholder[]>((acc, page) => {
        return [...acc, ...page.stakeholders];
      }, []),
    [stakeholdersData?.pages],
  );

  return {
    stakeholders: stakeholders || [],
    hasNextPage,
    fetchNextPage,
    stakeholdersData,
    isEmpty: stakeholders?.length === 0,
    numberOfFetchedItems,
    isLoading,
  };
};
