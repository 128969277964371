import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDelete = () => {
  const { mutate: mutateDelete, isPending } = useAppMutation([QueryKey.DELETE_COMPANY], {
    mutationFn: ({ companyId }: { companyId: string }) =>
      ApiService.delete({ endpoint: BackendRoute.COMPANIES, routePath: [companyId] }),
  });

  return { deleteCompany: mutateDelete, isPending };
};
