import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type MastercardLogoProps = Omit<IconProps, 'iconColor'>;

const MastercardLogo: FC<MastercardLogoProps> = ({ className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="12"
    viewBox="0 0 18 12"
    width="18"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.4325 1.63281H6.56421V10.3623H11.4325V1.63281Z" fill="#EA611C" />
    <path
      d="M6.87449 6.00192C6.87449 4.29604 7.65724 2.68547 8.99841 1.63717C6.58334 -0.259309 3.08962 0.155247 1.19002 2.56634C-0.709571 4.97744 -0.28956 8.4702 2.1255 10.3619C4.14442 11.9439 6.97949 11.9439 8.99841 10.3619C7.65724 9.3136 6.87449 7.70303 6.87449 6.00192Z"
      fill="#E31221"
    />
    <path
      d="M17.9999 6.00204C17.9999 9.07071 15.5085 11.5533 12.4396 11.5533C11.1939 11.5533 9.98155 11.134 9.00311 10.3668C11.4182 8.47031 11.8382 4.98233 9.93859 2.57123C9.66654 2.22338 9.35153 1.90889 9.00311 1.63729C11.4182 -0.259187 14.9119 0.160134 16.8115 2.57123C17.5799 3.54329 17.9999 4.75361 17.9999 6.00204Z"
      fill="#F49D1E"
    />
    <path
      d="M17.4655 9.43794V9.25687H17.5371V9.21875H17.351V9.25687H17.4321V9.43794H17.4655ZM17.8235 9.43794V9.22351H17.7662L17.6994 9.376L17.6326 9.22351H17.5848V9.43794H17.623V9.27593L17.6851 9.41412H17.728L17.7901 9.27593V9.43794H17.8235Z"
      fill="#F49D1E"
    />
  </svg>
);

MastercardLogo.displayName = 'MastercardLogo';

export default MastercardLogo;
