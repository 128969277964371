import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { EditIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { antiDilutionBaseTitle } from '../../../../types/share-classes.types';
import { ChildrenFormProps, EditShareClassStepVariant } from '../type';

export type EditPreviewProps = {
  onEditClick: (step: EditShareClassStepVariant) => void;
} & ChildrenFormProps;

export const EditPreview: FC<EditPreviewProps> = ({ handleCloseModal, formData, onEditClick }) => {
  const { stepOne, stepFour, stepThree, stepTwo } = formData();
  const { format } = useFormat();
  return (
    <form className="flex h-full flex-col gap-4 overflow-hidden">
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col rounded-lg bg-gray-50">
          <div className="flex h-9 w-full items-center justify-between px-4">
            <span className="text-sm font-[550] text-gray-700">General</span>
            <Button className="w-fit" onClick={() => onEditClick(1)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          </div>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem className="pt-0" title="Share Class Name" value={stepOne.name} />

            <SummaryItem title="Creation date" value={format(stepOne.creationDate, 'dd/MM/yyyy')} />

            <div className="flex flex-col gap-3 py-3">
              <SummaryItem
                className="py-0"
                title="Voting rights"
                value={
                  stepOne.votingRight?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />

              <SummaryItem
                className="py-0"
                title="Voting Multiplier"
                value={stepOne.votingRight?.value || '-'}
              />
            </div>
            <div className="flex flex-col gap-3 py-3">
              <SummaryItem
                className="py-0"
                title="Conversion Ratio"
                value={
                  stepOne.conversionRatio?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />
              <SummaryItem
                className="py-0"
                title="Voting Multiplier"
                value={stepOne.conversionRatio?.value || '-'}
              />
            </div>
            <SummaryItem
              className="pb-0"
              title="Dividend Right"
              value={
                stepOne.dividendRightEnabled ? (
                  <AppFormattedMessage id={StringKey.YES} />
                ) : (
                  <AppFormattedMessage id={StringKey.NO} />
                )
              }
            />
          </div>
        </div>
        <div className="flex h-fit flex-col rounded-lg bg-gray-50">
          <div className="flex h-9 w-full items-center justify-between px-4">
            <span
              className={twMerge(
                'text-sm font-[550] text-gray-700',
                !stepTwo?.liquidityPreferences?.enabled && 'text-gray-400',
              )}
            >
              Liquidity
            </span>
            <Button className="w-fit" onClick={() => onEditClick(2)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          </div>
          {stepTwo?.liquidityPreferences?.enabled && (
            <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
              <div className="flex flex-col gap-3 pb-3">
                <SummaryItem
                  className="py-0"
                  title="Seniority"
                  value={stepTwo?.liquidityPreferences?.seniority || '-'}
                />

                <SummaryItem
                  className="py-0"
                  title="Multiple value"
                  value={stepTwo?.liquidityPreferences?.multiple || '-'}
                />
              </div>
              <div className="flex flex-col gap-3 pt-3">
                <SummaryItem
                  className="py-0"
                  title="Participating"
                  value={
                    stepTwo?.liquidityPreferences?.participating?.enabled ? (
                      <AppFormattedMessage id={StringKey.YES} />
                    ) : (
                      <AppFormattedMessage id={StringKey.NO} />
                    )
                  }
                />
                <SummaryItem
                  className="py-0"
                  title="Cap Value"
                  value={stepTwo?.liquidityPreferences?.participating?.capValue || '-'}
                />
                <SummaryItem
                  className="py-0"
                  title="Interest"
                  value={
                    stepTwo?.liquidityPreferences?.participating?.interest
                      ? stepTwo?.liquidityPreferences?.participating?.interest + '%'
                      : '-'
                  }
                />
                <SummaryItem
                  className="py-0"
                  title="Days per year"
                  value={stepTwo?.liquidityPreferences?.participating?.daysPersYear || '-'}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex h-fit flex-col rounded-lg bg-gray-50">
          <div className="flex h-9 w-full items-center justify-between px-4">
            <span
              className={twMerge(
                'text-sm font-[550] text-gray-700',
                !stepThree?.antiDilutionRights?.enabled && 'text-gray-400',
              )}
            >
              Anti - Dilution rights
            </span>
            <Button className="w-fit" onClick={() => onEditClick(3)} styleType="NONE">
              <EditIcon iconColor="#2565C8" />
            </Button>
          </div>
          {stepThree?.antiDilutionRights?.enabled && (
            <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
              <SummaryItem
                className="pt-0"
                title="Base"
                value={
                  stepThree?.antiDilutionRights?.base
                    ? antiDilutionBaseTitle[stepThree?.antiDilutionRights?.base]
                    : '-'
                }
              />

              <SummaryItem
                className="pb-0"
                title="Primitive rights"
                value={
                  stepThree?.antiDilutionRights?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />
            </div>
          )}
        </div>
        <div className="flex h-[38px] w-full items-center justify-between rounded-lg border border-gray-100 bg-white">
          <div className="flex w-full gap-3 overflow-hidden px-4 py-2">
            <span className="w-[120px] flex-shrink-0 text-xs font-[450] text-gray-500">
              Board Resolution:
            </span>
            <span
              className={twMerge(
                'max-w-[250px] truncate text-xs font-[450] text-gray-700',
                !stepFour?.doc?.name && 'text-sunbeam-400',
              )}
            >
              {stepFour?.doc?.name || 'No file attached'}
            </span>
          </div>

          <Button className="w-fit pr-4" onClick={() => onEditClick(4)} styleType="NONE">
            <EditIcon iconColor="#2565C8" />
          </Button>
        </div>
      </div>

      <div className="flex h-9 w-full justify-start gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
      </div>
    </form>
  );
};
