import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export const TransactionOverviewItem = ({
  value,
  title,
  className,
}: {
  title: string;
  className?: string;
  value: string | ReactNode;
}) => (
  <div className="flex w-full items-center justify-between gap-8 p-3 text-sm font-[450] text-gray-700">
    <span>{title}</span>
    <span className={twMerge('truncate', className)}>{value}</span>
  </div>
);
