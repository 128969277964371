import { useNavigate as useNavigateRouter } from 'react-router-dom';

import { PageRoute } from '../types/pageTypes';

export const useNavigate = (endpoint: PageRoute) => {
  const navigate = useNavigateRouter();

  return (searchParams: (string | number)[] = []) =>
    navigate(`${endpoint}${searchParams ? '/' + searchParams.join('/') : ''}`);
};
