import { ListboxOptions, ListboxOptionsProps } from '@headlessui/react';
import React, { ElementRef, forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';

export type SelectOptionsProps = ListboxOptionsProps;

const SelectOptions = memo(
  forwardRef<ElementRef<typeof ListboxOptions>, SelectOptionsProps>(
    ({ className, ...props }, ref) => (
      <ListboxOptions
        className={(props) =>
          twMerge(
            'w-min-w-full sm:text-sm absolute z-10 mt-1 flex max-h-56 w-fit flex-col gap-2 overflow-auto rounded bg-white p-2 text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none',
            typeof className === 'string' ? className : className?.(props),
          )
        }
        ref={ref}
        {...props}
      ></ListboxOptions>
    ),
  ),
);

SelectOptions.displayName = 'SelectOptions';

export default SelectOptions;
