import {
  ComboboxOptions as HeadlessComboboxOptions,
  ComboboxOptionsProps as HeadlessComboboxOptionsProps,
} from '@headlessui/react';
import React, { ElementRef, forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';

export type ComboboxOptionsProps = {
  className?: string;
} & HeadlessComboboxOptionsProps;

const ComboboxOptions = memo(
  forwardRef<ElementRef<typeof HeadlessComboboxOptions>, ComboboxOptionsProps>(
    ({ className, children, ...props }, ref) => (
      <HeadlessComboboxOptions
        ref={ref}
        {...props}
        className={twMerge(
          'absolute z-2 mt-1 flex h-fit max-h-[250px] w-[var(--input-width)] flex-col gap-2 overflow-y-auto rounded border bg-white p-2 shadow-xl animate-in zoom-in-75 [--anchor-gap:var(--spacing-1)] empty:hidden',
          className,
        )}
      >
        {children}
      </HeadlessComboboxOptions>
    ),
  ),
);

ComboboxOptions.displayName = 'ComboboxOptions';

export default ComboboxOptions;
