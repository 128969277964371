import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PatchUserDto } from '../../types/userTypes';
import { useAppMutation } from '../useAppMutation';

export const useUpdate = () => {
  const { mutate: update, isPending } = useAppMutation([QueryKey.PATCH_USER], {
    mutationFn: ({ data }: { data: PatchUserDto }) =>
      ApiService.patch({ endpoint: BackendRoute.USERS }, { body: data }),
  });
  return { update, isPending };
};
