import React, { FC, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { CalendarIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { Calendar } from '../../../../components/Calendar';
import { Checkbox } from '../../../../components/Checkbox';
import { DropDown } from '../../../../components/Dropdown';
import { FormInput, Input } from '../../../../components/Input';
import { Popover, PopoverContent, PopoverTrigger } from '../../../../components/Popover';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ChildrenFormProps } from '../type';
import { FormSchema, formSchemaStepOne } from '../Validation';

export type StepOneProps = ChildrenFormProps;

export const StepOne: FC<StepOneProps> = ({
  control,
  handleCloseModal,
  nextFormStep,
  setFormData,
  lockMode,
}) => {
  const [isOpenCalendar, setOpenCalendar] = useState(false);
  const { stepOne } = useWatch<FormSchema>({ control });
  const { success } = formSchemaStepOne.safeParse(stepOne);
  const { format } = useFormat();
  const isValid = success;

  return (
    <form className="flex h-full flex-col gap-4 overflow-hidden">
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex w-full gap-4">
          <FormInput
            control={control}
            isShownErrorMessage={false}
            name="stepOne.name"
            placeholder="Share Class Name"
            wrapperClassName="w-full"
          />

          <Popover open={isOpenCalendar}>
            <PopoverTrigger className="relative w-full cursor-pointer">
              <div
                className="absolute z-50 h-full w-full"
                onClick={() => setOpenCalendar((prev) => !prev)}
              />
              <Input
                disabledInput
                icon={<CalendarIcon className="mr-2 size-6" />}
                onChange={() => ''}
                onIconClick={() => setOpenCalendar((prev) => !prev)}
                placeholder="Creation date"
                value={stepOne?.creationDate ? format(stepOne?.creationDate, 'dd/MM/yyyy') : ''}
                wrapperClassName="cursor-pointer"
              />
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                initialFocus
                mode="single"
                onSelect={(date) => {
                  if (!date) return;
                  setFormData('stepOne.creationDate', date);
                  setOpenCalendar(false);
                }}
                selected={stepOne?.creationDate}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div className="flex w-full flex-col gap-3 rounded-md bg-gray-50 px-[2px]">
          <span className="px-4 pt-3 text-sm font-[450] text-gray-700">General</span>
          <div className="flex w-full flex-col divide-y-[1px] divide-gray-100 rounded-md bg-white shadow-sm">
            <div className="flex h-20 w-full items-center justify-between gap-4 px-4">
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={stepOne?.votingRight?.enabled}
                  onChange={(state) =>
                    state
                      ? setFormData('stepOne.votingRight.enabled', state)
                      : setFormData('stepOne.votingRight', undefined)
                  }
                />
                <span className="text-sm font-[450] text-gray-700">Voting Right</span>
              </div>
              {stepOne?.votingRight?.enabled && (
                <FormInput
                  control={control}
                  isShownErrorMessage={false}
                  maxDecimalPlaces={2}
                  name="stepOne.votingRight.value"
                  placeholder="Multiple value"
                />
              )}
            </div>
            <div className="flex h-20 w-full items-center justify-between gap-4 px-4">
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={stepOne?.conversionRatio?.enabled}
                  onChange={(state) =>
                    state
                      ? setFormData('stepOne.conversionRatio.enabled', state)
                      : setFormData('stepOne.conversionRatio', undefined)
                  }
                />
                <span className="text-nowrap text-sm font-[450] text-gray-700">
                  Conversion Ratio
                </span>
              </div>
              {stepOne?.conversionRatio?.enabled && (
                <FormInput
                  control={control}
                  isShownErrorMessage={false}
                  maxDecimalPlaces={2}
                  name="stepOne.conversionRatio.value"
                  placeholder="Multiple value"
                />
              )}
            </div>
            <div className="flex h-20 w-full items-center gap-4 px-4">
              <div className="flex items-center gap-2">
                <Checkbox
                  checked={stepOne?.dividendRightEnabled}
                  onChange={(state) => {
                    setFormData('stepOne.dividendRightEnabled', state);
                  }}
                />
                <span className="text-sm font-[450] text-gray-700">Dividend Right</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col divide-y-[1px] divide-gray-100 rounded-lg bg-gray-50 px-4">
          <DropDown
            answer="You can select whichever name you want for your share class. The list of share classes you name will appear in the cap table in the view ‘by Share Class’ and will appear as columns in the cap table in the view ‘by Stakeholder’."
            question="How to name a share class?"
          />
          <DropDown
            answer="Voting rights refer to the entitlement of shareholders to vote on certain company matters, such as electing board members or approving significant corporate actions. The number of votes a shareholder has typically corresponds to the number of shares they own, but different share classes can have different voting rights, including enhanced voting rights or no voting rights at all."
            question="What are voting rights?"
          />
          <DropDown
            answer="Dividend rights are the entitlements of shareholders to receive a portion of the company's profits, usually distributed as dividends. Shareholders with dividend rights receive payments based on the number and type of shares they hold. The specific rights and amounts can vary based on the share class, with some classes receiving preferential or higher dividend rates."
            question="What are dividend rights?"
          />
          <DropDown
            answer="Dividend rights are the entitlements of shareholders to receive a portion of the company's profits, usually distributed as dividends. Shareholders with dividend rights receive payments based on the number and type of shares they hold. The specific rights and amounts can vary based on the share class, with some classes receiving preferential or higher dividend rates."
            question="What are dividend rights?"
          />
        </div>
      </div>
      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : (
              <AppFormattedMessage id={StringKey.NEXT} />
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};
