import React, { FC, useState } from 'react';

import Loader from '../../../components/Loader';
import { PaginationController } from '../../../components/PaginationController';
import { Pool } from '../../../hooks';
import { PageRoute } from '../../../types/pageTypes';
import { PoolPlansTab } from '../../../types/pool-plans.types';
import { PoolCard } from './Cards/PoolCard';
import { PoolMobileDatePicker } from './PoolMobileDatePicker';

type PoolPlansMobileProps = {
  companyId: string;
  selectedTab: PoolPlansTab;
  currentPage: number;
  handlePageChange: (page: number) => void;
};

export const PoolPlansMobile: FC<PoolPlansMobileProps> = ({
  companyId,
  selectedTab,
  currentPage,
  handlePageChange,
}) => {
  const [date, setDate] = useState<Date | undefined>(new Date());
  const numberOfFetchedItems = 5;
  const { pools, totalPages, isLoading } = Pool.usePools({
    companyId,
    currentPage,
    take: Number(numberOfFetchedItems),
  });
  const {
    pools: terminatedPools,
    totalPages: terminatedTotalPages,
    isLoading: isTerminatedLoading,
  } = Pool.useTerminatedPools({
    companyId,
    currentPage,
    take: Number(numberOfFetchedItems),
  });

  if (!pools || isLoading || !terminatedPools || isTerminatedLoading) return <Loader />;

  return (
    <>
      {pools && terminatedPools && (
        <>
          <PoolMobileDatePicker date={date} setDate={setDate} />
          <div className="flex min-h-full flex-col items-center p-[1px]">
            {selectedTab === PoolPlansTab.ACTIVE ? (
              <>
                {pools.map((pool) => (
                  <PoolCard
                    key={pool.id}
                    navigationLink={`${PageRoute.POOL_PLANS}/${pool.id}`}
                    pool={pool}
                  />
                ))}
              </>
            ) : (
              <>
                {terminatedPools.map((pool) => (
                  <PoolCard
                    key={pool.id}
                    navigationLink={`${PageRoute.POOL_PLANS}/${pool.id}`}
                    pool={pool}
                  />
                ))}
              </>
            )}
          </div>
          <PaginationController
            className="shrink-0 max-lg:px-4 lg:mx-6"
            currentPage={currentPage}
            onClick={handlePageChange}
            totalPages={
              selectedTab === PoolPlansTab.ACTIVE ? totalPages || 0 : terminatedTotalPages || 0
            }
          />
        </>
      )}
    </>
  );
};
