import React, { FC } from 'react';
import { useWatch } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { DropDown } from '../../../../components/Dropdown';
import { Switch } from '../../../../components/Switch';
import { StringKey } from '../../../../lang';
import { AntiDilutionBaseCombobox } from '../AntiDilutionBase';
import { ChildrenFormProps } from '../type';
import { FormSchema, formSchemaStepThree } from '../Validation';

export type StepThereProps = ChildrenFormProps;

export const StepThree: FC<StepThereProps> = ({
  control,
  handleCloseModal,
  prevFormStep,
  nextFormStep,
  setFormData,
  lockMode,
}) => {
  const { stepThree } = useWatch<FormSchema>({ control });
  const { success } = formSchemaStepThree.safeParse(stepThree);
  const isValid = success;
  return (
    <form className="flex h-full flex-col gap-4 overflow-hidden">
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div
          className={twMerge(
            'flex flex-col rounded-md border border-gray-100 bg-white',
            stepThree?.antiDilutionRights?.enabled && 'bg-gray-50',
          )}
        >
          <div className="flex items-center gap-2 px-4 py-3">
            <Switch
              checked={stepThree?.antiDilutionRights?.enabled}
              onChange={(state) => {
                if (state) return setFormData('stepThree.antiDilutionRights.enabled', state);
                setFormData('stepThree.antiDilutionRights', undefined);
              }}
            />
            <span className="text-sm font-[450] text-gray-700">Anti - Dilution rights</span>
          </div>
          {stepThree?.antiDilutionRights?.enabled && (
            <div className="flex flex-col gap-3 divide-y-[1px] divide-gray-100 rounded-md bg-white px-4 py-3 shadow-sm">
              <AntiDilutionBaseCombobox
                onSelect={(base) => setFormData('stepThree.antiDilutionRights.base', base)}
                selectedBase={stepThree.antiDilutionRights.base}
              />
            </div>
          )}
        </div>

        <div className="flex shrink-0 flex-col divide-y-[1px] divide-gray-100 overflow-hidden rounded-lg bg-gray-50 px-4">
          <DropDown
            answer="Anti-dilution is a protective mechanism for investors that adjusts the conversion ratio of their shares to ensure their investment is not diluted during subsequent funding rounds. Essentially, it protects an investor's equity stake in a company if new shares are issued at a lower price than the price the existing investors originally paid."
            question="What is anti - dilution?"
          />
        </div>
      </div>

      <div className="flex h-9 w-full shrink-0 justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          {!lockMode && (
            <Button
              className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
              onClick={prevFormStep}
              styleType="NONE"
              type="button"
            >
              <AppFormattedMessage id={StringKey.BACK} />
            </Button>
          )}
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            disabled={!isValid}
            onClick={nextFormStep}
            type="button"
          >
            {lockMode ? (
              <AppFormattedMessage id={StringKey.UPDATE} />
            ) : stepThree?.antiDilutionRights?.enabled ? (
              <AppFormattedMessage id={StringKey.NEXT} />
            ) : (
              <AppFormattedMessage id={StringKey.SKIP} />
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};
