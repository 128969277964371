import React, { ComponentPropsWithoutRef, memo, ReactElement } from 'react';
import { Control, FieldPathByValue, FieldValues, PathValue } from 'react-hook-form';

import ComboboxOption from './ComboboxOption';

export type FormComboboxOptionProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
> = {
  control: Control<TFieldValues>;
  name: TPath;
  value: PathValue<TFieldValues, TPath>;
} & Omit<ComponentPropsWithoutRef<typeof ComboboxOption>, 'value'>;

const FormComboboxOption = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
>({
  children,
  ...props
}: FormComboboxOptionProps<TFieldValues, TPath>): ReactElement | null => {
  return <ComboboxOption {...props}>{children}</ComboboxOption>;
};

export default memo(FormComboboxOption) as typeof FormComboboxOption;
