import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type ArrowIconProps = IconProps;

const ArrowIcon: FC<ArrowIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="13"
    viewBox="0 0 12 10"
    width="13"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3334 5H0.666687M0.666687 5L4.66669 9M0.666687 5L4.66669 1"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);

export default ArrowIcon;
