import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { accessTokenStorage } from '../../storage/accessTokenStorage';
import { useAppMutation } from '../useAppMutation';

export const useLogout = ({ reloadWindow = true }: { reloadWindow?: boolean } = {}) => {
  const { mutate: logoutUser, isPending } = useAppMutation([QueryKey.GET_LOGOUT], {
    mutationFn: () => ApiService.delete({ endpoint: BackendRoute.AUTH_LOGOUT }),
    onSuccess: () => {
      accessTokenStorage.delete();
      reloadWindow && window.location.reload();
    },
  });
  return { logoutUser, isPending };
};
