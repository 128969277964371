import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';
import { useLocale } from '../useLocale';

export const useExport = () => {
  const { locale } = useLocale();
  const { mutate: exportStakeholders, isPending } = useAppMutation([QueryKey.STAKEHOLDERS_EXPORT], {
    mutationFn: ({ companyId }: { companyId: string }) =>
      ApiService.downloadFile(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'stakeholders', 'export'],
        },
        { locale },
      ),
  });
  return { exportStakeholders, isPending };
};
