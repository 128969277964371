import { z } from 'zod';

import { AntiDilutionBase } from '../../../types/share-classes.types';

export const MAX_FILE_SIZE = 1024 * 1024 * 10;

export const ACCEPTED_FILE_MIME_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
  'image/png',
  'image/jpeg',
];

export const fileSchemaMessage = 'File size is too large';
export const formSchemaStepOne = z.object({
  name: z.string().trim().min(1, { message: 'Required' }),
  creationDate: z.coerce.date(),
  votingRight: z
    .object({
      enabled: z.boolean(),
      value: z.coerce.number().min(1, { message: ' ' }),
    })
    .optional(),
  conversionRatio: z
    .object({
      enabled: z.boolean(),
      value: z.coerce.number().min(1, { message: ' ' }),
    })
    .optional(),
  dividendRightEnabled: z.boolean(),
});

const participating = z.union([
  z
    .object({
      enabled: z.boolean(),
      capValue: z.coerce.number().min(1, { message: ' ' }),
      interest: z.string().optional(),
      daysPersYear: z.undefined().optional(),
    })
    .refine(
      ({ interest, daysPersYear }) => (interest && daysPersYear) || (!interest && !daysPersYear),
    ),
  z.object({
    enabled: z.boolean(),
    capValue: z.coerce.number().min(0, { message: ' ' }),
    interest: z.coerce.number().min(1, { message: ' ' }),
    daysPersYear: z.string(),
  }),
]);
export const fromSchemaStepTwo = z
  .object({
    liquidityPreferences: z
      .object({
        enabled: z.boolean(),
        seniority: z.coerce.number().min(1, { message: ' ' }),
        multiple: z.coerce.number().min(1, { message: ' ' }),
        participating: participating.optional(),
      })
      .superRefine(({ multiple, participating }, ctx) => {
        if (participating && multiple > participating.capValue) {
          ctx.addIssue({
            code: 'custom',
            params: { participating: 'error' },
            path: ['liquidityPreferences', 'multiple'],
            message: 'Cap value is less than the multiple',
          });
        }
      })
      .optional(),
  })
  .optional();

export const formSchemaStepThree = z
  .object({
    antiDilutionRights: z
      .object({
        enabled: z.boolean(),
        base: z.nativeEnum(AntiDilutionBase),
      })
      .optional(),
  })
  .optional();

export const fromSchemaStepFour = z
  .object({
    docLink: z.string(),
    progress: z.number(),
    doc: z
      .custom<File>()
      .or(
        z.object({
          size: z.number(),
          type: z.string(),
          name: z.string(),
        }),
      )
      .refine(
        (files) => ACCEPTED_FILE_MIME_TYPES.includes(files?.type),
        'File format is not supported',
      )
      .refine((files) => {
        return files?.size <= MAX_FILE_SIZE;
      }, fileSchemaMessage),
  })
  .optional();
export const formSchema = z.object({
  stepOne: formSchemaStepOne,
  stepTwo: fromSchemaStepTwo,
  stepThree: formSchemaStepThree,
  stepFour: fromSchemaStepFour,
});

export type FormSchema = z.infer<typeof formSchema>;
