import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { FC, useState } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { Company, useLocale } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { ChildrenFormProps } from '../CompanySetupForm';
import BillingForm from './BillingForm';

export type BillingStepProps = ChildrenFormProps;

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const BillingStep: FC<BillingStepProps> = ({ companyId, nextFormStep, formData, prevFormStep }) => {
  const [isLoading, setLoading] = useState(true);

  const { clientSecret } = Company.useClientSecret({ companyId, enabled: !!stripe });

  const { locale } = useLocale();

  return (
    <>
      <Button
        className="absolute left-6 top-6 border-[1px] border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700 max-lg:hidden"
        onClick={prevFormStep}
        styleType="DEFAULT_ROUNDED"
      >
        <AppFormattedMessage id={StringKey.BACK} />
      </Button>
      <Elements
        options={{
          clientSecret: clientSecret,
          locale,
          fonts: [
            {
              cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap',
            },
          ],
        }}
        stripe={stripe}
      >
        <BillingForm
          clientSecret={clientSecret || ''}
          companyId={companyId}
          isLoading={isLoading}
          onSuccess={() => nextFormStep()}
          selectedPlanInfo={formData('stepFour')}
          setLoading={setLoading}
        />
      </Elements>
    </>
  );
};

BillingStep.displayName = 'BillingStep';

export default BillingStep;
