import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { ShareClass } from '../../types/share-classes.types';
import { useAppQuery } from '../useAppQuery';

export const useShareClass = ({
  companyId,
  shareClassId,
}: {
  companyId: string;
  shareClassId: string;
}) => {
  const { invalidateQueries } = useQueryClient();

  const { data: shareClass, isLoading } = useAppQuery<ShareClass>({
    queryKey: [QueryKey.GET_SHARE_CLASS, companyId, shareClassId],
    queryFn: () =>
      ApiService.get({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'share-classes', shareClassId],
      }),
    enabled: !!companyId && !!shareClassId,
  });

  const invalidateQuery = useCallback(() => {
    invalidateQueries({ queryKey: [QueryKey.GET_SHARE_CLASS] });
  }, [invalidateQueries]);

  return {
    invalidateQuery,
    shareClass,
    isLoading,
  };
};
