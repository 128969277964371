import { z } from 'zod';

export const formSchema = z.object({
  country: z.object(
    {
      name: z.string().min(1, { message: 'Required' }),
      iso2Code: z.string(),
    },
    { message: 'Required' },
  ),
  city: z.string({ message: 'Required' }).trim().min(1, { message: 'Required' }),
  state: z.object({
    name: z.string({ message: 'Required' }).min(1),
    iso2Code: z.string(),
  }),
  zipCode: z
    .string()
    .trim()
    .regex(/^(?!.*--)(?!.* -)(?!.*- )(?!.* {2})[a-zA-Z0-9 -]+$/, {
      message: 'Enter valid zip code',
    })
    .min(1, { message: 'Enter valid zip code' }),
  address: z.string({ message: 'Required' }).trim().min(1),
});

export type FormSchema = z.infer<typeof formSchema>;
