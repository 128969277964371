import React, { FC, useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { twMerge } from 'tailwind-merge';

import Loader from '../../../../../components/Loader';
import { useSharePlans } from '../../../../../hooks';
import { Pool } from '../../../../../types/pool-plans.types';
import { PoolPlansPopover } from '../../PoolPlansPopover';

type ScrollablePoolPlanItemProps = {
  companyId: string;
  pool: Pool;
  isSelected: boolean;
  onEditClick: (id: string) => void;
  onViewClick: (id: string) => void;
  onDeleteClick: (id: string) => void;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
  onEditTerminationDateClick: (id: string) => void;
};

export const ScrollablePoolPlanItem: FC<ScrollablePoolPlanItemProps> = ({
  pool,
  isSelected,
  onEditClick,
  onViewClick,
  onDeleteClick,
  onEditPlanClick,
  onViewPlanClick,
  onDeletePlanClick,
  companyId,
  onEditTerminationDateClick,
}) => {
  const planDilutedShares = 0;
  const poolDilutedShares = 0;

  const planAvailablePool = 0;
  const poolAvailablePool = 0;

  const planPoolGranted = 0;
  const poolPoolGranted = 0;

  const { sharePlans, numberOfFetchedItems, fetchNextPage, hasNextPage } = useSharePlans({
    companyId,
    poolId: pool?.id || '',
  });
  const isTerminatedDatePassed = useMemo(() => {
    return pool?.terminationDate
      ? new Date(pool.terminationDate).getTime() < new Date().getTime()
      : false;
  }, [pool?.terminationDate]);

  return (
    <>
      <tr
        className={twMerge(
          'h-[72px] w-full border-b-[1px] border-b-gray-100 bg-white transition-colors',
          isSelected && 'bg-brand-25',
        )}
      >
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !poolDilutedShares && 'text-gray-300',
            )}
          >
            {poolDilutedShares || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span className="max-w-[100px] truncate text-sm text-gray-700">
            <span
              className={twMerge(
                'max-w-[100px] truncate text-sm text-gray-700',
                !poolAvailablePool && 'text-gray-300',
              )}
            >
              {poolAvailablePool || '-'}
            </span>
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span className="max-w-[100px] truncate text-sm text-gray-700">
            <span
              className={twMerge(
                'max-w-[100px] truncate text-sm text-gray-700',
                !poolPoolGranted && 'text-gray-300',
              )}
            >
              {poolPoolGranted || '-'}
            </span>
          </span>
        </td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="relative">
          <PoolPlansPopover
            id={pool.id}
            isTerminated={isTerminatedDatePassed}
            onDeleteClick={onDeleteClick}
            onEditClick={onEditClick}
            onEditTerminationDateClick={onEditTerminationDateClick}
            onViewClick={onViewClick}
            text="Pool"
          />
        </td>
      </tr>
      {isSelected && sharePlans && (
        <>
          <InfiniteScroll
            dataLength={numberOfFetchedItems}
            hasMore={hasNextPage}
            loader={<Loader />}
            next={fetchNextPage}
            scrollableTarget="plans-scrollbar-target"
          >
            {sharePlans.map((plan) => (
              <tr className="h-[72px] w-full bg-white" key={plan.id}>
                <td className="p-4 text-sm text-gray-700">{plan && planDilutedShares}</td>
                <td className="overflow-hidden p-4">
                  <span className="max-w-[100px] truncate text-sm text-gray-700">
                    {plan && planAvailablePool}
                  </span>
                </td>
                <td className="overflow-hidden p-4">
                  <span className="max-w-[100px] truncate text-sm text-gray-700">
                    {plan && planPoolGranted}
                  </span>
                </td>
                <td className="p-4 text-sm text-gray-300">-</td>
                <td className="p-4 text-sm text-gray-300">-</td>
                <td className="p-4 text-sm text-gray-300">-</td>
                <td className="p-4 text-sm text-gray-300">-</td>
                <td className="p-4 text-sm text-gray-300">-</td>
                <td className="p-4 text-sm text-gray-300">-</td>
                <td className="p-4 text-sm text-gray-300">-</td>
                <td className="relative">
                  {!plan.isTerminated && (
                    <PoolPlansPopover
                      id={plan.id}
                      isTerminated={
                        plan?.terminationDate
                          ? new Date(plan.terminationDate).getTime() < new Date().getTime()
                          : false
                      }
                      onDeleteClick={onDeletePlanClick}
                      onEditClick={onEditPlanClick}
                      onViewClick={onViewPlanClick}
                      text="Plan"
                    />
                  )}
                </td>
              </tr>
            ))}
          </InfiniteScroll>
        </>
      )}
    </>
  );
};
