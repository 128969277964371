import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CapQuestLogo, EyeIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { GoogleAuthButton } from '../../components/GoogleAuthButton';
import { Input } from '../../components/Input';
import OrDelimiter from '../../components/OrDelimiter';
import { BackendRoute } from '../../config';
import { HttpException } from '../../exceptions';
import { useAppMutation, useEnterEvent } from '../../hooks';
import { StringKey } from '../../lang';
import { ApiService } from '../../services';
import { inviteStakeholderStore } from '../../storage/invitedStakeholder';

interface LoginDto {
  email: string;
  password: string;
}

const Login = () => {
  const [loginDto, setLoginDto] = useState<LoginDto>({
    email: '',
    password: '',
  });
  const [isPasswordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();

  const continueWithGoogle = async () => {
    window.location.replace(`${process.env.REACT_APP_BACKEND_URL}/auth/google/callback`);
  };

  const loginMutation = useAppMutation<void, HttpException>([], {
    mutationFn: async () => {
      await ApiService.post<void>({ endpoint: BackendRoute.AUTH_LOGIN }, { body: loginDto });
    },
    onSuccess: () => navigate('/'),
    defaultErrorHandling: false,
  });

  useEnterEvent(() => loginDto.email.trim() && loginDto.password.trim() && loginMutation.mutate());

  const handleInvitationSync = useCallback(() => {
    const invitation = inviteStakeholderStore.get();
    if (!invitation) return;

    if (invitation.isRegistered) {
      inviteStakeholderStore.set({ ...invitation, isRegistered: false });
      navigate('/login');
      return;
    }

    setLoginDto({ ...loginDto, email: invitation.email });
  }, [loginDto, navigate]);

  useEffect(() => {
    handleInvitationSync();
  }, [handleInvitationSync]);

  return (
    <div className="flex h-full items-center justify-center overflow-y-auto bg-gray-50 font-inter">
      <div className="max-h-full max-lg:h-full max-lg:w-full">
        <div
          className="flex h-fit min-h-full w-full flex-col gap-8 rounded-lg bg-white px-4 pb-8 pt-16 lg:h-fit lg:w-[458px] lg:items-center lg:px-8 lg:pt-8 lg:text-center"
          style={{
            filter:
              'drop-shadow(0px 1px 2px #1018280F) drop-shadow(0px 2px 3px #1018281A) drop-shadow(0px -1px 3px #1018280A)',
          }}
        >
          <CapQuestLogo size={48} />
          <div className="flex flex-col gap-2">
            <div
              className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
              style={{
                background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
              }}
            >
              <AppFormattedMessage id={StringKey.WELCOME_BACK} />
            </div>
            <div className="text-sm text-gray-500">
              <AppFormattedMessage id={StringKey.SIGN_UP_TO_CAPQUEST} />
            </div>
          </div>
          <div className="flex w-full flex-col gap-6">
            <GoogleAuthButton onClick={continueWithGoogle} />

            <OrDelimiter />

            <form className="flex flex-col gap-4">
              <Input
                isError={loginMutation.isError}
                onChange={(value) => setLoginDto({ ...loginDto, email: value })}
                placeholder={<AppFormattedMessage id={StringKey.EMAIL_ADDRESS} />}
                type="email"
                value={loginDto.email}
              />
              <Input
                errorMessage={loginMutation.error?.body?.message}
                icon={<EyeIcon variant={isPasswordShown ? 'ACTIVE' : 'CLOSE'} />}
                isError={loginMutation.isError}
                onChange={(value) => setLoginDto({ ...loginDto, password: value })}
                onIconClick={() => setPasswordShown((prev) => !prev)}
                placeholder={<AppFormattedMessage id={StringKey.PASSWORD} />}
                type={isPasswordShown ? 'text' : 'password'}
                value={loginDto.password}
              />
              <div className="flex justify-end">
                <button
                  className="text-sm font-[450] text-brand-700 underline"
                  onClick={() => navigate('/forgot-password')}
                >
                  <AppFormattedMessage id={StringKey.FORGOT_PASSWORD} />?
                </button>
              </div>
            </form>
          </div>
          <div className="mt-2 w-full">
            <Button
              disabled={!loginDto.email.trim() || !loginDto.password.trim()}
              isLoading={loginMutation.isPending}
              onClick={() => loginMutation.mutate()}
            >
              <AppFormattedMessage id={StringKey.LOG_IN} />
            </Button>
          </div>

          <div className="flex items-center gap-2 text-sm">
            <div className="font-[450] text-gray-700">
              <AppFormattedMessage id={StringKey.DONT_HAVE_AN_ACCOUNT_YET} />?
            </div>
            <button
              className="font-[550] text-brand-700 underline"
              onClick={() => navigate('/register')}
            >
              <AppFormattedMessage id={StringKey.SIGN_UP} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
