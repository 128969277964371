import Lottie from 'lottie-react';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import confettiAnimation from '../../../../assets/animations/confetti.json';
import { CheckIcon, ChevronDownIcon } from '../../../../assets/icons';
import smilingFaceImage from '../../../../assets/images/smiling-face.png';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import Loader from '../../../../components/Loader';
import { ReactPortal } from '../../../../components/Modal/ReactPortal';
import { User } from '../../../../hooks';
import { StringKey } from '../../../../lang';

type FinalStepProps = {
  companyId: string;
};

const FinalStep: FC<FinalStepProps> = () => {
  const [visibleAnimation, setVisibleAnimation] = useState(true);
  const navigate = useNavigate();

  const { user, isLoading } = User.useUser();
  const handleSkip = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const handleGoToAddStakeholders = useCallback(() => {
    navigate('/stakeholders');
  }, [navigate]);

  if (isLoading || !user)
    return (
      <ReactPortal initialPortalId="modal">
        <Loader className="absolute left-0 top-0 z-[999999999] h-screen w-screen pl-10 pt-10 max-lg:pl-16 max-lg:pt-36" />
      </ReactPortal>
    );

  return (
    <>
      <div className="m-auto flex h-fit min-h-full w-full max-w-[400px] flex-col items-center gap-10">
        <div className="flex size-28 shrink-0 items-center justify-center rounded-full border-[12px] border-gray-50 bg-gradient-to-t from-forest-600 to-forest-300 shadow-md">
          <CheckIcon height={'38px'} iconColor="#ffffff" width={'38px'} />
        </div>
        <div className="flex flex-col gap-4 text-center">
          <span
            className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
            style={{
              background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
            }}
          >
            <AppFormattedMessage id={StringKey.WELCOME_ABOARD} />
          </span>
          <div className="flex flex-col gap-2">
            <span className="text-sm font-[450] text-gray-500">
              <AppFormattedMessage id={StringKey.SENDED_RECEIPT_TO_EMAIL} />
            </span>
            <span className="text-sm font-medium text-gray-700">{user.email}</span>
          </div>
        </div>
        <div className="flex flex-col gap-8 border-t-[1px] border-gray-200 pt-10 text-center">
          <span className="text-sm font-[450] text-gray-700">
            <AppFormattedMessage id={StringKey.GREETING_COMPANY_SETUP} />
          </span>
          <div className="flex gap-4">
            <Button
              className="w-fit border-[1px] border-gray-300 bg-gray-25 px-6 py-[10px] text-sm font-[450] text-gray-700"
              onClick={handleSkip}
              styleType="DEFAULT_ROUNDED"
            >
              <AppFormattedMessage id={StringKey.SKIP} />
            </Button>
            <Button
              className="w-full bg-brand-700 px-4 py-[10px] text-sm font-[550] text-white"
              onClick={handleGoToAddStakeholders}
            >
              <AppFormattedMessage id={StringKey.ADD_STAKEHOLDER} />
            </Button>
          </div>
          <div className="-mt-4 flex items-center justify-between rounded-xl border-[1px] border-gray-200 px-3 py-2">
            <div className="flex cursor-pointer items-center gap-4">
              <div className="flex !size-10 shrink-0 items-center justify-center rounded-lg border-[1px] border-gray-200">
                <img alt="" className="size-[20px]" src={smilingFaceImage} />
              </div>
              <div className="flex flex-col gap-2 text-start">
                <span className="text-sm font-[550] text-[#172335]">
                  <AppFormattedMessage id={StringKey.HELP_ME_SETUP} />
                </span>
                <span className="text-sm font-normal text-gray-600">
                  <AppFormattedMessage id={StringKey.LET_US_HELP_TO_SETUP} />
                </span>
              </div>
            </div>
            <Button
              className="h-fit w-fit -rotate-90 rounded p-1 transition-colors hover:bg-gray-100"
              styleType="NONE"
            >
              <ChevronDownIcon height={'16px'} iconColor="#172335" width={'16px'} />
            </Button>
          </div>
        </div>
        {visibleAnimation && (
          <Lottie
            animationData={confettiAnimation}
            className="absolute bottom-0 z-50 h-screen w-full max-lg:translate-y-[5%] max-lg:scale-105"
            loop={false}
            onComplete={() => setVisibleAnimation(false)}
          />
        )}
      </div>
    </>
  );
};

FinalStep.displayName = 'FinalStep';

export default FinalStep;
