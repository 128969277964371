import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useToggleStatus = () => {
  const { mutate: toggleStatus, isPending } = useAppMutation([QueryKey.DELETE_STAKEHOLDER], {
    mutationFn: ({ companyId, stakeholderId }: { companyId: string; stakeholderId: string }) =>
      ApiService.post({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'stakeholders', stakeholderId, 'toggle-status'],
      }),
  });

  return { toggleStatus, isPending };
};
