import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type TimeBasedDynamicIconProps = IconProps;

const TimeBasedDynamicIcon: FC<TimeBasedDynamicIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3667 11.5L21.3673 13.5L19.3667 11.5M21.6118 13C21.6481 12.6717 21.6667 12.338 21.6667 12C21.6667 7.02944 17.6373 3 12.6667 3C7.69619 3 3.66675 7.02944 3.66675 12C3.66675 16.9706 7.69619 21 12.6667 21C15.494 21 18.0168 19.6963 19.6667 17.6573M12.6667 7V12L15.6667 14"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

export default TimeBasedDynamicIcon;
