import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { HttpException } from '../../exceptions';
import { ApiService } from '../../services';
import { useAppQuery } from '../useAppQuery';

type FetchCompany = {
  companyId: string;
  onError?: (error: HttpException | unknown) => void;
  enabled?: boolean;
};

export const useClientSecret = ({ companyId, enabled = true, onError }: FetchCompany) => {
  const { invalidateQueries } = useQueryClient();

  const { data, isLoading } = useAppQuery<{
    clientSecret: string;
  }>({
    queryKey: [QueryKey.GET_CLIENT_SECRET, companyId],
    queryFn: () =>
      ApiService.get({ endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'subscribe'] }),
    enabled: !!companyId && enabled,
    onError,
  });

  const invalidateQuery = useCallback(() => {
    invalidateQueries({ queryKey: [QueryKey.GET_CLIENT_SECRET] });
  }, [invalidateQueries]);

  return {
    isLoading,
    invalidateQuery,
    ...data,
  };
};
