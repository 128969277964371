import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

export enum TagVariant {
  GREEN = 'border-forest-200 bg-forest-50 text-forest-600',
  BLUE = 'border-brand-200 bg-brand-50 text-brand-700',
  RED = 'border-fireside-200 bg-fireside-50 text-fireside-600',
}

export type TagProps = { variant: TagVariant; title: string } & OptionalFields<{
  borderColor: string;
}>;

export const Tag: FC<TagProps> = ({ title, variant, borderColor }) => {
  return (
    <span
      className={twMerge(
        'flex h-fit items-center whitespace-nowrap rounded-2xl px-2 py-[2px] !text-label-md !font-medium',
        variant,
        borderColor && 'border',
        borderColor,
      )}
    >
      {title}
    </span>
  );
};
