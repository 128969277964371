import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export enum CalendarIconVariant {
  DEFAULT,
  WITH_NUMBER,
}

export type CalendarIconProps = { variant?: CalendarIconVariant } & IconProps;

const calendarIconVariant: Record<
  CalendarIconVariant,
  (props: Except<CalendarIconProps, 'variant'>) => ReactNode
> = {
  [CalendarIconVariant.DEFAULT]: ({ iconColor = '#344054', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 8.33329H2.5M13.3333 1.66663V4.99996M6.66667 1.66663V4.99996M6.5 18.3333H13.5C14.9001 18.3333 15.6002 18.3333 16.135 18.0608C16.6054 17.8211 16.9878 17.4387 17.2275 16.9683C17.5 16.4335 17.5 15.7334 17.5 14.3333V7.33329C17.5 5.93316 17.5 5.2331 17.2275 4.69832C16.9878 4.22791 16.6054 3.84546 16.135 3.60578C15.6002 3.33329 14.9001 3.33329 13.5 3.33329H6.5C5.09987 3.33329 4.3998 3.33329 3.86502 3.60578C3.39462 3.84546 3.01217 4.22791 2.77248 4.69832C2.5 5.2331 2.5 5.93316 2.5 7.33329V14.3333C2.5 15.7334 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8211 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
      />
    </svg>
  ),
  [CalendarIconVariant.WITH_NUMBER]: ({ iconColor = '#344054', className, ...props }) => (
    <svg
      className={twMerge('shrink-0', className)}
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 7.33332H1.5M12.3333 0.666656V3.99999M5.66667 0.666656V3.99999M7.75 10.6667L9 9.83332V14M7.95833 14H10.0417M5.5 17.3333H12.5C13.9001 17.3333 14.6002 17.3333 15.135 17.0608C15.6054 16.8212 15.9878 16.4387 16.2275 15.9683C16.5 15.4335 16.5 14.7335 16.5 13.3333V6.33332C16.5 4.93319 16.5 4.23313 16.2275 3.69835C15.9878 3.22794 15.6054 2.84549 15.135 2.60581C14.6002 2.33332 13.9001 2.33332 12.5 2.33332H5.5C4.09987 2.33332 3.3998 2.33332 2.86502 2.60581C2.39462 2.84549 2.01217 3.22794 1.77248 3.69835C1.5 4.23313 1.5 4.93319 1.5 6.33332V13.3333C1.5 14.7335 1.5 15.4335 1.77248 15.9683C2.01217 16.4387 2.39462 16.8212 2.86502 17.0608C3.3998 17.3333 4.09987 17.3333 5.5 17.3333Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.3"
      />
    </svg>
  ),
};

const CalendarIcon: FC<CalendarIconProps> = ({ variant = CalendarIconVariant.DEFAULT, ...props }) =>
  calendarIconVariant[variant](props);

export default CalendarIcon;
