import React, { FC, useState } from 'react';

import { ChevronDownIcon } from '../../../assets/icons';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '../../../components/Combobox';
import { TerminationInterval, terminationIntervalTitle } from '../../../types/pool-plans.types';

const IntervalItemsList: FC = () => {
  return (
    <>
      {Object.entries(terminationIntervalTitle).map(([key, value]) => (
        <ComboboxOption key={key} value={key}>
          <span className="text-sm text-gray-700">{value}</span>
        </ComboboxOption>
      ))}
    </>
  );
};

export type IntervalComboboxProps = {
  onSelect?: (state: TerminationInterval) => void;
  defaultData?: TerminationInterval;
};

export const IntervalCombobox: FC<IntervalComboboxProps> = ({ defaultData, onSelect }) => {
  const [value, setValue] = useState<TerminationInterval | null>(defaultData || null);
  return (
    <Combobox
      as={'div'}
      className="relative"
      onChange={(value: TerminationInterval) => {
        onSelect?.(value);
        setValue(value);
      }}
    >
      <div className="relative">
        <ComboboxInput
          className={'bg-gray-900'}
          icon={<ChevronDownIcon className="mt-2" />}
          name={'stepOne.type'}
          onChange={() => ''}
          placeholder="Interval"
          readOnly
          value={value ? terminationIntervalTitle[value] : ''}
        />
        <ComboboxButton className="absolute left-0 top-0 z-1 h-full w-full" />
      </div>
      <ComboboxOptions className="w-full">
        <IntervalItemsList />
      </ComboboxOptions>
    </Combobox>
  );
};
