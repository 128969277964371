import { Content } from '@radix-ui/react-hover-card';
import React, { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

type HoverCardContentProps = {
  triangleClassName?: string;
  isShownTriangle?: boolean;
} & ComponentPropsWithoutRef<typeof Content>;

export const HoverCardContent = forwardRef<ElementRef<typeof Content>, HoverCardContentProps>(
  (
    {
      align = 'center',
      className,
      sideOffset = 20,
      side = 'top',
      children,
      triangleClassName,
      isShownTriangle = true,
      ...props
    },
    ref,
  ) => (
    <Content
      align={align}
      className={twMerge(
        'relative z-50 rounded-md p-4 shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      ref={ref}
      side={side}
      sideOffset={sideOffset}
      {...props}
    >
      {children}
      {isShownTriangle && (
        <div
          className={twMerge(
            'absolute -bottom-1 left-1/2 -ml-[6px] size-3 rotate-45 bg-[#101828]',
            triangleClassName,
          )}
        />
      )}
    </Content>
  ),
);
HoverCardContent.displayName = Content.displayName;
