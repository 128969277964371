import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { DateRange } from 'react-day-picker';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { EventUnion, SortByDate, SortByType, sortByTypeMap } from '../../types/events.types';
import { useFormat } from '../useFormat';

export type EventsResponse = {
  events: EventUnion[];
  totalPages: number;
};
export const calculateDateRangeFromDays = (days: number): { from: Date; to: Date } => {
  const to = new Date();
  const from = new Date();
  from.setDate(to.getDate() - days);
  return { from, to };
};

export const calculateDateRangeFromMonths = (months: number): { from: Date; to: Date } => {
  const to = new Date();
  const from = new Date();
  from.setMonth(to.getMonth() - months);
  return { from, to };
};

export const calculateDateRangeFromYears = (years: number): { from: Date; to: Date } => {
  const to = new Date();
  const from = new Date();
  from.setFullYear(to.getFullYear() - years);
  return { from, to };
};

export const dateFilterVariant: Record<
  SortByDate,
  (props: { from: Date | undefined; to: Date | undefined }) => {
    from: Date | undefined;
    to: Date | undefined;
  }
> = {
  [SortByDate.ALL_TIME]: () => ({ from: undefined, to: undefined }),
  [SortByDate.CUSTOM_DATE]: ({ from, to }) => ({ from, to }),
  [SortByDate.LAST_30_DAYS]: () => calculateDateRangeFromDays(30),
  [SortByDate.LAST_60_DAYS]: () => calculateDateRangeFromDays(60),
  [SortByDate.LAST_QUARTER]: () => calculateDateRangeFromMonths(3),
  [SortByDate.LAST_YEAR]: () => calculateDateRangeFromYears(1),
};

export const useEvents = ({
  companyId,
  typesFilter = [],
  currentPage = 0,
  take = 100,
  customDateFilter,
  dateFilter = SortByDate.ALL_TIME,
}: {
  companyId: string;
  take?: number;
  currentPage?: number;
  typesFilter?: SortByType[];
  dateFilter?: SortByDate;
  customDateFilter?: DateRange;
}) => {
  const { invalidateQueries } = useQueryClient();
  const { format } = useFormat();

  const dateRange = dateFilterVariant[dateFilter]({
    from: customDateFilter?.from,
    to: customDateFilter?.to,
  });

  const dateRangeTo = dateRange.to ? format(dateRange.to, 'yyyy-MM-dd') : null;
  const dateRangeFrom = dateRange.from ? format(dateRange.from, 'yyyy-MM-dd') : null;

  const skip = (currentPage - 1 <= 0 ? 0 : currentPage - 1) * take;
  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<EventsResponse>({
    queryKey: [
      QueryKey.GET_EVENTS,
      {
        to: dateRangeTo,
        from: dateRangeFrom,
        typesFilter,
        currentPage,
        take,
        skip,
      },
    ],
    queryFn: ({ pageParam = 0 }) =>
      ApiService.get(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'events'] },
        {
          queryParams: {
            take,
            skip: currentPage ? skip : pageParam,
            'start-date': dateRangeFrom,
            'end-date': dateRangeTo,
            'types-filter':
              typesFilter.length === sortByTypeMap.length ? '' : typesFilter.join(','),
          },
        },
      ),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.totalPages > allPages.length ? allPages.length * take : undefined,
    enabled: !!companyId,
  });

  const eventsList = useMemo(
    () =>
      data?.pages?.reduce<EventUnion[]>((acc, page) => {
        return [...acc, ...page.events];
      }, []),
    [data?.pages],
  );

  const invalidateQuery = () => {
    invalidateQueries({ queryKey: [QueryKey.GET_EVENTS] });
  };

  return {
    invalidateQuery,
    events: eventsList || [],
    fetchNextPage,
    totalPages: data?.pages[0]?.totalPages || 0,
    hasNextPage,
    isLoading,
    isEmpty: eventsList?.length === 0,
    isSearchResultEmpty: eventsList?.length === 0 && typesFilter.length !== sortByTypeMap.length,
  };
};
