import { FC, ReactNode } from 'react';

import { EventTypeBackend } from '../../../../../types/events.types';
import { tableHeadVariant } from './types';

export type BasicTableProps = {
  eventType: EventTypeBackend;
  children: ReactNode;
};

export const BasicTable: FC<BasicTableProps> = ({ eventType, children }) => (
  <table className="shrink-0 overflow-hidden text-nowrap rounded-md">
    <thead className="h-11 rounded-t-md border-b border-[#F2F2F2] bg-[#F9FAFB]">
      <tr>
        {tableHeadVariant[eventType].map((fieldName) => (
          <th className="pl-4 text-left" key={fieldName}>
            <span className="text-label-md font-medium text-[#172335]">{fieldName}</span>
          </th>
        ))}
      </tr>
    </thead>
    <tbody className="divide-y divide-gray-100 border-b border-[#F2F2F2]">{children}</tbody>
  </table>
);
