import { Listbox, ListboxProps } from '@headlessui/react';
import React, { ElementRef, forwardRef, memo } from 'react';

export type SelectProps = ListboxProps;

const Select = memo(
  forwardRef<ElementRef<typeof Listbox>, SelectProps>((props, ref) => (
    <Listbox ref={ref} {...props}></Listbox>
  )),
);

Select.displayName = 'Select';

export default Select;
