import React, { FC, ReactNode } from 'react';

import { SideBarContext } from '../../storage/sideBarContext';
import SideBar from '../SideBar/SideBar';

export type SideBarLayoutProps = { children: ReactNode };

const SideBarLayout: FC<SideBarLayoutProps> = ({ children }) => (
  <SideBarContext>
    <div className="flex h-full w-full">
      <SideBar />
      {children}
    </div>
  </SideBarContext>
);

SideBarLayout.displayName = 'SideBarLayout';

export default SideBarLayout;
