import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type DownloadIconProps = IconProps;

const DownloadIcon: FC<DownloadIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="18"
    viewBox="0 0 17 18"
    width="17"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 11.5V12.5C16 13.9001 16 14.6002 15.7275 15.135C15.4878 15.6054 15.1054 15.9878 14.635 16.2275C14.1002 16.5 13.4001 16.5 12 16.5H5C3.59987 16.5 2.8998 16.5 2.36502 16.2275C1.89462 15.9878 1.51217 15.6054 1.27248 15.135C1 14.6002 1 13.9001 1 12.5V11.5M12.6667 7.33333L8.5 11.5M8.5 11.5L4.33333 7.33333M8.5 11.5V1.5"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);

export default DownloadIcon;
