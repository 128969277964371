import React, { DetailedHTMLProps, FC, HTMLAttributes, ReactNode } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

export type CompanyCardItemProps = {
  title: ReactNode;
  value: string | ReactNode;
  titleClassName?: string;
  valueClassName?: string;
  className?: string;
} & Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'title'>;

const CompanyCardItem: FC<CompanyCardItemProps> = ({
  title,
  value,
  titleClassName,
  valueClassName,
  className,
  ...props
}) => (
  <div
    className={twMerge(
      'flex w-full items-center justify-between px-4 py-[10px] text-gray-700',
      className,
    )}
    {...props}
  >
    <span className={twMerge('text-label-md', titleClassName)}>{title}</span>
    <span className={twJoin('text-label-md', valueClassName)}>{value}</span>
  </div>
);

export default CompanyCardItem;
