import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type DashboardIconProps = IconProps;

const DashboardIcon: FC<DashboardIconProps> = ({ iconColor = '#2565C8', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="18"
    viewBox="0 0 20 18"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99984 12.3333V16.5M14.9998 16.5L11.707 13.7559C11.0997 13.2498 10.796 12.9968 10.4571 12.9001C10.1582 12.8148 9.84147 12.8148 9.54262 12.9001C9.20365 12.9968 8.89999 13.2498 8.29268 13.7559L4.99984 16.5M6.6665 8.16662V8.99995M9.99984 6.49995V8.99995M13.3332 4.83329V8.99995M18.3332 1.49995H1.6665M2.49984 1.49995H17.4998V8.33329C17.4998 9.73342 17.4998 10.4335 17.2274 10.9683C16.9877 11.4387 16.6052 11.8211 16.1348 12.0608C15.6 12.3333 14.9 12.3333 13.4998 12.3333H6.49984C5.09971 12.3333 4.39964 12.3333 3.86486 12.0608C3.39446 11.8211 3.012 11.4387 2.77232 10.9683C2.49984 10.4335 2.49984 9.73342 2.49984 8.33329V1.49995Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

DashboardIcon.displayName = 'DashboardIcon';

export default DashboardIcon;
