import React, { FC } from 'react';

import { IconProps } from './type';

export type SuccessCircleIconProps = IconProps;

const SuccessCircleIcon: FC<SuccessCircleIconProps> = ({ iconColor = '#667085' }) => (
  <svg fill="none" height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 5.54286V6.00286C10.9994 7.08107 10.6503 8.1302 10.0047 8.99377C9.35908 9.85735 8.45164 10.4891 7.41768 10.7948C6.38372 11.1005 5.27863 11.0638 4.26724 10.6902C3.25584 10.3165 2.39233 9.62591 1.80548 8.7214C1.21863 7.81688 0.939896 6.74689 1.01084 5.67102C1.08178 4.59514 1.4986 3.57103 2.19914 2.7514C2.89968 1.93177 3.84639 1.36055 4.89809 1.12293C5.9498 0.885317 7.05013 0.99403 8.035 1.43286M11 2L6 7.005L4.5 5.505"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
  </svg>
);

export default SuccessCircleIcon;
