import { useFormat, useLocale } from '../../hooks';
import { StringKey } from '../../lang';
import { AppFormattedMessage } from '../AppFormattedMessage';

const getMultiplePrefix = ({
  isMultiple,
  singularStr,
  pLuralStr,
  time,
}: {
  isMultiple: boolean;
  singularStr: string;
  pLuralStr?: string;
  time?: number | string;
}) => {
  return isMultiple ? `${time || 1} ${pLuralStr}` : `${time} ${singularStr}`;
};

export const FormattedDateDistance = ({ date }: { date: Date }) => {
  const { format } = useFormat();
  const diffInMs = new Date().getTime() - (date.getTime() - 100);
  const diffInSeconds = Math.floor(diffInMs / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = Math.floor(diffInDays / 30);
  const { messagesLocale } = useLocale();

  if (diffInSeconds < 60) return <AppFormattedMessage id={StringKey.LESS_THAN_MIN_AGO} />;

  if (diffInMinutes < 60)
    return (
      <AppFormattedMessage
        id={StringKey.MINUTES_AGO}
        values={{
          date: getMultiplePrefix({
            isMultiple: diffInMinutes >= 2,
            singularStr: messagesLocale[StringKey.SINGULAR_MIN],
            pLuralStr: messagesLocale[StringKey.PLURAL_MIN],
            time: diffInMinutes,
          }),
        }}
      />
    );

  if (diffInHours < 24)
    return (
      <AppFormattedMessage
        id={StringKey.HOURS_AGO}
        values={{
          date: getMultiplePrefix({
            isMultiple: diffInHours >= 2,
            singularStr: messagesLocale[StringKey.SINGULAR_HOUR],
            pLuralStr: messagesLocale[StringKey.PLURAL_HOUR],
            time: diffInHours,
          }),
        }}
      />
    );

  if (diffInDays < 30)
    return (
      <AppFormattedMessage
        id={StringKey.DAYS_AGO}
        values={{
          date: getMultiplePrefix({
            isMultiple: diffInDays >= 2,
            singularStr: messagesLocale[StringKey.SINGULAR_DAY],
            pLuralStr: messagesLocale[StringKey.PLURAL_DAY],
            time: diffInDays,
          }),
        }}
      />
    );

  if (diffInDays < 365)
    return (
      <AppFormattedMessage
        id={StringKey.MONTHS_AGO}
        values={{
          date: getMultiplePrefix({
            isMultiple: diffInDays >= 2,
            singularStr: messagesLocale[StringKey.SINGULAR_MONTH],
            pLuralStr: messagesLocale[StringKey.PLURAL_MONTH],
            time: diffInMonths,
          }),
        }}
      />
    );

  return format(date, 'dd/MM/yyy');
};
