import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type ArchiveIconProps = IconProps;

const ArchiveIcon: FC<ArchiveIconProps> = ({ iconColor = '#F04438', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 16 14"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.66665 4.33106C2.55731 4.32824 2.47791 4.32191 2.40653 4.30771C1.87761 4.2025 1.46414 3.78904 1.35893 3.26012C1.33331 3.13132 1.33331 2.97644 1.33331 2.66667C1.33331 2.3569 1.33331 2.20201 1.35893 2.07321C1.46414 1.54429 1.87761 1.13083 2.40653 1.02562C2.53532 1 2.69021 1 2.99998 1H13C13.3097 1 13.4646 1 13.5934 1.02562C14.1224 1.13083 14.5358 1.54429 14.641 2.07321C14.6666 2.20201 14.6666 2.3569 14.6666 2.66667C14.6666 2.97644 14.6666 3.13132 14.641 3.26012C14.5358 3.78904 14.1224 4.2025 13.5934 4.30771C13.522 4.32191 13.4427 4.32824 13.3333 4.33106M6.66665 7.66667H9.33331M2.66665 4.33333H13.3333V9.8C13.3333 10.9201 13.3333 11.4802 13.1153 11.908C12.9236 12.2843 12.6176 12.5903 12.2413 12.782C11.8135 13 11.2534 13 10.1333 13H5.86665C4.74654 13 4.18649 13 3.75867 12.782C3.38234 12.5903 3.07638 12.2843 2.88463 11.908C2.66665 11.4802 2.66665 10.9201 2.66665 9.8V4.33333Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);
export default ArchiveIcon;
