import { RadioGroup as HeadlessRadioGroup } from '@headlessui/react';
import React, { ComponentPropsWithoutRef, ElementRef, forwardRef, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export type RadioGroupProps = {
  errorMessage?: false | string;
  wrapperClassName?: string;
  label?: ReactNode;
  labelClassName?: string;
} & ComponentPropsWithoutRef<typeof HeadlessRadioGroup>;

const RadioGroup = forwardRef<ElementRef<typeof HeadlessRadioGroup>, RadioGroupProps>(
  ({ children, className, wrapperClassName, label, labelClassName, ...props }, ref) => (
    <div className={twMerge('flex flex-col gap-3', wrapperClassName)}>
      {label && (
        <span className={twMerge('text-xs font-normal text-gray-500', labelClassName)}>
          {label}
        </span>
      )}
      <HeadlessRadioGroup
        aria-label="Filter"
        className={twMerge(
          'flex w-full divide-x overflow-hidden rounded border-gray-200',
          className,
        )}
        ref={ref}
        {...props}
      >
        {children}
      </HeadlessRadioGroup>
    </div>
  ),
);

RadioGroup.displayName = 'RadioGroups';

export default RadioGroup;
