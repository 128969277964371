import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CapQuestLogo } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button/Button';
import { Input } from '../../components/Input';
import LineDelimiter from '../../components/LineDelimiter';
import { BackendRoute } from '../../config';
import { HttpException } from '../../exceptions';
import { StringKey } from '../../lang';
import { ApiService } from '../../services';
const ForgotPassword = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [isLinkSent, setIsLinkSent] = useState(false);

  const recoveryLinkMutation = useMutation<void, HttpException>({
    mutationFn: async () => {
      await ApiService.post({ endpoint: BackendRoute.RECOVERY }, { body: { email } });
    },
    onSuccess: () => setIsLinkSent(true),
  });

  const recoveryResendLinkMutation = useMutation<void, HttpException>({
    mutationFn: async () => {
      await ApiService.post({ endpoint: BackendRoute.RECOVERY_RESEND }, { body: { email } });
    },
    onSuccess: () => toast.success('Successfully resend recovery link'),
    onError: (e) => toast.error(e.body?.message),
  });

  return (
    <div className="flex h-full items-center justify-center overflow-y-auto bg-gray-50 font-inter">
      <div className="max-h-full max-lg:h-full max-lg:w-full">
        <div
          className="flex h-fit min-h-full w-full flex-col gap-8 rounded-lg bg-white px-4 pb-8 pt-16 lg:h-fit lg:w-[458px] lg:items-center lg:px-8 lg:pt-8 lg:text-center"
          style={{
            filter:
              'drop-shadow(0px 1px 2px #1018280F) drop-shadow(0px 2px 3px #1018281A) drop-shadow(0px -1px 3px #1018280A)',
          }}
        >
          <CapQuestLogo size={48} />

          <div className="flex flex-col gap-2">
            <div
              className="!bg-clip-text text-4xl-mobile font-bold text-transparent lg:text-4xl"
              style={{
                background: 'linear-gradient(132.59deg, #475467 29.58%, #101828 84.27%)',
              }}
            >
              {isLinkSent ? (
                <AppFormattedMessage id={StringKey.CHECK_YOUR_EMAIL} />
              ) : (
                <>
                  <AppFormattedMessage id={StringKey.FORGOT_PASSWORD} />?
                </>
              )}
            </div>
            <div className="text-sm text-gray-500">
              {isLinkSent ? (
                <AppFormattedMessage id={StringKey.PLEASE_CHECK_THE_EMAIL} values={{ email }} />
              ) : (
                <>
                  <AppFormattedMessage id={StringKey.ENTER_YOUR_EMAIL_ADDRESS_AND_WE_WILL} />
                </>
              )}
            </div>
          </div>

          {!isLinkSent && (
            <div className="flex w-full flex-col gap-10">
              <Input
                errorMessage={recoveryLinkMutation.error?.body?.message}
                onChange={(value) => setEmail(value)}
                placeholder={<AppFormattedMessage id={StringKey.EMAIL_ADDRESS} />}
                type="email"
                value={email}
              />
              <Button
                disabled={!email}
                isLoading={recoveryLinkMutation.isPending}
                onClick={() => recoveryLinkMutation.mutate()}
              >
                <AppFormattedMessage id={StringKey.SEND_EMAIL} />
              </Button>
            </div>
          )}

          <LineDelimiter />

          {isLinkSent ? (
            <button
              className="w-fit text-sm font-[450] text-brand-700 underline"
              onClick={() => recoveryResendLinkMutation.mutate()}
            >
              <AppFormattedMessage id={StringKey.RESEND_EMAIL} />
            </button>
          ) : (
            <button
              className="w-fit text-sm font-[450] text-brand-700 underline"
              onClick={() => navigate('/login')}
            >
              <AppFormattedMessage id={StringKey.BACK_TO_SIGN_IN} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
