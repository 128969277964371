import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  AddDocumentIcon,
  CircleConfirmIcon,
  CloseCircleIcon,
  EditIcon,
  ThreeDotsIcon,
  TrashBinIcon,
} from '../../../assets/icons';
import { AlertDialogWrapper } from '../../../components/AlertDialog';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../components/HoverCard';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { useFormat, useModalState } from '../../../hooks';
import { StringKey } from '../../../lang';
import { ShareClass } from '../../../types/share-classes.types';
import { getRoundedNumber } from '../../../utils/getRoundedNumber';
import { getS3FileOriginalName } from '../../../utils/getS3FileOriginalName';
import { DeleteShareClassModal } from '../Modals/DeleteShareClassModal';

export type ShareClassItemProps = {
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  stakeholders: number;
  handleDocumentClick: (id: string) => void;
} & Pick<
  ShareClass,
  | 'antiDilutionRights'
  | 'conversionRatio'
  | 'creationDate'
  | 'dividendRightEnabled'
  | 'docLink'
  | 'id'
  | 'isDefault'
  | 'liquidityPreferences'
  | 'name'
  | 'votingRight'
  | 'isUsed'
>;

export const ShareClassTableItem: FC<ShareClassItemProps> = ({
  id,
  handleDelete,
  handleEdit,
  handleDocumentClick,
  name,
  conversionRatio,
  dividendRightEnabled,
  votingRight,
  antiDilutionRights,
  creationDate,
  stakeholders,
  docLink,
  liquidityPreferences,
  isDefault,
  isUsed,
}) => {
  const { handleCloseModal, handleSuccessModal, handleOpenModal, isOpen, toggler } = useModalState({
    onSuccess: () => handleDelete(id),
  });
  const { format } = useFormat();
  return (
    <>
      <AlertDialogWrapper control={{ onOpenChange: toggler, open: isOpen }}>
        <DeleteShareClassModal onClose={handleCloseModal} onSuccess={handleSuccessModal} />
      </AlertDialogWrapper>
      <tr className="w-full bg-white" key={id}>
        <td className="max-w-[300px] truncate p-4 text-sm text-gray-700">{name}</td>
        <td className="p-4 text-sm text-gray-700">{format(creationDate || '', 'dd/MM/yyy')}</td>
        <td className="p-4 text-sm text-gray-700">{stakeholders}</td>
        <td className="p-4">
          <div className="flex flex-col gap-2 text-xs text-gray-700">
            <div className="flex items-center gap-1">
              {dividendRightEnabled ? (
                <CircleConfirmIcon />
              ) : (
                <CloseCircleIcon className="size-4" />
              )}
              Dividends
            </div>

            <div className="flex items-center gap-1">
              {antiDilutionRights ? <CircleConfirmIcon /> : <CloseCircleIcon className="size-4" />}
              Anti - Dilution
            </div>
          </div>
        </td>
        <td className="p-4 text-sm text-gray-700">{votingRight}</td>
        <td className="p-4 text-sm text-gray-700">
          {conversionRatio ? getRoundedNumber(conversionRatio) + 'x' : '-'}
        </td>
        <td className="p-4 text-sm text-gray-700">
          {liquidityPreferences ? liquidityPreferences?.seniority : '-'}
        </td>

        <td className="p-4 text-xs text-gray-700">
          {liquidityPreferences ? (
            <div className="flex flex-col text-wrap">
              <span>Multiple: {liquidityPreferences.multiple}</span>
              {liquidityPreferences.participating?.capValue && (
                <span>Participating Cap: {liquidityPreferences.participating?.capValue}</span>
              )}
              {liquidityPreferences.participating?.interest && (
                <span>Interest: {liquidityPreferences.participating?.interest}%</span>
              )}
            </div>
          ) : (
            '-'
          )}
        </td>
        <td
          className="p-4 text-sm text-gray-700"
          onClick={() => docLink && handleDocumentClick(docLink)}
        >
          <HoverCard>
            <HoverCardTrigger>
              {docLink ? <AddDocumentIcon className="cursor-pointer" /> : '-'}
            </HoverCardTrigger>
            {docLink && (
              <HoverCardContent className="max-w-[360px] rounded-lg bg-[#101828] px-3 py-2">
                <span className="block w-full truncate p-0 text-label-md font-[550] text-white">
                  {getS3FileOriginalName(docLink)}
                </span>
              </HoverCardContent>
            )}
          </HoverCard>
        </td>

        <td className="p-4 text-label-md font-[450]">
          <div className="flex h-full w-full shrink-0 items-center justify-between gap-4 pr-4">
            {isUsed ? (
              <span className="rounded-2xl bg-forest-50 px-2 py-[2px] text-forest-600">In Use</span>
            ) : (
              <span className="rounded-2xl bg-fireside-50 px-2 py-[2px] text-fireside-600">
                Not Used
              </span>
            )}
            <Popover>
              <PopoverTrigger
                className="h-fit w-fit rounded data-[state=open]:shadow-md"
                disabled={isDefault}
              >
                <div
                  className={twMerge(
                    'flex size-6 rotate-90 items-center justify-center rounded bg-transparent transition-colors',
                    !isDefault && 'hover:bg-gray-100',
                  )}
                >
                  <ThreeDotsIcon iconColor={isDefault ? '#D0D5DD' : '#344054'} />
                </div>
              </PopoverTrigger>
              <PopoverContent className="flex w-fit flex-col gap-[2px] rounded p-2 font-normal shadow-2xl [&>*]:font-inter [&>*]:font-normal">
                <PopoverClose>
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-gray-700 hover:bg-gray-50"
                    onClick={() => handleEdit(id)}
                  >
                    <EditIcon className="size-4" />
                    <span className="text-sm font-normal text-gray-700">Edit</span>
                  </div>
                  <div
                    className="flex w-full items-center gap-2 p-[6px] pr-8 text-sm text-fireside-500 hover:bg-gray-50"
                    onClick={handleOpenModal}
                  >
                    <TrashBinIcon className="size-4" iconColor="#F04438" />
                    <span className="text-sm font-normal text-fireside-500">
                      <AppFormattedMessage id={StringKey.DELETE} />
                    </span>
                  </div>
                </PopoverClose>
              </PopoverContent>
            </Popover>
          </div>
        </td>
      </tr>
    </>
  );
};
