import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const { search } = useLocation();

  const params = useMemo(() => new URLSearchParams(search), [search]);

  const get = (key: string) => params.get(key);

  const deleteParam = (key: string) => params.delete(key);

  const set = (key: string, value: string) => params.set(key, value);

  return { get, deleteParam, set };
};
