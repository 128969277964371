const baseApiUrl = 'https://api.countrystatecity.in/v1/countries';

export type CountryInfoDataType = {
  id: number;
  name: string;
  iso2: string;
  iso3: string;
  phonecode: number;
  capital: string;
  currency: string;
  native: string;
  emoji: string;
};

export type StateInfoDataType = {
  id: number;
  name: string;
  iso2: string;
};

export type CityInfoDataType = {
  id: number;
  name: string;
};

export enum RequestType {
  GET_ALL_COUNTRY_INFO = 'get_all_country_info',
  GET_STATES_BY_COUNTRY = 'get_states_by_country',
  GET_CITIES_BY_COUNTRY_AND_STATE = 'get_cities_by_country',
}

const defaultHeaders = new Headers();
defaultHeaders.append('X-CSCAPI-KEY', process.env.REACT_APP_COUNTRY_INFO_API_KEY ?? '');

export type ResponseDataType<T extends RequestType> = T extends RequestType.GET_ALL_COUNTRY_INFO
  ? CountryInfoDataType[]
  : T extends RequestType.GET_STATES_BY_COUNTRY
    ? StateInfoDataType[]
    : CityInfoDataType[];

const requestVariants: Record<RequestType, (country: string, state: string) => string> = {
  [RequestType.GET_ALL_COUNTRY_INFO]: () => baseApiUrl,
  [RequestType.GET_STATES_BY_COUNTRY]: (country) => `${baseApiUrl}/${country}/states`,
  [RequestType.GET_CITIES_BY_COUNTRY_AND_STATE]: (country, state) =>
    `${baseApiUrl}/${country}/states/${state}/cities`,
};

const ApiCountryInfoService = async <T extends RequestType>({
  requestType,
  countryCode = 'af',
  stateCode = 'gha',
}: {
  requestType: T;
  countryCode?: string;
  stateCode?: string;
}): Promise<ResponseDataType<T>> => {
  const url = requestVariants[requestType](countryCode, stateCode);
  const response = await fetch(url, { headers: defaultHeaders });
  const data = (await response.json()) as ResponseDataType<T>;

  if (!Array.isArray(data)) {
    throw new Error('Expected an array response');
  }

  return data;
};

export default ApiCountryInfoService;
