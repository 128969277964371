import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDelete = () => {
  const { mutate: deletePool, isPending } = useAppMutation([QueryKey.DELETE_POOL], {
    mutationFn: ({ companyId, poolId }: { companyId: string; poolId: string }) =>
      ApiService.delete({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'pools', poolId],
      }),
  });
  return { deletePool, isPending };
};
