import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IntlProvider } from 'react-intl';

import { Locale, localeMessagesMap } from '../lang';
import { localeStorage } from '../storage/localeStorage';

export const localeContext = createContext<{ locale: Locale; setLocale: (state: Locale) => void }>({
  locale: Locale.EN,
  setLocale: () => {},
});
type LocaleProvider = {
  children: ReactNode;
};

export const LocaleProvider: FC<LocaleProvider> = ({ children }) => {
  const [locale, setLocale] = useState<Locale>(Locale.EN);

  const handleChangeLocale = useCallback((locale: Locale) => {
    localeStorage.set(locale);
    setLocale(locale);
  }, []);

  useEffect(() => {
    const localStorageLocale = localeStorage.get();

    if (localStorageLocale) return setLocale(localStorageLocale);

    const navigatorLocale = navigator.language.toLowerCase();
    if (!navigatorLocale.startsWith('fr')) return;
    setLocale(Locale.FR);
  }, [handleChangeLocale]);
  return (
    <localeContext.Provider value={{ locale, setLocale: handleChangeLocale }}>
      <IntlProvider locale={locale} messages={localeMessagesMap[locale]}>
        {children}
      </IntlProvider>
    </localeContext.Provider>
  );
};

export const useLocaleContext = () => useContext(localeContext);
