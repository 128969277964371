import React, { FC, memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ArchiveIcon, ThreeDotsIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { DeprecatedModalWrapper } from '../../../components/Modal';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import { Company as CompanyHook, useFormat, useModalState } from '../../../hooks';
import { StringKey } from '../../../lang';
import { Company } from '../../../types/companyTypes';
import CompanyCardItem from './CompanyCardItem';
import DeleteInactiveCompanyModal from './DeleteInactiveCompanyModal';

export type IncompleteCompanyCardProps = {
  company: Company;
  invalidateQuery: () => void;
};

const IncompleteCompanyCard: FC<IncompleteCompanyCardProps> = memo(
  ({ company: { name, updatedAt, id, logoImgSrc }, invalidateQuery }) => {
    const navigate = useNavigate();
    const { format } = useFormat();
    const {
      isOpen: isOpenModal,
      handleOpenModal,
      handleSuccessModal,
      handleDismissModal,
    } = useModalState({
      onSuccess: () => deleteCompany({ companyId: id }, { onSuccess: invalidateQuery }),
    });

    const createdAtFormattedData = useMemo(
      () => format(updatedAt, 'dd/M/yyyy'),
      [format, updatedAt],
    );

    const { deleteCompany } = CompanyHook.useDelete();

    return (
      <>
        <DeprecatedModalWrapper isOpen={isOpenModal}>
          <DeleteInactiveCompanyModal onClose={handleDismissModal} onSuccess={handleSuccessModal} />
        </DeprecatedModalWrapper>
        <div
          className={
            'flex w-full max-w-[400px] flex-col gap-2 rounded-lg bg-gray-50 p-[2px] pt-2 text-center text-gray-300'
          }
        >
          <span
            className="text-label-sm font-bold uppercase"
            style={{
              fontFamily: 'Inter',
              fontSize: '10px',
              fontWeight: '700',
              lineHeight: '16px',
              letterSpacing: '1px',
              textAlign: 'center',
            }}
          >
            <AppFormattedMessage id={StringKey.INCOMPLETE_SETUP} />
          </span>

          <div className="flex h-full w-full flex-col divide-y rounded-md bg-white">
            <div className="flex w-full justify-between p-4">
              <div className="flex items-center gap-2 text-gray-300">
                {logoImgSrc ? (
                  <img
                    alt=""
                    className="size-9 rounded-full bg-gray-100 object-cover"
                    src={logoImgSrc}
                  />
                ) : (
                  <div className="size-9 rounded-full bg-gray-100 object-cover" />
                )}
                <span className="font-[550]">{name}</span>
              </div>
              <Popover>
                <PopoverTrigger>
                  <div className="flex size-6 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                    <ThreeDotsIcon />
                  </div>
                </PopoverTrigger>
                <PopoverContent className="flex w-fit flex-col rounded p-0 text-sm font-normal shadow-2xl">
                  <PopoverClose>
                    <div
                      className="flex items-center justify-center gap-1 p-3 pr-10 text-fireside-500 hover:bg-gray-50"
                      onClick={handleOpenModal}
                    >
                      <ArchiveIcon />
                      <span>
                        <AppFormattedMessage id={StringKey.DELETE} />
                      </span>
                    </div>
                  </PopoverClose>
                </PopoverContent>
              </Popover>
            </div>
            <div className="relative h-full w-full">
              <CompanyCardItem
                className="blur-md"
                title={<AppFormattedMessage id={StringKey.UPDATE} />}
                value="Some Data"
              />
              <CompanyCardItem
                className="blur-md"
                title={<AppFormattedMessage id={StringKey.ACCOUNT} />}
                value="Some Data"
              />
              <CompanyCardItem
                className="blur-md"
                title={<AppFormattedMessage id={StringKey.USER_ROLE} />}
                value="Some Data"
              />
              <CompanyCardItem
                className="blur-md"
                title={<AppFormattedMessage id={StringKey.DATE_CREATED} />}
                value="Some Data"
              />

              <div className="absolute left-0 top-0 z-4 flex h-full w-full flex-col items-center justify-center gap-2 text-label-sm font-[450] text-gray-500">
                <span>
                  <AppFormattedMessage id={StringKey.RESUME_COMPANY_SETUP} />
                </span>
                <Button
                  className="border-[1px] border-gray-300 px-4 py-[6px] text-sm font-normal text-gray-700 transition-colors hover:bg-gray-100"
                  onClick={() => navigate(`/company-setup/${id}`)}
                  styleType="DEFAULT_ROUNDED"
                >
                  <AppFormattedMessage id={StringKey.RESUME} />
                </Button>
                <span>
                  <AppFormattedMessage
                    id={StringKey.LAST_UPDATE}
                    values={{ date: createdAtFormattedData }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  },
);

IncompleteCompanyCard.displayName = 'IncompleteCompanyCard';

export default IncompleteCompanyCard;
