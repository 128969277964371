import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useInvitation = () => {
  const { mutate: cancelInvitation, isPending: isPendingCancelInvite } = useAppMutation(
    [QueryKey.CANCEL_STAKEHOLDER_INVITATION],
    {
      mutationFn: ({ companyId, stakeholderId }: { companyId: string; stakeholderId: string }) =>
        ApiService.post({
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'stakeholders', stakeholderId, 'cancel-invitation'],
        }),
    },
  );

  const { mutate: invite, isPending: isPendingInvite } = useAppMutation(
    [QueryKey.INVITE_STAKEHOLDER],
    {
      mutationFn: ({ companyId, stakeholderId }: { companyId: string; stakeholderId: string }) =>
        ApiService.post({
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'stakeholders', stakeholderId, 'invite'],
        }),
    },
  );

  return { cancelInvitation, isPendingInvite, invite, isPendingCancelInvite };
};
