import React, { memo, ReactElement, useCallback } from 'react';
import { Control, FieldPathByValue, FieldValues, PathValue, useController } from 'react-hook-form';

import ComboboxInput, { ComboboxInputProps } from './ComboboxInput';

export type FormComboboxInputProps<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
> = {
  controlled?: boolean;
  control: Control<TFieldValues>;
  defaultValue?: PathValue<TFieldValues, TPath>;
  name: TPath;
  onBlur?: () => void;
  customValue?: (value?: PathValue<TFieldValues, TPath>) => string | undefined;
} & Omit<ComboboxInputProps, 'defaultValue' | 'onBlur' | 'onChange' | 'value'>;

const FormComboboxInput = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, unknown>,
>({
  control,
  defaultValue,
  name,
  customValue,
  onBlur,
  ...props
}: FormComboboxInputProps<TFieldValues, TPath>): ReactElement | null => {
  const { field, fieldState } = useController({
    control,
    defaultValue,
    name,
  });
  const handleChange = useCallback((value: string) => field.onChange(() => value), [field]);

  const handleBlur = useCallback(() => {
    field?.onBlur();
    onBlur?.();
  }, [field, onBlur]);
  return (
    <ComboboxInput
      {...props}
      {...field}
      errorMessage={fieldState.error?.message ?? fieldState.error?.type}
      onBlur={handleBlur}
      onChange={handleChange}
      value={customValue ? (customValue(field.value) ?? '') : field.value || ''}
    />
  );
};

export default memo(FormComboboxInput) as typeof FormComboboxInput;
