import { formatDistanceStrict } from 'date-fns';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { dateFnsLocaleMap, Locale, StringKey } from '../../../lang';

export type CompanyDeactivateModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  expDate: number;
};
const CompanyDeactivateModal: FC<CompanyDeactivateModalProps> = ({ onClose, onSuccess }) => {
  const { locale } = useIntl();
  const sixMonthDate = new Date();
  sixMonthDate.setMonth(sixMonthDate.getMonth() + 6);
  return (
    <div className="flex w-full max-w-[450px] flex-col rounded bg-white p-4 text-xs font-normal text-gray-500 shadow-xl">
      <span className="text-xl font-semibold text-gray-700">
        <AppFormattedMessage id={StringKey.DEACTIVATE_COMPANY} />?
      </span>
      <span>Are you sure you want to deactivate the company?</span>
      <span className="mt-4 text-wrap">
        <AppFormattedMessage
          id={StringKey.LOSE_ACCESS_CAUTION}
          values={{
            time: formatDistanceStrict(new Date(), sixMonthDate, {
              locale: dateFnsLocaleMap[locale as Locale],
            }),
          }}
        />
      </span>
      <div className="mt-8 flex justify-end gap-2">
        <Button
          className="rounded border-[1px] border-gray-300 text-sm text-gray-700"
          onClick={onClose}
          styleType="DEFAULT_ROUNDED"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <Button
          className="rounded border-gray-300 bg-fireside-600 text-sm font-semibold text-gray-25"
          onClick={onSuccess}
          styleType="DEFAULT_ROUNDED"
        >
          <AppFormattedMessage id={StringKey.CONFIRM_DEACTIVATE} />
        </Button>
      </div>
    </div>
  );
};

export default CompanyDeactivateModal;
