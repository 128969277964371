import { FC } from 'react';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { StringKey } from '../../lang';

export type DeletePoolModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  text: string;
};

export const DeleteModal: FC<DeletePoolModalProps> = ({ onClose, onSuccess, text }) => (
  <div className="m-2 flex w-full min-w-[300px] flex-col gap-8 rounded bg-white p-4 xlg:w-[453px]">
    <div className="flex flex-col gap-1">
      <span className="text-xl font-[550] text-gray-700">Delete {text}?</span>
      <span className="text-xs font-[450] text-gray-500">
        All of the data associated to the {text.toLowerCase()} will be lost.
      </span>
    </div>
    <div className="flex h-11 justify-end gap-4">
      <Button
        className="h-full w-fit rounded border border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700"
        onClick={onClose}
        styleType="NONE"
      >
        <AppFormattedMessage id={StringKey.NO_KEEP_3} />
      </Button>
      <Button
        className="h-full w-fit bg-fireside-600 px-6 py-[10px] text-sm font-[550] text-white"
        onClick={onSuccess}
        styleType="NONE"
      >
        <AppFormattedMessage id={StringKey.CONFIRM_DELETE} />
      </Button>
    </div>
  </div>
);
