import { Transition } from '@headlessui/react';
import React, { Dispatch, FC, Fragment, useContext } from 'react';

import { ChevronDownIcon, ExcerciseIcon, ExportIcon, PlusIcon } from '../../assets/icons';
import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import Button from '../../components/Button';
import { DatePicker } from '../../components/DatePicker';
import { Radio, RadioGroup } from '../../components/RadioGroup';
import { Select, SelectButton, SelectOption, SelectOptions } from '../../components/Select';
import { useScreenSize } from '../../hooks';
import { StringKey } from '../../lang';
import { sideBarContext } from '../../storage/sideBarContext';
import {
  PoolPlansTab,
  PoolsPlansFilter,
  PoolsPlansSelect,
  SelectVariant,
} from '../../types/pool-plans.types';
import { toUpperCaseWords } from '../../utils/toUppercaseUtil';

export type PoolPlansHeaderProps = {
  setSelectedFilter: (filter: PoolsPlansFilter) => void;
  setSelected: Dispatch<React.SetStateAction<SelectVariant | undefined>>;
  selectedFilter: PoolsPlansFilter;
  selected: SelectVariant | undefined;
  selectedTab: PoolPlansTab;
  onAddPoolClick?: () => void;
  onAddPlanClick?: () => void;
  onExportClick?: () => void;
};

const selectVariant = Object.values(PoolsPlansSelect);

const PoolPlansHeader: FC<PoolPlansHeaderProps> = ({
  setSelectedFilter,
  setSelected,
  selectedFilter,
  selected,
  selectedTab,
  onAddPoolClick,
  onAddPlanClick,
  onExportClick,
}) => {
  const { isOpen: isSideBarExpanded } = useContext(sideBarContext);
  const { width } = useScreenSize();

  const handleSelect = (select: SelectVariant) => {
    if (select === PoolsPlansSelect.NEW_POOL) onAddPoolClick?.();
    if (select === PoolsPlansSelect.NEW_PLAN) onAddPlanClick?.();
    if (select === 'export') onExportClick?.();

    setSelected(select);
  };
  const isTablet = width <= 1233;

  return (
    <div className="flex w-full items-center justify-between px-3">
      <RadioGroup
        className="h-fit w-full divide-x divide-gray-200 overflow-hidden rounded border border-gray-200"
        onChange={setSelectedFilter}
        value={selectedFilter}
      >
        <Radio
          className="flex h-9 w-full items-center justify-center px-4 text-sm font-[450] text-[#858593] data-[checked]:bg-brand-25 data-[checked]:font-[550] data-[checked]:text-[#172335]"
          styleType="NONE"
          value={PoolsPlansFilter.BY_POOLS}
        >
          <span className="overflow-hidden text-ellipsis whitespace-nowrap">By Pools</span>
        </Radio>
        {selectedTab === PoolPlansTab.ACTIVE && (
          <Radio
            className="flex h-9 w-full items-center justify-center px-4 text-sm font-[450] text-[#858593] data-[checked]:bg-brand-25 data-[checked]:font-[550] data-[checked]:text-[#172335]"
            styleType="NONE"
            value={PoolsPlansFilter.BY_STAKEHOLDER}
          >
            <span className="overflow-hidden text-ellipsis whitespace-nowrap">By Stakeholder</span>
          </Radio>
        )}
      </RadioGroup>
      <div className="flex items-center gap-2">
        <span className="w-full text-sm font-[450] text-gray-700">
          {isSideBarExpanded && isTablet ? '' : 'Vest by:'}
        </span>
        <DatePicker className="rounded-tl-[4px] rounded-tr-[4px] border-b-[1px] border-gray-700 bg-gray-50 text-sm" />
        <Button className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] lg:w-fit">
          <ExcerciseIcon />
          <span className="text-sm font-[450] text-gray-700 max-lg:hidden">
            {isSideBarExpanded && isTablet ? '' : 'Exercise'}
          </span>
        </Button>
        <Button
          className="flex size-9 gap-1 border-[1px] border-gray-100 bg-gray-25 px-3 py-[6px] lg:w-fit"
          onClick={() => handleSelect('export')}
        >
          <ExportIcon />
          <span className="text-sm font-[450] text-gray-700 max-lg:hidden">
            <AppFormattedMessage id={StringKey.EXPORT} />
          </span>
        </Button>
        <Select onChange={handleSelect} value={selected}>
          {({ open }) => (
            <>
              <div className="relative rounded border-gray-200 px-[6px] py-2">
                <SelectButton className="bg-brand-700">
                  <PlusIcon />
                  <span className="text-sm font-[450] text-white">Add</span>
                  <ChevronDownIcon className="ml-2 h-4 w-4" iconColor="#ffffff" />
                </SelectButton>

                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                  show={open}
                >
                  <SelectOptions className="w-[115px]">
                    {selectVariant.map((select) => (
                      <SelectOption key={select} selectedClassName="bg-white" value={select}>
                        <span className="block text-sm font-normal text-gray-700">
                          {toUpperCaseWords(select)}
                        </span>
                      </SelectOption>
                    ))}
                  </SelectOptions>
                </Transition>
              </div>
            </>
          )}
        </Select>
      </div>
    </div>
  );
};

export default PoolPlansHeader;
