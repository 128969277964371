import { ListboxOption, ListboxOptionProps } from '@headlessui/react';
import React, { ElementRef, forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';

export type SelectOptionProps = ListboxOptionProps & { selectedClassName?: string };

const SelectOption = memo(
  forwardRef<ElementRef<typeof ListboxOption>, SelectOptionProps>(
    ({ className, children, selectedClassName, ...props }, ref) => (
      <ListboxOption
        className={(props) =>
          twMerge(
            'relative flex h-9 shrink-0 cursor-pointer select-none items-center rounded px-[6px] hover:bg-brand-25',
            typeof className === 'string' ? className : className?.(props),
            props.selected && twMerge('bg-brand-25', selectedClassName),
          )
        }
        ref={ref}
        {...props}
      >
        {children}
      </ListboxOption>
    ),
  ),
);

SelectOption.displayName = 'SelectOption';

export default SelectOption;
