import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useExport = ({ companyId }: { companyId: string }) => {
  const { mutate: mutateExport } = useAppMutation([QueryKey.EXPORT_EVENTS, companyId], {
    mutationFn: async () => {
      await ApiService.downloadFile({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'events-export'],
      });
    },
  });

  return { mutateExport };
};
