import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useCreate = () => {
  const {
    mutate: create,
    isPending,
    data,
  } = useAppMutation<{ id: string }>([QueryKey.CREATE_COMPANY], {
    mutationFn: () => ApiService.post({ endpoint: BackendRoute.COMPANIES }),
  });
  return { create, isPending, data };
};
