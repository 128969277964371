import React, { ComponentProps, FC } from 'react';
import { FormattedMessage as LibFormattedMessage } from 'react-intl';

import { StringKey } from '../../lang';

type AppFormattedMessageProps = ComponentProps<typeof LibFormattedMessage> & { id: StringKey };

export const AppFormattedMessage: FC<AppFormattedMessageProps> = ({ ...props }) => {
  return <LibFormattedMessage {...props} />;
};
