import { z } from 'zod';

export const formSchema = z.object({
  cardholderName: z.string(),
  cardNumber: z.boolean(),
  cardCvc: z.boolean(),
  cardExpiry: z.boolean(),
});

export type FormSchema = z.infer<typeof formSchema>;
