import { ListboxButton, ListboxButtonProps } from '@headlessui/react';
import React, { ElementRef, forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';
export type SelectButtonProps = ListboxButtonProps;

const SelectButton = memo(
  forwardRef<ElementRef<typeof ListboxButton>, SelectButtonProps>(
    ({ className, ...props }, ref) => (
      <ListboxButton
        className={(prop) =>
          twMerge(
            'relative flex h-9 w-fit cursor-pointer items-center justify-between gap-2 rounded border border-[#E5E5E5] bg-brand-25 px-4 py-[6px] text-sm text-[#172335]',
            typeof className === 'string' ? className : className?.(prop),
          )
        }
        ref={ref}
        {...props}
      ></ListboxButton>
    ),
  ),
);

SelectButton.displayName = 'SelectButton';

export default SelectButton;
