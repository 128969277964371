import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { SummaryItem } from '../../../../components/SummaryItem';
import { useFormat } from '../../../../hooks';
import { StringKey } from '../../../../lang';
import { antiDilutionBaseTitle } from '../../../../types/share-classes.types';
import { ChildrenFormProps } from '../type';

export type FinalStepProps = ChildrenFormProps;

export const FinalStep: FC<FinalStepProps> = ({
  handleCloseModal,
  nextFormStep,
  prevFormStep,
  formData,
}) => {
  const { stepOne, stepFour, stepThree, stepTwo } = formData();
  const { format } = useFormat();
  return (
    <form className="flex h-full flex-col gap-4 overflow-hidden">
      <div className="flex h-full flex-col gap-4 overflow-y-auto rounded-b-xl border-b border-gray-300 bg-white p-6 pt-0">
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">General</span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem className="pt-0" title="Share Class Name" value={stepOne.name} />

            <SummaryItem
              title="Creation date"
              value={stepOne?.creationDate && format(stepOne.creationDate, 'dd/MM/yyyy')}
            />

            <div className="flex flex-col gap-3 py-3">
              <SummaryItem
                className="py-0"
                title="Voting rights"
                value={
                  stepOne.votingRight?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />

              <SummaryItem
                className="py-0"
                title="Voting Multiplier"
                value={stepOne.votingRight?.value || '-'}
              />
            </div>
            <div className="flex flex-col gap-3 py-3">
              <SummaryItem
                className="py-0"
                title="Conversion Ratio"
                value={
                  stepOne.conversionRatio?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />
              <SummaryItem
                className="py-0"
                title="Voting Multiplier"
                value={stepOne.conversionRatio?.value || '-'}
              />
            </div>
            <SummaryItem
              className="pb-0"
              title="Dividend Right"
              value={
                stepOne.dividendRightEnabled ? (
                  <AppFormattedMessage id={StringKey.YES} />
                ) : (
                  <AppFormattedMessage id={StringKey.NO} />
                )
              }
            />
          </div>
        </div>
        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">Liquidity</span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <div className="flex flex-col gap-3 pb-3">
              <SummaryItem
                className="py-0"
                title="Seniority"
                value={stepTwo?.liquidityPreferences?.seniority || '-'}
              />

              <SummaryItem
                className="py-0"
                title="Multiple value"
                value={stepTwo?.liquidityPreferences?.multiple || '-'}
              />
            </div>
            <div className="flex flex-col gap-3 pt-3">
              <SummaryItem
                className="py-0"
                title="Participating"
                value={
                  stepTwo?.liquidityPreferences?.participating?.enabled ? (
                    <AppFormattedMessage id={StringKey.YES} />
                  ) : (
                    <AppFormattedMessage id={StringKey.NO} />
                  )
                }
              />
              <SummaryItem
                className="py-0"
                title="Cap Value"
                value={stepTwo?.liquidityPreferences?.participating?.capValue || '-'}
              />
              <SummaryItem
                className="py-0"
                title="Interest"
                value={
                  stepTwo?.liquidityPreferences?.participating?.interest
                    ? stepTwo?.liquidityPreferences?.participating?.interest + '%'
                    : '-'
                }
              />
              <SummaryItem
                className="py-0"
                title="Days per year"
                value={stepTwo?.liquidityPreferences?.participating?.daysPersYear || '-'}
              />
            </div>
          </div>
        </div>

        <div className="flex h-fit flex-col gap-2 rounded-lg bg-gray-50 pt-2">
          <span className="px-4 text-sm font-[550] text-gray-700">Anti - Dilution rights</span>
          <div className="flex flex-col divide-y divide-gray-100 rounded-b-lg border border-gray-100 bg-white p-4 pb-3">
            <SummaryItem
              className="pt-0"
              title="Base"
              value={
                stepThree?.antiDilutionRights?.base
                  ? antiDilutionBaseTitle[stepThree?.antiDilutionRights?.base]
                  : '-'
              }
            />

            <SummaryItem
              className="pb-0"
              title="Primitive rights"
              value={
                stepThree?.antiDilutionRights?.enabled ? (
                  <AppFormattedMessage id={StringKey.YES} />
                ) : (
                  <AppFormattedMessage id={StringKey.NO} />
                )
              }
            />
          </div>
        </div>
        <div className="flex w-full flex-col rounded-lg border border-gray-100 bg-white">
          <SummaryItem
            className="px-4 py-2"
            title="Board Resolution"
            value={stepFour?.doc.name || '-'}
          />
        </div>
      </div>

      <div className="flex h-9 w-full justify-between gap-3 px-6">
        <Button
          className="w-fit bg-transparent px-4 py-[6px] text-sm font-[450] text-gray-700"
          onClick={handleCloseModal}
          styleType="NONE"
          type="button"
        >
          <AppFormattedMessage id={StringKey.CANCEL} />
        </Button>
        <div className="flex gap-3">
          <Button
            className="h-full w-fit rounded border border-gray-100 bg-white px-3 py-[6px] text-sm font-[450] text-gray-700 shadow-xs"
            onClick={prevFormStep}
            styleType="NONE"
            type="button"
          >
            <AppFormattedMessage id={StringKey.BACK} />
          </Button>
          <Button
            className="h-full w-fit px-4 py-[6px] text-sm font-[550] text-white"
            onClick={nextFormStep}
            type="button"
          >
            Add share class
          </Button>
        </div>
      </div>
    </form>
  );
};
