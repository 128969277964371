import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type PoolPlansIconProps = IconProps;

const PoolPlansIcon: FC<PoolPlansIconProps> = ({ iconColor = '#98A2B3', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_377_6255)">
      <path
        d="M10.0001 1.66666C11.0944 1.66666 12.1781 1.8822 13.1891 2.30099C14.2002 2.71978 15.1188 3.33361 15.8926 4.10744C16.6665 4.88126 17.2803 5.79992 17.6991 6.81096C18.1179 7.82201 18.3334 8.90565 18.3334 10M10.0001 1.66666V9.99999M10.0001 1.66666C5.39771 1.66666 1.66675 5.39762 1.66675 9.99999C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10M10.0001 1.66666C14.6025 1.66666 18.3334 5.39762 18.3334 10M18.3334 10L10.0001 9.99999M18.3334 10C18.3334 11.3151 18.0222 12.6115 17.4251 13.7832C16.8281 14.955 15.9622 15.9688 14.8983 16.7418L10.0001 9.99999"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_377_6255">
        <rect fill="white" height="20" width="20" />
      </clipPath>
    </defs>
  </svg>
);

PoolPlansIcon.displayName = 'PoolPlansIcon';

export default PoolPlansIcon;
