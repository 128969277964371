import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type CircleConfirmIconProps = IconProps;

export const CircleConfirmIcon: FC<CircleConfirmIconProps> = ({
  iconColor = '#039855',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2281_25867)">
      <path
        d="M5.00001 8.00004L7.00001 10L11 6.00004M14.6667 8.00004C14.6667 11.6819 11.6819 14.6667 8.00001 14.6667C4.31811 14.6667 1.33334 11.6819 1.33334 8.00004C1.33334 4.31814 4.31811 1.33337 8.00001 1.33337C11.6819 1.33337 14.6667 4.31814 14.6667 8.00004Z"
        stroke={iconColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.04"
      />
    </g>
    <defs>
      <clipPath id="clip0_2281_25867">
        <rect fill="white" height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);
