import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type UploadIconProps = IconProps;

const UploadIcon: FC<UploadIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="21"
    viewBox="0 0 22 21"
    width="22"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 11L11 7M11 7L7 11M11 7V16.2C11 17.5907 11 18.2861 11.5505 19.0646C11.9163 19.5819 12.9694 20.2203 13.5972 20.3054C14.5421 20.4334 14.9009 20.2462 15.6186 19.8719C18.8167 18.2036 21 14.8568 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 14.7014 3.01099 17.9331 6 19.6622"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.3"
    />
  </svg>
);
export default UploadIcon;
