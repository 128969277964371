import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type DoubleChevronIconProps = IconProps;

const DoubleChevronIcon: FC<DoubleChevronIconProps> = ({
  iconColor = '#98A2B3',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="8"
    viewBox="0 0 11 8"
    width="11"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 7.33317L6.16667 3.99984L9.5 0.666504M4.83333 7.33317L1.5 3.99984L4.83333 0.666504"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);

DoubleChevronIcon.displayName = 'DoubleChevronIcon';

export default DoubleChevronIcon;
