import React, { FC, useCallback, useMemo } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';

import { ArchiveIcon, EditIcon, RefreshIcon, ThreeDotsIcon } from '../../../assets/icons';
import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { DeprecatedModalWrapper } from '../../../components/Modal';
import { Popover, PopoverClose, PopoverContent, PopoverTrigger } from '../../../components/Popover';
import {
  Company as CompanyHook,
  useFormat,
  useLocale,
  useModalState,
  useNavigate,
} from '../../../hooks';
import { StringKey } from '../../../lang';
import { companyTypeTranslation } from '../../../translations/companyTranslation';
import { stakeholderRoleTranslation } from '../../../translations/stakeholderTranslation';
import { Company } from '../../../types/companyTypes';
import { PageRoute } from '../../../types/pageTypes';
import { StakeholderRole } from '../../../types/stakeholderTypes';
import CompanyCardItem from './CompanyCardItem';
import CompanyDeactivateModal from './CompanyDeactivateModal';
import IncompleteCompanyCard from './IncompleteCompanyCard';

type MyCompanyCardProps = {
  isDemo?: boolean;
  invalidateQuery: () => void;
} & Company;

const MyCompanyCard: FC<MyCompanyCardProps> = (company) => {
  const {
    isDemo,
    invalidateQuery,
    createdAt,
    isActive,
    isCompleted,
    logoImgSrc,
    name,
    roleInCompany,
    type,
    isSelected,
    id,
    toCancelAt,
    inactivateAt,
  } = company;
  const { format } = useFormat();
  const navigate = useNavigate(PageRoute.COMPANY_SETTINGS);
  const { messagesLocale } = useLocale();

  const createdAtFormattedData = useMemo(
    () => format(createdAt, 'dd/MM/yyyy'),
    [createdAt, format],
  );

  const toCancelAtFormattedData = useMemo(
    () => (toCancelAt ? format(toCancelAt, 'dd/MM/yyyy') : undefined),
    [format, toCancelAt],
  );

  const inactivateAtFormattedData = useMemo(
    () => (inactivateAt ? format(inactivateAt, 'dd/MM/yyyy') : undefined),
    [format, inactivateAt],
  );

  const {
    isOpen: isOpenDeactivateModal,
    handleOpenModal,
    handleSuccessModal,
    handleDismissModal,
  } = useModalState({
    onSuccess: () => {
      deactivate({ companyId: id }, { onSuccess: invalidateQuery });
      invalidateQuery();
    },
  });

  const { paymentData } = CompanyHook.usePaymentDetails({
    companyId: id,
    enabled: isOpenDeactivateModal,
  });

  const { deactivate } = CompanyHook.useDeactivate();

  const { setSelected } = CompanyHook.useSetSelected();

  const { reactivate } = CompanyHook.useReactivate();

  const handleSelectCompany = useCallback(
    () =>
      setSelected(id, {
        onSuccess: () => {
          invalidateQuery();
        },
      }),
    [id, invalidateQuery, setSelected],
  );

  const handleReactivateCompany = useCallback(() => {
    reactivate({ companyId: id }, { onSuccess: invalidateQuery });
    invalidateQuery();
  }, [id, invalidateQuery, reactivate]);

  const handleEdit = () => {
    navigate();
  };

  return (
    <>
      <DeprecatedModalWrapper isOpen={isOpenDeactivateModal}>
        <CompanyDeactivateModal
          expDate={paymentData?.periodEnd || 0}
          onClose={handleDismissModal}
          onSuccess={handleSuccessModal}
        />
      </DeprecatedModalWrapper>

      {isCompleted ? (
        <div
          className={twMerge(
            'flex h-fit w-full max-w-[400px] flex-col gap-2 rounded-lg p-[2px] pt-2 text-center shadow-sm',
            isSelected ? 'bg-forest-400 text-gray-25' : 'bg-gray-50 text-gray-300',
          )}
        >
          {isActive ? (
            <span
              className={twJoin(
                'line-h cursor-pointer text-label-sm font-bold uppercase',
                !isSelected && 'cursor-pointer text-gray-700',
              )}
              onClick={() => isActive && handleSelectCompany()}
              style={{
                fontFamily: 'Inter',
                fontSize: '10px',
                fontWeight: '700',
                lineHeight: '16px',
                letterSpacing: '1px',
                textAlign: 'center',
              }}
            >
              {isSelected ? (
                <AppFormattedMessage id={StringKey.CURRENTLY_SELECTED} />
              ) : (
                <AppFormattedMessage id={StringKey.SWITCH_COMPANY} />
              )}
            </span>
          ) : (
            <span
              className={twJoin('cursor-pointer text-label-sm font-bold uppercase text-gray-300')}
              style={{
                fontFamily: 'Inter',
                fontSize: '10px',
                fontWeight: '700',
                lineHeight: '16px',
                letterSpacing: '1px',
                textAlign: 'center',
              }}
            >
              <AppFormattedMessage id={StringKey.INACTIVE_COMPANY} />
            </span>
          )}

          <div className="flex w-full flex-col divide-y rounded-md bg-white">
            <div className="flex w-full items-center justify-between p-4">
              <div className="flex items-center gap-2 overflow-hidden text-gray-600">
                {logoImgSrc ? (
                  <img
                    alt=""
                    className="size-9 shrink-0 rounded-full bg-gray-100 object-cover"
                    src={logoImgSrc}
                  />
                ) : (
                  <div className="size-9 shrink-0 rounded-full bg-gray-100 object-cover" />
                )}
                <span className="truncate font-[550]">{name}</span>
                {isDemo && (
                  <span
                    className={
                      'ml-2 w-fit rounded-2xl bg-sunbeam-500 px-2 py-[2px] text-label-sm font-[450] text-white'
                    }
                  >
                    <AppFormattedMessage id={StringKey.DEMO} />
                  </span>
                )}
              </div>
              {StakeholderRole.ADMIN === roleInCompany && (
                <Popover>
                  <PopoverTrigger className="h-fit w-fit rounded data-[state=open]:shadow-md">
                    <div className="flex size-6 items-center justify-center rounded bg-transparent transition-colors hover:bg-gray-100">
                      <ThreeDotsIcon />
                    </div>
                  </PopoverTrigger>
                  <PopoverContent className="flex w-fit flex-col rounded p-0 text-sm font-normal shadow-2xl">
                    <PopoverClose>
                      {isActive && (
                        <div
                          className="flex items-center justify-center gap-1 p-3 pr-10 text-gray-700 hover:bg-gray-50"
                          onClick={handleEdit}
                        >
                          <EditIcon />
                          <span>
                            <AppFormattedMessage id={StringKey.EDIT_DETAILS} />
                          </span>
                        </div>
                      )}

                      {isActive && !toCancelAtFormattedData && !inactivateAtFormattedData && (
                        <div
                          className="flex items-center gap-1 p-3 pr-10 text-fireside-500 hover:bg-gray-50"
                          onClick={handleOpenModal}
                        >
                          <ArchiveIcon />
                          <span>
                            <AppFormattedMessage id={StringKey.DEACTIVATE} />
                          </span>
                        </div>
                      )}

                      {(!isActive || toCancelAtFormattedData || inactivateAtFormattedData) && (
                        <div
                          className="flex items-center justify-center gap-1 p-3 pr-10 text-gray-700 hover:bg-gray-50"
                          onClick={handleReactivateCompany}
                        >
                          <RefreshIcon />
                          <span>
                            <AppFormattedMessage id={StringKey.REACTIVATE} />
                          </span>
                        </div>
                      )}
                    </PopoverClose>
                  </PopoverContent>
                </Popover>
              )}
            </div>

            <CompanyCardItem
              title={<AppFormattedMessage id={StringKey.UPDATE} />}
              value={type ? <AppFormattedMessage id={companyTypeTranslation[type]} /> : '-'}
            />
            <CompanyCardItem
              className="py-[6px]"
              title={<AppFormattedMessage id={StringKey.ACCOUNT} />}
              value={
                isActive ? (
                  toCancelAtFormattedData || inactivateAtFormattedData ? (
                    <AppFormattedMessage
                      id={StringKey.ACTIVE_UNTIL}
                      values={{ date: toCancelAtFormattedData || inactivateAtFormattedData }}
                    />
                  ) : (
                    <AppFormattedMessage id={StringKey.ACTIVE} />
                  )
                ) : (
                  <AppFormattedMessage id={StringKey.INACTIVE} />
                )
              }
              valueClassName={twMerge(
                'px-2 py-1 rounded',
                isActive
                  ? toCancelAtFormattedData || inactivateAtFormattedData
                    ? 'text-[#B54708] bg-[#FFFAEB]'
                    : 'text-forest-500 bg-forest-50'
                  : 'text-gray-400 bg-gray-100',
              )}
            />
            <CompanyCardItem
              title={<AppFormattedMessage id={StringKey.USER_ROLE} />}
              value={messagesLocale[stakeholderRoleTranslation[roleInCompany]]}
            />
            <CompanyCardItem
              title={<AppFormattedMessage id={StringKey.DATE_CREATED} />}
              value={createdAtFormattedData}
            />
          </div>
        </div>
      ) : (
        <IncompleteCompanyCard company={company} invalidateQuery={invalidateQuery} />
      )}
    </>
  );
};

export default MyCompanyCard;
