export type QueryParams = Record<string, unknown>;

export class QueryParamsBuilder {
  public buildQuery(queryParams: QueryParams) {
    const queries: string[] = [];

    for (const [key, value] of Object.entries(queryParams)) {
      if (value === undefined || value === '') continue;
      if (typeof value === 'number' || typeof value === 'string' || typeof value === 'boolean') {
        queries.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        continue;
      }
      queries.push(`${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`);
    }
    return queries.length > 0 ? '?' + queries.join('&') : '';
  }
}
