import React, { FC } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';

import { InfoCircle } from '../../../../assets/icons';
import { FormDatePicker } from '../../../../components/DatePicker';
import { DropDown } from '../../../../components/Dropdown';
import { HoverCard, HoverCardContent, HoverCardTrigger } from '../../../../components/HoverCard';
import { FormInput, Input } from '../../../../components/Input';
import { useFormat } from '../../../../hooks';
import { FormSchema } from '../validation';

export type ValuationStepOneProps = {
  setFormData: UseFormSetValue<FormSchema>;
  control: Control<FormSchema>;
  issuedShares: number;
  lockMode?: boolean;
};

export const ValuationStepOne: FC<ValuationStepOneProps> = ({
  control,
  setFormData,
  issuedShares,
  lockMode,
}) => {
  const { stepOne } = useWatch<FormSchema>({ control });
  const { format } = useFormat();
  const event = stepOne?.eventDetails?.valuation;
  const preMoneyValuation =
    lockMode && event?.sharePrice && event?.issuedSharesOnStart !== undefined
      ? event?.sharePrice * event?.issuedSharesOnStart
      : event?.sharePrice
        ? event?.sharePrice * issuedShares
        : 0;

  return (
    <div className="flex w-full flex-col gap-6">
      <div className="flex w-full flex-col gap-4">
        <FormInput
          control={control}
          name={'stepOne.eventDetails.valuation.name'}
          placeholder="Event Name"
          wrapperClassName="w-full"
        />
        <FormDatePicker
          iconColor="#98A2B3"
          inputValue={
            stepOne?.eventDetails?.valuation?.date
              ? format(stepOne?.eventDetails?.valuation?.date, 'dd/MM/yyyy')
              : ''
          }
          inputWrapperClassName="w-full"
          onSelect={(date) => {
            setFormData('stepOne.eventDetails.valuation.date', date);
          }}
          placeholder="Event Date"
          value={stepOne?.eventDetails?.valuation?.date}
        />
        <FormInput
          control={control}
          maxDecimalPlaces={2}
          name={'stepOne.eventDetails.valuation.sharePrice'}
          placeholder="Share Price"
          wrapperClassName="w-full"
        />
      </div>
      <div className="flex flex-col gap-3 rounded-md bg-brand-25 px-1 pb-1 pt-3">
        <div className="flex flex-col items-start gap-1 pl-[14px]">
          <div className="flex items-center gap-2">
            <span className="text-sm font-[550] text-brand-700">Pre-Money Valuation</span>
            <HoverCard>
              <HoverCardTrigger>
                <InfoCircle className="size-4 cursor-pointer" iconColor="#2565C8 " />
              </HoverCardTrigger>
              <HoverCardContent
                className="z-20 w-max rounded-lg bg-[#101828] px-3 pb-[6px] pt-1"
                isShownTriangle
                side="top"
                triangleClassName="bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 ml-0"
              >
                <span className="text-label-md font-[550] text-white">Calculated by CapQuest</span>
              </HoverCardContent>
            </HoverCard>
          </div>
          <span className="text-xs font-[450] text-gray-700">
            Issued Shares:{' '}
            {(stepOne?.eventDetails?.valuation?.issuedSharesOnStart !== undefined
              ? stepOne?.eventDetails?.valuation?.issuedSharesOnStart
              : issuedShares
            )?.toLocaleString('en-US') || 0}
          </span>
        </div>
        <div className="flex flex-col gap-3 rounded-md bg-white p-3 shadow-xs">
          <Input
            disabled
            onChange={() => {}}
            placeholder="Value"
            value={preMoneyValuation.toLocaleString('en-US')}
            wrapperClassName="w-full"
          />
          <DropDown
            answer="The Pre-Money Valuation is determined by multiplying the share price entered by the user with the total number of issued shares at the time of the event. This value reflects the estimated value of the company before any new funding or investment is taken into account."
            question="How this value is calculated?"
          />
        </div>
      </div>
    </div>
  );
};
