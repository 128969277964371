import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { PatchPoolDto } from '../../types/pool-plans.types';
import { useAppMutation } from '../useAppMutation';

export const useUpdate = () => {
  const { mutate: update, isPending } = useAppMutation([QueryKey.PATCH_POOL], {
    mutationFn: ({
      companyId,
      data,
      poolId,
    }: {
      data: PatchPoolDto;
      companyId: string;
      poolId: string;
    }) =>
      ApiService.patch(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId, 'pools', poolId] },
        { body: data },
      ),
  });
  return { update, isPending };
};
