import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type ProfileIconProps = IconProps;

const ProfileIcon: FC<ProfileIconProps> = ({ iconColor = '#344054', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="17"
    viewBox="0 0 16 17"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6668 16C14.6668 14.837 14.6668 14.2555 14.5233 13.7824C14.2001 12.717 13.3664 11.8834 12.3011 11.5602C11.828 11.4167 11.2465 11.4167 10.0835 11.4167H5.91683C4.75386 11.4167 4.17237 11.4167 3.69921 11.5602C2.63388 11.8834 1.8002 12.717 1.47703 13.7824C1.3335 14.2555 1.3335 14.837 1.3335 16M11.7502 4.75C11.7502 6.82107 10.0712 8.5 8.00016 8.5C5.92909 8.5 4.25016 6.82107 4.25016 4.75C4.25016 2.67893 5.92909 1 8.00016 1C10.0712 1 11.7502 2.67893 11.7502 4.75Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

ProfileIcon.displayName = 'ProfileIcon';

export default ProfileIcon;
