import React, { createContext, FC, ReactNode, useCallback, useState } from 'react';

import { sidebarStateStorage } from './sidebarStateStorage';

export const sideBarContext = createContext({
  isOpen: false,
  openSideBar: () => {},
  closeSideBar: () => {},
});
type SideBarContextProps = {
  children: ReactNode;
};
const sidebarState = sidebarStateStorage;

export const SideBarContext: FC<SideBarContextProps> = ({ children }) => {
  const [isOpen, setOpen] = useState(sidebarState.get() || false);
  const openSideBar = useCallback(() => {
    setOpen(true);
    sidebarState.set(true);
  }, []);

  const closeSideBar = useCallback(() => {
    setOpen(false);
    sidebarState.set(false);
  }, []);

  return (
    <sideBarContext.Provider value={{ isOpen, openSideBar, closeSideBar }}>
      {children}
    </sideBarContext.Provider>
  );
};

SideBarContext.displayName = 'SideBarContext';
