import {
  ComboboxOption as HeadlessComboboxOption,
  ComboboxOptionProps as HeadlessComboboxOptionProps,
} from '@headlessui/react';
import React, { ElementRef, forwardRef, memo } from 'react';
import { twMerge } from 'tailwind-merge';

export type ComboboxOptionProps = {
  className?: string;
} & HeadlessComboboxOptionProps;

const ComboboxOption = memo(
  forwardRef<ElementRef<typeof HeadlessComboboxOption>, ComboboxOptionProps>(
    ({ className, children, ...props }, ref) => (
      <HeadlessComboboxOption
        className={twMerge(
          'group z-3 flex select-none items-center gap-[6px] rounded px-3 py-1.5 hover:cursor-pointer hover:bg-brand-25',
          className,
        )}
        ref={ref}
        {...props}
      >
        {children}
      </HeadlessComboboxOption>
    ),
  ),
);

ComboboxOption.displayName = 'ComboboxOption';

export default ComboboxOption;
