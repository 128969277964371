import React, { FC } from 'react';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import { Modal } from '../../../components/Modal';
import { StringKey } from '../../../lang';

export type GrantAccessProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
};

const GrantAccessModal: FC<GrantAccessProps> = ({ isOpen, onDismiss, onSuccess }) => (
  <Modal
    description={<AppFormattedMessage id={StringKey.ARE_YOU_SURE_YOU_WANT_GRANT_ACCESS} />}
    dismissButtonContent={<AppFormattedMessage id={StringKey.NO} />}
    isOpen={isOpen}
    onDismiss={onDismiss}
    onSuccess={onSuccess}
    successButtonClassName="bg-brand-700"
    successButtonContent={<AppFormattedMessage id={StringKey.YES_GRANT} />}
    title={<AppFormattedMessage id={StringKey.GRANT_ACCESS} />}
  />
);

export default GrantAccessModal;
