import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type EmptyDocumentIconProps = IconProps;

export const EmptyDocumentIcon: FC<EmptyDocumentIconProps> = ({
  iconColor = '#344054',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3332 4.66671V4.53337C13.3332 3.41327 13.3332 2.85322 13.1152 2.42539C12.9234 2.04907 12.6175 1.74311 12.2412 1.55136C11.8133 1.33337 11.2533 1.33337 10.1332 1.33337H5.8665C4.7464 1.33337 4.18635 1.33337 3.75852 1.55136C3.3822 1.74311 3.07624 2.04907 2.88449 2.42539C2.6665 2.85322 2.6665 3.41327 2.6665 4.53337V11.4667C2.6665 12.5868 2.6665 13.1469 2.88449 13.5747C3.07624 13.951 3.3822 14.257 3.75852 14.4487C4.18635 14.6667 4.7464 14.6667 5.8665 14.6667H8.33317M11.9998 12V8.33337C11.9998 7.78109 12.4476 7.33337 12.9998 7.33337C13.5521 7.33337 13.9998 7.78109 13.9998 8.33337V12C13.9998 13.1046 13.1044 14 11.9998 14C10.8953 14 9.99984 13.1046 9.99984 12V9.33337"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
  </svg>
);
