import { useNavigate } from 'react-router-dom';

import { BackendRoute } from '../config';
import { QueryKey } from '../constants';
import { ApiService } from '../services';
import { Company } from '../types/companyTypes';
import { useAppQuery } from './useAppQuery';
import { useLocale } from './useLocale';

export const useSelectedCompany = () => {
  const navigate = useNavigate();
  const { locale } = useLocale();
  const {
    data: selectedCompany,
    isLoading,
    isFetched,
  } = useAppQuery({
    refetchOnReconnect: 'always',
    queryKey: [QueryKey.GET_SELECTED_COMPANY],
    queryFn: () =>
      ApiService.get<Company | undefined>(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: ['selected'],
        },
        { locale },
      ),
    onError: () => navigate('/error-page'),
  });

  return { selectedCompany, isLoading, isFetched };
};
