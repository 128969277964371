import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppMutation } from '../useAppMutation';

export const useDelete = () => {
  const { mutate: deletePlan, isPending } = useAppMutation([QueryKey.TERMINATE_SHARE_PLAN], {
    mutationFn: ({ companyId, planId }: { planId: string; companyId: string }) =>
      ApiService.delete({
        endpoint: BackendRoute.COMPANIES,
        routePath: [companyId, 'share-plans', planId],
      }),
  });
  return { deletePlan, isPending };
};
