import { Radio as HeadlessRadio } from '@headlessui/react';
import React, {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  Fragment,
  ReactNode,
} from 'react';
import { twMerge } from 'tailwind-merge';

enum StyleType {
  DEFAULT = 'lg:px-4 lg:py-3 px-3 py-2 text-gray-700 bg-gray-50 data-[checked]:text-gray-25 data-[checked]:bg-brand-700 data-[checked]:disabled:bg-gray-400 data-[checked]:disabled:text-gray-25',
  NONE = '',
}

export type RadioProps = {
  children?: ReactNode;
  onCheckedDisableClassName?: string;
  styleType?: keyof typeof StyleType;
} & ComponentPropsWithoutRef<typeof HeadlessRadio>;

const Radio = forwardRef<ElementRef<typeof HeadlessRadio>, RadioProps>(
  (
    {
      className,
      children,
      onCheckedDisableClassName = 'data-[checked]:bg-gray-400 data-[checked]:text-gray-25 cursor-default',
      styleType = 'DEFAULT',
      ...props
    },
    ref,
  ) => {
    return (
      <HeadlessRadio as={Fragment} ref={ref} {...props}>
        {(props) => (
          <div
            className={twMerge(
              'group relative flex w-full cursor-pointer select-none items-center justify-center transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white',
              StyleType[styleType],
              props.disabled && onCheckedDisableClassName,
              className,
            )}
          >
            {children}
          </div>
        )}
      </HeadlessRadio>
    );
  },
);

Radio.displayName = 'Radio';

export default Radio;
