import React, { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { CalendarIcon } from '../../assets/icons';
import { Calendar, CalendarProps } from '../Calendar';
import { Input } from '../Input';
import { Popover, PopoverContent, PopoverTrigger } from '../Popover';

type FormDatePickerProps = {
  onSelect?: (date: Date) => void;
  value?: Date | null;
  isDefaultOpenCalendar?: boolean;
  wrapperClassName?: string;
  calendar?: Except<CalendarProps, 'selected' | 'mode' | 'initialFocus'>;
  inputValue: string;
  inputWrapperClassName?: string;
  placeholder: string;
  iconColor?: string;
};

export const FormDatePicker: FC<FormDatePickerProps> = ({
  onSelect,
  value,
  isDefaultOpenCalendar,
  wrapperClassName,
  calendar,
  inputValue,
  inputWrapperClassName,
  placeholder,
  iconColor = '#344054',
}) => {
  const [isOpenCalendar, setOpenCalendar] = useState(isDefaultOpenCalendar ?? false);

  return (
    <Popover onOpenChange={setOpenCalendar} open={isOpenCalendar}>
      <PopoverTrigger className={twMerge('relative w-full cursor-pointer', wrapperClassName)}>
        <div
          className="absolute z-50 h-full w-full"
          onClick={() => setOpenCalendar((prev) => !prev)}
        />
        <Input
          autoComplete="off"
          icon={<CalendarIcon className="mr-2 size-6" iconColor={iconColor} />}
          onChange={() => ''}
          onIconClick={() => setOpenCalendar(true)}
          placeholder={placeholder}
          value={inputValue}
          wrapperClassName={inputWrapperClassName}
        />
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          {...calendar}
          initialFocus
          mode="single"
          onSelect={(date) => {
            if (!date) return;
            setOpenCalendar(false);
            onSelect?.(date);
          }}
          selected={value || undefined}
        />
      </PopoverContent>
    </Popover>
  );
};
