import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../../components/AppFormattedMessage';
import Button from '../../../components/Button';
import { StringKey } from '../../../lang';

export type UpdateStakeholderProps = {
  onDismiss: () => void;
  onSuccess: () => void;
};

const UpdateStakeholderModal: FC<UpdateStakeholderProps> = ({ onDismiss, onSuccess }) => (
  <div className="m-2 flex w-full max-w-[453px] flex-col gap-8 rounded bg-white p-4">
    <div className="flex flex-col gap-6 pr-[90px]">
      <div className="flex flex-col gap-1">
        <span className={twMerge('text-xl font-[550] text-gray-700')}>
          <AppFormattedMessage id={StringKey.UPDATE_STAKEHOLDER} />
        </span>
        <span className={twMerge('text-xs font-[450] text-gray-500')}>
          <AppFormattedMessage id={StringKey.UPDATE_INFORMATION_MESSAGE} />
        </span>
      </div>
    </div>
    <div className="flex h-11 justify-end gap-3">
      <Button
        className={twMerge(
          'border-[1px] border-gray-300 px-6 py-[10px] text-sm font-[450] text-gray-700 transition-colors hover:bg-gray-50',
        )}
        onClick={onDismiss}
        styleType="DEFAULT_ROUNDED"
      >
        <AppFormattedMessage id={StringKey.NO} />
      </Button>
      <Button
        className={twMerge(
          '"bg-brand-700 w-fit rounded bg-brand-700 px-6 py-[10px] text-sm font-[550] text-white',
        )}
        onClick={onSuccess}
        styleType="NONE"
      >
        <AppFormattedMessage id={StringKey.YES_UPDATE} />
      </Button>
    </div>
  </div>
);

export default UpdateStakeholderModal;
