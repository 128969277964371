import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type LockIconProps = IconProps;

const LockIcon: FC<LockIconProps> = ({ iconColor = '#98A2B3', className, ...props }) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="20"
    viewBox="0 0 16 18"
    width="20"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1667 7.33333V5.66667C12.1667 3.36548 10.3012 1.5 8.00004 1.5C5.69885 1.5 3.83337 3.36548 3.83337 5.66667V7.33333M8.00004 11.0833V12.75M5.33337 16.5H10.6667C12.0668 16.5 12.7669 16.5 13.3017 16.2275C13.7721 15.9878 14.1545 15.6054 14.3942 15.135C14.6667 14.6002 14.6667 13.9001 14.6667 12.5V11.3333C14.6667 9.9332 14.6667 9.23314 14.3942 8.69836C14.1545 8.22795 13.7721 7.8455 13.3017 7.60582C12.7669 7.33333 12.0668 7.33333 10.6667 7.33333H5.33337C3.93324 7.33333 3.23318 7.33333 2.6984 7.60582C2.22799 7.8455 1.84554 8.22795 1.60586 8.69836C1.33337 9.23314 1.33337 9.9332 1.33337 11.3333V12.5C1.33337 13.9001 1.33337 14.6002 1.60586 15.135C1.84554 15.6054 2.22799 15.9878 2.6984 16.2275C3.23318 16.5 3.93324 16.5 5.33337 16.5Z"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
  </svg>
);

LockIcon.displayName = 'LockIcon';

export default LockIcon;
