import { FC } from 'react';

import { useScreenSize, useSelectedCompany } from '../../hooks';
import { EventsDesktop } from './Desktop';
import { EventsMobile } from './Mobile/EventsMobile';
import { EventsMobileHistory } from './Mobile/EventsMobileHistory';

const Events: FC = () => {
  const { isMobile } = useScreenSize();
  const { selectedCompany } = useSelectedCompany();
  const companyId = selectedCompany?.id || '';
  return isMobile ? (
    <div className="flex w-full flex-col gap-4 p-[1px]">
      <EventsMobile companyId={companyId} />
      <EventsMobileHistory companyId={companyId} />
    </div>
  ) : (
    <EventsDesktop companyId={companyId} />
  );
};
export default Events;
