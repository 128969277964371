import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

import { IconProps } from './type';

export type UploadedFileIconProps = IconProps;

export const UploadedFileIcon: FC<UploadedFileIconProps> = ({
  iconColor = '#2565C8',
  className,
  ...props
}) => (
  <svg
    className={twMerge('shrink-0', className)}
    fill="none"
    height="16"
    viewBox="0 0 13 16"
    width="13"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3327 4.66668V4.53334C11.3327 3.41324 11.3327 2.85319 11.1147 2.42536C10.9229 2.04904 10.617 1.74308 10.2407 1.55133C9.81284 1.33334 9.25279 1.33334 8.13268 1.33334H3.86602C2.74591 1.33334 2.18586 1.33334 1.75803 1.55133C1.38171 1.74308 1.07575 2.04904 0.884003 2.42536C0.666016 2.85319 0.666016 3.41324 0.666016 4.53334V11.4667C0.666016 12.5868 0.666016 13.1468 0.884003 13.5747C1.07575 13.951 1.38171 14.2569 1.75803 14.4487C2.18586 14.6667 2.74591 14.6667 3.86602 14.6667H6.33268M9.99935 12V8.33334C9.99935 7.78106 10.4471 7.33334 10.9993 7.33334C11.5516 7.33334 11.9993 7.78106 11.9993 8.33334V12C11.9993 13.1046 11.1039 14 9.99935 14C8.89478 14 7.99935 13.1046 7.99935 12V9.33334"
      stroke={iconColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
  </svg>
);
