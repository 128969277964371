import { localStorageUtil } from '../utils/local-storage';

export interface InviteStakeholderStoreValue {
  email: string;
  id: string;
  isAccepted: boolean;
  isRegistered: boolean;
}

export const inviteStakeholderStore =
  localStorageUtil<InviteStakeholderStoreValue>('invitedStakeholder');
