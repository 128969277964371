import React, { FC, useState } from 'react';

import { HeartIcon } from '../../../../assets/icons';
import { AppFormattedMessage } from '../../../../components/AppFormattedMessage';
import Button from '../../../../components/Button';
import { Checkbox } from '../../../../components/Checkbox';
import { Textarea } from '../../../../components/Textarea';
import { useFormat, useLocale } from '../../../../hooks';
import { Locale, StringKey } from '../../../../lang';
import { arrayOfCancelledPlanReasonTranslation, CancelledPlanReason } from './Validation';

export type SubscriptionReportModalProps = {
  onClose: () => void;
  onSuccess: () => void;
  expireDate: string;
};

const SubscriptionReportModal: FC<SubscriptionReportModalProps> = ({
  onClose,
  onSuccess,
  expireDate,
}) => {
  const [selectedReasons, setSelectedReasons] = useState<CancelledPlanReason[]>([]);
  const { locale } = useLocale();
  const handleCheckboxChange = (reason: CancelledPlanReason) => {
    setSelectedReasons((prev) =>
      prev.includes(reason) ? prev.filter((item) => item !== reason) : [...prev, reason],
    );
  };
  const { format } = useFormat();

  return (
    <div className="max-h-full">
      <div className="mb-2 flex h-fit w-full max-w-[450px] flex-col gap-6 rounded bg-white p-4 shadow-xl">
        <span className="text-xl font-[550] text-gray-700">
          <AppFormattedMessage id={StringKey.SUBSCRIPTION_CANCELED} />
        </span>
        <span className="text-xs font-[450] text-gray-800">
          <AppFormattedMessage
            id={StringKey.PLAN_SOON_EXPIRE}
            values={{
              date: expireDate
                ? format(expireDate, locale === Locale.EN ? 'MMMM d, y' : 'd MMMM y')
                : '',
            }}
          />
        </span>
        <div className="flex gap-3 rounded bg-gray-50 p-3">
          <HeartIcon />
          <span className="text-xs font-medium text-gray-800">
            <AppFormattedMessage id={StringKey.CAN_REACTIVATE} />
          </span>
        </div>
        <div className="flex flex-col gap-3 rounded bg-brand-25 px-3 py-4">
          <span className="text-xs font-[450] text-gray-800">
            <AppFormattedMessage id={StringKey.HELP_US_MAKE_CAPQUEST_BETTTER} />
          </span>
          <div className="flex flex-col gap-4">
            {arrayOfCancelledPlanReasonTranslation.map(([messageKey, message]) => (
              <div className="flex gap-1" key={messageKey}>
                <Checkbox
                  checked={selectedReasons.includes(messageKey)}
                  onChange={() => handleCheckboxChange(messageKey)}
                />
                <span className="text-xs font-[450] text-gray-700">
                  <AppFormattedMessage id={message} />
                </span>
              </div>
            ))}
            <Textarea
              isShownCharCount={false}
              placeholder={<AppFormattedMessage id={StringKey.ANY_ADDITIONAL_FEEDBACK} />}
              placeholderClassName="text-xs !text-gray-300"
              styleVariant="LIGHT_W_PLACEHOLDER"
              textAreaClassName="bg-white border-transparent border-[1px] border-gray-200 rounded-lg"
            />
          </div>
        </div>

        <div className="mt-8 flex justify-end gap-2">
          <Button
            className="rounded border-[1px] border-gray-300 text-sm text-gray-700"
            onClick={onClose}
            styleType="DEFAULT_ROUNDED"
          >
            <AppFormattedMessage id={StringKey.NO_THANKS} />
          </Button>
          <Button className="w-fit rounded px-6 py-[10px] text-sm font-[550]" onClick={onSuccess}>
            <AppFormattedMessage id={StringKey.SUBMIT} />
          </Button>
        </div>
      </div>
    </div>
  );
};

SubscriptionReportModal.displayName = 'SubscriptionReportModal';

export default SubscriptionReportModal;
