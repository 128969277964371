import { FC, useState } from 'react';

import { PaginationController } from '../../../../components/PaginationController';
import { EventHooks, useFormat } from '../../../../hooks';
import {
  eventFormType,
  EventTypeBackend,
  SecondariesEventItem,
} from '../../../../types/events.types';
import { TransactionOverviewItem } from './TransactionOverviewItem';
import { TransactionTitleItem } from './TransactionTitleItem';
import { CardProps } from './type';

export const Secondaries: FC<CardProps> = ({ event, companyId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { format } = useFormat();
  const { items, totalPages } = EventHooks.useTransactions<SecondariesEventItem>({
    eventId: event.id,
    eventType: EventTypeBackend.SECONDARIES,
    companyId: companyId,
    currentPage,
  });
  if (event.type !== EventTypeBackend.SECONDARIES) return <></>;

  const { type } = event;

  return (
    <>
      {items.map(
        ({ sharesCount, shareClass, date, fromStakeholder, sharesValue, toStakeholder }, i) => (
          <div
            className="flex flex-col divide-y divide-gray-100 rounded-md shadow-sm"
            key={i + shareClass.id}
          >
            <TransactionTitleItem
              createdAt={format(date, 'dd/MM/yyyy')}
              name={eventFormType[type]}
            />
            <TransactionOverviewItem title={'Transaction Type'} value={eventFormType[type]} />
            <TransactionOverviewItem title={'From Stakeholder'} value={fromStakeholder.fullName} />
            <TransactionOverviewItem title={'To Stakeholder'} value={toStakeholder.fullName} />
            <TransactionOverviewItem title={'Share Class'} value={shareClass.name} />
            <TransactionOverviewItem title={'No of Shares'} value={sharesCount} />
            <TransactionOverviewItem title={'Share Value'} value={sharesValue} />
            <TransactionOverviewItem title={'Transfer Amount'} value={sharesCount} />
          </div>
        ),
      )}
      <PaginationController
        className="mt-6 shrink-0"
        currentPage={currentPage}
        onClick={setCurrentPage}
        totalPages={totalPages}
      />
    </>
  );
};
