import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { AppFormattedMessage } from '../../components/AppFormattedMessage';
import { StringKey } from '../../lang';
import { stakeholderRoleTranslation } from '../../translations/stakeholderTranslation';
import { StakeholderRole } from '../../types/stakeholderTypes';

export enum StakeholderActionStatus {
  ACCESS_REVOKED = 'access-revoked',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INVITE_USER = 'invite-user',
  INVITED = 'invited',
}

const stakeholderTranslation: Record<StakeholderActionStatus | StakeholderRole, StringKey> = {
  ...stakeholderRoleTranslation,
  [StakeholderActionStatus.ACCESS_REVOKED]: StringKey.ACCESS_REVOKED,
  [StakeholderActionStatus.ACTIVE]: StringKey.ACTIVE,
  [StakeholderActionStatus.INACTIVE]: StringKey.INACTIVE,
  [StakeholderActionStatus.INVITE_USER]: StringKey.INVITE_USER,
  [StakeholderActionStatus.INVITED]: StringKey.INVITED,
};

type StakeHolderStatusProps = {
  type: StakeholderActionStatus | StakeholderRole;
  onClick?: () => void;
  icon?: ReactNode;
  className?: string;
};

const StakeholderActionStatusVariant: Record<StakeholderActionStatus | StakeholderRole, string> = {
  [StakeholderActionStatus.ACCESS_REVOKED]: 'bg-fireside-50 text-fireside-600',
  [StakeholderActionStatus.ACTIVE]: 'bg-forest-50 text-forest-600',
  [StakeholderActionStatus.INACTIVE]: 'bg-[#FFFAEB] text-[#B54708]',
  [StakeholderRole.ADMIN]: 'bg-brand-25 text-brand-700',
  [StakeholderRole.CONTRIBUTOR]: 'bg-[#FCFAFF] text-[#7F56D9]',
  [StakeholderRole.STAKEHOLDER]: 'bg-[#F4FAFF] text-[#25A1C8]',
  [StakeholderActionStatus.INVITE_USER]: 'border-gray-200 border text-gray-600',
  [StakeholderActionStatus.INVITED]: 'bg-gray-50 border text-gray-500',
};

const StakeholderStatus: FC<StakeHolderStatusProps> = ({ type, onClick, icon, className }) => (
  <span
    className={twMerge(
      'flex shrink-0 items-center gap-1 rounded-2xl px-2 py-[2px] !text-label-md font-[450]',
      StakeholderActionStatusVariant[type],
      className,
    )}
    onClick={() => onClick?.()}
  >
    <AppFormattedMessage id={stakeholderTranslation[type]} />
    {icon}
  </span>
);

export default StakeholderStatus;
