import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { Company } from '../../types/companyTypes';
import { useAppQuery } from '../useAppQuery';
import { useLocale } from '../useLocale';

type FetchCompany = {
  companyId: string;
  enabled?: boolean;
};

export const useCompany = ({ companyId, enabled }: FetchCompany) => {
  const { invalidateQueries } = useQueryClient();
  const { locale } = useLocale();
  const { data: company, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_COMPANY, companyId],
    queryFn: () =>
      ApiService.get<Company>(
        { endpoint: BackendRoute.COMPANIES, routePath: [companyId] },
        { locale },
      ),
    enabled: !!companyId && enabled,
  });
  const invalidateQuery = useCallback(() => {
    invalidateQueries({ queryKey: [QueryKey.GET_COMPANY] });
  }, [invalidateQueries]);

  return {
    isLoading,
    invalidateQuery,
    company,
  };
};
