import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { BackendRoute } from '../../config';
import { QueryKey } from '../../constants';
import { ApiService } from '../../services';
import { useAppQuery } from '../useAppQuery';
import { useLocale } from '../useLocale';

export const useBalance = ({
  companyId,
  stakeholderId,
  shareClassId,
}: {
  companyId: string;
  stakeholderId: string;
  shareClassId: string;
}) => {
  const { locale } = useLocale();
  const { invalidateQueries } = useQueryClient();

  const { data: balance, isLoading } = useAppQuery({
    queryKey: [QueryKey.GET_STAKEHOLDER_BALANCE, companyId, stakeholderId, shareClassId],
    queryFn: () =>
      ApiService.get<number>(
        {
          endpoint: BackendRoute.COMPANIES,
          routePath: [companyId, 'stakeholders', stakeholderId, 'balance', shareClassId],
        },
        { locale },
      ),
    enabled: !!companyId && !!stakeholderId && !!shareClassId,
  });

  const invalidateQuery = useCallback(() => {
    invalidateQueries({ queryKey: [QueryKey.GET_STAKEHOLDER_BALANCE] });
  }, [invalidateQueries]);

  return {
    invalidateQuery,
    balance,
    isLoading,
  };
};
