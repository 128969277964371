import React, { FC, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import { SharePlanUnionType } from '../../../../../types/pool-plans.types';
import { Stakeholder } from '../../../../../types/stakeholderTypes';
import { PoolPlansPopover } from '../../PoolPlansPopover';

type ScrollablePlanItemProps = {
  plan: SharePlanUnionType;
  isSelected: boolean;
  onEditPlanClick: (id: string) => void;
  onViewPlanClick: (id: string) => void;
  onDeletePlanClick: (id: string) => void;
  onEditPlanTerminationDateClick: (id: string) => void;
};

export const ScrollablePlanItem: FC<ScrollablePlanItemProps> = ({
  plan,
  isSelected,
  onEditPlanClick,
  onViewPlanClick,
  onDeletePlanClick,
  onEditPlanTerminationDateClick,
}) => {
  const planDilutedShares = 0;
  const stakeholderDilutedShares = 0;

  const planAvailablePool = 0;
  const stakeholderAvailablePool = 0;

  const planPoolGranted = 0;
  const stakeholderPoolGranted = 0;

  const stakeholders: Stakeholder[] = [];

  const isTerminatedDatePassed = useMemo(() => {
    return plan?.terminationDate
      ? new Date(plan.terminationDate).getTime() < new Date().getTime()
      : false;
  }, [plan?.terminationDate]);

  return (
    <>
      <tr
        className={twMerge(
          'h-[72px] w-full border-b-[1px] border-b-gray-100 bg-white transition-colors',
          isSelected && 'bg-brand-25',
        )}
      >
        <td className="overflow-hidden p-4">
          <span
            className={twMerge(
              'max-w-[100px] truncate text-sm text-gray-700',
              !planDilutedShares && 'text-gray-300',
            )}
          >
            {planDilutedShares || '-'}
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span className="max-w-[100px] truncate text-sm text-gray-700">
            <span
              className={twMerge(
                'max-w-[100px] truncate text-sm text-gray-700',
                !planAvailablePool && 'text-gray-300',
              )}
            >
              {planAvailablePool || '-'}
            </span>
          </span>
        </td>
        <td className="overflow-hidden p-4">
          <span className="max-w-[100px] truncate text-sm text-gray-700">
            <span
              className={twMerge(
                'max-w-[100px] truncate text-sm text-gray-700',
                !planPoolGranted && 'text-gray-300',
              )}
            >
              {planPoolGranted || '-'}
            </span>
          </span>
        </td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="p-4 text-sm text-gray-300">-</td>
        <td className="relative">
          <PoolPlansPopover
            id={plan.id}
            isTerminated={isTerminatedDatePassed}
            onDeleteClick={onDeletePlanClick}
            onEditClick={onEditPlanClick}
            onEditTerminationDateClick={onEditPlanTerminationDateClick}
            onViewClick={onViewPlanClick}
            text="Plan"
          />
        </td>
      </tr>
      {isSelected && stakeholders && (
        <>
          {stakeholders.map((stakeholder) => (
            <tr className="h-[72px] w-full bg-white" key={stakeholder.id}>
              <td className="p-4 text-sm text-gray-700">{stakeholderDilutedShares}</td>
              <td className="overflow-hidden p-4">
                <span className="max-w-[100px] truncate text-sm text-gray-700">
                  {stakeholderAvailablePool}
                </span>
              </td>
              <td className="overflow-hidden p-4">
                <span className="max-w-[100px] truncate text-sm text-gray-700">
                  {stakeholderPoolGranted}
                </span>
              </td>
              <td className="p-4 text-sm text-gray-300">-</td>
              <td className="p-4 text-sm text-gray-300">-</td>
              <td className="p-4 text-sm text-gray-300">-</td>
              <td className="p-4 text-sm text-gray-300">-</td>
              <td className="p-4 text-sm text-gray-300">-</td>
              <td className="p-4 text-sm text-gray-300">-</td>
              <td className="p-4 text-sm text-gray-300">-</td>
              <td className="relative"></td>
            </tr>
          ))}
        </>
      )}
    </>
  );
};
