import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { PaginationArrowIcon, ThreeDotsIcon } from '../../assets/icons';
import { StringKey } from '../../lang';
import { AppFormattedMessage } from '../AppFormattedMessage';
import Button from '../Button';

type PaginationControllerItemProps = {
  children: ReactNode;
  isSelected?: boolean;
  className?: string;
  onClick?: () => void;
  isInactive?: boolean;
};

const paginate = (totalPages: number, currentPage: number) => {
  const pages: (number | string)[] = [];
  const maxVisiblePages = 7;
  if (totalPages <= maxVisiblePages) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i);
    }
  } else {
    const leftEdge = 1;
    const rightEdge = totalPages;
    const leftRange = [1, 2, 3];
    const rightRange = [totalPages - 2, totalPages - 1, totalPages];

    if (currentPage <= 3) {
      pages.push(...leftRange);
      pages.push('...');
      pages.push(...rightRange.slice(-3));
    } else if (currentPage >= totalPages - 2) {
      pages.push(leftEdge);
      pages.push('...');
      pages.push(...rightRange);
    } else {
      pages.push(leftEdge);
      pages.push('...');
      pages.push(currentPage - 1);
      pages.push(currentPage);
      pages.push(currentPage + 1);
      pages.push('...');
      pages.push(rightEdge);
    }
  }

  return pages;
};

export const PaginationControllerItem: FC<PaginationControllerItemProps> = ({
  children,
  isSelected = false,
  className,
  onClick,
  isInactive = false,
}) => (
  <div
    className={twMerge(
      'flex h-10 min-w-10 shrink-0 cursor-pointer items-center justify-center transition-colors',
      isSelected && 'bg-brand-25',
      !isInactive && 'hover:bg-gray-100',
      className,
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

type PaginationControllerProps = {
  totalPages: number;
  currentPage: number;
  onClick: (pageNumber: number) => void;
  className?: string;
  itemClassName?: string;
};
export const PaginationController: FC<PaginationControllerProps> = ({
  totalPages,
  onClick,
  currentPage,
  className,
  itemClassName,
}) => {
  if (totalPages < currentPage || totalPages <= 1) return null;

  const pages = paginate(totalPages, currentPage);
  const handlePageChange = (currentPage: number) => {
    if (currentPage < 1) return;
    if (currentPage > totalPages) return;
    onClick?.(currentPage);
  };

  return (
    <div
      className={twMerge(
        'flex h-10 w-full divide-gray-200 self-end overflow-hidden rounded border-gray-200 lg:w-fit lg:divide-x-[1px] lg:border lg:shadow-xs',
        className,
      )}
    >
      <Button
        className="flex items-center gap-2 px-4 max-lg:size-9 max-lg:rounded-lg max-lg:border"
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
        styleType="NONE"
      >
        <PaginationArrowIcon iconColor="#344054" />
        <span className="text-sm font-[550] text-gray-800 max-lg:hidden">
          <AppFormattedMessage id={StringKey.PREVIOUS} />
        </span>
      </Button>
      <div className="flex divide-x-[1px] divide-gray-200 max-lg:hidden">
        {pages.map((page, i) =>
          typeof page === 'number' ? (
            <PaginationControllerItem
              className={itemClassName}
              isSelected={currentPage === page}
              key={page}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </PaginationControllerItem>
          ) : (
            <PaginationControllerItem className={itemClassName} isInactive key={`${page}_${i}`}>
              <ThreeDotsIcon />
            </PaginationControllerItem>
          ),
        )}
      </div>

      <div className="flex h-full w-full items-center justify-center lg:hidden">
        <span className="text-nowrap text-xs font-[450] text-gray-700">
          Page {currentPage} of {totalPages}
        </span>
      </div>
      <Button
        className="flex items-center gap-2 px-4 max-lg:size-9 max-lg:rounded-lg max-lg:border"
        disabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
        styleType="NONE"
      >
        <span className="text-sm font-[550] text-gray-800 max-lg:hidden">
          <AppFormattedMessage id={StringKey.NEXT} />
        </span>
        <PaginationArrowIcon className="rotate-180" iconColor="#344054" />
      </Button>
    </div>
  );
};
